import { gql, useMutation } from '@apollo/client';

export const UPSERT_WHITELABEL_CONFIG = gql`
  mutation UpsertWhitelabelConfig($primaryColor: String!, $secondaryColor: String!) {
    upsertWhitelabelConfig(primaryColor: $primaryColor, secondaryColor: $secondaryColor) {
      id
      primaryColor
      secondaryColor
    }
  }
`;

export interface WhitelabelConfig {
  id: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface WhitelabelConfigUpsertInput {
  primaryColor: string;
  secondaryColor: string;
}

export interface WhitelabelConfigUpsertResult {
  upsertWhitelabelConfig: WhitelabelConfig;
}

export function useWhitelabelConfigUpsertMutation() {
  return useMutation<WhitelabelConfigUpsertResult, WhitelabelConfigUpsertInput>(UPSERT_WHITELABEL_CONFIG, {
    refetchQueries: ['GetUser'],
    context: {
      headers: {
        'apollo-require-preflight': true, // Ensures the mutation process is handled correctly
      },
    },
  });
}


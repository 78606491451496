import { gql, useMutation } from '@apollo/client';
import { PROCESSING_CONSENT_FIELDS, ProcessingConsent } from './useAddProcessingConsentMutation';

// Define the GraphQL mutation
const WITHDRAW_PROCESSING_CONSENT = gql`
  ${PROCESSING_CONSENT_FIELDS}
  mutation WithdrawProcessingConsent($id: String!, $withdrawal: Boolean!) {
    withdrawProcessingConsent(id: $id, withdrawal: $withdrawal) {
      ...ProcessingConsentFields
    }
  }
`;

// TypeScript interfaces for input and result
export interface WithdrawProcessingConsentInput {
  id: string;
  withdrawal: boolean;
}

export interface WithdrawProcessingConsentResult {
  // Define the structure based on the expected response
  // For example:
  withdrawProcessingConsent: ProcessingConsent;
}

// React Hook
export function useWithdrawProcessingConsentMutation() {
  return useMutation<WithdrawProcessingConsentResult, WithdrawProcessingConsentInput>(WITHDRAW_PROCESSING_CONSENT, {
    // refetchQueries: ['GetUser'],
    update: (cache, { data }) => {
      cache.writeFragment({
        id: `ProcessingConsent:${data?.withdrawProcessingConsent.id}`,
        fragment: PROCESSING_CONSENT_FIELDS,
        data: data?.withdrawProcessingConsent,
      });
    },
  });
}

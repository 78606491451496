import { useSnackbar } from '@app/hooks/useSnackbar';
import { Button, NotificationModal } from '@components/ui';
import { useUserEndTrialMutation } from '@dieterApi/user/useUserEndTrialMutation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function TrialPanel() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className="mt-5 bg-gray-200 p-8 rounded-md flex gap-8 flex-col sm:flex-row">
      <span className="text-black">{t('components.block.OutputView.TrialPanel.header')}</span>
      <Button onClick={() => setOpen(true)}>{t('components.block.OutputView.TrialPanel.button')}</Button>
      <QuitTrialPeriod open={open} onClose={() => setOpen(false)} />
    </div>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
}

function QuitTrialPeriod({ open, onClose }: Props) {
  const { t } = useTranslation();
  const [endTrial, { loading }] = useUserEndTrialMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    endTrial()
      .then(() => {
        enqueueSnackbar(t('components.block.OutputView.QuitTrialPeriodModal.success'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('components.block.OutputView.QuitTrialPeriodModal.error'), { variant: 'error' });
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <NotificationModal open={open} onClose={onClose}>
      <div className="p-12 flex flex-col gap-4">
        <h1 className="text-2xl font-bold">{t('components.block.OutputView.QuitTrialPeriodModal.header')}</h1>
        <p className="text-black">{t('components.block.OutputView.QuitTrialPeriodModal.p1')}</p>
        <p>{t('components.block.OutputView.QuitTrialPeriodModal.p2')}</p>
        <div className="flex gap-4">
          <button onClick={onClose} className="text-primary-root hover:text-primary-800 underline">
            {t('components.ui.alert_dialog.actions.cancel')}
          </button>
          <Button spinner={loading} onClick={handleClick}>
            {t('components.block.OutputView.QuitTrialPeriodModal.button')}
          </Button>
        </div>
      </div>
    </NotificationModal>
  );
}

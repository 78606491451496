import { Button } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import './route-glossary.sass';

interface GlossaryParams {
  glossaryItem: string | undefined;
}

export default function Glossary() {
  const { t } = useTranslation();
  const history = useHistory();

  const { glossaryItem } = useParams<GlossaryParams>();
  return (
    <div className="2xl:container">
      <div className="dtRouteGlossary">
        <h3>{t('route.glossary.heading', 'Hier entsteht in Kürze ein wunderschönes Glossar zum Thema')}</h3>
        <h2>{glossaryItem}</h2>
        <Button onClick={() => history.goBack()}>{t('common.back', 'Zurück')}</Button>
      </div>
    </div>
  );
}

import { gql, useMutation } from '@apollo/client';
import { USER_FIELDS, User } from './useUserQuery';

const CONFIRM_USER = gql`
  ${USER_FIELDS}
  mutation ConfirmUser($token: String!) {
    confirmUser(token: $token) {
      ...UserFields
      token
    }
  }
`;

export interface ConfirmUserResult {
  confirmUser: User;
}

export interface ConfirmUserInput {
  token: string;
}

export function useUserConfirmMutation() {
  return useMutation<ConfirmUserResult, ConfirmUserInput>(CONFIRM_USER, {
    fetchPolicy: 'no-cache',
  });
}

import { useUser } from '@app/context/UserContext';
import { useDashboard } from '@app/routes/Dashboard/useDashboard';
import { ApplicationType, UserTopicApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import { Menu, MenuItem, Tooltip } from '@mui/material';

import { useState } from 'react';
import { PartnerData } from './PartnerListEntry';
import { PartnerQuickSelectModal } from './PartnerQuickSelectModal';
import { ContractType } from './typesAndEnums';

interface AddPartnerMenuProps {
  topic: UserTopicApplicationsQuestionnaires;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  setNewEntry: (newEntry: PartnerData) => void;
}

export function AddPartnerMenu({ topic, anchorEl, handleClose, setNewEntry }: AddPartnerMenuProps) {
  const { user } = useUser();
  const open = Boolean(anchorEl);
  const dashboard = useDashboard();
  const [quickSelectOpen, setQuickSelectOpen] = useState<boolean>(false);
  const quickApp = topic.applications.find((app) => app.type === ApplicationType.ListEntryLong);

  const handleClick = () => {
    quickApp && dashboard.create(quickApp.id);
  };

  const handleManualClick = () => {
    user && setNewEntry({ partnerName: '', contractType: ContractType.AVV, index: 'MANUAL' });
  };

  return (
    <>
      <Menu
        className="dtAddPartnerMenu"
        anchorEl={anchorEl}
        id="add-partner-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          title={'Wähle aus einer Liste der häufigsten Dienste aus'}
          placement="left"
        >
          <MenuItem onClick={() => setQuickSelectOpen(true)}>Schnellauswahl</MenuItem>
        </Tooltip>
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          title={'Dieter hilft dir bei der Wahl des nötigen Vertrags'}
          placement="left"
        >
          <MenuItem onClick={handleClick}>Geführter Dialog</MenuItem>
        </Tooltip>
        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={'Du weisst genau was du tust'} placement="left">
          <MenuItem onClick={handleManualClick}>Manueller Eintrag</MenuItem>
        </Tooltip>
      </Menu>
      <PartnerQuickSelectModal open={quickSelectOpen} onClose={() => setQuickSelectOpen(false)} />
    </>
  );
}

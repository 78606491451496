import { useTasks } from '@app/context/TaskContext';
import { MarketingTile } from '@components/ui/MarketingTile/MarketingTile';
import { UserTopicStatusApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import { Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';
import { TaskItem } from '../../Tasks/TaskItem';
import { TaskWithApp } from '../../Tasks/TaskList';
import { useDashboard } from '../../useDashboard';
import { FormItem } from '../Forms/FormItem';

interface Props {
  topic: UserTopicStatusApplicationsQuestionnaires;
  isLocked?: boolean;
}

export function TopicTaskList({ topic, isLocked }: Props) {
  const tasks = useTasks();
  const dashboard = useDashboard();
  const isXL = useMediaQuery('(min-width: 1280px)');

  const isExperimental = topic.id === 'topic-experimental';

  const dummyTasks = [0, 1, 2].map((i) => ({
    id: 'dummy' + i,
  })) as TaskWithApp[];

  const filteredTasks = !tasks?.length ? dummyTasks : tasks?.filter((t) => t.topic.id === topic?.id && !t.isTopicFocus);

  return (
    <div className="grid-cols-1 xl:grid-cols-2 grid gap-9">
      {!isExperimental
        ? filteredTasks.map((task, i, tarr) =>
            isXL ? (
              <React.Fragment key={task.id}>
                {task.id.startsWith('dummy') ? (
                  <Skeleton key={task.id} height={'190px'} variant="rectangular" />
                ) : (
                  <TaskItem key={task.id} task={task} indentedLeft={!(i % 2)} indentedRight={!!(i % 2)} />
                )}
                {(!!(i % 2) || i + 1 == tarr.length) && (
                  <>
                    <div
                      key={task.id + 'B'}
                      id={`portal-container-${task.id}`}
                      className="col-span-full hidden -mt-9"
                    />
                    {!!tarr[i - 1]?.id && (
                      <div
                        key={task.id + 'A'}
                        id={`portal-container-${tarr[i - 1].id}`}
                        className="col-span-full hidden -mt-9"
                      />
                    )}
                  </>
                )}
              </React.Fragment>
            ) : (
              <div key={task.id}>
                {task.id.startsWith('dummy') ? (
                  <Skeleton key={task.id} height={'190px'} variant="rectangular" />
                ) : (
                  <TaskItem task={task} />
                )}
              </div>
            )
          )
        : topic.applications.map((app) => <FormItem key={app.id} app={app} dashboard={dashboard} index={0} />)}
      {isLocked && <MarketingTile topic={topic} />}
    </div>
  );
}

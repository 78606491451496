import { gql, useMutation } from '@apollo/client';
import { SCAN_RESULT_FIELDS, ScanResultData } from './useScanResultQuery';

// Define your GraphQL mutation
const CREATE_SCAN_RESULT = gql`
  ${SCAN_RESULT_FIELDS}
  mutation CreateScanResult($startUrl: String!, $dpsUrl: String, $force: Boolean) {
    createScanResult(startUrl: $startUrl, dpsUrl: $dpsUrl, force: $force) {
      ...ScanResultFields
    }
  }
`;

// TypeScript interfaces for input and response
export interface CreateScanResultInput {
  startUrl: string;
  dpsUrl?: string;
  force?: boolean;
}

export interface CreateScanResultData {
  createScanResult: ScanResultData;
}

// Custom hook for the mutation
export function useCreateScanResultMutation() {
  return useMutation<CreateScanResultData, CreateScanResultInput>(CREATE_SCAN_RESULT, {
    refetchQueries: ['GetUser'],
  });
}

import AllowedValueTypes from "@legalosApi/types/AllowedValueTypes";
import { IQuestion } from "@legalosApi/types/IQuestion";
import QuestionType from "@legalosApi/types/QuestionType";
import { Currency } from "./Currency";
import { DateInput } from "./DateInput";
import { MultilineText } from "./MultilineText";
import { SelectSome } from "./SelectSome";
import { Text } from "./Text";

export interface Props<T = AllowedValueTypes> {
  disabled?: boolean;
  onAction: () => void;
  onChange: (value: AllowedValueTypes) => void;
  question: IQuestion;
  value: T;
  placeholder?: string;
}

export function Input(props: Props) {
  switch (props.question.type) {
    case QuestionType.CURRENCY:
      return <Currency {...(props as any)} />;
    case QuestionType.DATE:
      return <DateInput {...(props as any)} />;
    case QuestionType.MULTILINE_TEXT:
      return <MultilineText {...(props as any)} />;
    case QuestionType.SELECT_SOME:
      return <SelectSome {...(props as any)} />;
    case QuestionType.TEXT:
      return <Text {...(props as any)} />;
    case QuestionType.FLOAT:
      return <Currency unit="" {...(props as any)} />;
    default:
      return <div>Unsupported input type</div>;
  }
}

import { gql } from "@apollo/client";

export const QUESTIONNAIRE_APP = gql`
  query questionnaireApp($input: QuestionnaireAppInput) {
    questionnaireApp(input: $input) {
      _id
      title
      language
      published
    }
  }
`;

import { gql, useMutation } from '@apollo/client';
import { QUESTIONNAIRE_FIELDS, UserQuestionnaire } from '@dieterApi/user/useUserQuery';

const DUPLICATE_QUESTIONNAIRE = gql`
  ${QUESTIONNAIRE_FIELDS}
  mutation DuplicateQuestionnaire($localQuestionnaireId: String!, $companyId: String!) {
    duplicateQuestionnaire(localQuestionnaireId: $localQuestionnaireId, companyId: $companyId) {
      ...UserQuestionnaireFields
      progressHash
    }
  }
`;

export interface DuplicateQuestionnaireInput {
  localQuestionnaireId: string;
  companyId: string;
}

export interface DuplicateQuestionnaireResult {
  duplicateQuestionnaire: UserQuestionnaire & { progressHash: string };
}

export function useDuplicateQuestionnaireMutation() {
  return useMutation<DuplicateQuestionnaireResult, DuplicateQuestionnaireInput>(DUPLICATE_QUESTIONNAIRE, {
    refetchQueries: ['GetUser'],
  });
}

import { gql } from '@apollo/client';
import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';

export const QUESTION_LIST_INSERT_ELEMENT = gql`
  mutation questionlistInsertElement($input: QuestionListInsertElementInput) {
    questionListInsertElement(input: $input) {
      ...QuestionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

import { UserFile, UserQuestionnaireApplicationTopic } from '@dieterApi/user/useUserQuery';
import { MUIDataTableMeta } from 'mui-datatables';

export enum ContractType {
  AVV = 'AVV',
  JC = 'JC',
  SCC = 'SCC',
}

export enum NoContractType {
  DataTransfer = 'Data Transfer',
  None = 'none',
}

export type ListEntryContractType = ContractType | NoContractType;

export const validContractTypes: ListEntryContractType[] = [ContractType.AVV, ContractType.JC, ContractType.SCC];

export const contractTypeMapping = {
  [ContractType.AVV]: 'Auftragsverarbeitungsvertrag (AVV)',
  [ContractType.JC]: 'Joint Controlling Agreement (JC)',
  [ContractType.SCC]: 'Standardvertragsklauseln (SCC)',
  [NoContractType.DataTransfer]: 'kein Vertrag nötig (aber echte Datenweitergabe)',
  [NoContractType.None]: 'kein Vertrag nötig',
};

export enum ContractDirection {
  Contractor = 'Ich bin der Auftragnehmer',
  Client = 'Ich bin der Auftraggeber',
}

export const contractDirectionMapping = {
  [ContractDirection.Client]: 'Auftragnehmer',
  [ContractDirection.Contractor]: 'Auftraggeber',
};

type UserValueIndex = string;

export interface PartnerTableMeta extends MUIDataTableMeta {
  rowData: [
    string, // 0 - partnerName
    ListEntryContractType | null, // 1 -contractType
    UserValueIndex | null, // 2 - index
    UserFile | null, // 3 -contractFile
    UserQuestionnaireApplicationTopic | null, // 4- contract
    UserFile | null, // 5- toms
    ContractDirection | null, // 6 - direction
    null,
    null,
    boolean, // 9 - contractLocked
    boolean, // 10 -contractSigned
    UserFile, // 11 - signedContractFile
  ];
}

import { useMessages } from '@app/context/MessageContext';
import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { NavigationSection } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { calculateTopicStatus } from '@app/utils/topicStatus';
import { ApplicationStatus } from '@dieterApi/user/useUserQuery';
import { Box, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import Confetti from './Confetti';
import './route-info.sass';

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

export function ProgressBadge() {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.info.progressbadge' });
  const { navigation, setNavigation, navigateDashboard } = useNavigation();
  const { tutorial } = useMessages();
  const { user } = useUser();
  const tasks = useTasks();
  const [shouldFire, setShouldFire] = useState(false);
  const relevantTopics = user?.topics.filter((topic) => !topic.tags.map((t) => t.tag).includes('experimental'));
  const totalTopics = relevantTopics?.length || 0;
  const completedTopics = user
    ? user.topics.filter(
        (topic) => calculateTopicStatus(topic, undefined, user.userValues, tasks) === ApplicationStatus.COMPLETED
      ).length
    : 0;
  const inProgressTopics = user
    ? user.topics.filter(
        (topic) => calculateTopicStatus(topic, undefined, user.userValues, tasks) === ApplicationStatus.IN_PROGRESS
      ).length
    : 0;

  const progress = (completedTopics + 0.5 * inProgressTopics) / totalTopics;
  const shouldAnimate = navigation.animateBadge && tutorial.wasOpen;

  const { number } = useSpring({
    // reset: true,
    // reverse: flip,
    from: { number: shouldAnimate ? 0 : progress * 100 },
    to: { number: progress * 100 },
    config: {
      duration: 2000,
      clamp: true,
    },
    onRest: () => {
      setNavigation((nav) => void (nav.animateBadge = false));
      setShouldFire(true);
    },
  });

  const handleClick = () => {
    navigateDashboard(NavigationSection.Status);
  };

  useEffect(() => {
    setNavigation((nav) => void (nav.progress = progress));
  }, [progress]);

  const showStatus = navigation.activeSection === NavigationSection.Status;
  return (
    <div
      className={cx(
        'dtInfo__progressBadge flex flex-col gap-2 w-full',
        {
          showStatus,
        },
        showStatus && 'bg-primary-200'
      )}
      onClick={handleClick}
      data-testid="badge-status-button"
    >
      <div>{t('header', 'Dein DSGVO-Status')}</div>
      <div className="flex-1 progressContainer">
        {/* <ProgressBar min={0} max={100} now={60} /> */}
        <AnimatedLinearProgress value={number} />
      </div>
      <Confetti shouldFire={shouldFire} />
    </div>
  );
}

const AnimatedLinearProgress = animated(({ value }) => <LinearProgressWithLabel value={value} />);

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{ height: '15px' }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

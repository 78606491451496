import { useNavigation } from '@app/hooks/useNavigation';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Collapse } from '@mui/material';
import cx from 'classnames';
import { marked } from 'marked';
import ReactDOMServer from 'react-dom/server';

import cheerio from 'cheerio';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import './ui-markdown.sass';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}

export function Markdown({ className, text, ...props }: Props) {
  const { t } = useTranslation();
  let visibleParagraphs: string[];
  let hiddenParagraphs: string[] = [];
  const {
    navigation: { isMobile },
  } = useNavigation();
  const [isOpen, setOpen] = useState(false);
  const cumulativeSum = (sum: number) => (value: number) => (sum += value);
  const THRESHOLD_CHARS = 400;
  const textSplit = text.split(/\r?\n/).filter((line) => line.trim() !== '');

  // const splitIndex = textSplit
  //   .map((x) => x.length)
  //   .map(cumulativeSum(0))
  //   .findIndex((x) => x > THRESHOLD_CHARS);
  const splitIndex = 0;

  const html = textSplit.map((line) => markedWithExternalLinks(line));

  if (splitIndex > 0) {
    visibleParagraphs = html.slice(0, splitIndex);
    hiddenParagraphs = html.slice(splitIndex);
  } else {
    visibleParagraphs = html;
  }

  const visibleParagraphsComponent = visibleParagraphs.map((p, index) => {
    return (
      <div {...props} key={index} className={cx('dtMarkdown', className)} dangerouslySetInnerHTML={{ __html: p }}></div>
    );
  });

  const hiddenParagraphsComponent = hiddenParagraphs.map((p, index) => {
    return (
      <div {...props} key={index} className={cx('dtMarkdown', className)} dangerouslySetInnerHTML={{ __html: p }}></div>
    );
  });

  return (
    <>
      {visibleParagraphsComponent}
      {isMobile && hiddenParagraphs.length > 0 && !isOpen && (
        <button className="dtUiMarkdownButton" onClick={() => setOpen(true)}>
          {t('components.ui.markdown.continue')}
        </button>
      )}
      {isMobile ? <Collapse in={isOpen}>{hiddenParagraphsComponent}</Collapse> : hiddenParagraphsComponent}
    </>
  );
}

const markedWithExternalLinks = (text: string) => {
  // This function is a workaround for the marked library not supporting the target attribute for links
  // Whenever a link to an external website is created, the target attribute is added
  const markedText = marked(text);
  const $ = cheerio.load(markedText);
  const iconString = ReactDOMServer.renderToString(<OpenInNewIcon className="external-link-icon" fontSize="small" />);

  $('a[href^="http"]').attr('target', '_blank').attr('rel', 'noopener noreferrer').after(iconString);

  // on <ol> tags add the tailwind class "list-decimal"
  $('ol').addClass('list-decimal list-outside ml-5');

  // on <ul> tags add the tailwind class "list-disc"
  $('ul').addClass('list-disc list-outside ml-5');

  // $('p').addClass('leading-loose');

  return $.html();
};

import { gql } from '@apollo/client';
export const QUESTIONNAIRE_DOWNLOAD_PDF = gql`
  mutation questionnaireDownloadPdf($questionnaireId: String!) {
    questionnaireDownloadPdf(questionnaireId: $questionnaireId) {
      mimeType
      fileName
      fileBase64
    }
  }
`;

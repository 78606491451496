import { gql } from '@apollo/client';
import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';

export const TOGGLE_CALCULATED_QUESTION_STATE = gql`
  mutation questionCalculatedStateUpdate($input: QuestionCalculatedStateUpdateInput) {
    questionCalculatedStateUpdate(input: $input) {
      ...QuestionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

import {
  BannerVariants,
  ButtonDisplayLocation,
  UserFacingUpdateSettingInput,
} from '@app/api/usercentrics/updateSetting';
import { CategorySlug } from '@app/api/usercentrics/updateTechnology';
import { useUser } from '@app/context/UserContext';
import { useUserCentrics } from '@app/hooks/useUsercentrics';
import { Button, Input, Spinner } from '@components/ui';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Button as MuiButton,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AddTemplateModal } from './AddTemplateModal';

export function ConsentManagement() {
  const { t } = useTranslation();
  const { user } = useUser();

  const {
    setting,
    url,
    templates,
    setUrl,
    scanInProgress,
    scan,
    error,
    removeTechnology,
    updateSetting,
    changeCategory,
    advancedError,
    updateInProgress,
  } = useUserCentrics();

  const slug2Name: Record<CategorySlug, string> = {
    essential: t('dashboard.consentmanagement.slugs.essential'),
    functional: t('dashboard.consentmanagement.slugs.functional'),
    marketing: t('dashboard.consentmanagement.slugs.marketing'),
  };
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [advancedConfig, setAdvancedConfig] = useState<
    UserFacingUpdateSettingInput & { smartDataProtection: boolean; brandColor?: string }
  >({
    imprintUrl: setting?.imprintUrl || '',
    privacyPolicyUrl: setting?.privacyPolicyUrl || '',
    googleConsentMode: setting?.googleConsentMode || false,
    buttonDisplayLocation: setting?.buttonDisplayLocation || 'bl',
    smartDataProtection: user?.getValue('UC_SMART_DATA_PROTECTION') === 'false',
    firstLayer: {
      variant: 'BANNER',
    },
    brandColor: setting?.customization?.color?.primary,
    customization: setting?.customization,
  });

  // update advanced config, when setting changes
  useEffect(() => {
    if (!setting) return;
    setAdvancedConfig({
      imprintUrl: setting.imprintUrl,
      privacyPolicyUrl: setting.privacyPolicyUrl,
      googleConsentMode: setting.googleConsentMode,
      buttonDisplayLocation: setting.buttonDisplayLocation,
      smartDataProtection: user?.getValue('UC_SMART_DATA_PROTECTION') === 'true',
      firstLayer: {
        variant: setting.firstLayer.variant,
      },
      customization: setting.customization,
      brandColor: setting.customization?.color?.primary,
    });
  }, [setting]);

  const handleAdvancedChange = (prop: string) => (event: any) => {
    setAdvancedConfig({
      ...advancedConfig,
      [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    });
  };

  // Assuming you have a method to handle category changes
  const handleCategoryChange = (templateId: string, newCategory: CategorySlug) => {
    // Update logic for changing category based on templateId
    // console.log(`Updated ${templateId} to category ${newCategory}`);
    changeCategory(templateId, newCategory);
  };

  const saveAdvancedConfig = () => {
    const brandColor = stripHashtags(advancedConfig.brandColor);

    updateSetting({
      ...advancedConfig,
      customization: {
        color: {
          primary: brandColor,
          acceptBtnBackground: brandColor,
          denyBtnBackground: brandColor,
        },
      },
    });
  };

  const basicSnippet = `<!-- CMP Banner Script -->
  <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="${setting?.settingsId}"></script>`;
  const sdpSnippet = `

<!-- Smart Data Protector -->
  <script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>`;
  const gcpSnippet = `

<!-- Define dataLayer and the gtag function. -->
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
    dataLayer.push(arguments);
    }
    // Set default consent to 'denied' // Determine actual values based on your own requirements
    gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    wait_for_update: 500,
    // milliseconds to wait for update from the CMP
    });
  </script>`;
  const sdpUnblockSnippet = `

<!-- Deactivate Blocking of GTM by Smart Data Protector -->
  <script>uc.deactivateBlocking([
    'BJ59EidsWQ', // GTM template ID
    ]);
  </script>`;
  const gtmSnippet = `

<!-- Google Tag Manager -->
  <script type="text/javascript">
    (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-XXXXXXXX'); //replace GTM-XXXX with Google Tag Manager ID
  </script>`;
  const fullSnippet = `${basicSnippet}${advancedConfig.smartDataProtection ? sdpSnippet : ''}${
    advancedConfig.googleConsentMode ? gcpSnippet : ''
  }${advancedConfig.smartDataProtection && advancedConfig.googleConsentMode ? sdpUnblockSnippet : ''}${
    advancedConfig.googleConsentMode ? gtmSnippet : ''
  }`;

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    }
  }, [fullSnippet]); // Dependency array includes `fullSnippet` to re-apply effect when it changes

  const checkoutUrl = new URL('/api/checkout', window.location.origin);
  checkoutUrl.searchParams.append('usercentricsonly', 'true');
  checkoutUrl.searchParams.append('interval', 'month');
  checkoutUrl.searchParams.append('email', user?.email || '');
  checkoutUrl.searchParams.append('forcompany', user?.company.id || '');
  const stripeCustomerId = user?.companies.find((c) => c.isPrincipal)?.stripeCustomerId;
  stripeCustomerId && checkoutUrl.searchParams.append('stripe_customer_id', stripeCustomerId);

  return (
    <div className="flex flex-col gap-5">
      <AddTemplateModal open={addModalOpen} onClose={() => setAddModalOpen(false)} />
      <div className="text-base font-medium">{t('dashboard.consentmanagement.title')}</div>
      <p>{t('dashboard.consentmanagement.description')}</p>
      {!user?.hasFeature('Usercentrics') && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-4">
          <p className="font-medium">{t('dashboard.consentmanagement.notavailable.title')}</p>
          <p>{t('dashboard.consentmanagement.notavailable.description')}</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-primary-root hover:text-primary-700"
            href={checkoutUrl.toString()}
          >
            {t('dashboard.consentmanagement.notavailable.cta')}
          </a>
        </div>
      )}
      {error && <div className="text-red-500">{error}</div>}
      <div className="flex flex-col gap-8">
        <div className="flex gap-4 w-full flex-col md:flex-row">
          <div className="flex-grow">
            <Input
              textSize="small"
              placeholder={t('dashboard.consentmanagement.urlinput')}
              onKeyDown={(e) => {
                e.key === 'Enter' && scan();
              }}
              value={url}
              disabled={!user?.hasFeature('Usercentrics')}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <MuiButton className="w-full md:w-fit" onClick={scan} disabled={!user?.hasFeature('Usercentrics')}>
            Service Scan
          </MuiButton>
        </div>

        <div className="">
          <p className="font-medium">{t('dashboard.consentmanagement.tableheader')}:</p>

          {scanInProgress ? (
            <Spinner />
          ) : setting?.consentTemplates.length ? (
            <table className="table-auto w-full text-gray-600">
              <tbody>
                {setting?.consentTemplates?.map((t, idx) => (
                  <tr key={idx} className="border-b border-gray-200">
                    <td className="px-4 py-2 bg-gray-50">{t.template.dataProcessor}</td>
                    <td className="px-4 py-2 bg-gray-50 text-sm text-gray-400">{t.templateId}</td>
                    <td className="px-4 py-2 bg-gray-50">
                      <Select
                        labelId={`category-select-label-${idx}`}
                        id={`category-select-${idx}`}
                        value={t.categorySlug}
                        onChange={(event) => handleCategoryChange(t.templateId, event.target.value as CategorySlug)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                        fullWidth
                      >
                        {Object.entries(slug2Name).map(([key, name]) => (
                          <MenuItem key={key} value={key}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </td>
                    <td className="px-4 py-2 bg-gray-50 text-right">
                      {/* We never allow removing the usercentrics template */}
                      {t.templateId !== 'H1Vl5NidjWX' && (
                        <Tooltip title="Entfernen">
                          <IconButton onClick={() => removeTechnology(t.templateId)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t('dashboard.consentmanagement.none')}</p>
          )}
          <button
            onClick={() => setAddModalOpen(true)}
            className="underline text-primary-root hover:text-primary-700 mt-3"
          >
            {t('dashboard.consentmanagement.addservice')}
          </button>
        </div>
        {/* Advanced Configuration Section */}
        <Accordion expanded={isAdvancedOpen} onChange={() => setIsAdvancedOpen(!isAdvancedOpen)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p>{t('dashboard.consentmanagement.advancedconfig')}</p>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col gap-4">
              <TextField
                label={t('dashboard.consentmanagement.imprinturl')}
                variant="outlined"
                size="small"
                value={advancedConfig.imprintUrl}
                onChange={handleAdvancedChange('imprintUrl')}
              />
              <TextField
                label={t('dashboard.consentmanagement.policyurl')}
                size="small"
                variant="outlined"
                value={advancedConfig.privacyPolicyUrl}
                onChange={handleAdvancedChange('privacyPolicyUrl')}
              />

              <div className="flex gap-4 items-center">
                <input
                  type="color"
                  className="h-8 w-8 rounded-lg cursor-pointer"
                  value={ensureHashtag(advancedConfig.brandColor) || '#3ba1db'}
                  onChange={handleAdvancedChange('brandColor')}
                />
                <TextField
                  label={t('dashboard.consentmanagement.brandColor')}
                  size="small"
                  variant="outlined"
                  value={ensureHashtag(advancedConfig.brandColor) || '#3ba1db'}
                  onChange={handleAdvancedChange('brandColor')}
                />
              </div>
              <div className="flex items-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advancedConfig.smartDataProtection}
                      onChange={handleAdvancedChange('smartDataProtection')}
                    />
                  }
                  label="Smart Data Protector"
                />
                <Tooltip title={t('dashboard.consentmanagement.sdp')}>
                  <HelpCenterIcon fontSize="small" className="text-gray-300" />
                </Tooltip>
              </div>
              <div className="flex items-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advancedConfig.googleConsentMode}
                      onChange={handleAdvancedChange('googleConsentMode')}
                    />
                  }
                  label="Google Consent Mode"
                />
                <Tooltip title={t('dashboard.consentmanagement.gcm')}>
                  <HelpCenterIcon fontSize="small" className="text-gray-300" />
                </Tooltip>
              </div>

              <LayoutSelector
                onLayoutSelect={(layout) => setAdvancedConfig({ ...advancedConfig, firstLayer: { variant: layout } })}
                color={ensureHashtag(advancedConfig.brandColor) || '#3ba1db'}
                current={advancedConfig.firstLayer.variant}
              />
              <LocationSelector
                onLocationSelect={(location) =>
                  setAdvancedConfig({ ...advancedConfig, buttonDisplayLocation: location })
                }
                current={advancedConfig.buttonDisplayLocation}
                color={ensureHashtag(advancedConfig.brandColor) || '#3ba1db'}
              />
              {advancedError && <div className="text-red-500">{advancedError}</div>}
              <Button onClick={saveAdvancedConfig} spinner={updateInProgress}>
                {t('common.save')}
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
        {user?.hasFeature('Usercentrics') && (
          <div>
            {/* Code Snippet Section */}
            <div className="mt-4">
              <p className="mb-2">
                <Trans t={t} i18nKey="dashboard.consentmanagement.snippetdescription" values={{ header: '<header/>' }}>
                  Übertrage diesen Code-Abschnitt in den{' '}
                  <span className="font-mono text-sm text-primary-root">{'<header/>'}</span> deiner Webseite. Mehr
                  Informationen zur Integration findest du in der{' '}
                  <a
                    href="https://docs.usercentrics.com/#/browser-cmp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-1"
                  >
                    Usercentrics Dokumentation <OpenInNewIcon fontSize="inherit" className="text-gray-400" />
                  </a>
                </Trans>
                .
              </p>
              {advancedConfig.googleConsentMode && (
                <p className="bg-secondary-100 border-l-4 border-secondary-500 text-secondary-700 p-4 mt-4">
                  <Trans t={t} i18nKey="dashboard.consentmanagement.gcmwarning">
                    <span className="font-semibold">Du hast Google Consent Mode ausgewählt!</span> Bitte folge diesem{' '}
                    <a href="https://dieterfilestorage.blob.core.windows.net/public/Google_Consent_Mode_Implementation_Guide_Usercentrics.pdf">
                      Leitfaden
                    </a>{' '}
                    um sicherzustellen, dass Google Tag Manager korrekt konfiguriert ist.
                  </Trans>
                </p>
              )}
              <textarea
                ref={textareaRef}
                readOnly
                className="w-full h-fit p-4 bg-gray-100 text-gray-800 font-mono text-xs border border-gray-300 rounded-md shadow-sm focus:ring-primary-root focus:outline-none overflow-y-hidden"
                value={fullSnippet}
              />
            </div>
            {/* Important advice */}
            <div className="bg-secondary-100 border-l-4 border-secondary-500 text-secondary-700 p-4 mt-4">
              <p className="font-medium">{t('dashboard.consentmanagement.important')}:</p>
              {t('dashboard.consentmanagement.disclaimer')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const stripHashtags = (color?: string) => color?.replace('#', '');
const ensureHashtag = (color?: string) => (!color ? color : color?.startsWith('#') ? color : `#${color}`);

const LocationSelector = ({
  onLocationSelect,
  current,
  color,
}: {
  onLocationSelect: (location: ButtonDisplayLocation) => void;
  current?: ButtonDisplayLocation;
  color: string;
}) => {
  const { t } = useTranslation();

  const locations: { id: ButtonDisplayLocation; label: string; transform?: string }[] = [
    { id: 'bl', label: t('dashboard.consentmanagement.bl') },
    { id: 'br', label: t('dashboard.consentmanagement.br'), transform: 'scaleX(-1)' },
  ];

  const selectLocation = (location: ButtonDisplayLocation) => {
    onLocationSelect(location);
  };

  return (
    <div>
      <div className="mb-2 text-gray-500">{t('dashboard.consentmanagement.widget_position')}</div>

      <div className="grid grid-cols-2 gap-4">
        {locations.map((location) => (
          <div
            key={location.id}
            className={`p-4  ${current === location.id ? 'border-primary-root border-3' : 'border-gray-300 border-2'}
            rounded-lg cursor-pointer flex flex-col items-center justify-center w-full`}
            onClick={() => selectLocation(location.id)}
          >
            <div style={{ transform: location.transform }}>
              <IconButtonPosition color={color} />
            </div>
            {location.label}
          </div>
        ))}
      </div>
    </div>
  );
};
function IconButtonPosition({ color }: { color: string }) {
  return (
    <svg width="220px" height="140px" viewBox="0 0 220 140" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Icon/layout/button</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/layout/button">
          <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="220" height="140" rx="8"></rect>
          <g id="Wireframe" transform="translate(10.000000, 10.000000)" fill="#F7F7F7">
            <rect id="Rectangle-Copy" x="0" y="0" width="10" height="10" rx="5"></rect>
            <rect id="Rectangle-Copy-5" x="160" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-7" x="115" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-8" x="70" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-2" x="0" y="15" width="200" height="60"></rect>
            <rect id="Rectangle-Copy-3" x="0" y="79" width="98" height="41"></rect>
            <rect id="Rectangle-Copy-4" x="102" y="79" width="98" height="41"></rect>
          </g>
          <rect id="Rectangle" fill={color} x="10" y="115" width="15" height="15" rx="7.5"></rect>
          <rect id="Rectangle" stroke="#DEDEDE" strokeWidth="4" x="2" y="2" width="216" height="136" rx="8"></rect>
        </g>
      </g>
    </svg>
  );
}

function IconLayoutBanner({ color }: { color: string }) {
  return (
    <svg width="220px" height="140px" viewBox="0 0 220 140" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Icon/layout/banner</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/layout/banner">
          <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="220" height="140" rx="8"></rect>
          <g id="Wireframe" transform="translate(10.000000, 10.000000)" fill="#F7F7F7">
            <rect id="Rectangle-Copy" x="0" y="0" width="10" height="10" rx="5"></rect>
            <rect id="Rectangle-Copy-5" x="160" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-7" x="115" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-8" x="70" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-2" x="0" y="15" width="200" height="60"></rect>
            <rect id="Rectangle-Copy-3" x="0" y="79" width="98" height="41"></rect>
            <rect id="Rectangle-Copy-4" x="102" y="79" width="98" height="41"></rect>
          </g>
          <path
            d="M0,104 L220,104 L220,132 C220,136.418278 216.418278,140 212,140 L8,140 C3.581722,140 4.09379668e-15,136.418278 0,132 L0,104 L0,104 Z"
            id="Rectangle"
            fill={color}
          ></path>
          <rect id="Rectangle" stroke="#DEDEDE" strokeWidth="4" x="2" y="2" width="216" height="136" rx="8"></rect>
        </g>
      </g>
    </svg>
  );
}

function IconLayoutWall({ color }: { color: string }) {
  return (
    <svg width="220px" height="140px" viewBox="0 0 220 140" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Icon/layout/wall</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/layout/wall">
          <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="220" height="140" rx="8"></rect>
          <g id="Wireframe" transform="translate(10.000000, 10.000000)">
            <rect id="Rectangle-Copy" fill="#F5F8FC" x="0" y="0" width="10" height="10" rx="5"></rect>
            <rect id="Rectangle-Copy-5" fill="#F7F7F7" x="160" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-7" fill="#F7F7F7" x="115" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-8" fill="#F7F7F7" x="70" y="0" width="40" height="10"></rect>
            <rect id="Rectangle-Copy-2" fill="#F7F7F7" x="0" y="15" width="200" height="60"></rect>
            <rect id="Rectangle-Copy-3" fill="#F7F7F7" x="0" y="79" width="98" height="41"></rect>
            <rect id="Rectangle-Copy-4" fill="#F7F7F7" x="102" y="79" width="98" height="41"></rect>
          </g>
          <rect id="Rectangle" fill={color} x="80" y="35" width="60" height="70" rx="4"></rect>
          <rect id="Rectangle" stroke="#DEDEDE" strokeWidth="4" x="2" y="2" width="216" height="136" rx="8"></rect>
        </g>
      </g>
    </svg>
  );
}

interface LayoutOption {
  id: BannerVariants;
  label: string;
  IconComponent: ({ color }: { color: string }) => JSX.Element;
}

const LayoutSelector = ({
  onLayoutSelect,
  current,
  color,
}: {
  onLayoutSelect: (layout: BannerVariants) => void;
  color: string;
  current: BannerVariants;
}) => {
  const layouts: LayoutOption[] = [
    { id: 'BANNER', label: 'Banner', IconComponent: IconLayoutBanner },
    { id: 'WALL', label: 'Wall', IconComponent: IconLayoutWall },
  ];

  const selectLayout = (layout: LayoutOption) => {
    onLayoutSelect(layout.id);
  };

  return (
    <div>
      <div className="mb-2 text-gray-500">Layout</div>
      <div className="flex justify-around gap-4">
        {layouts.map((layout) => (
          <div
            key={layout.id}
            className={`p-4  ${current === layout.id ? 'border-primary-root border-3' : 'border-gray-300 border-2'}
            rounded-lg cursor-pointer flex flex-col items-center justify-center w-full`}
            onClick={() => selectLayout(layout)}
            role="button"
            tabIndex={0}
          >
            <layout.IconComponent color={color} />
            <div className="mt-2">{layout.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

import { useUser } from '@app/context/UserContext';
import { Spinner } from '@components/ui/Spinner/Spinner';
import InfoIcon from '@mui/icons-material/Info';
import { Divider, Tooltip } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useDashboard } from '../useDashboard';

const quickStartAppIds = [
  'app-dse-privacy-policy-create',
  'app-dse-imprint-create',
  'app-toms-create',
  'app-deletions-concept-create',
];

export function Quickstart() {
  const { user } = useUser();
  const { t } = useTranslation();
  const dashboard = useDashboard();

  const quickStartApps = user?.topics.flatMap((topic) =>
    topic.applications.filter((app) => quickStartAppIds.includes(app.id) && app.questionnaires?.length === 0)
  );

  const handleClick = (appId: string) => {
    dashboard.create(appId);
  };

  if (!quickStartApps || quickStartApps.length === 0 || user?.isReadOnly) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3 mb-2">
      <div className="flex flex-row justify-between items-center mb-2">
        <span className="text-gray-400 text-xl font-normal">{t('dashboard.info.quickstart.title')}</span>
        <Tooltip title="Springe direkt in einen Fragebogen und erstelle ein DSGVO-konformes Dokument">
          <InfoIcon className="text-gray-300 hover:text-gray-400" fontSize="small" />
        </Tooltip>
      </div>
      {quickStartApps?.map((app) => {
        const isBusy = dashboard.affects('create', app.id);
        return (
          <button
            key={app.id}
            className="bg-gray-200 w-full py-2 rounded-md hover:bg-secondary-root transition-all relative"
            onClick={() => handleClick(app.id)}
          >
            <Trans t={t} i18nKey={`dashboard.info.quickstart.label`} values={{ value: app.title }}>
              {app.title} erstellen
            </Trans>
            {isBusy && (
              <div className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
                <Spinner size="small" />
              </div>
            )}
          </button>
        );
      })}
      <Divider className="!mt-3" />
    </div>
  );
}

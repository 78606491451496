import { useUser } from '@app/context/UserContext';
import { useMFASetupMutation } from '@dieterApi/mfa/useMFASetupMutation';
import { useMFAToggleMutation } from '@dieterApi/mfa/useMFAToggleMutation';
import { Android as AndroidIcon, Apple as AppleIcon } from '@mui/icons-material';
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MFAVerifyInput } from './MFAVerify';

const apps = [
  {
    name: 'Google Authenticator',
    appleLink: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    androidLink: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  },
  {
    name: 'Microsoft Authenticator',
    appleLink: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
    androidLink: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
  },
  {
    name: 'TOTP Authenticator',
    appleLink: 'https://apps.apple.com/us/app/totp-authenticator-fast-2fa/id1404230533',
    androidLink: 'https://play.google.com/store/apps/details?id=com.authenticator.authservice2&hl=de&gl=US',
  },
];

export function MFASetup() {
  const { t } = useTranslation();
  const { user } = useUser();
  const [setupMFA, { data: setupData }] = useMFASetupMutation();
  const [toggleMFA, { data: toggleData }] = useMFAToggleMutation();

  const [enabled, setEnabled] = useState(user?.mfaEnabled || false);

  useEffect(() => {
    setupMFA();
  }, [setupMFA]);

  const handleToggle = () => {
    toggleMFA({ variables: { enable: !enabled } });
    setEnabled(!enabled);
  };

  return (
    <div className="dtRoutePassword flex flex-col justify-center items-center p-4 gap-8">
      <div className="dtRoutePassword__panel flex flex-col items-center gap-3">
        <Typography variant="h6" gutterBottom>
          {t('route.mfasetup.title')}
        </Typography>
        {setupData ? (
          <div className="flex flex-col items-center">
            <img src={setupData.setupMFA.url} alt="QR-Code für MFA-Setup" className="h-[250px] w-[250px]" />
            <span className="text-xs -mt-2">{setupData.setupMFA.secret}</span>
          </div>
        ) : (
          <Skeleton variant="rectangular" width={250} height={250} />
        )}
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={enabled} onChange={handleToggle} disabled={!enabled} />}
            label={enabled ? t('route.mfasetup.activated') : t('route.mfasetup.deactivated')}
          />
        </FormGroup>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {t('route.mfasetup.desc')}
        </Typography>
        {!enabled && <MFAVerifyInput mode="setup" onVerify={() => handleToggle()} />}
        <p className="font-semibold text-sm mt-6">{t('route.mfasetup.favapps')}</p>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {apps.map((app) => (
                <TableRow key={app.name}>
                  <TableCell>{app.name}</TableCell>
                  <TableCell>
                    <IconButton href={app.appleLink} target="_blank" aria-label="Apple Store Link">
                      <AppleIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton href={app.androidLink} target="_blank" aria-label="Android Market Link">
                      <AndroidIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="bg-secondary-100 border-l-4 border-secondary-500 text-secondary-700 p-4 mt-4">
          <strong>Info:</strong>
          <p>{t('route.mfasetup.info1')}</p>
          <p>{t('route.mfasetup.info2')}</p>
          <p>
            <Trans t={t} i18nKey={'route.mfasetup.info3'}>
              Wenn du den Zugriff auf die App verlieren solltest, kann der Account nur noch durch eine/n
              SupportmitarbeiterIn freigeschaltet werden. Bitte kontaktiere uns in diesem Fall im Chat oder unter
              <a href="mailto:info@dieter-datenschutz.de">info@dieter-datenschutz.de</a>.
            </Trans>
          </p>
        </div>
      </div>

      <div>
        <Link to="/dashboard">{t('common.action.back', 'Zurück')}</Link>
      </div>
    </div>
  );
}

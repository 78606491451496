import { gql, useLazyQuery } from '@apollo/client';

const GET_BILLING_PORTAL_SESSION = gql`
  query GetBillingPortalSession {
    getBillingPortalSession
  }
`;

export interface GetBillingPortalSessionResult {
  getBillingPortalSession: string;
}

export function useGetBillingPortalSessionQuery() {
  return useLazyQuery<GetBillingPortalSessionResult>(GET_BILLING_PORTAL_SESSION);
}

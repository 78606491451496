import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button, FileItem, FileUpload, NotificationModal } from '@components/ui';
import { UserFile, UserTopic } from '@dieterApi/user/useUserQuery';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import CreateIcon from '@mui/icons-material/Create';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Skeleton } from '@mui/material';
import cx from 'classnames';
import { useState } from 'react';
import { PartnerTableMeta } from './typesAndEnums';

interface Props {
  open: boolean;
  onClose: () => void;
  onCreate: (tableMeta: PartnerTableMeta) => void;
  topic: UserTopic;
  tableMeta?: PartnerTableMeta; // this is the identifier for the userValue-index where we add the file reference
}

export function AddContractModal({ open, onClose, onCreate, topic, tableMeta }: Props) {
  const { user } = useUser();
  const {
    navigation: { isMobile },
  } = useNavigation();
  const [addUserValue] = useUserValueAddMutation();
  const [uploadActive, setUploadActive] = useState(false);
  const index = tableMeta?.rowData[2];
  const hasFiles = topic.files.length > 0;

  const handleAttachFile = (file: UserFile) => {
    index &&
      addUserValue({
        variables: {
          key: 'DATA_PROCESSOR_CONTRACTFILE',
          value: file.id,
          index,
        },
        context: { userId: user?.id || '' },
      }).finally(() => {
        closeAndReset();
      });
  };

  const closeAndReset = () => {
    setUploadActive(false);
    onClose();
  };

  return (
    <NotificationModal open={open} onClose={closeAndReset}>
      {!index ? (
        <Skeleton width={200} />
      ) : (
        <div className="dtAddContractModal flex flex-col gap-20">
          {uploadActive ? (
            <div className={cx('flex flex-col gap-20 justify-between')}>
              <span>
                {hasFiles
                  ? 'Lade den Vertrag hoch oder verknüpfe eine bereits hochgeladene Datei.'
                  : 'Lade den Vertrag hoch.'}
              </span>
              <FileUpload topicId={topic.id} onComplete={handleAttachFile} />
              {topic.files.map((file) => (
                <div key={file.id} className="dtAddContractModal__fileItem" onClick={() => handleAttachFile(file)}>
                  <FileItem file={file} withActions={true} />
                </div>
              ))}
              <Button onClick={() => setUploadActive(false)}>Zurück</Button>
            </div>
          ) : (
            <>
              <div>
                Ist bereits ein Vertrag für diesen Partner vorhanden oder sollen wir gemeinsam einen neuen erstellen?
              </div>
              <div className={cx('flex gap-20 items-center justify-between flex-wrap md:flex-nowrap')}>
                <div className="flex-grow md:flex-grow-0">
                  <Button icon={<FileOpenIcon />} onClick={() => setUploadActive(true)}>
                    Vorhanden
                  </Button>
                </div>
                <div className="flex-1">
                  <Button icon={<CreateIcon />} onClick={() => onCreate(tableMeta)}>
                    Neu erstellen
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </NotificationModal>
  );
}

import { gql } from '@apollo/client';
import { CONSENT_TEMPLATE_FRAGMENT, ConsentTemplate } from './getSetting';

export const MUTATE_TEMPLATES = gql`
  ${CONSENT_TEMPLATE_FRAGMENT}
  mutation MutateTemplates($settingsId: String!, $language: String!, $consentTemplates: ConsentTemplateMutationInput!) {
    mutateTemplates(settingsId: $settingsId, language: $language, consentTemplates: $consentTemplates) {
      consentTemplates {
        ...ConsentTemplateFields
      }
    }
  }
`;

export interface MutateTemplatesInput {
  settingsId: string;
  language: string;
  consentTemplates: ConsentTemplateMutationInput;
}

interface ConsentTemplateMutationInput {
  removed?: string[];
  reordered?: string[];
  updated?: SettingConsentTemplateInput[];
  added?: SettingConsentTemplateInput[];
}

export type CategorySlug = 'essential' | 'functional' | 'marketing';

interface SettingConsentTemplateInput {
  /** The unique identifier for the template. */
  templateId: string;

  /** Template version. */
  version?: string;

  /** Category slug. */
  categorySlug?: CategorySlug;

  /**
   * The RECOMMENDED default consent status for a consent created from that template.
   * It can be customized by every settings that uses it. If set to true, consent template
   * will be executed by default.
   */
  defaultConsentStatus?: boolean;

  /**
   * [DEPRECATED] Describes in plain words, why the data is processed, by whom, where, how, and what for.
   */
  description?: string;

  /** Is Template auto-update allowed. */
  isAutoUpdateAllowed?: boolean;

  /**
   * [DEPRECATED] There are two types of consents: "predefined" = Usercentrics provided Consent,
   * "custom" = custom consent, user takes care of it.
   */
  type?: string;

  /** Indicates whether the template is deactivated. */
  isDeactivated?: boolean;

  /**
   * If 'true' this property will prevent the CMP to display any legal basis for this
   * particular DPS, regardless of the actual DPS legal basis and the 'legalBasisList' property.
   */
  disableLegalBasis?: boolean;

  /**
   * Indicate if the DPS should be treated as a DPS that transfers data to a 3rd country.
   */
  usesThirdCountry?: boolean;
}

export interface MutateTemplatesResult {
  mutateTemplates: {
    consentTemplates: ConsentTemplate[];
  };
}

import { gql } from '@apollo/client';

export const entityTypeDefinitionsFragment = gql`
  fragment EntityTypeDefinitionsFragment on Questionnaire {
    entityTypeDefinitions {
      entityType
      displayNameProperties {
        fields
        separatorString
      }
      properties {
        propertyKey
        questionType
        questionText
        isRequired
        options {
          optionText
          optionValue
        }
      }
    }
  }
`;

export const entityFragment = gql`
  fragment EntityFragment on Entity {
    __typename
    _id
    userId
    type
    data
    contractInstanceIds
  }
`;

export const questionFragment = gql`
  fragment QuestionFragment on Question {
    _id
    persistentId
    questionText
    description
    type
    value
    state
    # documentScrollTargetId
    consumedByQuestionIds
    __typename
    calculatedState
    options {
      value
      label
      origLabel # this is a custom field added in this backend and not originally from legalOs
      __typename
    }
    isList
    listId
    listIndex
    tags {
      # this is a custom field added in this backend and not originally from legalOs
      variable
      process
    }
    customerSpecificMetadata # this is the new functionality with custom key/value pairs
    canInsertAndRemoveListItem
    listHasMoreThanOneElements
    mainLoopingQuestionQuestionText
    mainLoopingQuestionValueText
  }
`;

export const sectionFragment = gql`
  fragment SectionFragment on Section {
    _id
    title
    state
    # documentScrollTargetId
    description
    # debugIdentifier
    __typename
    questions {
      ...QuestionFragment
    }
  }
  ${questionFragment}
`;

export const questionnaireFragment = gql`
  fragment QuestionnaireFragment on Questionnaire {
    _id
    state
    title
    __typename
    locale
    sections {
      ...SectionFragment
      children {
        ...SectionFragment
      }
    }
    # document
    # styles
    # hasTableOfContents
    entities {
      ...EntityFragment
    }
    ...EntityTypeDefinitionsFragment
    application {
      id
      title
      topic {
        id
        title
      }
    }
  }
  ${entityTypeDefinitionsFragment}
  ${entityFragment}
  ${questionFragment}
  ${sectionFragment}
`;

import { useMessages } from '@app/context/MessageContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button, NotificationModal } from '@components/ui';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export function FullCompletionMessage() {
  const { t } = useTranslation();
  const { fullcompletion } = useMessages();
  const { setNavigation } = useNavigation();

  useEffect(() => {
    fullcompletion.setContext('fullcompletion');
  }, []);

  return (
    <NotificationModal open={fullcompletion.isOpen} onClose={fullcompletion.close}>
      <div className="flex flex-col p-[30px] text-center">
        <div className="bg-primary-root -mx-[40px] px-9 py-7 -mt-[40px] flex flex-col text-center gap-4">
          <img src="/assets/images/dieter_schaf_cape.svg" alt="Info" className="h-[250px]" />
          <span className="text-white font-medium text-lg">{t('dashboard.messages.fullcompletion.header')}</span>
        </div>
        <p className="mt-5">{t('dashboard.messages.fullcompletion.p1')}</p>
        <p>{t('dashboard.messages.fullcompletion.p2')}</p>
        <p>
          <Trans t={t} i18nKey={'dashboard.messages.fullcompletion.p3'}>
            <strong>Aber vergiss nicht:</strong> Datenschutz ist kein Zustand, sondern ein Prozess. Deshalb werde ich
            dich in regelmäßigen Abständen daran erinnern, deine Datenschutz-Unterlagen zu aktualisieren.
          </Trans>
        </p>

        <div className="pointer-events-auto bg-white my-5">
          <div className="flex flex-col items-center text-center gap-5">
            <Button onClick={fullcompletion.close} data-testid="button-close">
              {t('dashboard.messages.fullcompletion.button')}
            </Button>
            <button
              className="text-primary-root underline hover:text-primary-800"
              onClick={() => setNavigation((nav) => void (nav.boom = true))}
            >
              Mehr Party!
            </button>
          </div>
        </div>
      </div>
    </NotificationModal>
  );
}

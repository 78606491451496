export type IconType =
  | 'archive-24'
  | 'check-16'
  | 'check-50'
  | 'chevron-down-16'
  | 'download-16'
  | 'file-24'
  | 'hourglass-16'
  | 'trash-16'
  | 'upload-16'
  | 'chevron-right-16'
  | 'chevron-left-16'
  | 'clock-16'
  | 'premium-16'
  | 'lock-24'
  | 'login-36'
  | 'logout-36'
  | 'user-36'
  | 'edit-24'
  | 'info-36'
  | 'cross-24'
  | 'print-24'
  | 'pdf-16'
  | 'task-32'
  | 'form-24'
  | 'file-done-1024'
  | 'doc-16';

export interface Props {
  className?: string;
  scale?: number;
  size?: number;
  type: IconType;
}

export function Icon({ className, scale = 1, size, type }: Props) {
  const originalSize = parseInt(type.substr(type.lastIndexOf('-') + 1));
  if (typeof size !== 'number') {
    size = originalSize * scale;
  }

  return (
    <svg
      className={className}
      height={size}
      viewBox={`0 0 ${originalSize * scale} ${originalSize * scale}`}
      width={size}
    >
      <use href={`/assets/images/icons.svg#${type}`} />
    </svg>
  );
}

import { gql, useMutation } from '@apollo/client';

const DELETE_QUESTIONNAIRE = gql`
  mutation DeleteQuestionnaire($localQuestionnaireId: String!) {
    deleteQuestionnaire(id: $localQuestionnaireId)
  }
`;

export interface DeleteQuestionnaireInput {
  localQuestionnaireId: string;
}

export interface DeleteQuestionnaireResult {
  deleteQuestionnaire: string;
}

export function useDeleteQuestionnaireMutation() {
  return useMutation<DeleteQuestionnaireResult, DeleteQuestionnaireInput>(DELETE_QUESTIONNAIRE, {
    update(cache, { data }) {
      cache.evict({
        id: `Questionnaire:${data?.deleteQuestionnaire}`,
      });
    },
  });
}

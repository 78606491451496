import { UserRole } from '@dieterApi/user/useUserQuery';
import Chip from '@mui/material/Chip/Chip';
import { useTranslation } from 'react-i18next';

export function UserRoleChip({ role }: { role: UserRole }) {
  const { t } = useTranslation();
  const { label, color } = (
    {
      [UserRole.Editor]: { label: t('route.user-management.role.Editor', 'Editor'), color: 'secondary' },
      [UserRole.Admin]: { label: t('route.user-management.role.Admin', 'Admin'), color: 'primary' },
      [UserRole.ReadOnly]: { label: t('route.user-management.role.ReadOnly', 'ReadOnly'), color: 'info' },
    } as const
  )[role];

  return <Chip size="small" label={label} color={color} sx={{ fontSize: 'small' }} />;
}

import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { IMessage, MessageNames } from './MessageContext';

interface IMessageQueueItem {
  name: MessageNames;
  message: IMessage;
  order: number;
}

interface IMessageQueueContext {
  messageQueue: IMessageQueueItem[];
  enqueueMessage: (item: IMessageQueueItem) => void;
  dequeueMessage: (name: MessageNames) => void;
}

const MessageQueueContext = createContext<IMessageQueueContext>({} as IMessageQueueContext);

export const MessageQueueProvider = ({ children }: { children: React.ReactNode }) => {
  const [messageQueue, setMessageQueue] = useState<IMessageQueueItem[]>([]);

  const enqueueMessage = useCallback((item: IMessageQueueItem) => {
    // only enqueue if not already in queue

    setMessageQueue((prevQueue) => {
      if (prevQueue.find((i) => i.name === item.name)) {
        return prevQueue;
      }
      return [...prevQueue, item].sort((a, b) => a.order - b.order);
    });
  }, []);

  const dequeueMessage = useCallback((name: MessageNames) => {
    setMessageQueue((prevQueue) => prevQueue.filter((item) => item.name !== name));
  }, []);

  const contextValue = useMemo(
    () => ({
      messageQueue,
      enqueueMessage,
      dequeueMessage,
    }),
    [messageQueue, enqueueMessage, dequeueMessage]
  );

  return <MessageQueueContext.Provider value={contextValue}>{children}</MessageQueueContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components -- required for context, will not hurt HMR
export const useMessageQueue = () => {
  return useContext(MessageQueueContext);
};

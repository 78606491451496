import { gql } from '@apollo/client';
import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';

export const QUESTION_LIST_REMOVE_ELEMENT = gql`
  mutation questionlistRemoveElement($input: QuestionListRemoveElementInput) {
    questionListRemoveElement(input: $input) {
      ...QuestionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

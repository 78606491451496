import { useUser } from '@app/context/UserContext';
import { Button, Input } from '@components/ui';
import { useMFAVerifyMutation } from '@dieterApi/mfa/useMFAVerifyMutation';
import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export function MFAVerify() {
  const { t } = useTranslation();
  return (
    <div className="dtRoutePassword flex flex-col justify-center items-center p-4">
      <div className="dtRoutePassword__panel flex flex-col items-center gap-3">
        <Typography variant="h6" gutterBottom>
          {t('route.mfasetup.title')}
        </Typography>
        <MFAVerifyInput mode="verify" />
      </div>
    </div>
  );
}

export function MFAVerifyInput({ mode, onVerify }: { mode: 'setup' | 'verify'; onVerify?: () => void }) {
  const { t } = useTranslation();
  const { user, userLoading } = useUser();
  const [token, setToken] = useState<string>('');
  const [error, setError] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [verifyMFA, { data: verifyData, loading }] = useMFAVerifyMutation();
  const isSetup = mode === 'setup';

  // always focus on the input field
  useEffect(() => {
    !isSetup && inputRef.current?.focus();
  }, []);

  const handleVerify = () => {
    if (!token) return;
    verifyMFA({ variables: { token } }).then((res) => {
      if (!res.data?.verifyMFA) {
        setError(t('route.mfaverify.invalid'));
        // set the focus on the input field and clear the input
        inputRef.current?.focus();
        setToken('');
      } else {
        setError('');
        if (onVerify) onVerify();
      }
    });
  };
  return (
    <>
      <div className="flex gap-4 items-center">
        <Input
          ref={inputRef}
          placeholder={t('route.mfaverify.digits')}
          required
          onChange={(event) => {
            setToken(event.target.value);
          }}
          onKeyDown={(e) => (e.key === 'Enter' ? handleVerify() : null)}
          type="password"
          name="totp"
          value={token}
          id="totp"
          data-testid="totp-input"
        />

        {/* <div className="flex items-center"> */}
        <Button className=" w-50" spinner={loading || userLoading} onClick={handleVerify} data-testid="verify-button">
          {isSetup ? t('route.mfaverify.activate') : t('route.mfaverify.validate')}
        </Button>
        {/* </div> */}
      </div>
      <div className="text-red-500">{error}</div>
      <div className="text-sm">
        <Trans t={t} i18nKey="route.mfaverify.desc">
          Falls du Probleme mit der Authentifizierung hast, kontaktiere uns im Chat oder über
          <a href="mailto:info@dieter-datenschutz.de">info@dieter-datenschutz.de</a>.
        </Trans>
      </div>
    </>
  );
}

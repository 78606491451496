import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Chat } from './useInitiateChatMutation';

const GET_CHAT = gql`
  query GetChat($id: String) {
    getChat(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        email
      }
      messages {
        role
        content
      }
      length
      scope
    }
  }
`;

const GET_ALL_CHATS = gql`
  query GetAllChats {
    getChat {
      id
      createdAt
      updatedAt
      user {
        id
        email
      }
      length
      scope
    }
  }
`;

export interface GetChatInput {
  id?: string;
}

export interface GetChatResult {
  getChat: ChatWithUser[];
}

export type ChatWithUser = Chat & { user: { id: string; email: string } };

export function useGetChatQuery(id?: string) {
  return useLazyQuery<GetChatResult, GetChatInput>(GET_CHAT, {
    variables: { id },
  });
}

export function useGetAllChatsQuery() {
  return useQuery<GetChatResult>(GET_ALL_CHATS);
}

import { DocumentNode } from '@apollo/client';
import { ENTITIES_QUERY } from '../gql/entitiesQuery';
import { ENTITY_QUERY } from '../gql/entityQuery';
import { GET_QUESTIONNAIRE_ENTITY_TYPE_DEFINITIONS } from '../gql/entityTypeDefinitions';
import { QUESTIONNAIRE } from '../gql/questionnaire';
import { QUESTIONNAIRE_APP } from '../gql/questionnaireApp';
import { QUESTIONNAIRE_DOC } from '../gql/questionnaireDoc';
import {
  QUESTIONNAIRE_RESULTS,
  QUESTIONNAIRE_RESULTS_NODES,
  QUESTIONNAIRE_RESULTS_QUESTIONS,
  QUESTIONNAIRE_RESULTS_TREE,
} from '../gql/questionnaireResults';
import type IAppData from './data/IAppData';
import IDocData from './data/IDocData';
import type IEntitiesQueryData from './data/IEntitiesQueryData';
import type IEntityQueryData from './data/IEntityQueryData';
import type IQuestionnaireEntityTypeDefinitionsData from './data/IQuestionnaireEntityTypeDefinitionsData';
import type IQuestionnaireQueryData from './data/IQuestionnaireQueryData';
import type IQuestionnaireResultsData from './data/IQuestionnaireResultsData';
import type {
  IQuestionnaireResultsNodesData,
  IQuestionnaireResultsQuestionsData,
  IQuestionnaireResultsTreeData,
} from './data/IQuestionnaireResultsData';
import type IAppInput from './input/IAppInput';
import IDocInput from './input/IDocInput';
import type IEntitiesQueryInput from './input/IEntitesQueryInput';
import type IEntityQueryInput from './input/IEntityQueryInput';
import type IQuestionnaireEntityTypeDefinitionsInput from './input/IQuestionnaireEntityTypeDefinitionsInput';
import type IQuestionnaireQueryInput from './input/IQuestionnaireQueryInput';
import type IQuestionnaireResultsQueryInput from './input/IQuestionnaireResultsQueryInput';

export const ALL_QUERIES = [
  'entities',
  'entity',
  'entityTypeDefinitions',
  'questionnaire',
  'questionnaireDoc',
  'questionnaireApp',
  'questionnaireResults',
  'questionnaireResultsQuestions',
  'questionnaireResultsNodes',
  'questionnaireResultsTree',
] as const;

export type LegalOSQuery = typeof ALL_QUERIES[number];

export type QueryInputType<T extends LegalOSQuery> = T extends 'entities'
  ? IEntitiesQueryInput
  : T extends 'entity'
  ? IEntityQueryInput
  : T extends 'entityTypeDefinitions'
  ? IQuestionnaireEntityTypeDefinitionsInput
  : T extends 'questionnaire'
  ? IQuestionnaireQueryInput
  : T extends 'questionnaireApp'
  ? IAppInput
  : T extends 'questionnaireResults'
  ? IQuestionnaireResultsQueryInput
  : T extends 'questionnaireResultsQuestions'
  ? IQuestionnaireResultsQueryInput
  : T extends 'questionnaireResultsNodes'
  ? IQuestionnaireResultsQueryInput
  : T extends 'questionnaireResultsTree'
  ? IQuestionnaireResultsQueryInput
  : T extends 'questionnaireDoc'
  ? IDocInput
  : never;

export type QueryOutputType<T extends LegalOSQuery> = T extends 'entities'
  ? IEntitiesQueryData
  : T extends 'entity'
  ? IEntityQueryData
  : T extends 'entityTypeDefinitions'
  ? IQuestionnaireEntityTypeDefinitionsData
  : T extends 'questionnaire'
  ? IQuestionnaireQueryData
  : T extends 'questionnaireApp'
  ? IAppData
  : T extends 'questionnaireDoc'
  ? IDocData
  : T extends 'questionnaireResults'
  ? IQuestionnaireResultsData
  : T extends 'questionnaireResultsQuestions'
  ? IQuestionnaireResultsQuestionsData
  : T extends 'questionnaireResultsNodes'
  ? IQuestionnaireResultsNodesData
  : T extends 'questionnaireResultsTree'
  ? IQuestionnaireResultsTreeData
  : never;

export const QueryMap: Record<LegalOSQuery, DocumentNode> = {
  entities: ENTITIES_QUERY,
  entity: ENTITY_QUERY,
  entityTypeDefinitions: GET_QUESTIONNAIRE_ENTITY_TYPE_DEFINITIONS,
  questionnaire: QUESTIONNAIRE,
  questionnaireDoc: QUESTIONNAIRE_DOC,
  questionnaireApp: QUESTIONNAIRE_APP,
  questionnaireResults: QUESTIONNAIRE_RESULTS,
  questionnaireResultsQuestions: QUESTIONNAIRE_RESULTS_QUESTIONS,
  questionnaireResultsNodes: QUESTIONNAIRE_RESULTS_NODES,
  questionnaireResultsTree: QUESTIONNAIRE_RESULTS_TREE,
};

import { useNavigation } from '@app/hooks/useNavigation';
import { useDashboard } from '@app/routes/Dashboard/useDashboard';
import { Button, NotificationModal } from '@components/ui';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Snippet = { text: string; pricingParam: string };

export function QuestionnaireChangedModal() {
  const { t } = useTranslation();
  const dashboard = useDashboard();

  const {
    navigation: {
      questionnaireHasChanged: { modalOpen, questionnaire },
    },
    setNavigation,
  } = useNavigation();

  const handleClose = () => {
    setNavigation((nav) => {
      nav.questionnaireHasChanged = {
        modalOpen: false,
        questionnaire: undefined,
      };
      nav.preferredTab = 'Forms';
    });
  };

  const handleClick = () => {
    questionnaire && dashboard.open(questionnaire.id, undefined, true);
    handleClose();
  };

  const title = questionnaire?.application.title;

  if (!questionnaire) return null;

  return (
    <NotificationModal open={modalOpen} onClose={handleClose}>
      <div className="flex flex-col p-[30px]">
        <div className="bg-primary-root -mx-[40px] px-9 py-7 -mt-[40px] flex flex-col text-center gap-4">
          <img src="/assets/images/ssl_03.svg" alt="Info" className="h-[150px]" />
          <span className="text-white font-medium text-lg">{t('components.ui.questionnaire_changed.header')}</span>
        </div>
        <p className="mt-4">
          <Trans t={t} i18nKey={'components.ui.questionnaire_changed.p1'} values={{ title: title }}>
            Wir entwickeln Dieter stetig weiter! Seit deinem letzten Besuch, haben wir den Fragebogen{' '}
            <strong>{title}</strong> überarbeitet.
          </Trans>
        </p>
        {/* <p>{t('components.ui.questionnaire_changed.p2')}</p> */}
        <p>{t('components.ui.questionnaire_changed.p3')}</p>
        <p>{t('components.ui.questionnaire_changed.p4')}</p>

        <div className="pointer-events-auto bg-white my-5">
          <div className="flex flex-col items-center text-center gap-5">
            <Button onClick={handleClick} data-testid="button-subscribe">
              {t('components.ui.questionnaire_changed.letsgo')}
            </Button>
            <Link to="#" onClick={handleClose} data-testid="button-alternative">
              {t('components.ui.questionnaire_changed.later')}
            </Link>
          </div>
        </div>
      </div>
    </NotificationModal>
  );
}

import { Skeleton } from '@mui/material';

export function TasksSkeleton() {
  return (
    <div className="flex flex-col">
      <Skeleton width={150} />
      {[0, 1, 2].map((idx) => {
        return <SingleTaskSkeleton key={idx} />;
      })}
    </div>
  );
}

export function SingleTaskSkeleton() {
  return <Skeleton height={150} />;
}

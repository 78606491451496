import { NavigationSection } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { Badge } from '@mui/material';
import cx from 'classnames';
import { OpenTaskType, UserFlowItemType } from '../../constants';

export type ProgressType = UserFlowItemType | OpenTaskType;

interface Props {
  title: string;
  type: ProgressType;
  count: number;
  newBadge?: boolean;
}

// this maps ProgressTypes to NavigationSection types
const sectionMapping = {
  [UserFlowItemType.questionnaire]: NavigationSection.QuestionnaireList,
  [UserFlowItemType.document]: NavigationSection.DocumentList,
  [UserFlowItemType.task]: NavigationSection.TaskList,
  [OpenTaskType.openTask]: NavigationSection.OpenTaskList,
};

export function ProgressListItem({ title, type, count, newBadge }: Props) {
  const { navigation, navigateDashboard } = useNavigation();
  const handleClick = (type: ProgressType) => {
    navigateDashboard(sectionMapping[type]);
  };

  const isActive = navigation.activeSection === sectionMapping[type];

  return (
    <div
      className={cx('dtProgressListItem flex items-center', { isActive })}
      onClick={() => handleClick(type)}
      data-testid={`progress-${type}-button`}
    >
      <div className={cx('icon', type)} />
      <div className="title">{title}</div>
      <Badge color="error" variant={newBadge ? 'dot' : undefined} />
      <div className="count" data-testid={`progress-${type}-count`}>
        {count}
      </div>
      <Badge />
    </div>
  );
}

import { useUser } from '@app/context/UserContext';
import { Topics } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { TagValues, UserTopic } from '@dieterApi/user/useUserQuery';
import { Collapse } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CloseButton } from '../CloseButton/CloseButton';

interface Props {
  topic: UserTopic;
}

const excludeTopics = [Topics.CHECKUP, Topics.DSMGMT];

/**
 * A component that displays a disclaimer message if the user's selected language is not supported for the current topic.
 * @param topic The current topic.
 */
export function LanguageDisclaimer({ topic }: Props) {
  const { user } = useUser();
  const {
    navigation: { languageDisclaimerDismissed },
    setNavigation,
  } = useNavigation();
  const { t, i18n } = useTranslation();
  const [dismissed, setDismissed] = useState(languageDisclaimerDismissed[topic.id] || false);
  // If the user's locale matches the topic's tags or is German, or if the user's locale is not set, don't display the disclaimer
  if (
    topic.tags.map((t) => t.tag).includes((i18n.language.slice(0, 2) as TagValues) || 'de') ||
    i18n.language.slice(0, 2) === 'de' ||
    !user?.locale
  ) {
    return null;
  }
  // If the topic is not supported, don't display the disclaimer
  if (excludeTopics.includes(topic.id)) {
    return null;
  }
  const lang = i18n.language.slice(0, 2);
  const displayLang = new Intl.DisplayNames(lang, { type: 'language' }).of(lang);

  const handleClick = () => {
    setDismissed(true);
    setNavigation((nav) => {
      nav.languageDisclaimerDismissed[topic.id] = true;
    });
  };

  return (
    <Collapse in={!dismissed}>
      <div className="bg-red-100 rounded-lg p-4 text-sm text-red-700 relative mb-5">
        <p className="font-bold">{t('components.ui.language_disclaimer.header', 'Haftungsausschluss')}</p>
        <Trans i18nKey="components.ui.language_disclaimer.text.p1" t={t} values={{ displayLang }}>
          <p className="mb-2">
            Du hast <strong>{displayLang}</strong> als deine primäre Sprache ausgewählt. Bitte beachte, dass die Inhalte
            dieses Bereichs bisher nur im deutschen Rechtsraum uneingeschränkt gültig sind. Wir arbeiten mit Hochdruck
            daran, die Inhalte auch für andere Länder rechtssicher zu gestalten.
          </p>
        </Trans>
        <p className="mt-2 font-semibold">
          {t(
            'components.ui.language_disclaimer.text.p2',
            'Die Nutzung der Dokumente in anderen Ländern als Deutschland erfolgt auf eigene Gefahr.'
          )}
        </p>

        <CloseButton
          offset={false}
          btnColor="red"
          fontSize="small"
          onClick={handleClick}
          tooltip={t('components.ui.language_disclaimer.tooltip')}
        />
      </div>
    </Collapse>
  );
}

import { gql } from '@apollo/client';
import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';

export const QUESTIONNAIRE_UPDATE = gql`
  ${questionnaireFragment}
  mutation questionnaireUpdate($input: QuestionnaireUpdateInput) {
    questionnaireUpdate(input: $input) {
      ...QuestionnaireFragment
    }
  }
`;

import { gql, useMutation } from '@apollo/client';

const DOWNLOAD_SIGNATURE_REQUEST = gql`
  mutation DownloadSignatureConfirmation($id: String!) {
    downloadSignatureConfirmation(id: $id) {
      mimeType
      fileName
      fileBase64
    }
  }
`;

export interface DownloadSignatureRequestInput {
  id: string;
}

export interface DownloadSignatureRequestResult {
  downloadSignatureConfirmation: {
    mimeType: string;
    fileName: string;
    fileBase64: string;
  };
}

export function useDownloadSignatureRequestMutation() {
  return useMutation<DownloadSignatureRequestResult, DownloadSignatureRequestInput>(DOWNLOAD_SIGNATURE_REQUEST);
}

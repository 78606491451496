import { useSnackbar } from '@app/hooks/useSnackbar';
import { Field, Input } from '@components/ui';
import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
}

export function EmailField({ disabled = false, onChange, onEnter, ...rest }: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Field className={cx('mb-20', rest.className)} htmlFor="email" label={t('common.form_field.email.label', 'E-Mail')}>
      <Input
        disabled={disabled}
        required
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e);
        }}
        onKeyPress={(e) => (e.key === 'Enter' ? onEnter && onEnter() : null)}
        // onBlur={(event) =>
        //   event.target.value &&
        //   !validate(event.target.value) &&
        //   enqueueSnackbar('E-Mail Adresse ungültig.', {
        //     variant: 'error',
        //   })
        // }
        type="email"
        placeholder={t('common.form_field.email.placeholder', 'deine@email.de')}
        value={value}
        id="email"
        data-testid="email-field"
        autoComplete="username"
        {...rest}
      />
    </Field>
  );
}

import { useNavigation } from '@app/hooks/useNavigation';
import { Spinner } from '@components/ui';
import { DeleteAccountModal } from '@components/ui/AccountMenu/DeleteAccountModal.tsx';
import { useUserLogoutMutation } from '@dieterApi/user/useUserLogoutMutation.ts';
import { User, UserRole } from '@dieterApi/user/useUserQuery.ts';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ExpertModeModal } from './ExpertModeModal';

interface IProps {
  onClose?(): void;
  user?: User | null;
  portalUrl?: string;
  loading?: boolean;
}
function AccountMenuItems({ onClose, user, portalUrl, loading }: IProps) {
  const { t } = useTranslation();
  const {
    setNavigation,
    navigation: { expertModeEnabled },
  } = useNavigation();
  const [logout] = useUserLogoutMutation();
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = React.useState(false);
  const [expertModeModalOpen, setExpertModeModalOpen] = React.useState(false);

  const toggleExpertMode = (checked: boolean) => {
    if (checked) {
      // if the user wants to enable it, we open a dialog to confirm
      setExpertModeModalOpen(true);
    } else {
      setNavigation((nav) => void (nav.expertModeEnabled = checked));
    }
  };

  const [showWhitelabelConfig, setShowWhitelabelConfig] = React.useState(false);
  React.useEffect(() => {
    if (user?.companies) {
      const principalCompany = user.companies.find((company) => company.isPrincipal === true);
      if (principalCompany?.features?.includes('Whitelabel')) {
        setShowWhitelabelConfig(true);
      }
    }
  }, [user]);

  return (
    <>
      {!(!user || user.isAnonymous) && (
        <>
          <MenuItem
            onClick={onClose}
            component={NavLink}
            disabled={!user.mfaVerified}
            activeClassName="Mui-selected !text-primary-root"
            to="/userconfig"
          >
            {t('route.user-config.header')}
          </MenuItem>
          {showWhitelabelConfig && (
            <MenuItem
              onClick={onClose}
              component={NavLink}
              activeClassName="Mui-selected !text-primary-root"
              to="/whitelabel"
            >
              {t('route.whitelabel.config_ui', 'Whitelabel Configurator')}
            </MenuItem>
          )}

          <MenuItem
            onClick={onClose}
            component={NavLink}
            activeClassName="Mui-selected !text-primary-root"
            to="/usermanagement"
            disabled={user.role !== UserRole.Admin || !user.mfaVerified}
          >
            {t('layout.global.header.account_dropdown_option.user_management')}
          </MenuItem>
          <MenuItem
            onClick={onClose}
            component={NavLink}
            activeClassName="Mui-selected !text-primary-root"
            to="/mfa"
            disabled={!user.mfaVerified}
          >
            {t('layout.global.header.account_dropdown_option.mfa')}
          </MenuItem>

          <MenuItem
            component="a"
            onClick={onClose}
            href={portalUrl}
            rel="noopener noreferrer"
            disabled={!portalUrl || !user.mfaVerified}
            className="flex justify-between gap-2"
          >
            {t('layout.global.header.account_dropdown_option.payment_settings', 'Zahlungseinstellungen')}
            {loading && <Spinner size="small" />}
          </MenuItem>
          <MenuItem
            onClick={() => setOpenDeleteAccountModal(true)}
            data-testid="button-account-menu-delete"
            disabled={!user.mfaVerified}
          >
            {t('layout.global.header.account_dropdown_option.delete_account', 'Account löschen')}
          </MenuItem>
          <Divider />
        </>
      )}
      <Tooltip title={t('layout.global.header.account_dropdown_option.expert_mode_tooltip')} placement="left">
        <MenuItem>
          <FormControlLabel
            control={<Switch checked={expertModeEnabled} onChange={(e) => toggleExpertMode(e.target.checked)} />}
            label={t('layout.global.header.account_dropdown_option.expert_mode')}
          />
        </MenuItem>
      </Tooltip>
      <Divider />
      <MenuItem onClick={onClose} component={NavLink} activeClassName="Mui-selected !text-primary-root" to="/terms">
        {t('layout.global.header.account_dropdown_option.terms', 'Rechtliches')}
      </MenuItem>
      <MenuItem
        component={NavLink}
        onClick={onClose}
        activeClassName="Mui-selected !text-primary-root"
        to="/privacy"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-1"
      >
        {t('layout.global.header.account_dropdown_option.privacy')}
        <OpenInNewIcon fontSize="inherit" className="text-gray-400" />
      </MenuItem>
      <MenuItem onClick={() => window.UC_UI.showSecondLayer()} data-testid="button-account-show-uc-settings">
        {t('layout.global.header.account_dropdown_option.privacy_settings')}
      </MenuItem>

      <MenuItem
        component={NavLink}
        onClick={onClose}
        activeClassName="Mui-selected !text-primary-root"
        to="/imprint"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-1"
      >
        {t('layout.global.header.account_dropdown_option.imprint', 'Impressum')}
        <OpenInNewIcon fontSize="inherit" className="text-gray-400" />
      </MenuItem>
      {!(!user || user.isAnonymous) && (
        <div>
          <Divider />
          <MenuItem onClick={() => logout()}>{t('common.action.logout', 'Ausloggen')}</MenuItem>
        </div>
      )}
      <DeleteAccountModal open={openDeleteAccountModal} onClose={() => setOpenDeleteAccountModal(false)} />
      <ExpertModeModal open={expertModeModalOpen} onClose={() => setExpertModeModalOpen(false)} />
    </>
  );
}

export default AccountMenuItems;

import { gql, useMutation } from '@apollo/client';
import { OfferType } from '@app/context/NavigationContext';
import { USER_FIELDS, User } from './useUserQuery';

const UPDATE_COMPANY_USER = gql`
  ${USER_FIELDS}
  mutation UpdateCompany(
    $firstName: String
    $lastName: String
    $companyName: String
    $website: String
    $phone: String
    $street: String
    $zip: String
    $city: String
    $subscription: String
    $couponcode: String
    $triggeredFromQuestionnaireId: String
  ) {
    updateCompany(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      website: $website
      phone: $phone
      street: $street
      zip: $zip
      city: $city
      subscription: $subscription
      couponcode: $couponcode
      triggeredFromQuestionnaireId: $triggeredFromQuestionnaireId
    ) {
      ...UserFields
    }
  }
`;

export interface UpdateCompanyUserResult {
  subscribeUser: User;
}

export interface UpdateCompanyUserInput {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  website?: string;
  phone?: string;
  street?: string;
  zip?: string;
  city?: string;
  subscription?: OfferType;
  couponcode?: string;
  triggeredFromQuestionnaireId?: string;
}

export function useUserUpdateCompanyMutation() {
  return useMutation<UpdateCompanyUserResult, UpdateCompanyUserInput>(UPDATE_COMPANY_USER, {
    refetchQueries: ['GetUser'],
    // update(cache, { data }) {
    //   cache.modify({
    //     fields: {
    //       getUser: () => (data && data.subscribeUser ? data.subscribeUser : null),
    //     },
    //   });
    // },
  });
}

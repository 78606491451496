export enum ChatTokenType {
  ContactEmployee = 'CONTACT_EMPLOYEE',
  OnboardingDSE = 'ONBOARDING_DSE',
  OnboardingTOMs = 'ONBOARDING_TOMS',
  OnboardingCheckup = 'ONBOARDING_CHECKUP',
  OnboardingVVT = 'ONBOARDING_VVT',
  OnboardingAVV = 'ONBOARDING_AV',
  OnboardingImprint = 'ONBOARDING_IMPRINT',
  OnboardingInfo = 'ONBOARDING_INFO',
}

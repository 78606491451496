import { useNavigation } from '@app/hooks/useNavigation';
import { Icon } from '@components/ui';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Collapse } from '@mui/material';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskWithApp } from './TaskList';

interface Props {
  task: TaskWithApp;
  dismissable?: boolean;
}

export function TaskItemDone({ task, dismissable = true }: Props) {
  const { t } = useTranslation();
  const { navigation, setNavigation } = useNavigation();

  const handleDismiss = (e: MouseEvent<HTMLButtonElement>) => {
    //e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    setNavigation((nav) => void (nav.dismissedTasks = { ...nav.dismissedTasks, [task.persistentId]: true }));
  };

  return (
    <Collapse in={(!dismissable || !task.isDismissed) && task.done}>
      <div className="flex justify-between py-4 items-center gap-4">
        <div>
          <div className="text-success text-3xl">
            <CheckBoxIcon fontSize="inherit" />
          </div>
        </div>
        <div className="flex flex-col grow gap-1">
          <span className="text-sm">
            {t('dashboard.tasks.taskitemdone')} <strong>{task.label}</strong>
          </span>
          <span className="text-[0.8em] leading-3 text-success">
            {t('common.section')} {': '} {task.topic.title}
          </span>
        </div>
        <div>
          {dismissable && (
            <button onClick={handleDismiss} data-testid="task-dismiss-button" className="text-success">
              <Icon type="cross-24" size={20} />
            </button>
          )}
        </div>
      </div>
    </Collapse>
  );
}

import { gql, useLazyQuery } from '@apollo/client';

export const GET_QUESTIONNAIRESUMMARY = gql`
  query GetQuestionnaireSummary($companyId: String!) {
    getQuestionnaireSummary(companyId: $companyId) {
      id
      questionnaireId
      createdAt
      updatedAt
      label
      progress
      isBroken
      version
      maxVersion
      versionDate
      companyName
    }
  }
`;

export interface GetQuestionnaireSummaryResult {
  getQuestionnaireSummary: QuestionnaireSummary[];
}

export interface QuestionnaireSummary {
  id: string;
  questionnaireId: string;
  createdAt: Date;
  updatedAt: Date;
  label: string;
  isBroken: boolean;
  version: number;
  maxVersion: number;
  versionDate: Date;
  progress: number;
  companyName: string;
}

export interface GetQuestionnaireSummaryResultInput {
  companyId?: string;
}

export function useQuestionnaireSummaryQuery() {
  return useLazyQuery<GetQuestionnaireSummaryResult, GetQuestionnaireSummaryResultInput>(GET_QUESTIONNAIRESUMMARY);
}

import AllowedValueTypes from "@legalosApi/types/AllowedValueTypes";
import QuestionType from "@legalosApi/types/QuestionType";
import EntityPropertyInputType from "@legalosApi/types/entity/EnitityPropertyInputType";
import EntityType from "@legalosApi/types/entity/EntityType";
import IEntity from "@legalosApi/types/entity/IEntity";
import { IEntityTypeDefinition } from "@legalosApi/types/entity/IEntityTypeDefinition";
import React from "react";
import { useTranslation } from "react-i18next";
import { localizeValue } from "./localizeValue";

export function useValueToLocalizedString({
  entities,
  entityTypeDefinitions,
}: {
  entities: IEntity[];
  entityTypeDefinitions: IEntityTypeDefinition[];
}) {
  const { t, i18n } = useTranslation();
  const locale = i18n.language || "de_DE";
  return React.useCallback(
    (value: AllowedValueTypes, questionType: QuestionType, entityPropertyName?: string) => {
      if ([QuestionType.PERSON, QuestionType.COMPANY].includes(questionType)) {
        const entityTypeDefinition = entityTypeDefinitions.find(
          (def) => def.entityType === (questionType === QuestionType.PERSON ? EntityType.PERSON : EntityType.COMPANY)
        );
        const property = entityTypeDefinition?.properties?.find((prop) => prop.propertyKey === entityPropertyName);
        const entity = entities.find(({ _id: entityId }) => entityId === value);
        // Returns stringified value if entity not found
        if (!entity) {
          return null;
        }
        const propertyValue = (!!entityPropertyName && entity.data?.[entityPropertyName]) || "";
        // Returns stringified property value if definition not found
        if (!property) {
          return String(propertyValue);
        }
        switch (property.questionType) {
          case EntityPropertyInputType.DATE:
            return localizeValue({
              value: propertyValue,
              type: QuestionType.DATE,
              locale,
              t,
            });
          case EntityPropertyInputType.CURRENCY:
            return localizeValue({
              t,
              value: propertyValue,
              locale,
              type: QuestionType.CURRENCY,
            });
          case EntityPropertyInputType.FLOAT:
            return localizeValue({
              t,
              value: propertyValue,
              locale,
              type: QuestionType.FLOAT,
            });
          case EntityPropertyInputType.BOOLEAN:
            return localizeValue({
              t,
              value: propertyValue,
              locale,
              type: QuestionType.BOOLEAN,
            });
          case EntityPropertyInputType.SELECT_ONE:
          case EntityPropertyInputType.SELECT_SOME:
            return (
              property.options?.find(({ optionValue }) => optionValue === propertyValue)?.optionText ||
              String(propertyValue)
            );
          default:
            return localizeValue({
              t,
              value: propertyValue,
              locale,
              type: QuestionType.TEXT,
            });
        }
      } else {
        return localizeValue({ t, value, locale, type: questionType });
      }
    },
    [entities, entityTypeDefinitions, locale, t]
  );
}

import { gql, useMutation } from '@apollo/client';

const UPGRADE_SUBSCRIPTION = gql`
  mutation UpgradeSubscription(
    $product: ProductEnum
    $topic: TopicEnum
    $mode: ModeEnum
    $interval: IntervalEnum
    $promocode: String
    $preview: Boolean
  ) {
    upgradeSubscription(
      product: $product
      topic: $topic
      mode: $mode
      interval: $interval
      preview: $preview
      promocode: $promocode
    ) {
      type
      data
      billingCycleAnchor
      intervalWarning
      promocodeWarning
    }
  }
`;

export interface UpgradeSubscriptionInput {
  product: 'basic' | 'premium' | 'ticket';
  topic?: 'toms' | 'dse' | 'vvt' | 'avv';
  mode: 'companies' | 'agencies' | 'dpos';
  interval: 'month' | 'year';
  promocode?: string;
  preview?: boolean;
}

export interface UpgradeSubscriptionResult<T> {
  upgradeSubscription: {
    type: T;
    // data: T extends 'Subscription' ? Subscription : T extends 'InvoiceUpgrade' ? InvoiceUpgrade : never;
    data: string;
    billingCycleAnchor: number;
    intervalWarning: boolean;
    promocodeWarning: boolean;
  };
}

export function useUpgradeSubscriptionMutation() {
  return useMutation<UpgradeSubscriptionResult<'Subscription'>, UpgradeSubscriptionInput>(UPGRADE_SUBSCRIPTION, {});
}

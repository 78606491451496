import { useUser } from '@app/context/UserContext';
import { pickLatest } from '@app/utils/pickLatest';
import { DateTime } from 'luxon';

import { useTranslation } from 'react-i18next';

export function LastAction() {
  const { t } = useTranslation();
  const { user } = useUser();
  return (
    <div className="dtInfo__lastAction">
      <dl>
        <dt>{t('dashboard.info.lastaction.lastlogin')}</dt>
        <dd>
          {user?.previousLogin
            ? DateTime.fromJSDate(new Date(user.previousLogin))
                .setLocale(user?.locale || 'en')
                .toRelative()
            : '-'}
        </dd>

        <dt>{t('dashboard.info.lastaction.lastaction')}</dt>
        <dd>{pickLatest(user?.questionnaires)?.application.title || '-'}</dd>
      </dl>
    </div>
  );
}

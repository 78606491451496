import { useUser } from '@app/context/UserContext';
import SchoolIcon from '@mui/icons-material/School';
import { useTranslation } from 'react-i18next';
import { EmployeeTable } from './EmployeeTable';
import './training.sass';

export function Training() {
  const { user } = useUser();
  const { t } = useTranslation();

  const n = user?.company.employees.length || 0;

  return (
    <div className="dtTraining flex flex-col gap-10">
      <div className="dtRouteDashboard__itemTitle flex flex-row items-center gap-10">
        <SchoolIcon />
        <div className="dtRouteDashboard__itemTitleInput flex-grow-1">{t('dashboard.training.title')}</div>
        {n > 0 && (
          <span className="whitespace-nowrap text-2xl">
            {t('common.count')}: {n}
          </span>
        )}
      </div>
      <EmployeeTable />
    </div>
  );
}

import { IQuestion } from '@legalosApi/types/IQuestion';
import { ISection } from '@legalosApi/types/ISection';
import QuestionType from '@legalosApi/types/QuestionType';

export interface IChoiceQuestion extends IQuestion {
  type: QuestionType.SELECT_ONE;
}

export interface IListQuestion extends IQuestion {
  isList: true;
  listId: string;
  listIndex: string;
}

export interface IStep {
  id: string;
  parents: Array<ISection>;
  type: string;
}

export interface ChoiceStep extends IStep {
  type: 'choice';
  question: IChoiceQuestion;
}

export interface ListStep extends IStep {
  type: 'list';
  listId: string;
  items: Array<{
    index: string;
    questions: Array<IListQuestion>;
  }>;
}

export interface MessageStep extends IStep {
  type: 'message';
  question: IQuestion;
}

export interface QuestionStep extends IStep {
  type: 'question';
  question: IQuestion;
}

export interface ToStepsState {
  isAborted: boolean;
  steps: Array<AnyStep>;
}

export type AnyStep = ChoiceStep | QuestionStep | ListStep | MessageStep;

export function isChoiceQuestion(value: IQuestion): value is IChoiceQuestion {
  return value.type === QuestionType.SELECT_ONE;
}

export function isListQuestion(value: IQuestion): value is IListQuestion {
  return value.isList === true;
}

export function toSteps(
  sections: Array<ISection>,
  anchestrors: Array<ISection> = [],
  state: ToStepsState = { isAborted: false, steps: [] }
) {
  for (const section of sections) {
    const parents = [...anchestrors, section];

    if (!state.isAborted && section.questions) {
      let lastStep: AnyStep | undefined = undefined;

      for (const question of section.questions) {
        const props = { id: question._id, parents };
        const step = toStep(question, props, lastStep);

        if (step === false) {
          state.isAborted = true;
          return state.steps;
        } else if (step) {
          lastStep = step;
          state.steps.push(step);
        }
      }
    }

    if (!state.isAborted && section.children) {
      toSteps(section.children, parents, state);
    }
  }

  return state.steps;
}

interface SharedProps {
  id: string;
  parents: Array<ISection>;
}

function toStep(question: IQuestion, props: SharedProps, prevStep?: AnyStep): AnyStep | false | null {
  if (isListQuestion(question)) {
    return toListStep(question, props, prevStep);
  } else if (
    question.type === QuestionType.SELECT_ONE &&
    (question.options.every((option) => option.origLabel.startsWith('Knoten')) ||
      question.tags.process.some((tag) => tag === 'INFO'))
  ) {
    return toMessageStep(question, props);
  } else if (
    question.type === QuestionType.SELECT_ONE &&
    question.options.every((option) => option.label.startsWith('Exit'))
  ) {
    return false;
  } else if (isChoiceQuestion(question)) {
    return toChoiceStep(question, props);
  } else {
    return toQuestionStep(question, props);
  }
}

function toChoiceStep(question: IChoiceQuestion, props: SharedProps): ChoiceStep {
  return {
    ...props,
    type: 'choice',
    question,
  };
}

function toListStep(question: IListQuestion, props: SharedProps, prevStep?: AnyStep): ListStep | null {
  if (prevStep && prevStep.type === 'list' && prevStep.listId === question.listId) {
    let item = prevStep.items.find((item) => item.index === question.listIndex);
    if (!item) {
      item = { index: question.listIndex, questions: [] };
      prevStep.items.push(item);
    }

    item.questions.push(question);
    return null;
  }

  return {
    ...props,
    type: 'list',
    listId: question.listId,
    items: [
      {
        index: question.listIndex,
        questions: [question],
      },
    ],
  };
}

function toMessageStep(question: IQuestion, props: SharedProps): MessageStep {
  return {
    ...props,
    type: 'message',
    question,
  };
}

function toQuestionStep(question: IQuestion, props: SharedProps): QuestionStep {
  return {
    ...props,
    type: 'question',
    question,
  };
}

import { gql } from '@apollo/client';

export const GET_TEMPLATES = gql`
  query ConsentTemplates($includeDeprecated: Boolean) {
    consentTemplates(includeDeprecated: $includeDeprecated) {
      templateId
      dataProcessor
    }
  }
`;

export interface GetConsentTemplatesInput {
  includeDeprecated?: boolean;
}

export interface ConsentTemplate {
  templateId: string;
  dataProcessor: string;
}

export interface GetConsentTemplatesResult {
  consentTemplates: ConsentTemplate[];
}

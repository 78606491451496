import cx from 'classnames';
import * as React from 'react';
import createTransition, { Transition, TransitionEffect } from './createTransition';
import dissolve from './effects/dissolve';
import './ui-swap.sass';

const defaultEffect = dissolve();

export interface Props {
  children?: React.ReactElement | null;
  className?: string;
  effect?: TransitionEffect;
  pageClassName?: string;
  style?: React.CSSProperties;
  uri: string;
}

export interface State {
  child: React.ReactElement | null;
  index: number;
  lastChild: React.ReactElement | null;
  transition: Transition | null;
  uri: string;
}

export function Swap(props: Props) {
  const [state, setState] = React.useState<State>({
    child: props.children || null,
    index: 0,
    lastChild: null,
    transition: null,
    uri: props.uri,
  });

  if (props.uri !== state.uri && !state.transition) {
    setState({
      ...state,
      child: props.children || null,
      index: state.index + 1,
      lastChild: state.child,
      transition: createTransition({
        childRef: props.children ? React.createRef() : null,
        effect: props.effect || defaultEffect,
        lastChildRef: state.child ? React.createRef() : null,
      }),
      uri: props.uri,
    });
  } else if (state.child !== props.children) {
    setState({
      ...state,
      child: props.children || null,
    });
  }

  const { index, lastChild, transition } = state;
  const { className, pageClassName, style } = props;
  const elements: Array<React.ReactElement> = [];
  const child = transition ? state.child : props.children;

  if (pageClassName) {
    if (lastChild) {
      elements.push(
        <div className={pageClassName} key={index - 1} ref={transition ? (transition.lastChildRef as any) : undefined}>
          {lastChild}
        </div>
      );
    }

    if (child) {
      elements.push(
        <div
          className={cx(pageClassName, 'current')}
          key={index}
          ref={transition ? (transition.childRef as any) : undefined}
        >
          {child}
        </div>
      );
    }
  } else {
    if (lastChild) {
      elements.push(
        React.createElement(lastChild.type, {
          ...lastChild.props,
          key: index - 1,
          ref: transition ? transition.lastChildRef : undefined,
        })
      );
    }

    if (child) {
      elements.push(
        React.createElement(child.type, {
          ...child.props,
          key: index,
          ref: transition ? transition.childRef : undefined,
        })
      );
    }
  }

  transition?.begin(() => {
    setState({
      ...state,
      lastChild: null,
      transition: null,
    });
  });

  return (
    <div className={className} style={style}>
      {elements}
    </div>
  );
}

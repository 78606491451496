import { gql, useQuery } from "@apollo/client";
import QuestionType from "@legalosApi/types/QuestionType";

const GET_USER_VALUE_DETAILS = gql`
  query GetUserValueDetails($key: String!) {
    getUserValueDetails(key: $key) {
      key
      type
      options
      optionsLocalized
      affectedQuestionnaireIds
    }
  }
`;

export interface UserValueDetailsInput {
  key: string;
}

export interface UserValueDetailsResult {
  getUserValueDetails: {
    key: string;
    type: QuestionType;
    options: string[];
    optionsLocalized: string[];
    affectedQuestionnaireIds: string[];
  };
}

export function useUserValueDetailsQuery(key: string) {
  return useQuery<UserValueDetailsResult, UserValueDetailsInput>(GET_USER_VALUE_DETAILS, {
    variables: { key },
  });
}

import { gql } from '@apollo/client';
import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';

export const TOGGLE_SHARED_QUESTION_STATE = gql`
  mutation questionSharedStateUpdate($input: QuestionSharedStateUpdateInput) {
    questionSharedStateUpdate(input: $input) {
      ...QuestionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

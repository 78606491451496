import { useNavigation } from '@app/hooks/useNavigation';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cx from 'classnames';
import { MessageStep } from '../Quest/utils';
import './navigation.sass';

export function NavigationMobile() {
  const {
    navigation: { scope, steps, stepIndex, onAdvance },
  } = useNavigation();

  const isQuestScope = scope === 'quest';

  const handleForwardClick = () => {
    onAdvance(steps[stepIndex].id, 'forward');
  };

  const handleBackwardClick = () => {
    onAdvance(steps[stepIndex].id, 'backward');
  };

  const nextStep = steps[stepIndex] as MessageStep;
  const backwardEnabled = stepIndex > 0;
  const forwardEnabled = stepIndex < steps.length - 1 && nextStep?.question.state !== QuestionnaireState.UNTOUCHED;

  return (
    <div className="dtNavigationMobile md:hidden">
      <div className="flex gap-20 mx-auto">
        {isQuestScope && (
          <div className="dtNavigation_chevrons flex justify-center gap-16">
            <button
              className={cx('hover:scale-125 hover:text-primary-root text-gray-500 transition-all', {
                hidden: !backwardEnabled,
              })}
              onClick={handleBackwardClick}
            >
              <ArrowBackIosIcon />
            </button>

            <button
              className={cx('hover:scale-125 hover:text-primary-root text-gray-500 transition-all', {
                hidden: !forwardEnabled,
              })}
              onClick={handleForwardClick}
            >
              <ArrowForwardIosIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

import { useErrorCreateMutation } from '@dieterApi/error/useErrorCreateMutation';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar as useSnackbarOriginal } from 'notistack';

export interface OptionsObjectWithStack extends OptionsObject {
  stack?: string;
}

export function useSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbarOriginal();
  const [createError] = useErrorCreateMutation();

  const enqueueSnackbarWithErrorLogging = (
    message: SnackbarMessage,
    options?: OptionsObjectWithStack | undefined
  ): SnackbarKey => {
    if (options?.variant === 'error') {
      createError({
        variables: { message: message?.toString() || 'no message', stack: options?.stack || 'no stacktrace' },
      });
    }
    return enqueueSnackbar(message, {
      ...options,
    });
  };

  return { enqueueSnackbar: enqueueSnackbarWithErrorLogging, closeSnackbar };
}

import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { useSnackbar } from '@app/hooks/useSnackbar';
import OutputView from '@components/block/OutputView/OutputView';
import { AlertDialog, Spinner } from '@components/ui';
import VirtualizedAutocomplete from '@components/ui/VirtualizedAutocomplete';
import { CompanySubscription, useCompaniesQuery } from '@dieterApi/company/useCompanyQuery';
import { useQuestionnaireDocumentShareQuery } from '@dieterApi/questionnaire/useQuestionnaireDocumentShareQuery';
import {
  QuestionnaireSummary,
  useQuestionnaireSummaryQuery,
} from '@dieterApi/questionnaire/useQuestionnaireSummaryQuery';
import { useUpgradeQuestionnaireMutation } from '@dieterApi/questionnaire/useUpgradeQuestionnaireMutation';
import { User, UserQuestionnaireApplication } from '@dieterApi/user/useUserQuery';
import { ISelectOption } from '@legalosApi/types/ISelectOption';
import { Checkbox, Divider, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './route-admin.sass';

interface Props {
  user?: User | null;
}

interface RowMeta {
  dataIndex: number;
  rowIndex: number;
}

interface ICompanySelectorOption extends ISelectOption {
  subscription: CompanySubscription;
}

export function AdminDocuments({ user }: Props) {
  const { t } = useTranslation();
  // Show snackbar when error occurs
  const { enqueueSnackbar } = useSnackbar();

  // State for upgradable questionnaire
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [upgradableQuestionnaire, setUpgradableQuestionnaire] = useState<QuestionnaireSummary | null>(null);

  // Fetch all companies from server
  // State for controlling the display of only active companies
  const [onlyActive, setOnlyActive] = useState(true);
  const { data: cData, loading: cLoading } = useCompaniesQuery({ variables: { onlyActive } });
  const [thisCompany, setThisCompany] = useState<ICompanySelectorOption | null>(null);

  // Fetch questionnaires from server
  const [getQuestionnaires, { data: qData, loading }] = useQuestionnaireSummaryQuery();
  const companiesForSelect = cData?.getCompanies.map((c) => {
    return { value: c.id, label: c.name, origLabel: c.name, subscription: c.subscription } as ICompanySelectorOption;
  });
  const questionnaires = qData?.getQuestionnaireSummary;

  // Fetch share token for selected questionnaire
  const { generatePath } = useQuestionaireRoute();
  const [getShareToken, { data: shareTokenData }] = useQuestionnaireDocumentShareQuery();
  const urlToken = shareTokenData?.getQuestionnaireShareToken;

  // Upgrade questionnaire version
  const [upgradeQuestionnaire, { loading: upgradeLoading }] = useUpgradeQuestionnaireMutation();

  // State for selected questionnaire and opened questionnaire
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<QuestionnaireSummary | null>(null);
  const [openedQuestionnaire, setOpenedQuestionnaire] = useState<UserQuestionnaireApplication | null>();

  // @Wasim: This should be triggered AFTER a company has been selected
  useEffect(() => {
    getQuestionnaires({ variables: { companyId: thisCompany?.value } });
  }, [thisCompany?.value]);

  // Update selected questionnaire when questionnaires change
  useEffect(() => {
    if (selectedQuestionnaire) {
      const refreshedQuestionnaire = questionnaires?.find((q) => q.id === selectedQuestionnaire.id);
      refreshedQuestionnaire && setSelectedQuestionnaire(refreshedQuestionnaire);
    }
  }, [qData]);

  // Get new share token when opened questionnaire changes
  useEffect(() => {
    if (openedQuestionnaire) {
      getShareToken({
        variables: {
          applicationId: openedQuestionnaire.application.id,
          type: 'Questionnaire',
          forCompanyId: openedQuestionnaire.companyId,
        },
      });
    }
  }, [openedQuestionnaire]);

  // Handle upgrade button click
  const handleUpgrade = () => {
    upgradableQuestionnaire &&
      upgradeQuestionnaire({ variables: { localQuestionnaireId: upgradableQuestionnaire?.id } }).catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      });
  };

  // Define columns for MUIDataTable
  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'id',
      label: t('route.admin.documents-table.id-label', 'ID'),
      options: {
        display: false,
      },
    },
    {
      name: 'createdAt',
      label: t('route.admin.documents-table.created-at-label', 'Erstellt'),
      options: {
        customBodyRender: (value) => {
          return new Date(value).toLocaleString();
        },
      },
    },
    {
      name: 'updatedAt',
      label: t('route.admin.documents-table.updated-at-label', 'Geändert'),
      options: {
        customBodyRender: (value) => {
          return new Date(value).toLocaleString();
        },
      },
    },
    {
      name: 'label',
      label: t('route.admin.documents-table.label-label', 'Anwendung'),
    },
    {
      name: 'isBroken',
      label: t('route.admin.documents-table.is-broken-label', 'Zustand'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const isBroken = value;
          const version = tableMeta.rowData[5];
          const versionDiff = version.current !== version.max;

          return isBroken || versionDiff ? (
            <button
              className={cx(
                ' rounded-lg  text-center text-sm px-2 ',
                isBroken
                  ? 'bg-red-100 text-red-600 hover:bg-red-200'
                  : 'bg-primary-100 text-primary-root hover:bg-primary-200'
              )}
              onClick={() => {
                const questionnaire = questionnaires?.find((q) => q.id === tableMeta.rowData[0]);
                if (!questionnaire) return;
                setUpgradableQuestionnaire(questionnaire);
                setOpenUpgradeDialog(true);
              }}
            >
              {isBroken ? 'Broken' : 'Upgrade'}
            </button>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'version',
      label: t('route.admin.documents-table.version-label', 'Version'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          if (upgradeLoading && upgradableQuestionnaire?.id === id) return <Spinner size="small" />;

          return `${value.current} / ${value.max}`;
        },
      },
    },
    {
      name: 'versionDate',
      label: t('route.admin.documents-table.version-date-label', 'Versionsdatum'),
      options: {
        customBodyRender: (value) => {
          return new Date(value).toLocaleDateString();
        },
      },
    },
    {
      name: 'progress',
      label: t('route.admin.documents-table.progress-label', 'Fortschritt'),
      options: {
        customBodyRender: (value) => {
          return <LinearProgressWithLabel value={value * 100} />;
        },
      },
    },
    {
      name: 'companyName',
      label: t('route.admin.documents-table.company-name-label', 'Firma'),
    },
  ];

  const data = questionnaires?.map((q) => {
    return { ...q, version: { current: q.version, max: q.maxVersion } };
  });

  // Handle row click in MUIDataTable
  const handleClick = (rowData: string[], { dataIndex }: RowMeta) => {
    if (questionnaires) {
      const questionnaire = questionnaires[dataIndex];
      setSelectedQuestionnaire(questionnaire);
      setOpenedQuestionnaire(null);
    }
  };

  // Define options for MUIDataTable
  const options: MUIDataTableProps['options'] = {
    download: false,
    print: false,
    elevation: 2,
    onRowClick: handleClick,
    selectableRows: 'none',
    selectableRowsHeader: false,
    onRowsDelete: () => false,
  };

  return (
    <div className="dtRouteAdminPanel flex flex-col gap-20">
      <div className="flex gap-4">
        {cLoading ? <Spinner size="small" /> : null}

        <VirtualizedAutocomplete
          className="grow"
          multiple={false}
          options={companiesForSelect || []}
          value={thisCompany}
          onChange={(_, val) => {
            setThisCompany(val as ICompanySelectorOption);
          }}
        />

        {/* Checkbox to toggle onlyActive state */}
        <FormControlLabel
          control={<Checkbox checked={onlyActive} onChange={(e) => setOnlyActive(e.target.checked)} />}
          label={t('route.admin.documents-table.active_checkbox')}
        />
      </div>

      {user?.isAdmin ? (
        <>
          {/* Render MUIDataTable */}

          {thisCompany?.value ? (
            <div>
              {loading ? (
                <Spinner />
              ) : (
                <MUIDataTable
                  columns={columns}
                  data={data!}
                  title={t('route.admin.documents-table.title', 'Alle Dokumente')}
                  options={options}
                />
              )}
            </div>
          ) : (
            <span>
              {t(
                'route.admin.documents-table.note.missing-selected-company-message',
                'Company auswählen um Dokument einzusehen'
              )}
            </span>
          )}

          {/* Render selected questionnaire */}
          {selectedQuestionnaire ? (
            <div className="flex lg:flex-row flex-col-reverse gap-20">
              <div className="flex-1 paper">
                <OutputView
                  localQuestionnaireId={selectedQuestionnaire.id}
                  onOpenQuestionnaire={(questionnaire) => setOpenedQuestionnaire(questionnaire)}
                />
              </div>
              <div className="flex-1 paper p-5 flex flex-col gap-10">
                {/* Render link to open questionnaire in LegalOs app */}
                <Divider />
                <a
                  className="hover:underline text-primary-root hover:text-primary-800"
                  href={`https://dieter.legalos.io/documents/${selectedQuestionnaire.questionnaireId}/edit`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('route.admin.documents-table.action.open-in-legalOS', 'In LegalOs öffnen')}
                </a>

                {/* Render link to edit questionnaire in LegalOs app */}
                {urlToken && (
                  <Link
                    to={generatePath({
                      questionnaireId: selectedQuestionnaire.questionnaireId,
                      section: undefined,
                      token: urlToken,
                      revisit: true,
                    })}
                  >
                    {t('route.admin.documents-table.action.edit-questionnaire-in-app', 'In App bearbeiten')}
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <span>
              {t(
                'route.admin.documents-table.note.missing-selected-questionnaire-message',
                'Zeile auswählen um Dokument einzusehen'
              )}
            </span>
          )}
          <AlertDialog
            open={openUpgradeDialog}
            setOpen={setOpenUpgradeDialog}
            onAccept={() => handleUpgrade()}
            message={t('route.admin.upgrade-dialog-message', 'Möchtest du die Fragebogenversion aktualisieren?')}
            acceptText={t('route.common.upgrade')}
          />
        </>
      ) : (
        <h1 className="uppercase">{t('common.unauthorized', 'UNAUTHORIZED')}</h1>
      )}
    </div>
  );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

import { AnyStep } from '../Quest/utils';
import './navigation.sass';

export interface Props {
  stepIndex: number;
  steps: Array<AnyStep>;
}

export function ProgressBar({ stepIndex, steps }: Props) {
  const currentSection = stepIndex > -1 && steps[stepIndex].parents[0]._id;

  // boolean map for each section, true if section is active
  const sectionMap = steps.map((step) => {
    return step.parents.some((section) => {
      return section._id === currentSection;
    });
  });

  const progress = (stepIndex - sectionMap.indexOf(true)) / (sectionMap.lastIndexOf(true) - sectionMap.indexOf(true));

  return <div className="dtNavigation_progress" style={{ width: (progress * 100).toFixed(2) + '%' }} />;
}

import { gql, useLazyQuery } from '@apollo/client';
import { FILE_FIELDS, UserFile } from '@dieterApi/user/useUserQuery';
import download from 'downloadjs';

const DOWNLOAD_FILE = gql`
  ${FILE_FIELDS}
  query DownloadFile($id: String!, $token: String) {
    getFileDownload(id: $id, token: $token) {
      ...UserFileFields
    }
  }
`;

export interface FileDownloadInput {
  id: string;
  token?: string;
}

export interface FileDownloadResult {
  getFileDownload: UserFile;
}

export function useFileDownloadQuery() {
  return useLazyQuery<FileDownloadResult, FileDownloadInput>(DOWNLOAD_FILE, {
    onCompleted: (data) => {
      const file = data.getFileDownload;
      file.url &&
        fetch(file.url)
          .then((res) => res.blob())
          .then((blob) => {
            download(blob, file.name);
          });
    },
  });
}

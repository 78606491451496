import cx from 'classnames';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
// import { Complete } from './Funnel/Complete';
// import { OrderProcessing } from './Funnel/OrderProcessing';
// import { Pricing } from './Funnel/Pricing';
// import { SubscriptionForm } from './Funnel/SubscriptionForm';

import { NavigationSection } from '@app/context/constants';
import { sectionUrl } from '@app/context/utils';
import { useNavigation } from '@app/hooks/useNavigation';
import SentryRoute from '@components/SentryRoute.ts';
import { MultiTenant } from './MultiTenant/MultiTenant';
import { Overview } from './Overview';
import { Status } from './Status';
import { Summary } from './Summary/Summary';
import { DoneTasks } from './Tasks/DoneTasks';
import { TopicRoutes } from './Topic/TopicRoutes';
import { Training } from './Training/Training';
import { OpenTaskType, UserFlowItemType } from './constants';
import './route-dashboard.sass';
import { Dashboard } from './useDashboard';
interface Props {
  dashboard: Dashboard;
}

export function SectionBody({ dashboard }: Props) {
  const { path } = useRouteMatch();

  const {
    navigation: { activeSection },
  } = useNavigation();

  return (
    <div className={cx('w-full', activeSection === NavigationSection.Status && 'bg-primary-200')}>
      {/* {content} */}
      <Switch>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.Overview)}`}>
          <Overview />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.DoneTasks)}`}>
          <DoneTasks />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.Status)}`}>
          <Status />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.QuestionnaireList)}`}>
          <Summary dashboard={dashboard} type={UserFlowItemType.questionnaire} />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.TaskList)}`}>
          <Summary dashboard={dashboard} type={UserFlowItemType.task} />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.DocumentList)}`}>
          <Summary dashboard={dashboard} type={UserFlowItemType.document} />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.OpenTaskList)}`}>
          <Summary dashboard={dashboard} type={OpenTaskType.openTask} />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.Training)}`}>
          <Training />
        </SentryRoute>
        <SentryRoute path={`${path}${sectionUrl(NavigationSection.MultiTenant)}`}>
          <MultiTenant />
        </SentryRoute>
        <SentryRoute path={`${path}/topic/:topicPath?/:subSectionPath?`}>
          <TopicRoutes dashboard={dashboard} />
        </SentryRoute>
        <SentryRoute path={`${path}`}>
          <Redirect to={`${path}${sectionUrl(NavigationSection.Overview)}`} />
        </SentryRoute>
      </Switch>
    </div>
  );
}

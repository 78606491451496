// mutation for creating an error

import { gql, useMutation } from '@apollo/client';

const TOGGLE_MFA = gql`
  mutation TogleMFA($enable: Boolean!) {
    toggleMFA(enable: $enable)
  }
`;

export interface ToggleMFAResult {
  toggleMFA: boolean;
}

export interface ToggleMFAInput {
  enable: boolean;
}

export function useMFAToggleMutation() {
  return useMutation<ToggleMFAResult, ToggleMFAInput>(TOGGLE_MFA, {
    refetchQueries: ['GetUser'],
  });
}

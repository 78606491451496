import * as React from "react";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import Autocomplete, { IAutocompleteProps } from "@components/ui/Autocomplete/Autocomplete";

import VirtualizedListbox from "./VirtualizedListbox";
import { matchSorter } from "match-sorter";
import { ChipTypeMap } from "@mui/material";
import { useMemo, useState } from "react";

function VirtualizedAutocomplete<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({ renderOption, value, ...other }: IAutocompleteProps<Multiple, DisableClearable, ChipComponent>) {
  const [search, setSearch] = useState<string>();

  // @ts-expect-error
  const query = useMemo(() => value?.lebal || search, [search, value]);

  return (
    <Autocomplete
      {...other}
      value={value}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={VirtualizedListbox}
      onInputChange={(_, val) => setSearch(val)}
      renderOption={(props, option, state) => [props, option, state, query, value] as React.ReactNode}
      filterOptions={(options, { inputValue }) => matchSorter(options, query || inputValue, { keys: ["label"] })}
    />
  );
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export default VirtualizedAutocomplete;

import { gql } from '@apollo/client';
import { SETTING_FRAGMENT, UserCentricsSetting } from './getSetting';

export const ADD_TECHNOLOGY = gql`
  ${SETTING_FRAGMENT}
  mutation AddTechnology($settingsId: String!, $isSitemap: Boolean!, $url: String) {
    addTechnology(settingsId: $settingsId, isSitemap: $isSitemap, url: $url) {
      ...SettingFields
    }
  }
`;

export interface AddTechnologyInput {
  settingsId: string;
  isSitemap: boolean;
  url?: string;
}

export interface AddTechnologyResult {
  addTechnology: UserCentricsSetting;
}

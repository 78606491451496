import { pickLatest } from '@app/utils/pickLatest';
import { calculateTopicStatus } from '@app/utils/topicStatus';
import { ApplicationType, User, UserTopicStatusApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';

export function getTopicStates(topic: UserTopicStatusApplicationsQuestionnaires, user: User | undefined) {
  const apps = topic.applications.filter((app) =>
    [ApplicationType.Primary, ApplicationType.Secondary].includes(app.type)
  );
  const primaryApp = apps.find((app) => app.type === ApplicationType.Primary);
  const noApps = apps.length === 0;
  const isExperimental = topic.tags.map((t) => t.tag).includes('experimental') && !user?.experimental;
  const isAllOpen = topic.tags.map((t) => t.tag).includes('allopen');
  const hasFiles = !topic?.tags.map((t) => t.tag).includes('nofiles');
  const questionnaires = apps?.flatMap((a) => a.questionnaires || null) || [];
  const primaryQuestionnaires = apps.find((q) => q.type === ApplicationType.Primary)?.questionnaires || [];
  const noQuestionnaires = questionnaires.length === 0;
  const latestPrimaryQuestionnaire = pickLatest(primaryQuestionnaires);
  const questionnairesExisting = !noQuestionnaires;
  const primaryQuestionnaireDone = latestPrimaryQuestionnaire?.progress === 1;
  const anyQuestionnaireDone = apps.some((app) => app.questionnaires?.some((q) => q.progress === 1));
  const allQuestionnairesDone =
    questionnairesExisting && apps.every((app) => app.questionnaires?.some((q) => q.progress === 1));
  const showTeaser = !questionnairesExisting && !isExperimental && !noApps && (user?.company.isPrincipal || false);
  const isCheckUp = topic.id === 'topic-checkup';
  const isLocked = calculateTopicStatus(topic, undefined, user?.userValues) === 'DISABLED';

  return {
    apps,
    primaryApp,
    noApps,
    isExperimental,
    isAllOpen,
    hasFiles,
    questionnaires,
    primaryQuestionnaires,
    noQuestionnaires,
    latestPrimaryQuestionnaire,
    questionnairesExisting,
    anyQuestionnaireDone,
    primaryQuestionnaireDone,
    allQuestionnairesDone,
    showTeaser,
    isCheckUp,
    isLocked,
  };
}

import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import './newbadge.sass';

export function NewBadge() {
  const { t } = useTranslation();
  // Updated animation for scaling
  const props = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.2)' },
    // Specify the reverse option to scale back to original size
    reverse: true,
    config: { duration: 500 }, // Duration in milliseconds
  });
  return (
    <animated.div
      style={props}
      className="bg-danger rounded-full text-white flex items-center text-xs h-fit px-[7px] py-[2px]"
    >
      {t('components.ui.newbadge.new')}
    </animated.div>
  );
}

export function NewVersionBadge() {
  const { t } = useTranslation();
  const props = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.2)' },
    // Specify the reverse option to scale back to original size
    reverse: true,
    config: { duration: 500 }, // Duration in milliseconds
  });
  return (
    <animated.div style={props} className="bg-primary-root rounded-full text-white flex items-center text-xs h-fit">
      {t('components.ui.newbadge.newversion')}
    </animated.div>
  );
}

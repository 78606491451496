import { useSnackbar } from '@app/hooks/useSnackbar';
import Error from '@app/routes/Error/Error';
import { Button, Spinner } from '@components/ui';
import { useUserConfirmMutation } from '@dieterApi/user/useUserConfirmMutation';
import { useConfirmSendEmailMutation } from '@dieterApi/user/useUserConfirmSendMutation';
import { User } from '@dieterApi/user/useUserQuery';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import './route-confirmation.sass';

interface Props {
  user?: User | null;
}

export default function Confirmation({ user }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mailSent, setMailSent] = useState(false);

  const [sendMail, { loading: sendMailLoading }] = useConfirmSendEmailMutation();
  const [confirm, { loading: confirmLoading, data: confirmData }] = useUserConfirmMutation();
  const loading = sendMailLoading || confirmLoading;

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  // send confirmation mail when not sent before
  useEffect(() => {
    if (!mailSent && user?.confirmed === false && user?.mailSent === 0) {
      sendMail().catch((e) => {
        // TODO/WM/ASK: it will be better for "multi lang" to map error codes to messages
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
      });
      setMailSent(true);
    }
  }, [mailSent]);

  // confirm user when confirmation link is clicked
  useEffect(() => {
    const token = query.get('token');

    if (token) {
      if (!user || user?.confirmed === false) {
        confirm({ variables: { token } })
          .then(() => {
            enqueueSnackbar(
              t('route.confirmation.success_message', 'Deine E-Mail-Adresse wurde erfolgreich bestätigt.'),
              { variant: 'success' }
            );
            setTimeout(() => window.location.reload(), 2500);
          })
          .catch((e) => {
            // TODO/WM/ASK: it will be better for "multi lang" to map error codes to messages
            enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
          });
      }
      // user?.confirmed === true &&
      //   !justConfirmed &&
      //   enqueueSnackbar('Deine E-Mail-Adresse ist bereits bestätigt.', { variant: 'info' });
    }
  }, [query]);

  // // disable navigation header for this view
  // useEffect(() => {
  //   setNavigation((nav) => void (nav.scope = undefined));
  // }, []);

  // redirect to dashboard if user is confirmed
  useEffect(() => {
    user?.confirmed && history.push('/');
  });

  return (
    <div className="dtRouteConfirmation flex flex-col justify-center items-center">
      <div className="dtRouteConfirmation__panel">
        {loading ? (
          // User information is still loading
          <div>{t('common.loading', 'Loading...')}</div>
        ) : confirmData ? (
          // User is not logged in but was confirmed
          <div className="ga4-event-confirm-email-success">
            <Trans t={t} i18nKey="route.confirmation.confirmed_and_logged_out">
              <strong>Deine E-Mail Adresse wurde bestätigt. </strong>
              <div className="mb-20">Dieter wird sich gleich um deinen Datenschutz kümmern!</div>
            </Trans>
            <Spinner />
          </div>
        ) : user?.confirmed === false ? (
          // User is logged in but not confirmed
          <>
            <Trans t={t} i18nKey="route.confirmation.not_confirmed_and_logged_in">
              <p>Nur noch ein kleiner Schritt!</p>
              <div className="mb-20 mt-20 ga4-event-confirm-email-request">
                Bitte öffne den Link in der Bestätigungsmail, die wir dir soeben gesendet haben.
              </div>
            </Trans>
            <Button onClick={() => sendMail()} disabled={loading} data-testid="button-resend">
              {t('route.confirmation.send_again', 'Erneut senden')}
            </Button>
          </>
        ) : (
          // All other cases
          <Error />
        )}
      </div>
    </div>
  );
}

import { useMemo, useState } from 'react';

import { ExpandLess, ExpandMore, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';

import { isNumber } from 'lodash';
import { useLocalizedTranslation } from './Translation';

interface Props {
  value?: number;
  onSelect?(val: number): void;
  locale: string;
}
function TenantsSelect({ value, onSelect, locale }: Props) {
  const { t: tl } = useLocalizedTranslation(locale);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const options = [
    {
      value: 0,
      label: tl('tenants_select.0'),
    },
    {
      value: 1,
      label: tl('tenants_select.1'),
    },
    {
      value: 5,
      label: tl('tenants_select.5'),
    },
    {
      value: 10,
      label: tl('tenants_select.10'),
    },
  ];

  const selectedIndex = useMemo(() => {
    const index = options.findIndex((opt) => opt.value === value);
    return index === -1 ? undefined : index;
  }, [options, value]);

  const handleMenuItemClick = (selected: (typeof options)[number]) => {
    onSelect?.(selected.value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" sx={{ bgcolor: 'background.paper', width: '100%' }}>
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-expanded={open ? 'true' : undefined}
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 1,
            width: '100%',
            borderRadius: 1.5,
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="inherit" className="w-full max-w-[210px] truncate">
            {isNumber(selectedIndex) ? options[selectedIndex].label : 'Paket auswählen'}
          </Typography>
          <ListItemIcon sx={{ justifyContent: 'flex-end', minWidth: '0px' }}>
            {open ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </ListItemIcon>
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'lock-button', role: 'listbox' }}
        slotProps={{ paper: { sx: { minWidth: anchorEl?.offsetWidth } } }}
      >
        {options.map((option, index) => (
          <MenuItem key={option.value} onClick={() => handleMenuItemClick(option)}>
            <ListItemIcon>
              {index === selectedIndex ? (
                <RadioButtonChecked fontSize="small" color="primary" />
              ) : (
                <RadioButtonUnchecked fontSize="small" />
              )}
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default TenantsSelect;

// queries.js
import { gql, useSubscription } from '@apollo/client';
import { ChatMessage } from '@app/hooks/types';

export const CHAT_STREAM_SUBSCRIPTION = gql`
  subscription OnChatStream($chatId: String!) {
    chatStream(chatId: $chatId) {
      role
      content
    }
  }
`;

interface ChatStreamSubscriptionResult {
  chatStream: ChatMessage;
}

interface ChatStreamSubscriptionVariables {
  chatId: string;
}

export const useChatStreamSubscription = ({ chatId, onData }: { chatId: string; onData: () => void }) => {
  return useSubscription<ChatStreamSubscriptionResult, ChatStreamSubscriptionVariables>(CHAT_STREAM_SUBSCRIPTION, {
    variables: {
      chatId,
    },
    // onData,
  });
};

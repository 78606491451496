import { gql, useMutation } from '@apollo/client';
import { FILE_FIELDS, UserFile } from '@dieterApi/user/useUserQuery';

export interface DieterFile {
  id: string;
  name: string;
  size: number;
  uri: string;
}

const UPLOAD_FILE = gql`
  ${FILE_FIELDS}
  mutation UploadFile($file: Upload!, $size: Int!, $topicId: String!, $token: String) {
    uploadFile(file: $file, size: $size, topicId: $topicId, token: $token) {
      ...UserFileFields
    }
  }
`;

export interface FileUploadInput {
  file: File;
  size: number;
  topicId: string;
  token?: string;
}

export interface FileUploadResult {
  uploadFile: UserFile;
}

export function useFileUploadMutation() {
  return useMutation<FileUploadResult, FileUploadInput>(UPLOAD_FILE, {
    refetchQueries: ['GetUser'],
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
    // update: (cache, { data }) => {
    //   cache.modify({
    //     fields: {
    //       getUser(existingUser: User) {
    //         const newUser = {
    //           ...existingUser,
    //           topics: existingUser.topics?.map((topic) => {
    //             return { ...topic, files: [...topic.files, data?.uploadFile] };
    //           }),
    //         };
    //         return newUser;
    //       },
    //     },
    //   });
    // },
  });
}

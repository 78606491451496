import { FileItem, FileUpload, NotificationModal } from '@components/ui';
import { UserFile, UserTopic } from '@dieterApi/user/useUserQuery';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { PartnerTableMeta } from './typesAndEnums';

export const SignedContractModal = ({
  topic,
  tableMeta,
  ...props
}: {
  open: boolean;
  onClose: () => void;
  topic: UserTopic;
  tableMeta?: PartnerTableMeta;
}) => {
  const [addUserValue] = useUserValueAddMutation();
  const index = tableMeta?.rowData[2] as string;

  const signedContractFile = tableMeta?.rowData[11];

  const handleSignedContractUpload = (file: UserFile) => {
    addUserValue({
      variables: {
        key: 'DATA_PROCESSOR_SIGNED_CONTRACT',
        value: file.id,
        index,
      },
    });
  };

  return (
    <NotificationModal {...props}>
      <div className="flex flex-col gap-4 p-8">
        <h3 className="font-medium text-xl">Unterschriebenen Vertrag verwalten</h3>
        <div className="">Hier kannst du den unterschriebenen Vertrag hochladen:</div>
        <FileUpload topicId="topic-av" onComplete={handleSignedContractUpload} />
        {signedContractFile && (
          <p className="mt-6">
            <strong>Unterschriebener Vertrag:</strong>
            <FileItem file={signedContractFile} withActions={true} />
          </p>
        )}
      </div>
    </NotificationModal>
  );
};

import { SectionItem } from '@app/routes/Dashboard/SectionItem.tsx';
import { calculateTopicStatus } from '@app/utils/topicStatus.ts';
import { Divider } from '@mui/material';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useUser } from '@app/context/UserContext.tsx';

import { useNavigation } from '@app/hooks/useNavigation.ts';
import { useCallback, useRef, useState } from 'react';

import { useTasks } from '@app/context/TaskContext';
import { ApplicationStatus, User } from '@dieterApi/user/useUserQuery.ts';

function SideMenuList() {
  const { t } = useTranslation();
  const { user } = useUser();
  const tasks = useTasks();
  const { navigation } = useNavigation();
  const { isMobile } = navigation;
  const { activeSection } = navigation;

  const [isScrolled, setIsScrolled] = useState(false);

  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  // height calculations for scrollable navigation items
  const itemHeights = itemsRef.current.map((item) => item?.clientHeight || 0);

  const itemContainerRef = useRef<HTMLDivElement>(null);
  const itemContainerRefCallback = useCallback<(node: HTMLDivElement) => void>(
    (node) => {
      if (node !== null) {
        node.onscroll = null;
        // @ts-ignore
        itemContainerRef.current = node;
        node.addEventListener('scroll', () => {
          const { scrollTop } = node;
          setIsScrolled(scrollTop > 0);
        });
      }
    },
    [activeSection]
  );

  const scrollOnClick = (index: number, offset?: number) => {
    return () =>
      index > 2
        ? itemContainerRef.current?.scrollTo(
            0,
            itemHeights.slice(0, Math.min(index - 2)).reduce((a, b) => a + b, 0) + (offset || 0)
          )
        : itemContainerRef.current?.scrollTo(0, 0);
  };

  // identify the topic which contains the onboarding app. this needs to receive a special status while not a customer yet
  const onboardingTopic = user?.topics.find((t) => t.applications.find((a) => a.id === navigation.onboarding.appId));

  const analysisTopics =
    user?.topics
      .filter((topic) => topic.tags.map((tag) => tag.tag).includes('analysis'))
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .filter((t) => !t.tags.some((tag) => tag.tag === 'experimental-invisible' && !user.experimental)) || [];
  const actionTopics =
    user?.topics
      .filter((topic) => topic.tags.map((tag) => tag.tag).includes('action'))
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .filter((t) => !t.tags.some((tag) => tag.tag === 'experimental-invisible' && !user.experimental)) || [];

  const enabledActionTopics = actionTopics.filter(
    (t) => t.status !== ApplicationStatus.DISABLED || t.id === onboardingTopic?.id
  );
  const disabledActionTopics = actionTopics.filter(
    (t) => t.status === ApplicationStatus.DISABLED && t.id !== onboardingTopic?.id
  );

  return (
    <div className="dtRouteDashboard__sectionList">
      <div
        className={cx('dtRouteDashboard__sectionList__items', {
          isScrolled,
          'h-[calc(100vh_-_92px)] pt-4 pb-12': isMobile,
        })}
        ref={!isMobile ? itemContainerRefCallback : undefined}
      >
        <div className="flex flex-col">
          <div className="ml-2 text-gray-500 text-sm" ref={(el) => (itemsRef.current[0] = el)}>
            {t('route.dashboard.subheader.analysis', 'Analyse')}
          </div>
          {analysisTopics.map((topic, i) => {
            return (
              <SectionItem
                key={topic.id}
                topic={topic}
                status={calculateTopicStatus(topic, undefined, user?.userValues, tasks)}
                ref={(el) => (itemsRef.current[i + 1] = el)}
                scrollOnClick={scrollOnClick(i + 1)}
                showStatusColor={false}
              />
            );
          })}
          <Divider sx={{ margin: '10px' }} />
          <div className="ml-2 text-gray-500 text-sm" ref={(el) => (itemsRef.current[1 + analysisTopics.length] = el)}>
            {t('route.dashboard.subheader.your-actions', 'Deine Maßnahmen')}
          </div>
          {enabledActionTopics.map((topic, i) => {
            const isOnboardingTopic = topic.id === onboardingTopic?.id;
            return (
              <SectionItem
                key={topic.id}
                topic={topic}
                status={calculateTopicStatus(topic, isOnboardingTopic, user?.userValues, tasks)}
                ref={(el) => (itemsRef.current[i + 2 + analysisTopics.length] = el)}
                scrollOnClick={scrollOnClick(i + 2 + analysisTopics.length, 11)}
                showStatusColor={userFinishedDSMgmt(user)}
              />
            );
          })}
          {disabledActionTopics.map((topic, i) => {
            return (
              <SectionItem
                key={topic.id}
                topic={topic}
                status={calculateTopicStatus(topic)}
                ref={(el) => (itemsRef.current[i + 2 + analysisTopics.length] = el)}
                scrollOnClick={scrollOnClick(i + 2 + analysisTopics.length, 11)}
                showStatusColor={userFinishedDSMgmt(user)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const userFinishedDSMgmt = (user: User | undefined) => {
  return user?.questionnaires?.find((q) => q.application.id === 'app-ds-mgmt')?.progress === 1;
};

export default SideMenuList;

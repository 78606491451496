import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import './closebutton.sass';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  offset?: boolean;
  btnColor?: 'primary' | 'secondary' | 'white' | 'gray' | 'red';
  fontSize?: 'small' | 'medium' | 'large';
  tooltip?: string;
}

export function CloseButton({ onClick, offset = true, btnColor = 'primary', fontSize = 'medium', ...props }: Props) {
  return (
    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={props.tooltip || undefined}>
      <div className={cx('dtCloseButton', { offset })}>
        <button onClick={onClick} {...props} className={cx(btnColor)} data-testid="button-close">
          <CloseIcon fontSize={fontSize} />
        </button>
      </div>
    </Tooltip>
  );
}

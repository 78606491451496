import { useUser } from '@app/context/UserContext';
import { Button, NotificationModal } from '@components/ui';
import { UserRole } from '@dieterApi/user/useUserQuery';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListItemText,
  MenuItem,
  ModalProps,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<ModalProps, 'children' | 'onSubmit'> {
  userValues?: any[];
  onSubmit(role: UserRole, enabledCompanies: string[]): void;
}

function UserManagementUpdateModal(props: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { userValues = [], onSubmit, ...rest } = props;
  const hasAll = userValues[3].length === user?.companies?.length;
  const [role, setRole] = useState<UserRole>(userValues[2]);
  const [enabledCompanies, setEnabledCompanies] = useState<string[]>(userValues[3].map((c: any) => c.id));
  const [all, setAll] = useState(hasAll);
  const availableCompanies = user?.companies;

  const isAdmin = role === UserRole.Admin;
  useEffect(() => {
    if (all) {
      setEnabledCompanies(availableCompanies?.map((company) => company.id) || []);
    } else {
      setEnabledCompanies(userValues[3].map((c: any) => c.id));
    }
  }, [all]);

  useEffect(() => {
    if (isAdmin) {
      setAll(true);
    }
  }, [isAdmin]);

  return (
    <NotificationModal {...rest}>
      <div className="flex flex-col gap-5 p-3 md:p-12">
        <div className="h3">{t('route.user-management.update-modal.header', 'Update User Role')}</div>
        <div className="flex flex-col gap-5 w-full">
          <TextField
            className="w-full"
            name="role"
            label={t('route.user-management.table.header.role', 'Role')}
            variant="filled"
            size="small"
            select
            value={role}
            onChange={(evt) => setRole(evt.target.value as unknown as UserRole)}
          >
            <MenuItem value={UserRole.Editor}>{t('route.user-management.role.Editor', 'Editor')}</MenuItem>
            <MenuItem value={UserRole.Admin}>{t('route.user-management.role.Admin', 'Admin')}</MenuItem>
            <MenuItem value={UserRole.ReadOnly}>{t('route.user-management.role.ReadOnly', 'ReadOnly')}</MenuItem>
          </TextField>
          <FormControl>
            <FormLabel component="legend">{t('route.user-management.access_to_tenants')}</FormLabel>
            <FormGroup row>
              <FormControlLabel
                label={t('common.all')}
                control={<Checkbox checked={all} onChange={(evt) => setAll(evt.target.checked)} disabled={isAdmin} />}
              />

              {!all && (
                <Select
                  className="w-full"
                  name="enabledCompaniesList"
                  placeholder="Aktive Mandanten"
                  label="Aktive Mandanten"
                  variant="filled"
                  size="small"
                  multiple
                  value={enabledCompanies}
                  renderValue={(selected) =>
                    availableCompanies
                      ?.filter((c) => selected.includes(c.id))
                      .map((c) => c.name)
                      .join(', ')
                  }
                  onChange={(evt) => setEnabledCompanies(evt.target.value as unknown as string[])}
                >
                  {availableCompanies?.map((company) => {
                    return (
                      <MenuItem key={company.id} value={company.id}>
                        <Checkbox checked={enabledCompanies.indexOf(company.id) > -1} />
                        <ListItemText primary={company.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </FormGroup>
          </FormControl>
        </div>
        <div>
          <Button onClick={() => onSubmit(role, enabledCompanies)} disabled={!enabledCompanies.length}>
            {t('common.save', 'Speichern')}
          </Button>
        </div>
      </div>
    </NotificationModal>
  );
}

export default UserManagementUpdateModal;

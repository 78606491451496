import React, { createContext, useReducer } from 'react';

export const FlowContext = createContext([] as Flow);
export const FlowDispatchContext = createContext({} as React.Dispatch<FlowActionAdd | FlowActionExecute>);

interface Props {
  children: React.ReactNode;
}

export function FlowProvider({ children }: Props) {
  const [flow, dispatch] = useReducer(useFlowReducer, initialFlow);

  return (
    <FlowContext.Provider value={flow}>
      <FlowDispatchContext.Provider value={dispatch}>{children}</FlowDispatchContext.Provider>
    </FlowContext.Provider>
  );
}

export type UseFlowTuple = [Flow, React.Dispatch<FlowActionAdd | FlowActionExecute>];

type Flow = {
  id: number;
  callback: (args?: any) => Promise<any>;
  args: any;
}[];

interface FlowActionAdd {
  type: 'add';
  callback: (args?: any) => Promise<any>;
  args?: any;
  order?: number;
}
interface FlowActionExecute {
  type: 'execute' | 'reset';
}

function useFlowReducer(flow: Flow, action: FlowActionAdd | FlowActionExecute): Flow {
  // const { enqueueSnackbar } = useSnackbar();
  switch (action.type) {
    case 'add': {
      const { type, callback, args, order } = action;
      return [
        ...flow,
        {
          id: order || flow?.length || 1,
          callback,
          args,
        },
      ];
    }
    case 'execute': {
      // sort flow ascending by id
      const sortedFlow = flow.sort((a, b) => a.id - b.id);
      sortedFlow.reduce((prev, { callback, args }) => {
        return prev
          .then(() => callback(args))
          .catch((e) => {
            console.warn('err', e.message);
            // enqueueSnackbar(err.message, { variant: 'error' });
          });
      }, Promise.resolve());
      return [];
    }
    case 'reset': {
      return [];
    }
    default: {
      const _exhaustive: never = action;
      return _exhaustive;
    }
  }
}

const initialFlow: Flow = [];

import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button } from '@components/ui';
import { Task } from '@dieterApi/task/useTaskQuery';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  task: Task;
  onClose: () => void;
  onConfirm: () => void;
}

export function TutorialBody({ task, onClose, onConfirm }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { tutorialSteps, automated } = task;
  const { setNavigation } = useNavigation();
  const [step, setStep] = useState(0);
  const maxStep = tutorialSteps.length - 1;

  const handleClick = () => {
    if (step === maxStep) {
      if (automated) {
        onConfirm();
      } else {
        onClose();
      }
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  // disable the blur effect on other tasks when this
  // tutorial component unmounts (e.g. when the user navigates)
  useEffect(() => {
    return () => {
      setNavigation((nav) => void (nav.taskOpen = { ...nav.taskOpen, tutorial: '' }));
    };
  }, []);

  // The following parts are specials for handling the premium onboarding tasks
  // ---------------------------------------------------------------------------
  const handleOpenDPOContract = () => {
    const questionnaire = user?.questionnaires?.find((q) => q.application.id === 'app-dsb-training');
    if (questionnaire) {
      setNavigation((nav) => {
        nav.documentPaper = {
          modalOpen: true,
          questionnaire,
          index: 0,
        };
      });
    }
  };

  useEffect(() => {
    // Select the button after the component mounts
    const button = document.querySelector('#openDPOContractButton');
    if (button) {
      // Attach the event handler
      button.addEventListener('click', handleOpenDPOContract);
    }

    // Clean up the event listener
    return () => {
      if (button) {
        button.removeEventListener('click', handleOpenDPOContract);
      }
    };
  }, []); // Empty dependency array means this runs once after the initial render

  const STATE = user?.getValue('STATE') || 'STATE';

  const text = tutorialSteps[step].replace('{{STATE}}', STATE).replace('{{LINK}}', stateToAgencyMap[STATE]);

  return (
    <div className="flex flex-col gap-10 w-full">
      <div className="dtTutorialBody__step" dangerouslySetInnerHTML={{ __html: text }} />

      {!(task.automated && task.done) && (
        <div className="flex">
          {step > 0 && (
            <button className="underline text-primary-root hover:text-primary-700" onClick={handleBack}>
              Zurück
            </button>
          )}
          <div className="grow" />
          <div className="">
            <Button
              onClick={handleClick}
              data-testid={`button-finish-tutorial-${task.persistentId}`}
              className="text-base"
            >
              {task.automated ? t('common.finish') : t('common.continue')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

// we have a special task for onboarding premium members. The tutorial includes a link to the
// relevant public agency website based on a users german State. This is a mapping, which is used to find the correct link
const stateToAgencyMap: Record<string, string> = {
  Bayern: 'https://www.lda.bayern.de/de/dsb-meldung.html',
  'Baden-Württemberg': 'https://www.baden-wuerttemberg.datenschutz.de/dsb-online-mitteilen-aendern-loeschen/',
  Berlin: 'https://www.datenschutz-berlin.de/datenschutz/datenschutzbeauftragte/meldung-von-datenschutzbeauftragten/',
  Brandenburg:
    'https://www.lda.brandenburg.de/lda/de/service/formulare-und-musterschreiben/meldung-des-datenschutzbeauftragten/',
  Bremen: 'https://www.datenschutz.bremen.de/wir-ueber-uns/online-meldungen-15780',
  Hamburg: 'https://datenschutz-hamburg.de/service-information/dsb-an-/abmelden',
  Hessen: 'https://datenschutz.hessen.de/service/meldung-eines-datenschutzbeauftragten',
  'Mecklenburg-Vorpommern': 'https://www.datenschutz-mv.de/kontakt/Mitteilung-von-Datenschutzbeauftragten/',
  Niedersachsen: 'https://nds.dsb-meldung.de/',
  'Nordrhein-Westfalen': 'https://www.ldi.nrw.de/kontakt/datenschutzbeauftragte-meldeportal-fuer-kontaktdaten',
  'Rheinland-Pfalz':
    'https://www.datenschutz.rlp.de/de/themenfelder-themen/online-services/meldeformular-datenschutzbeauftragter-gem-art-37-abs-7-ds-gvo/',
  Saarland: 'https://www.datenschutz.saarland.de/online-dienste/meldung-datenschutzbeauftragter',
  Sachsen: 'https://www.datenschutz.sachsen.de/datenschutzbeauftragten-melden.html',
  'Sachsen-Anhalt': 'https://datenschutz.sachsen-anhalt.de/service/online-formulare/datenschutzbeauftragten-melden',
  'Schleswig-Holstein': 'https://www.datenschutzzentrum.de/formular/meldung-dsb.php/',
  Thüringen: 'https://tld.dsb-meldung.de/',
  STATE: 'https://www.google.com/search?q=datenschutz+beauftragten+melden&oq=datenschutz+beauftragten+melden',
};

import { Button, NotificationModal, Spinner } from '@components/ui';
import { InvoicePreview } from '@dieterApi/subscription/InvoicePreview';
import {
  UpgradeSubscriptionResult,
  useUpgradeSubscriptionMutation,
} from '@dieterApi/subscription/useSubscriptionUpgradeMutation';
import { Dangerous } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IconButton, TextField } from '@mui/material'; // Importing Material UI components
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Balancer from 'react-wrap-balancer';
import InvoiceSkeleton from './InvoiceSkeleton';
import { UpgradeProps } from './Pricing';

interface Props {
  open: boolean;
  onClose: () => void;
  previewData?: UpgradeSubscriptionResult<'InvoicePreview'>;
  updatePreview: (input: UpgradeProps) => void;
  upgradeInput?: UpgradeProps;
  error?: string;
  setError?: (error: string) => void;
}

export function UpgradeModal({ previewData, upgradeInput, error, updatePreview, setError, ...props }: Props) {
  const { t } = useTranslation();
  const [upgradeSubscription, { data: upgradeData, loading: upgradeLoading }] = useUpgradeSubscriptionMutation();
  const [localPromoCode, setLocalPromoCode] = useState<string>('');
  const [discountApplied, setDiscountApplied] = useState<boolean>(false);
  const { data, intervalWarning, billingCycleAnchor, promocodeWarning } = previewData?.upgradeSubscription || {};
  const invoice = data && (JSON.parse(data) as InvoicePreview);

  if (error) {
    return (
      <NotificationModal {...props} maxWidth="700px">
        <div className="p-6 flex flex-col items-center py-12 gap-6">
          <p className="text-lg font-semibold flex items-center gap-2">
            {t('route.pricing.upgrade.error')} <Dangerous className="text-danger" />
          </p>
          <p>
            <Balancer>{error}</Balancer>
          </p>
        </div>
      </NotificationModal>
    );
  }

  if (!invoice)
    return (
      <NotificationModal {...props} maxWidth="700px">
        <InvoiceSkeleton />
      </NotificationModal>
    );

  if (upgradeData) {
    // this should be an upgrade success message
    return (
      <NotificationModal {...props} maxWidth="700px">
        <div className="p-6 flex flex-col items-center py-12 gap-6">
          <p className="text-lg font-semibold flex items-center gap-2">
            {t('route.pricing.upgrade.congrats')} <CheckCircleOutlineIcon className="text-success" />
          </p>
          <p>
            <Balancer>{t('route.pricing.upgrade.success')}</Balancer>
          </p>
          <Spinner />
        </div>
      </NotificationModal>
    );
  }

  const {
    account_name,
    currency,
    customer_name,
    customer_email,
    lines,
    total,
    subtotal,
    total_discount_amounts,
    tax,
    period_end,
    period_start,
  } = invoice;

  const discount = total_discount_amounts.reduce((sum, discount) => sum + discount.amount, 0);

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  const hasProrationItem = lines.data.some((line) => line.amount < 0);

  const handleUpgrade = () => {
    upgradeSubscription({
      variables: { ...(upgradeInput as UpgradeProps), preview: false },
    })
      .then(() => {
        // set timer to 30 seconds, then navigate to /dashboard
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 10000);
      })
      .catch((e) => {
        setError && setError(t('route.pricing.upgrade.error2'));
      });
  };

  const handleApplyPromoCode = () => {
    setDiscountApplied(true);
    localPromoCode && updatePreview({ ...upgradeInput!, promocode: localPromoCode });
  };

  const handleChangePromoCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalPromoCode(e.target.value);
    setDiscountApplied(false);
  };

  const interval = lines.data.find((l) => l.amount > 0)?.price.recurring.interval;
  const oldInterval = lines.data.find((l) => l.amount < 0)?.price.recurring.interval;
  const intervalMismatch = oldInterval && interval !== oldInterval;

  const contractStart = formatDate(billingCycleAnchor || 0);
  // adding 12 months to the billingCycleAnchor minus 1 day
  const contractEnd = formatDate((billingCycleAnchor || 0) + 31536000);

  const newContractStart = formatDate(period_start);
  const newContractEnd = formatDate(period_end + 31536000);

  const periodMismatch = contractStart !== newContractStart;
  return (
    <NotificationModal {...props} maxWidth="700px" className="relative" withDieter={true}>
      <div className="p-6 py-12">
        <h2 className="text-2xl font-bold mb-4">{t('route.pricing.upgrade.header')}</h2>
        <p className="mb-2">
          <strong>{t('route.pricing.upgrade.account')}:</strong> {account_name}
        </p>
        <p className="mb-2">
          <strong>{t('route.pricing.upgrade.name')}:</strong> {customer_name}
        </p>
        <p className="mb-2">
          <strong>E-Mail:</strong> {customer_email}
        </p>
        {intervalMismatch ? (
          <>
            <p className="mb-2">
              <strong>{t('route.pricing.upgrade.old_payment')}:</strong>{' '}
              {oldInterval === 'month' ? t('route.pricing.upgrade.monthly') : t('route.pricing.upgrade.yearly')}
            </p>
            <p className="mb-2">
              <strong>{t('route.pricing.upgrade.new_payment')}:</strong>{' '}
              {interval === 'month' ? t('route.pricing.upgrade.monthly') : t('route.pricing.upgrade.yearly')}
            </p>
          </>
        ) : (
          <p className="mb-2">
            <strong>{t('route.pricing.upgrade.payment')}:</strong>{' '}
            {interval === 'month' ? t('route.pricing.upgrade.monthly') : t('route.pricing.upgrade.yearly')}
          </p>
        )}
        {periodMismatch ? (
          <>
            <p className="mb-2">
              <strong>{t('route.pricing.upgrade.old_period')}:</strong> {contractStart} - {contractEnd}
            </p>
            <p className="mb-2">
              <strong>{t('route.pricing.upgrade.new_period')}:</strong> {newContractStart} - {newContractEnd}
            </p>
          </>
        ) : (
          <p className="mb-2">
            <strong>{t('route.pricing.upgrade.period')}:</strong> {contractStart} - {contractEnd}
          </p>
        )}
        <div className="border-t py-4 mb-4">
          <h3 className="text-xl font-semibold mb-2">{t('route.pricing.upgrade.details')}</h3>

          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left py-2">{t('route.pricing.upgrade.description')}</th>
                <th className="text-right py-2">{t('route.pricing.upgrade.amount')}</th>
                <th className="text-right py-2">{t('route.pricing.upgrade.tax')}</th>
              </tr>
            </thead>
            <tbody>
              {lines.data.map((line) => (
                <tr key={line.id} className="border-b">
                  <td className="py-2">
                    <strong>{line.description}</strong>
                    <br />
                    <span className="text-sm text-gray-600">
                      {t('route.pricing.upgrade.period2')}: {formatDate(line.period.start)} -{' '}
                      {formatDate(line.period.end)}
                    </span>
                  </td>
                  <td className="py-2 text-right">
                    {(line.amount / 100).toFixed(2)} {currency.toUpperCase()}
                  </td>
                  <td className="py-2 text-right ">
                    {(line.tax_amounts.reduce((sum, tax) => sum + tax.amount, 0) / 100).toFixed(2)}{' '}
                    {currency.toUpperCase()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mb-4">
          <div className="flex justify-between">
            <p className="text-lg">
              <strong>{t('route.pricing.upgrade.subtotal')}:</strong>
            </p>
            <p className="text-lg">
              {(subtotal / 100).toFixed(2)} {currency.toUpperCase()}
            </p>
          </div>
          {discount > 0 && (
            <div className="flex justify-between">
              <p className="text-lg">
                <strong>{t('route.pricing.upgrade.discount')}:</strong>
              </p>
              <p className="text-lg">
                {-(discount / 100).toFixed(2)} {currency.toUpperCase()}
              </p>
            </div>
          )}
          <div className="flex justify-between">
            <p className="text-lg">
              <strong>{t('route.pricing.upgrade.tax')}:</strong>
            </p>
            <p className="text-lg">
              {(tax / 100).toFixed(2)} {currency.toUpperCase()}
            </p>
          </div>
          <div className="flex justify-between mt-2">
            <p className="text-xl font-bold">
              <strong>{t('route.pricing.upgrade.total')}:</strong>
            </p>
            <p className="text-xl font-bold">
              {(total / 100).toFixed(2)} {currency.toUpperCase()} {total < 0 && t('route.pricing.upgrade.credit')}
            </p>
          </div>
        </div>

        <div className="mb-4 flex gap-2 items-center w-full justify-end">
          {localPromoCode && (
            <IconButton onClick={handleApplyPromoCode} color="primary" aria-label="apply promo code" size="small">
              {t('route.pricing.upgrade.apply')}
            </IconButton>
          )}
          <div className="w-1/3">
            <TextField
              label="Promo Code"
              value={localPromoCode}
              onChange={handleChangePromoCode}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
        </div>
        <Button
          onClick={handleUpgrade}
          spinner={upgradeLoading}
          className="mb-2"
          disabled={!!localPromoCode && !discountApplied}
        >
          {t('route.pricing.upgrade.order')}
        </Button>
        {promocodeWarning && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-6">
            {t('route.pricing.upgrade.note_promocode')}
          </div>
        )}
        {hasProrationItem && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mt-6">
            {t('route.pricing.upgrade.note_prorate')} {total < 0 && t('route.pricing.upgrade.note_prorate2')}
          </div>
        )}
        {intervalMismatch && (
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-6">
            {t('route.pricing.upgrade.note_interval')}
          </div>
        )}
        {intervalWarning && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-6">
            {t('route.pricing.upgrade.note_intervalwarning')}
          </div>
        )}
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-6">
          {t('route.pricing.upgrade.note_contract')}
        </div>
      </div>
    </NotificationModal>
  );
}

import { Spinner } from '@components/ui';
import { DecaretoRawResult } from '@dieterApi/scan/types';
import { ScanResultData } from '@dieterApi/scan/useScanResultQuery';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoopIcon from '@mui/icons-material/Loop';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ScanResultDisplay({
  data,
  loading,
  startUrl,
  noScansLeft,
  onRestart,
}: {
  data?: ScanResultData;
  startUrl: string;
  loading: boolean;
  noScansLeft: boolean;
  onRestart: () => void;
}) {
  const { t } = useTranslation();

  const results = JSON.parse(data?.results || '{}') as DecaretoRawResult;

  // Convert the base64-encoded image to a URL
  const imageSrc = `data:image/png;base64,${data?.reportPreview}`;
  const isFinished = data?.scanFinished;

  return (
    <div className="flex flex-col gap-3 items-center">
      {!isFinished || loading ? (
        <div className="self-center flex flex-col gap-4 items-center">
          <Spinner size="large" />
          <span>{t('route.scanresult.loading')}</span>
        </div>
      ) : (
        <div className="text-3xl text-success flex gap-4 items-center">
          <CheckCircleOutlineIcon fontSize="inherit" />
          <span>{t('route.scanresult.scan_finished')}</span>
          <span>{new Date(data.createdAt).toLocaleDateString()}</span>
          {!noScansLeft && (
            <Tooltip title={'Scan erneut starten'} followCursor>
              <IconButton onClick={onRestart}>
                <LoopIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}

      <span className="text-lg">{startUrl || data?.startUrl}</span>

      {/* Render the image if reportPreview is available */}
      {data?.reportPreview && (
        <Tooltip title={'Vollständigen Report als PDF öffnen'} followCursor>
          <a
            href={data.reportPdfS3}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full flex flex-col items-center"
          >
            <img
              src={imageSrc}
              alt="Report Thumbnail"
              className="lg:!w-3/4 !w-full shadow-lg hover:-translate-y-2 transition-all duration-300 cursor-pointer border-[1px] border-gray-200"
            />
          </a>
        </Tooltip>
      )}
      {results.data?.raw_data?.services && (
        <div className="self-start mt-8">
          <span className="font-medium">{t('route.scanresult.identified_services')}</span>
          <ul className="list-disc">
            {results.data.raw_data.services.map((service) => {
              return <li key={service.service_id}>{service.name}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

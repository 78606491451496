import React from 'react';
import { Icon } from '../Icon/Icon';
import './ui-tickbox.sass';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type: 'checkbox' | 'radio';
}

export function Tickbox({ label, type, ...props }: Props) {
  return (
    <label className="dtUiTickbox">
      <input {...props} className="dtUiTickbox__input" type={type} />
      <div className="dtUiTickbox__display flex flex-row align-items-start">
        <div className="dtUiTickbox__widget">
          <Icon type="check-16" />
        </div>
        <div className="dtUiTickbox__label">{label}</div>
      </div>
    </label>
  );
}

import { IFileObject } from '@components/block/Quest/Download/Download';
import { useLegalOSMutation } from '@legalosApi/graphql/mutations';
import download from 'downloadjs';
import sanitize from 'sanitize-filename';

export function downloadFile({ mimeType, fileName, fileBase64 }: IFileObject) {
  return download(`data:${mimeType};base64,${fileBase64}`, sanitize(fileName), mimeType);
}

export function useDownload(): [(questionnaireId: string) => void, boolean] {
  const [download, { loading }] = useLegalOSMutation('questionnaireDownloadDocx', {
    onCompleted: ({ questionnaireDownloadDocx }) => downloadFile(questionnaireDownloadDocx),
  });

  return [
    function (questionnaireId: string) {
      download({
        variables: {
          input: { questionnaireId },
        },
      });
    },
    loading,
  ];
}

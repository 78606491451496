import { useUser } from '@app/context/UserContext';
import { NavigationSubsection, Topics } from '@app/context/constants';
import { subSectionUrl } from '@app/context/utils';
import { useNavigation } from '@app/hooks/useNavigation';
import { getTopicStates } from '@app/utils/getTopicStates';
import SentryRoute from '@components/SentryRoute.ts';
import { Icon } from '@components/ui';
import { LanguageDisclaimer } from '@components/ui/LanguageDisclaimer/LanguageDisclaimer';
import { UserTopicStatusApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import LockIcon from '@mui/icons-material/Lock';
import { Collapse, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Switch, useRouteMatch } from 'react-router-dom';

import { Dashboard } from '../useDashboard';
import { ConsentForms } from './SubSections/ConsentForms/ConsentForms';
import { ConsentManagement } from './SubSections/ConsentManagement/ConsentManagement';
import { Incidents } from './SubSections/Incidents/Incidents';
import { InfoForms } from './SubSections/InfoForms/InfoForms';
import { PartnerListEntry } from './SubSections/PartnerListEntry/PartnerListEntry';
import { Requests } from './SubSections/Requests/Requests';
import { SubContracts } from './SubSections/SubContracts/SubContracts';
import { TechnologyScan } from './SubSections/TechnologyScan/TechnologyScan';
import TicketCallToAction from './TicketCallToAction';
import { TopicContent } from './TopicContent';
import './topic.sass';

interface Props {
  topic: UserTopicStatusApplicationsQuestionnaires;
  dashboard: Dashboard;
}

export function Topic({ topic, dashboard }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { path } = useRouteMatch();
  const {
    navigation: { onboarding },
  } = useNavigation();
  const topicStates = getTopicStates(topic, user);
  const [collapseOpen, setCollapseOpen] = useState(topicStates.showTeaser);
  const handleToggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const TOPIC_LOCKED_TEXT = t('dashboard.topic.forms.formitem.locked');

  // If User has not subscribed and has finished DSE questionnaire, show call to action
  // (only for topic DSE)
  const onboardingQuestionnaireId = topic.applications
    .find((a) => a.id === onboarding.appId)
    ?.questionnaires?.find((q) => q.progress === 1)?.id;
  const onboardingQuestionnaire = user?.questionnaires?.find((q) => q.id === onboardingQuestionnaireId);

  const onboardAppIsPublic = onboardingQuestionnaire?.application.tags?.map((t) => t.tag).includes('public');

  const showTicketCallToAction =
    onboardingQuestionnaire?.application.topic.id === topic.id &&
    !user?.isSubscriber &&
    onboardingQuestionnaireId &&
    !onboardAppIsPublic &&
    topic.id !== Topics.CHECKUP;

  return (
    <div className="dtRouteDashboard__item flex flex-col gap-20">
      <div className="dtRouteDashboard__itemTitle flex flex-row items-center gap-10">
        <Icon type="archive-24" />
        <div className="dtRouteDashboard__itemTitleInput flex-grow-1">{topic.title}</div>
        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={t('dashboard.topic.tooltip')}>
          <button onClick={handleToggleCollapse} className="text-gray-400 hover:text-primary-root">
            <HelpOutlineRoundedIcon fontSize="small" />
          </button>
        </Tooltip>
      </div>
      <div className="d-flex flex-col">
        <LanguageDisclaimer topic={topic} />
        {topic.helpText && topic.helpText?.length > 0 && (
          <Collapse in={collapseOpen}>
            {topic.helpText.map((ht, idx) => (
              <p key={idx}>{ht}</p>
            ))}
          </Collapse>
        )}
      </div>
      {topicStates.isLocked && !showTicketCallToAction && (
        <div className="flex gap-2 items-center">
          <div className="text-primary-root text-3xl">
            <LockIcon fontSize="inherit" />
          </div>
          <span className="italic text-gray-500 text-xs">
            {TOPIC_LOCKED_TEXT} <Link to="/pricing">{t('dashboard.topic.pricinglink')}</Link>
          </span>
        </div>
      )}
      <Switch>
        {/* Routes for subSections */}
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.ContractPartners)}`}>
          <PartnerListEntry topic={topic} topicStates={topicStates} />
        </SentryRoute>
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.TechnologyScan)}`}>
          <TechnologyScan />
        </SentryRoute>
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.SubContracts)}`}>
          <SubContracts topic={topic} topicStates={topicStates} />
        </SentryRoute>
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.Incidents)}`}>
          <Incidents topic={topic} topicStates={topicStates} />
        </SentryRoute>
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.Requests)}`}>
          <Requests topic={topic} topicStates={topicStates} />
        </SentryRoute>
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.ConsentForms)}`}>
          <ConsentForms topic={topic} topicStates={topicStates} />
        </SentryRoute>
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.InfoForms)}`}>
          <InfoForms topic={topic} topicStates={topicStates} />
        </SentryRoute>
        <SentryRoute path={`${path}${subSectionUrl(NavigationSubsection.Usercentrics)}`}>
          <ConsentManagement />
        </SentryRoute>

        {showTicketCallToAction && onboardingQuestionnaire ? (
          <SentryRoute path={`${path}`}>
            <TicketCallToAction questionnaire={onboardingQuestionnaire} />
          </SentryRoute>
        ) : (
          // Regular topic route
          <SentryRoute path={`${path}`}>
            <TopicContent
              topic={topic}
              dashboard={dashboard}
              topicStates={topicStates}
              setCollapseOpen={setCollapseOpen}
            />
          </SentryRoute>
        )}
      </Switch>
    </div>
  );
}

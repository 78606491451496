import { gql, useMutation } from '@apollo/client';
import { Locales } from '@components/block/Header/LangSelect';
import { useTranslation } from 'react-i18next';
import { USER_FIELDS, User } from './useUserQuery';

const LOGIN_USER = gql`
  ${USER_FIELDS}
  mutation LoginUser($email: String!, $password: String!, $token: String, $locale: String) {
    loginUser(email: $email, password: $password, token: $token, locale: $locale) {
      ...UserFields
      token
    }
  }
`;

export interface UserLoginInput {
  email: string;
  password: string;
  token?: string;
  locale?: Locales;
}

export interface UserLoginResult {
  loginUser: User;
}

export function useUserLoginMutation() {
  const { i18n } = useTranslation();
  return useMutation<UserLoginResult, UserLoginInput>(LOGIN_USER, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => data?.loginUser || null,
        },
      });
    },
    onCompleted(data) {
      if (!data?.loginUser) return;
      i18n.changeLanguage(data.loginUser.locale);
    },
    // onCompleted(data) {
    //   // if (data && data.loginUser.token) {
    //   //   sessionStorage.removeItem('token');
    //   //   sessionStorage.setItem('token', data.loginUser.token);
    //   //   // this is mandatory since we cannot get the active applications
    //   //   // from the login response
    //   client.refetchQueries({ include: ['GetUser'] });
    //   // }
    // },
  });
}

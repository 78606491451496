import { gql, useLazyQuery } from '@apollo/client';

const GET_QUESTIONNAIRE_HRML = gql`
  query GetQuestionnaireHtml($id: String!, $chunkSize: Int) {
    getQuestionnaireHtml(id: $id, chunkSize: $chunkSize)
  }
`;

export interface GetQuestionnaireHtmlInput {
  id: string;
  chunkSize?: number;
}

export interface GetQuestionnaireHtmlResult {
  getQuestionnaireHtml: [string];
}

export function useGetQuestionnaireHtmlQuery() {
  return useLazyQuery<GetQuestionnaireHtmlResult, GetQuestionnaireHtmlInput>(GET_QUESTIONNAIRE_HRML, {
    fetchPolicy: 'no-cache',
  });
}

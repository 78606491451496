import { gql, useMutation } from '@apollo/client';
import { USER_FIELDS, User } from './useUserQuery';

const DELETE_TENANT = gql`
  ${USER_FIELDS}
  mutation DeleteTenant($companyId: String!) {
    deleteTenant(companyId: $companyId) {
      ...UserFields
    }
  }
`;

export interface DeleteTenantInput {
  companyId: string;
}

export interface DeleteTenantResult {
  deleteTenant: User;
}

export function useDeleteTenantMutation() {
  return useMutation<DeleteTenantResult, DeleteTenantInput>(DELETE_TENANT, {
    refetchQueries: ['GetUser'],
  });
}

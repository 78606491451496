import { gql, useMutation } from '@apollo/client';

const UPDATE_UTM_PARAMS = gql`
  mutation UpdateUtmParams($utmParams: String!) {
    updateUtmParams(utmParams: $utmParams)
  }
`;

export interface UpdateUtmParamsInput {
  utmParams: string;
}

export interface UpdateUtmParamsResult {
  updateUtmParams: boolean;
}

export function useUpdateUtmParamsMutation() {
  return useMutation<UpdateUtmParamsResult, UpdateUtmParamsInput>(UPDATE_UTM_PARAMS, {});
}

import { gql } from '@apollo/client';
import { entityTypeDefinitionsFragment } from '@legalosApi/graphql/fragments/fragments';

export const GET_QUESTIONNAIRE_ENTITY_TYPE_DEFINITIONS = gql`
  query questionnaireEntityTypeDefinitions($input: QuestionnaireInput) {
    questionnaire(input: $input) {
      ...EntityTypeDefinitionsFragment
    }
  }

  ${entityTypeDefinitionsFragment}
`;

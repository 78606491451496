import { CalculatedState } from '@legalosApi/types/CalculatedState';
import { IQuestionnaire } from '@legalosApi/types/IQuestionnaire';
import { SharedQuestionState } from '@legalosApi/types/SharedQuestionState';
import type { ExecutionResult } from 'graphql';
import * as React from 'react';
import { useLegalOSMutation } from './../graphql/mutations/index';

export const useToggleSharedQuestionState = () => {
  const [questionSharedStateUpdate, { loading: toggleSharedQuestionStateLoading }] =
    useLegalOSMutation('questionSharedStateUpdate');

  const toggleSharedQuestionState = React.useCallback(
    (questionId: string, currentSharedQuestionState: SharedQuestionState) =>
      questionSharedStateUpdate({
        variables: {
          input: {
            _id: questionId,
            isLinkedToSharedQuestion: currentSharedQuestionState === SharedQuestionState.UNLINKED,
          },
        },
      }),
    [questionSharedStateUpdate]
  );

  return { toggleSharedQuestionState, toggleSharedQuestionStateLoading };
};

export const useToggleCalculatedState = () => {
  const [questionCalculatedStateUpdate, { loading: toggleCalculatedStateLoading }] = useLegalOSMutation(
    'questionCalculatedStateUpdate'
  );
  const toggleCalculatedState = React.useCallback(
    (questionId: string, currentCalculatedState: CalculatedState) =>
      questionCalculatedStateUpdate({
        variables: {
          input: {
            _id: questionId,
            isCalculationOverridden: currentCalculatedState === CalculatedState.CALCULATED,
          },
        },
      }),
    [questionCalculatedStateUpdate]
  );

  return { toggleCalculatedState, toggleCalculatedStateLoading };
};

export const useInsertListElement = (questionnaireId: string) => {
  const [listInsertElement, { loading: insertListElementLoading }] = useLegalOSMutation('questionListInsertElement');
  const insertListElement = React.useCallback(
    (listId: string, indexToInsert: number) =>
      listInsertElement({
        variables: {
          input: {
            listId,
            listIndex: indexToInsert,
            questionnaireId,
          },
        },
      }),
    [listInsertElement, questionnaireId]
  );

  return { insertListElement, insertListElementLoading };
};

export const useRemoveListElement = (questionnaireId: string) => {
  const [listRemoveElement, { loading: removeListElementLoading }] = useLegalOSMutation('questionListRemoveElement');
  const removeListElement = React.useCallback(
    (listId: string, indexToRemove: number) =>
      listRemoveElement({
        variables: {
          input: {
            listId,
            listIndex: indexToRemove,
            questionnaireId,
          },
        },
      }),
    [listRemoveElement, questionnaireId]
  );

  return { removeListElement, removeListElementLoading };
};

export const useStateUpdateMutations = (questionnaireId: string) => {
  const { toggleSharedQuestionState, toggleSharedQuestionStateLoading } = useToggleSharedQuestionState();

  const { toggleCalculatedState, toggleCalculatedStateLoading } = useToggleCalculatedState();

  const { insertListElement, insertListElementLoading } = useInsertListElement(questionnaireId);

  const { removeListElement, removeListElementLoading } = useRemoveListElement(questionnaireId);

  const isStateUpdateInProgress =
    toggleSharedQuestionStateLoading ||
    toggleCalculatedStateLoading ||
    insertListElementLoading ||
    removeListElementLoading;

  return {
    toggleSharedQuestionState,
    toggleSharedQuestionStateLoading,
    toggleCalculatedState,
    toggleCalculatedStateLoading,
    insertListElement,
    insertListElementLoading,
    removeListElement,
    removeListElementLoading,
    isStateUpdateInProgress,
  };
};

export interface IStateUpdateMutations {
  toggleSharedQuestionState: (
    questionId: string,
    currentSharedQuestionState: SharedQuestionState
  ) => Promise<ExecutionResult<{ questionSharedStateUpdate: IQuestionnaire }>>;
  toggleCalculatedState: (
    questionId: string,
    currentCalculatedState: CalculatedState
  ) => Promise<
    ExecutionResult<{
      questionCalculatedStateUpdate: IQuestionnaire;
    }>
  >;
  insertListElement: (
    listId: string,
    indexToInsert: number
  ) => Promise<
    ExecutionResult<{
      questionListInsertElement: IQuestionnaire;
    }>
  >;
  removeListElement: (
    listId: string,
    indexToRemove: number
  ) => Promise<
    ExecutionResult<{
      questionListRemoveElement: IQuestionnaire;
    }>
  >;
}

export interface IStateUpdateLoadingStates {
  toggleSharedQuestionStateLoading: boolean;
  toggleCalculatedStateLoading: boolean;
  insertListElementLoading: boolean;
  removeListElementLoading: boolean;
  isStateUpdateInProgress: boolean;
}

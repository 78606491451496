import { gql, useLazyQuery } from '@apollo/client';
import { QUESTIONNAIRE_FIELDS, UserQuestionnaire } from '@dieterApi/user/useUserQuery';

// this query is used to only run the expensive hash calculation, when it is really needed
export const GET_QUESTIONNAIRES = gql`
  ${QUESTIONNAIRE_FIELDS}
  query GetQuestionnaires($ids: [String]) {
    getQuestionnaires(ids: $ids) {
      ...UserQuestionnaireFields
      progressHash
    }
  }
`;

export interface GetQuestionnairesResult {
  getQuestionnaires: (UserQuestionnaire & {
    progressHash: string;
  })[];
}

export interface GetQuestionnairesInput {
  ids: string[];
}

export function useQuestionnairesQuery() {
  return useLazyQuery<GetQuestionnairesResult, GetQuestionnairesInput>(GET_QUESTIONNAIRES, {
    // fetchPolicy: 'no-cache',
  });
}

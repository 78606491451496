import { useSnackbar } from '@app/hooks/useSnackbar';
import { Locales } from '@components/block/Header/LangSelect';
import { Button, EmailField, Field, Input, Loading } from '@components/ui';
import { useUserLoginMutation } from '@dieterApi/user/useUserLoginMutation';
import cx from 'classnames';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './route-login.sass';

export function Login() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [login, { loading }] = useUserLoginMutation();

  // Get query parameter "authtoken" from URL
  const queryParams = new URLSearchParams(location.search);
  const authtoken = queryParams.get('authtoken');

  // Get linkmessage parameter from URL
  const linkmessage = queryParams.get('linkmessage');

  // Get first two letters of language code
  const langString = i18n.language.slice(0, 2) as Locales;

  const [variables, setVariables] = useState({
    name: '',
    email: '',
    password: '',
    register: false,
    locale: langString,
  });

  const handleLogin = () => {
    // If already loading, do nothing
    if (loading) {
      return;
    }
    // Otherwise, attempt to log in the user
    login({ variables }).catch((e) => enqueueSnackbar(e.message, { variant: 'error', stack: e.stack }));
  };

  if (linkmessage) {
    return (
      <div className="dtRouteLogin flex justify-center items-center" data-testid="login-route">
        <div className="flex gap-8 items-center">
          <img src="/assets/images/dieter_super.svg" alt="dieter super" className="w-48" />
          <div className="max-w-[400px]">
            <div className="text-primary-root text-3xl mb-4">{t('route.login.thanks')}</div>
            <p className="text-lg">{t('route.login.thanksdesc')}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {authtoken && authtoken !== 'without' && loading ? (
        <div className="mt-24">
          <Loading />
        </div>
      ) : (
        <div className="dtRouteLogin flex justify-center items-center" data-testid="login-route">
          <div>
            <strong>
              <Trans t={t} i18nKey="route.login.heading">
                Willkommen zurück
              </Trans>
            </strong>
            <p data-testid="login-header">
              <Trans t={t} i18nKey="route.login.instruction">
                Gib deine E-Mail Adresse und dein Passwort ein, um dich einzuloggen.
              </Trans>
            </p>
          </div>

          <div className="dtRouteLogin__panel">
            <img className="dieter-head" src="/assets/images/dieter_kopf.svg" alt="dieter logo" />
            <EmailField
              disabled={loading}
              onChange={(event) => {
                setVariables({ ...variables, email: event.target.value });
              }}
              onEnter={handleLogin}
              name="username"
              autoComplete="current-username"
            />
            <Field className="mb-20" htmlFor="password" label={t('route.login.form_field.password.label')}>
              <Input
                disabled={loading}
                required
                onChange={(event) => {
                  setVariables({ ...variables, password: event.target.value });
                }}
                onKeyPress={(e) => (e.key === 'Enter' ? handleLogin() : null)}
                type="password"
                name="password"
                autoComplete="current-password"
                value={variables.password}
                id="password"
                data-testid="password-field"
              />
            </Field>
            <div className="flex justify-between flex-wrap dtRouteLogin__btn_container">
              <Button
                className={cx('mb-20 w-50')}
                spinner={loading}
                // disabled={loginDisabled}
                onClick={handleLogin}
                data-testid="login-button"
              >
                <Trans t={t} i18nKey="route.login.action.login">
                  Anmelden
                </Trans>
              </Button>
            </div>
            <div className="flex justify-between flex-wrap">
              <Link to="/reset" data-testid="link-password-reset">
                <Trans t={t} i18nKey="route.login.action.forgot_password">
                  Passwort vergessen?
                </Trans>
              </Link>
              <div>
                <Trans t={t} i18nKey="route.login.action.new_user">
                  Noch kein Konto?&nbsp;
                  <Link to="/onboarding" className="strong-link">
                    Jetzt loslegen
                  </Link>
                </Trans>
              </div>
            </div>
          </div>
          {/* <button className="social-login" onClick={handleLinkedIn}>
            <img
              src="/assets/images/linkedin/Sign-In-Large---Default.png"
              onMouseOver={(e) => (e.currentTarget.src = '/assets/images/linkedin/Sign-In-Large---Hover.png')}
              onMouseOut={(e) => (e.currentTarget.src = '/assets/images/linkedin/Sign-In-Large---Default.png')}
            />
          </button> */}
        </div>
      )}
    </>
  );
}

import { gql, useMutation } from '@apollo/client';
import { User, USER_FIELDS } from './useUserQuery';

const SEND_CONFIRMATION_EMAIL = gql`
  ${USER_FIELDS}
  mutation ConfirmSendEmail {
    confirmSendEmail {
      ...UserFields
    }
  }
`;

export interface ConfirmSendEmailResult {
  confirmSendEmail: User;
}

export function useConfirmSendEmailMutation() {
  return useMutation<ConfirmSendEmailResult>(SEND_CONFIRMATION_EMAIL, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => (data && data.confirmSendEmail ? data.confirmSendEmail : null),
        },
      });
    },
  });
}

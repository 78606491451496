import { useTasks } from '@app/context/TaskContext';
import { useTranslation } from 'react-i18next';
import { DoneTaskList } from './DoneTaskList';

export function DoneTasks() {
  const { t } = useTranslation();
  const tasks = useTasks();
  return (
    <div className="dtRouteDashboard__mainPanel">
      <h3>{t('dashboard.tasks.donetasks')}</h3>
      <DoneTaskList tasks={tasks} />
    </div>
  );
}

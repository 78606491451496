import { useNavigation } from '@app/hooks/useNavigation';
import { Task, Tasks, TaskType, useTaskQuery } from '@dieterApi/task/useTaskQuery';
import { useTaskSetStatusMutation } from '@dieterApi/task/useTaskSetStatusMutation';
import React, { createContext, useContext, useEffect } from 'react';
import { useUser } from './UserContext';
import { Topics } from './constants';
export const TaskContext = createContext<Tasks | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export function TaskProvider({ children }: Props) {
  const [getTasks, { data: taskData }] = useTaskQuery();
  const [setTaskStatus] = useTaskSetStatusMutation();
  const { navigation, setNavigation } = useNavigation();
  const { user } = useUser();
  let tasks: Tasks | undefined;
  tasks = taskData?.getTasks;

  const topicPriority = user?.topics.map((topic, idx, arr) => ({
    id: topic.id,
    priority: arr.length - arr.findIndex((t) => t.id === topic.id),
  }));

  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // The following block is identifying new tasks
  // --> this covers questionnaire and process items
  useEffect(() => {
    if (user) {
      getTasks();
    }
  }, [user?.id]);

  useEffect(() => {
    if (tasks) {
      tasks.forEach((task) => {
        if (task.id && navigation.newTasks[task.persistentId] === undefined) {
          setNavigation((nav) => {
            nav.newTasks[task.persistentId] = true;
          });
        }
      });
    }
  }, [tasks]);

  // The following block finds out if a task belongs to a legacy questionnaire. If so it is filtered out
  tasks = tasks?.filter(
    (task) =>
      task.type !== TaskType.QUESTIONNAIRE ||
      !task.application?.tags?.some((tag) => tag.tag === 'legacy') ||
      user?.questionnaires?.find((q) => q.application.id === task.application?.id && q.progress === 1)
  );

  // The following block is identifying the focus Tasks with the highest priority
  // -- per topic
  const topicFocus = {} as Record<Topics, string | undefined>;
  user?.topics.forEach((topic) => {
    const topicTasks = tasks?.filter(
      (t) => t.topic.id === topic.id && t.done === false && !navigation.ignoredTasks[t.persistentId]
    );
    if (topicTasks?.length)
      topicFocus[topic.id] = topicTasks.reduce((prev, curr) => (prev.priority > curr.priority ? prev : curr)).id;
  });
  // -- overall
  const overallFocus = tasks
    ?.filter((t) => t.done === false && !navigation.ignoredTasks[t.persistentId])
    .reduce(
      (prev, curr) =>
        (prev?.priority || topicPriority?.find((tp) => tp.id === prev?.topic.id)?.priority || 0) >
        (curr?.priority || topicPriority?.find((tp) => tp.id === curr?.topic.id)?.priority || 0)
          ? prev
          : curr,
      null as any as Task
    )?.id;

  tasks =
    tasks
      ?.map((task) => {
        return {
          ...task,
          application: user?.topics
            .map((topic) => topic.applications)
            .flat()
            .find((app) => app.id === task.application?.id),
          isTopicFocus: topicFocus[task.topic.id] === task.id,
          isOverallFocus: overallFocus === task.id,
          isNew: Boolean(navigation.newTasks[task.persistentId]),
          isDismissed: navigation.dismissedTasks[task.persistentId],
          isIgnored: navigation.ignoredTasks[task.persistentId],
          setStatus: (status: boolean) =>
            setTaskStatus({
              variables: { taskId: task.id, status },
              optimisticResponse: {
                setTaskStatus: {
                  ...task,
                  done: status,
                },
              },
            }).catch((e) => {
              console.error(e);
            }),
          setIgnore: (ignore: boolean) => {
            setNavigation((nav) => {
              if (ignore) {
                nav.ignoredTasks = { ...nav.ignoredTasks, [task.persistentId]: true };
              } else {
                delete nav.ignoredTasks[task.persistentId];
              }
            });
          },
        };
      })
      .sort((a, b) => (a.application?.order || 0) - (b.application?.order || 0))
      .sort((a, b) => Number(b?.done) - Number(a?.done)) || [];

  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // --> this covers document items
  const questionnaires = user?.questionnaires?.filter((q) => q.progress === 1);

  useEffect(() => {
    if (questionnaires) {
      // compare previous documents and current documents and flag the new ones in the navigationState
      questionnaires.forEach((q) => {
        if (navigation.newDocuments && navigation.newDocuments[q.id] === undefined) {
          const docTag = q.application.documentLabels.map((label) => true);
          setNavigation((nav) => void (nav.newDocuments = { ...nav.newDocuments, [q.id]: docTag }));
        }
      });
    }
  }, [questionnaires]);

  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  return <TaskContext.Provider value={tasks}>{children}</TaskContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components -- required for context, will not hurt HMR
export function useTasks() {
  return useContext(TaskContext);
}

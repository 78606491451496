// mutation for creating an error

import { gql, useMutation } from '@apollo/client';

const SETUP_MFA = gql`
  mutation SetupMFA {
    setupMFA {
      secret
      url
    }
  }
`;

export interface SetupMFAResult {
  setupMFA: {
    secret: string;
    url: string;
  };
}

export function useMFASetupMutation() {
  return useMutation<SetupMFAResult>(SETUP_MFA);
}

import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { copyToClipboard } from '@app/utils/copyToClipboard';
import { AlertDialog, Button, Loading, Spinner } from '@components/ui';
import { useEmployeeAddMutation } from '@dieterApi/employee/useEmployeeAddMutation';
import { useEmployeeDeleteMutation } from '@dieterApi/employee/useEmployeeDeleteMutation';
import { useEmployeeUpdateMutation } from '@dieterApi/employee/useEmployeeUpdateMutation';
import { useQuestionnaireDocumentShareQuery } from '@dieterApi/questionnaire/useQuestionnaireDocumentShareQuery';
import { TaskType, Tasks } from '@dieterApi/task/useTaskQuery';
import { User } from '@dieterApi/user/useUserQuery';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Skeleton, TableCell, TableRow, Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import './employeetable.sass';

export function EmployeeTable() {
  const { t } = useTranslation();
  const { user } = useUser();
  const tasks = useTasks();

  const { enqueueSnackbar } = useSnackbar();
  const [updateName] = useEmployeeUpdateMutation();
  const [deleteEmployee] = useEmployeeDeleteMutation();
  const [getToken, { data: tokenData, loading }] = useQuestionnaireDocumentShareQuery();
  const [addEmployee] = useEmployeeAddMutation();
  const [hasCopied, setHasCopied] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<MUIDataTableMeta | null>(null);
  const blankEntry = { id: '', name: '', createdAt: '', trainings: [] };
  const [newEntry, setNewEntry] = useImmer<EmployeeData>(blankEntry);

  // when new input field on manual entry row is added, focus it
  const handleRef = useCallback<(node: HTMLInputElement) => void>((node) => {
    if (node !== null) {
      node.focus();
    } else {
      // clear focus
      window.focus();
      // document.activeElement?.blur();
    }
  }, []);

  const employeeData = useMemo(() => {
    const parsedData = parseEmployeeData(user, tasks);
    return newEntry.id ? [...parsedData, newEntry] : parsedData;
  }, [user, tasks, newEntry]);

  const handleDelete = (tableMeta: MUIDataTableMeta) => {
    const employeeId = tableMeta.rowData[0];
    const employee = employeeData.find((e) => e.id === employeeId);
    deleteEmployee({
      variables: {
        employeeId,
      },
      context: {
        companyId: user?.company?.id || '',
      },
    })
      .then(() => {
        enqueueSnackbar(t('dashboard.training.employee.table.snackbars.delete_employee.success'), {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
      });
  };

  const handleAdd = () => {
    setNewEntry((draft) => {
      draft.id = 'new';
    });
  };

  const handleUpdate = (employee: EmployeeData, name: string) => {
    if (!name) return;
    if (employee.name === name) return;
    updateName({
      variables: {
        employeeId: employee.id,
        name,
      },
      optimisticResponse: {
        updateEmployee: {
          id: employee.id,
          name,
          createdAt: employee?.createdAt || '',
        },
      },
    })
      .then(() => {
        enqueueSnackbar(t('dashboard.training.employee.table.snackbars.update_name.success'), { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
      });
  };

  const handleSave = (name: string, followUp: boolean = false) => {
    if (!name) return;
    addEmployee({
      variables: {
        name,
      },
      context: {
        companyId: user?.company?.id || '',
      },
    })
      .then(() => {
        // TODO <ask> why this is commented out
        enqueueSnackbar(t('dashboard.training.employee.table.snackbars.save_employee.success'), { variant: 'success' });
        followUp
          ? setNewEntry((draft) => {
              draft.id = 'new';
            })
          : setNewEntry(blankEntry);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
      });
  };

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'id',
      label: t('dashboard.training.employee.table.columns.id.label'),
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: t('dashboard.training.employee.table.columns.name.label'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const employeeId = tableMeta.rowData[0];
          const isNew = employeeId === 'new';
          // find original name by matching employeeId
          const employee = employeeData.find((e) => e.id === employeeId);
          const originalValue = employee?.name || '';

          return (
            <div>
              <input
                data-testid="employee-table-name"
                className="w-full bg-transparent text-sm"
                ref={isNew ? handleRef : null}
                placeholder={t('dashboard.training.employee.table.columns.name.body.input_placeholder')}
                type="text"
                value={value}
                onChange={(e) => {
                  updateValue(e.target.value);
                }}
                onBlur={(e) => {
                  if (isNew) {
                    // delay to allow for click on save button
                    setTimeout(() => {
                      setNewEntry(blankEntry);
                    }, 300);
                  } else {
                    updateValue(originalValue);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isNew) {
                      handleSave(value, true);
                    } else {
                      handleUpdate(employee!, value);
                    }
                  }
                  // blur on escape
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                  }
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'countString',
      label: t('dashboard.training.employee.table.columns.countString.label'),
      options: {
        customBodyRender: (value, tableMeta) => {
          if (!tasks) return <Spinner size="small" />;
          const notStarted = tableMeta.rowData[5] === 0;

          const fullComplete = tableMeta.rowData[4] === tableMeta.rowData[5];
          return (
            <div className="flex gap-10">
              <span>{value}</span>
              {fullComplete ? (
                <CheckCircleOutlineIcon fontSize="small" color="success" />
              ) : (
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  title={t('dashboard.training.employee.table.columns.countString.body.states.not_completed.tooltip')}
                >
                  <ErrorOutlineIcon fontSize="small" color={notStarted ? 'error' : 'secondary'} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'actions',
      label: t('dashboard.training.employee.table.columns.actions.label'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const employeeId = tableMeta.rowData[0];
          const name = tableMeta.rowData[1];
          const isNew = employeeId === 'new';

          return (
            <div className="dtEmployeeTable__action">
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={t(
                  `dashboard.training.employee.table.columns.actions.body.states.${
                    isNew ? 'new_employee' : 'existed_employee'
                  }.tooltip`
                )}
              >
                <IconButton
                  className="dtEmployeeTable__deleteButton"
                  color="info"
                  size="small"
                  onClick={() => {
                    if (isNew) {
                      handleSave(name);
                    } else {
                      setDeleteRow(tableMeta);
                      setDeleteAlertOpen(true);
                    }
                  }}
                  data-testid={`buton-action-employee-${tableMeta.rowIndex}`}
                >
                  {isNew ? (
                    <SaveIcon fontSize="small" data-testid="action-employee-btn-save-icon" />
                  ) : (
                    <DeleteIcon fontSize="small" data-testid="action-employee-btn-delete-icon" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          );
        },
        customHeadLabelRender: () => '',
      },
    },
    {
      name: 'totalCount',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'confirmedCount',
      options: {
        display: 'excluded',
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // sortOrder: {
    //   name: 'name',
    //   direction: 'asc',
    // },
    download: false,
    print: false,
    elevation: 0,
    // onRowClick: handleClick,
    selectableRows: 'none',
    selectableRowsHeader: false,
    // selectableRowsOnClick: true,
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      return employeeData[dataIndex].trainings.length > 0;
    },
    expandableRowsOnClick: true,

    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const trainings = employeeData[rowMeta.dataIndex].trainings;

      return (
        // <tr>
        // <td colSpan={colSpan}>
        <TableRow>
          <TableCell colSpan={colSpan}>
            <div className="dtEmployeeTable__expandableRow">
              {trainings.map((training) => {
                const parsedDate = training.createdAt ? new Date(training.createdAt) : null;
                const date = parsedDate?.toLocaleDateString('de-DE');
                return (
                  <div key={training.name} className="expandableRows">
                    <div className="rowItem flex-1">{training.name}</div>
                    {!training.hasConfirmed && <TrainingLink training={training} />}
                    {training.hasConfirmed && <div className="rowItem">{date}</div>}
                    <div className="rowItem">
                      {training.hasConfirmed ? (
                        <CheckCircleOutlineIcon fontSize="small" color="success" data-testid="check-icon" />
                      ) : (
                        <Tooltip
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          title={t(
                            'dashboard.training.employee.table.expandable_row.training_records.tooltips.not_confirmed'
                          )}
                        >
                          <ErrorOutlineIcon fontSize="small" color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => (
      <Button
        className="text-base mb-3"
        onClick={handleAdd}
        icon={<AddIcon />}
        data-testid="add-employee-button"
        disabled={user?.isReadOnly}
      >
        {t('dashboard.training.employee.table.toolbar.actions.add')}
      </Button>
    ),
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    textLabels: {
      selectedRows: {
        text: t('dashboard.training.employee.table.textLabels.selectedRows.text'),
        delete: t('dashboard.training.employee.table.textLabels.selectedRows.delete'),
        deleteAria: t('dashboard.training.employee.table.textLabels.selectedRows.deleteAria'),
      },
      body: {
        noMatch: t('dashboard.training.employee.table.textLabels.body.noMatch'),
      },
    },
  };

  return (
    <div className="dtEmployeeTable">
      {employeeData ? (
        <MUIDataTable
          columns={columns}
          data={employeeData}
          title={t('dashboard.training.employee.table.title')}
          options={options}
        />
      ) : (
        <Loading />
      )}
      <AlertDialog
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        message={
          <Trans
            t={t}
            i18nKey="dashboard.training.employee.table.dialogs.delete.message"
            components={{ bold: <b /> }}
            shouldUnescape
            values={{ userName: deleteRow?.rowData[1] }}
          />
        }
        onAccept={() => deleteRow && handleDelete(deleteRow)}
      />
    </div>
  );
}

const TrainingLink = ({ training }: { training: Training }) => {
  const { t } = useTranslation();
  const [getToken, { data: tokenData, loading }] = useQuestionnaireDocumentShareQuery();
  const [hasCopied, setHasCopied] = useState(false);

  const handleCopyLink = (training: Training) => {
    if (tokenData && !loading) {
      const token = tokenData.getQuestionnaireShareToken;
      const url = token ? window.location.origin + '/share?token=' + token : '';
      copyToClipboard(url).then(() => setHasCopied(true));
    }
  };

  useEffect(() => {
    getToken({
      variables: {
        localQuestionnaireId: training.type === 'Document' ? training.questionnaireId : undefined,
        applicationId: training.type === 'Document' ? undefined : training.applicationId,
        type: training.type,
        documentNumber: training.documentNumber,
      },
    });
  }, []);

  return loading ? (
    <Skeleton width={150} height={24} />
  ) : (
    <div className="flex">
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        title={
          hasCopied
            ? t('dashboard.topic.process.publishprocess.copied')
            : t('dashboard.topic.process.publishprocess.clipboard')
        }
      >
        <IconButton
          onClick={() => handleCopyLink(training)}
          onMouseLeave={() => setTimeout(() => setHasCopied(false), 1000)}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={'In neuem Fenster öffnen'}>
        <a
          className="underline text-primary-root hover:text-primary-800 flex items-center gap-1"
          href={`/share?token=${tokenData?.getQuestionnaireShareToken}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('dashboard.training.employee.table.link')}
          <OpenInNewIcon fontSize="inherit" className="text-gray-400" />
        </a>
      </Tooltip>
    </div>
  );
};

interface EmployeeData {
  id: string;
  name: string;
  createdAt: string;
  confirmedCount?: number;
  totalCount?: number;
  countString?: string;
  trainings: Training[];
}

interface Training {
  name: string;
  createdAt: string;
  hasConfirmed: boolean;
  questionnaireId: string;
  applicationId: string;
  documentNumber: number;
  type: 'Document' | 'Questionnaire';
}

const AllShareTaskTypes = [TaskType.PUBLISHPROCESS, TaskType.SHAREQUESTIONNAIRE];

const parseEmployeeData = (user: User | undefined, tasks: Tasks | undefined): EmployeeData[] => {
  const publishTasks = tasks?.filter((t) => AllShareTaskTypes.includes(t.type)) || [];
  const publishApplications = publishTasks.map((t) => t.application?.id).filter(Boolean) as string[];

  const employeeData: EmployeeData[] =
    user?.company?.employees.map((employee) => {
      const trainings = publishApplications.map((app) => {
        const task = publishTasks.find((t) => t.application?.id === app);
        const questionnaires = user?.questionnaires?.filter((q) => q.application.id === app);
        const confirmedQuestionnaire = questionnaires?.find((q) =>
          q.documentConfirmations.some((dc) => dc.employeeId === employee.id)
        );
        const finishedQuestionnaire = questionnaires?.find((q) => q.state === QuestionnaireState.COMPLETE);

        const hasConfirmed = !!confirmedQuestionnaire;
        const createdAt =
          confirmedQuestionnaire?.documentConfirmations.find((dc) => dc.employeeId === employee.id)?.createdAt || '';
        const appTitle = user.topics?.flatMap((t) => t.applications).find((a) => a.id === app)?.title || '';

        const isQuestionnaireTask = task?.type === TaskType.SHAREQUESTIONNAIRE;
        return {
          name: appTitle,
          createdAt,
          hasConfirmed,
          questionnaireId: (isQuestionnaireTask ? confirmedQuestionnaire?.id : finishedQuestionnaire?.id) || '',
          applicationId: app,
          documentNumber: task?.section || 0,
          type: (isQuestionnaireTask ? 'Questionnaire' : 'Document') as 'Document' | 'Questionnaire',
        };
      });

      const prelim = {
        id: employee.id,
        name: employee.name,
        createdAt: employee.createdAt,
        trainings,
        confirmedCount: trainings.filter((t) => t.hasConfirmed).length,
        totalCount: trainings.length,
      };
      return { ...prelim, countString: `${prelim.confirmedCount}/${prelim.totalCount}` };
    }) || [];
  // sort the output by id
  employeeData.sort((a, b) => (a.id > b.id ? 1 : -1));
  return employeeData;
};

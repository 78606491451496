import { useUser } from '@app/context/UserContext';
import { UserRole } from '@dieterApi/user/useUserQuery';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserManagementTable from './UserManagementTable';

export function UserManagement() {
  const { t } = useTranslation();
  const { user } = useUser();

  const isAdmin = user?.role === UserRole.Admin;

  return (
    <div className="2xl:container  flex flex-col p-6">
      <h1>{t('route.user-management.header')}</h1>
      <p>{t('route.user-management.description')}</p>

      <div className="min-h-[40px] flex justify-end">
        <div>
          <Link to="/dashboard">{t('common.action.back', 'Zurück')}</Link>
        </div>
      </div>
      {isAdmin ? (
        <UserManagementTable />
      ) : (
        <p className="text-lg text-red-600">{t('route.user-management.no-permission')}</p>
      )}
    </div>
  );
}

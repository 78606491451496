import { TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
  Topic: {
    fields: {
      files: {
        merge: false,
      },
    },
  },
  Question: {
    fields: {
      options: {
        merge: false,
      },
    },
  },
  Section: {
    fields: {
      questions: {
        merge: false,
      },
    },
  },
  LocalQuestionnaire: {
    merge: true,
  },
};

import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { Quest as Instance } from '@components/block';
import { Locales } from '@components/block/Header/LangSelect';
import { Loading } from '@components/ui';
import { useLegalOSQuery } from '@legalosApi/graphql/queries';
import { useStateUpdateMutations } from '@legalosApi/hooks/useStateUpdateMutations';
import { useUpdateSingleQuestion } from '@legalosApi/hooks/useUpdateSingleQuestion';
import { IQuestionnaire } from '@legalosApi/types/IQuestionnaire';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

export interface IQuestionnaireData {
  questionnaire: IQuestionnaire;
}

export default function Questionnaire() {
  const { i18n } = useTranslation();
  const { currentParams, jwt } = useQuestionaireRoute();
  const questionnaireId = currentParams?.questionnaireId;

  const { loading, data, refetch, error } = useLegalOSQuery('questionnaire', {
    variables: {
      input: { _id: questionnaireId!, token: jwt, locale: jwt ? (i18n.language.slice(0, 2) as Locales) : undefined },
    },
    // fetchPolicy: 'network-only',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { updateSingleQuestion, isUpdateInProgress } = useUpdateSingleQuestion({
    questionnaireData: data,
    refetchQuestionnaire: refetch,
  });

  const { isStateUpdateInProgress, ...stateUpdateMutations } = useStateUpdateMutations(questionnaireId!);

  if (error) {
    return <Redirect to="/" />;
  }

  if (loading || !data || !data.questionnaire) {
    return (
      <div className="mt-24">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {/* <Helmet title={data.questionnaire.title} htmlAttributes={{ lang: i18n.language }} /> */}
      <Instance
        isStateUpdateInProgress={isStateUpdateInProgress}
        stateUpdateMutations={stateUpdateMutations}
        onUpdateQuestion={updateSingleQuestion}
        questionnaire={data.questionnaire}
        isUpdateQuestionsInProgress={isUpdateInProgress}
        refetchQuestionnaire={refetch}
      />
    </>
  );
}

import { createContext, useContext, useEffect, useState } from 'react';
import { OfferType } from './NavigationContext';
import { useUser } from './UserContext';

export interface IFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  company: string;
  website: string;
  street: string;
  zip: string;
  city: string;
  couponcode: string;
}

interface OrderState {
  offer: OfferType;
  formValues: IFormValues;
  setOffer: (offer: OfferType) => void;
  setFormValues: (formValues: IFormValues) => void;
}

export const OrderContext = createContext<OrderState>({} as OrderState);

const initialFormValues = {
  firstName: '',
  lastName: '',
  phone: '',
  company: '',
  website: '',
  street: '',
  zip: '',
  city: '',
  couponcode: '',
};

export function OrderProvider({ children }: { children: React.ReactNode }) {
  const { user } = useUser();
  const [offer, setOffer] = useState<OfferType>('Basic');
  const [formValues, setFormValues] = useState<IFormValues>(initialFormValues);

  useEffect(() => {
    if (user) {
      setFormValues({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phone: user.company.phone || '',
        company: user.company.name || user.getValue('COMPANY_NAME') || '',
        website: user.company.website || '',
        street: user.company.street || '',
        zip: user.company.zip || '',
        city: user.company.city || '',
        couponcode: user.company.couponCode || '',
      });
      setOffer(user.company.subscription || 'Basic');
    }
  }, [user]);

  return (
    <OrderContext.Provider value={{ offer, formValues, setOffer, setFormValues }}>{children}</OrderContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components -- required for context, will not hurt HMR
export function useOrder() {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error('useOrder must be used within a OrderProvider');
  }
  return context;
}

import { NavigationSection } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { OutlineMobile, OutlineProps } from '@components/block/Quest/Outline/OutlineMobile.tsx';
import { Button } from '@components/ui';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionList } from './SectionList';
import './quest-outline.sass';

export function Outline(props: OutlineProps) {
  const { activeIds, onSection, sections, saveAndClose = true } = props;
  const { t } = useTranslation();
  const {
    navigateDashboard,
    navigation: { activeTopic },
  } = useNavigation();

  const handleClick = () => {
    navigateDashboard(NavigationSection.Topic, activeTopic);
  };

  return (
    <>
      <OutlineMobile {...props} />
      <div className="dtQuestOutline hidden md:block">
        <div className="dtQuestOutline__header">{t('route.docs.quest_outline_header', 'Agenda')}</div>
        <SectionList activeIds={activeIds} depth={0} onSection={onSection} sections={sections} />
        {saveAndClose && (
          <>
            <Divider />
            <Button onClick={handleClick} data-testid="button-save-and-close" btnColor="white">
              {t('route.docs.button_save_and_close', 'Pausieren & später fortfahren')}
            </Button>
            <p>
              {t(
                'route.docs.hint_save_and_close',
                'Wir speichern deinen Fortschritt automatisch und du kannst jederzeit an gleicher Stelle fortfahren. Alles in deinem Tempo.'
              )}
            </p>
          </>
        )}
      </div>
    </>
  );
}

import { gql, useMutation } from '@apollo/client';
import { USER_FIELDS, User } from './useUserQuery';

const UPDATE_TENANT = gql`
  ${USER_FIELDS}
  mutation UpdateTenant(
    $companyId: String!
    $companyName: String!
    $website: String
    $phone: String!
    $street: String!
    $zip: String!
    $city: String!
  ) {
    updateTenant(
      companyId: $companyId
      companyName: $companyName
      website: $website
      phone: $phone
      street: $street
      zip: $zip
      city: $city
    ) {
      ...UserFields
    }
  }
`;

export interface UpdateTenantInput {
  companyId: string;
  companyName: string;
  website?: string;
  phone: string;
  street: string;
  zip: string;
  city: string;
}

export interface UpdateTenantResult {
  updateTenant: User;
}

export function useUpdateTenantMutation() {
  return useMutation<UpdateTenantResult, UpdateTenantInput>(UPDATE_TENANT, {
    refetchQueries: ['GetUser'],
  });
}

import { useUser } from '@app/context/UserContext';
import { NavigationSection, Topics } from '@app/context/constants';
import { navigationSubsectionKey } from '@app/context/utils';
import { useNavigation } from '@app/hooks/useNavigation';
import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { MFANotice } from '@app/routes/Dashboard/MFANotice';
import { Icon } from '@components/ui';
import { SavedHint } from '@components/ui/SavedHint/SavedHint';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageStep } from '../Quest/utils';
import { ProgressBar } from './ProgressBar';
import { Timer } from './Timer';
import './navigation.sass';

export function Navigation() {
  const { t } = useTranslation();
  const {
    navigation: {
      scope,
      steps,
      stepIndex,
      activeSection,
      activeTopic,
      activeSubSection,
      onAdvance,
      isMobile,
      itemListOpen,
      questTask,
    },
    setNavigation,
    navigateDashboard,
  } = useNavigation();

  const { user } = useUser();

  const { currentParams } = useQuestionaireRoute();
  const isQuestScope = scope?.startsWith('quest');
  const isExternal = scope === 'quest_external';
  const isDashboardScope = scope === 'dashboard';
  const isOnboardingScope = scope === 'onboarding';
  const sectionTitle = stepIndex > -1 && steps[stepIndex].parents[0].title;

  const handleForwardClick = () => {
    onAdvance(steps[stepIndex].id, 'forward');
  };

  const handleBackwardClick = () => {
    onAdvance(steps[stepIndex].id, 'backward');
  };

  const handleBreadcrumbClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const newSection = user?.topics.find((t) => t.title === e.currentTarget.text)?.id;
    if (!user?.isAnonymous) {
      navigateDashboard(NavigationSection.Topic, newSection as Topics, activeSubSection);
    }
  };

  const handleToggleItemList = () => {
    setNavigation((nav) => void (nav.itemListOpen = !nav.itemListOpen));
  };

  // this handles the keyboard navigation
  useEffect(() => {
    if (!isQuestScope) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        event.preventDefault();
        if (forwardEnabled) {
          handleForwardClick();
        }
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault();
        if (backwardEnabled) {
          handleBackwardClick();
        }
      }
    };
    window.addEventListener('keyup', handleKeyDown);
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, [stepIndex]);

  // on mount we close the menu
  useEffect(() => {
    setNavigation((nav) => void (nav.itemListOpen = !isMobile));
  }, [isMobile]);

  const nextStep = steps[stepIndex] as MessageStep;
  const backwardEnabled = stepIndex > 0;
  const forwardEnabled = stepIndex < steps.length - 1 && nextStep?.question.state !== QuestionnaireState.UNTOUCHED;

  return (
    <div className={cx('dtNavigation', isDashboardScope && 'hidden md:block relative')}>
      {isDashboardScope && <MFANotice />}
      <div className="2xl:container flex gap-20 mx-auto px-2 md:px-9 items-center justify-between">
        <Breadcrumbs
          maxItems={isMobile ? 1 : 10}
          itemsBeforeCollapse={0}
          className="dtNavigation__breadcrumbs"
          aria-label="breadcrumb"
        >
          {!isExternal && (
            <Link underline="hover" color="inherit" onClick={() => navigateDashboard(NavigationSection.Overview)}>
              {t('common.overview', 'Übersicht')}
            </Link>
          )}
          {!isExternal && (isQuestScope || activeSubSection) ? (
            <Link underline="hover" color="inherit" href="/" onClick={handleBreadcrumbClick}>
              {activeTopic && user?.topics.find((t) => t.id === activeTopic)?.title}
            </Link>
          ) : (
            activeSection !== NavigationSection.Overview && (
              <Typography color="text.primary">
                {activeTopic ? user?.topics.find((t) => t.id === activeTopic)?.title : activeSection}
              </Typography>
            )
          )}
          {isQuestScope ? (
            <Typography className="dtNavigation__breadcrumbs__questStep" color="text.primary">
              {sectionTitle}
            </Typography>
          ) : (
            activeSubSection && (
              <Typography className="dtNavigation__breadcrumbs__questStep" color="text.primary">
                {t(`common.navigationSubsection.${navigationSubsectionKey(activeSubSection)}`, activeSubSection)}
              </Typography>
            )
          )}
        </Breadcrumbs>

        {currentParams.revisit && (
          <div className="bg-primary-root rounded-md flex-shrink text-center text-white text-sm self-center px-4 md:ml-6 flex justify-between items-center gap-10">
            <span>{t('layout.global.navigation.revisit_tag_label', 'Modus: Wiedervorlage')}</span>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={
                'Du hast diesen Fragebogen bereits abgeschlossen. Du kannst in jeden Punkt der Agenda direkt springen und deine Antworten anpassen. Das resultierende Dokument wird dann automatisch ebenfalls angepasst.'
              }
            >
              <div className="hover:text-secondary-root">
                <HelpOutlineIcon fontSize="inherit" />
              </div>
            </Tooltip>
          </div>
        )}

        {isQuestScope && (
          <div className="dtNavigation_chevrons hidden md:flex justify-end">
            <SavedHint />
            <div
              onClick={handleBackwardClick}
              className={cx('dtNavigation_chevron_button', { 'btn-disabled': !backwardEnabled })}
              data-testid="button-chevron-left"
            >
              {t('common.action.back', 'Zurück')}
            </div>

            <button
              className={cx('dtNavigation_chevron_button', { 'btn-disabled': !forwardEnabled })}
              onClick={handleForwardClick}
              data-testid="button-chevron-right"
            >
              <Icon type="chevron-right-16" />
            </button>
          </div>
        )}
        {/*{isDashboardScope && isMobile && <CollapseToggle onClick={handleToggleItemList} open={itemListOpen} />}*/}
        {isQuestScope && (
          <div className="justify-end">
            <Timer duration={questTask.duration || 5} />
            <div className="block md:hidden">
              <SavedHint />
            </div>
          </div>
        )}
      </div>
      {isQuestScope && <ProgressBar steps={steps} stepIndex={stepIndex} />}
    </div>
  );
}

import { useNavigation } from '@app/hooks/useNavigation';
import { useGetAllChatsQuery } from '@dieterApi/chat/useChatQuery';
import { User } from '@dieterApi/user/useUserQuery';
import { useState } from 'react';
import { AdminChatItem } from './AdminChatItem';

interface Props {
  user?: User | null;
}

export function AdminChats({ user }: Props) {
  const { data } = useGetAllChatsQuery();
  const { setNavigation } = useNavigation();
  const chats = [...(data?.getChat || [])].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

  const [openedChat, setOpenedChat] = useState<string | null>(null);

  const handleClick = (id: string) => {
    setOpenedChat((prev) => (prev == id ? null : id));
    setNavigation((draft) => {
      if (!draft.chatsViewed.includes(id)) {
        draft.chatsViewed.push(id);
      }
    });
  };

  return (
    <div className="flex flex-col gap-3 mt-4">
      {chats?.map((c) => <AdminChatItem key={c.id} chat={c} collapsed={openedChat != c.id} onClick={handleClick} />)}
    </div>
  );
}

import { ChoiceButton } from '@components/ui';
import { ISelectOption } from '@legalosApi/types/ISelectOption';
import React from 'react';

export interface Props {
  disabled?: boolean;
  onClick: () => void;
  option: ISelectOption;
  shortcutKey: string;
  isSelected?: boolean;
  isHighlighted?: boolean;
}

export function Option({ disabled, onClick, option, isSelected, isHighlighted }: Props) {
  const [didTrigger, setDidTriggered] = React.useState(false);
  function onTrigger() {
    setDidTriggered(true);
    onClick();
  }

  return (
    // <div className="w-100">
    // <Shortcut disabled={disabled} didTrigger={didTrigger} onAction={onTrigger} shortcutKey={shortcutKey}>
    <ChoiceButton
      disabled={disabled}
      key={option.value}
      onClick={onTrigger}
      isSelected={isSelected}
      isHighlighted={isHighlighted}
      spinner={didTrigger}
    >
      {option.label}
    </ChoiceButton>
    // </Shortcut>
    // </div>
  );
}

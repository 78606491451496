import { gql } from '@apollo/client';

export const QUESTIONNAIRE_DOC = gql`
  query questionnaireDoc($input: QuestionnaireInput) {
    questionnaire(input: $input) {
      _id
      document
      styles
    }
  }
`;

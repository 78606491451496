import { Modal, ModalProps, Paper } from '@mui/material';

import { isOverflown } from '@app/utils/isOverflown';
import cx from 'classnames';
import { useRef } from 'react';
import { Slide } from '../Animations/Slide';
import { CloseButton } from '../CloseButton/CloseButton';

interface Props extends ModalProps {
  withCloseButton?: boolean;
  maxWidth?: string;
  withDieter?: boolean;
}

export function NotificationModal({
  open,
  onClose,
  children,
  maxWidth = '550px',
  withCloseButton = true,
  withDieter = false,
  ...props
}: Props) {
  const modalRef = useRef<HTMLDivElement>(null);
  const overFlow = isOverflown(modalRef.current);

  return (
    <Modal open={Boolean(open)} onClose={onClose} {...props}>
      <Slide in={Boolean(open)}>
        <Paper
          className={cx(
            `absolute p-[10px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:w-5/6 w-[95vw] max-h-[85vh] bg-white shadow-md pointer-events-auto border-none overflow-y-auto z-[1400]`,
            { overFlow }
          )}
          style={{ maxWidth }}
          elevation={10}
          ref={modalRef}
        >
          <div className="relative">
            {withCloseButton && <CloseButton onClick={onClose as () => void} />}
            {children}
          </div>
        </Paper>
      </Slide>
    </Modal>
  );
}

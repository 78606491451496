import { gql, useLazyQuery } from '@apollo/client';
import { Locales } from '@components/block/Header/LangSelect';

// Define the GraphQL query for the new endpoint
const GET_QUESTIONNAIRE_API_DOC = gql`
  query GetQuestionnaireApiDoc($id: String!) {
    getQuestionnaireApiDoc(id: $id)
  }
`;

// Define TypeScript interfaces for the input and result of the query
export interface GetQuestionnaireApiDocInput {
  id: string;
  locale?: Locales;
}

export interface GetQuestionnaireApiDocResult {
  getQuestionnaireApiDoc: string;
}

// Define the custom React hook for the new GraphQL query
export function useGetQuestionnaireApiDocQuery() {
  return useLazyQuery<GetQuestionnaireApiDocResult, GetQuestionnaireApiDocInput>(GET_QUESTIONNAIRE_API_DOC, {
    fetchPolicy: 'no-cache',
  });
}

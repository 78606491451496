import { useUser } from '@app/context/UserContext';
import { copyToClipboard } from '@app/utils/copyToClipboard';
import { Button, Input, Spinner } from '@components/ui';
import { useQuestionnaireDocumentShareQuery } from '@dieterApi/questionnaire/useQuestionnaireDocumentShareQuery';
import { Task } from '@dieterApi/task/useTaskQuery';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button as MuiButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  task: Task;
  type: 'Document' | 'Questionnaire';
  onConfirm: () => void;
}

export function PublishProcessBody({ task, type, onConfirm }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [getToken, { data, loading }] = useQuestionnaireDocumentShareQuery();
  const token = data?.getQuestionnaireShareToken;

  const url = token ? window.location.origin + '/share?token=' + token : '';

  // get the token for the document
  useEffect(() => {
    if (type == 'Document') {
      const taskQuestionnaire = user?.questionnaires?.find((q) => q.application.id === task.application?.id);
      taskQuestionnaire &&
        getToken({
          variables: { localQuestionnaireId: taskQuestionnaire?.id, type, documentNumber: task.section },
        });
    } else {
      getToken({
        variables: { applicationId: task.application?.id, type, documentNumber: task.section },
      });
    }
  }, []);

  const handleClick = () => {
    if (!showConfirmDialog) {
      setShowConfirmDialog(true);
    } else {
      // set Task to Done
      onConfirm();
    }
  };

  const handleAbortClick = () => {
    setShowConfirmDialog(false);
  };

  const handleCopyClick = () => {
    copyToClipboard(url).then(() => setHasCopied(true));
  };

  return token ? (
    <div className="flex flex-col gap-10 flex-1">
      {!showConfirmDialog ? (
        <>
          <div>{task.actionText}</div>
          <div className="flex">
            <div className="flex-1">
              <Input
                type="text"
                value={url}
                onFocus={(e) => e.target.select()}
                textSize={'small'}
                readOnly={true}
                data-testid={'input-share-url'}
              />
            </div>
            <div>
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                  hasCopied
                    ? t('dashboard.topic.process.publishprocess.copied')
                    : t('dashboard.topic.process.publishprocess.clipboard')
                }
              >
                <MuiButton
                  onClick={handleCopyClick}
                  onMouseLeave={() => setTimeout(() => setHasCopied(false), 1000)}
                  data-testid={'button-copy-clipboard'}
                >
                  <ContentCopyIcon />
                </MuiButton>
              </Tooltip>
            </div>
          </div>
        </>
      ) : (
        <div>{task.confirmationText}</div>
      )}
      {!task.done && (
        <div className="flex gap-10">
          <div className="flex-4" />
          <div className="flex-2">
            <Button onClick={handleClick} className="text-base">
              {showConfirmDialog ? t('common.yes') : t('common.finish')}
            </Button>
          </div>
          {showConfirmDialog && (
            <div className="flex-2">
              <Button onClick={handleAbortClick} btnColor="gray" className="text-base">
                {t('common.no')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
}

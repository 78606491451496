import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';
import { gql } from '@apollo/client';

export const QUESTIONNAIRE = gql`
  query questionnaire($input: QuestionnaireInput) {
    questionnaire(input: $input) {
      ...QuestionnaireFragment
    }
  }

  ${questionnaireFragment}
`;

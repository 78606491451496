import { useUser } from '@app/context/UserContext';
import { useHealthCheck } from '@app/hooks/useHealthCheck';
import { useNavigation } from '@app/hooks/useNavigation';
import { useQueryParams } from '@app/hooks/useQuestionaireRoute';
import { Header, Navigation } from '@components/block';
import { Loading } from '@components/ui';
import { useGetShareTokenJWTQuery } from '@dieterApi/sharetoken/useGetShareTokenJWTQuery';
import { useEffect, useState } from 'react';
// import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from '@sentry/react';

import { useSnackbar } from '@app/hooks/useSnackbar';
import { Locales } from '@components/block/Header/LangSelect';
import SentryRoute from '@components/SentryRoute.ts';
import { HubSpotChat } from '@components/ui/HubspotChat/HubspotChat';
import { useUserLoginMutation } from '@dieterApi/user/useUserLoginMutation';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import Admin from './Admin/Admin';
import Dashboard from './Dashboard/Dashboard';
import DocumentEmbed from './DocumentEmbed/DocumentEmbed';
import DocumentShare from './DocumentShare/DocumentShare';
import Error from './Error/Error';
import { Unavailable } from './Error/Unavailable';
import FileShare from './FileShare/FileShare';
import Glossary from './Glossary/Glossary';
import { Login } from './Login/Login';
import { Onboarding } from './Onboarding/Onboarding';
import { Pricing } from './Pricing/Pricing';
import { Print } from './Print/Print';
import Questionnaire from './Questionnaire/Questionnaire';
import { Terms } from './Terms/Terms';
import Confirmation from './UserActions/Confirmation/Confirmation';
import { MFASetup } from './UserActions/MFA/MFASetup';
import { MFAVerify } from './UserActions/MFA/MFAVerify';
import Password from './UserActions/Password/Password';
import ResetPassword from './UserActions/ResetPassword/ResetPassword';
import { UserConfiguration } from './UserConfiguration/UserConfiguration';
import { UserManagement } from './UserManagement/UserManagement';

import { useUpdateUtmParamsMutation } from '@dieterApi/company/useCompanyUpdateUtmParamsMutation';
import './route.sass';
import { WhitelabelConfigForm } from './Whitelabel/WhitelabelConfiguration';

declare global {
  interface Window {
    dataLayer?: Array<Record<string, unknown>>;
    // This is the tapfiliate tracking function
    tap?: (type: string, stripeCustomerId: string) => void;
    _paq?: Array<unknown>;
  }
}

interface UCEvent extends Event {
  detail: {
    event: string;
    [key: string]: string | boolean;
  };
}

// ATTENTION: Revisit with @loadable/component
// Lazy load route components
// const Admin = React.lazy(() => import('./Admin/Admin'));
// const Confirmation = React.lazy(() => import('./UserActions/Confirmation/Confirmation'));
// // const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
// const DocumentEmbed = React.lazy(() => import('./DocumentEmbed/DocumentEmbed'));
// const DocumentShare = React.lazy(() => import('./DocumentShare/DocumentShare'));
// const Error = React.lazy(() => import('./Error/Error'));
// const FileShare = React.lazy(() => import('./FileShare/FileShare'));
// const Glossary = React.lazy(() => import('./Glossary/Glossary'));
// const Password = React.lazy(() => import('./UserActions/Password/Password'));
// const ResetPassword = React.lazy(() => import('./UserActions/ResetPassword/ResetPassword'));
// const Questionnaire = React.lazy(() => import('./Questionnaire/Questionnaire'));

export function Root() {
  const location = useLocation();
  const history = useHistory();
  const { user, userLoading } = useUser();
  const { navigation, setNavigation } = useNavigation();
  const isHealthy = useHealthCheck();
  const loading = userLoading || navigation.questionnaireWrapup;
  const isEmbed = location.pathname.includes('/embed');
  const [conversionTracked, setConversionTracked] = useState(false);

  const [updateUtmParams] = useUpdateUtmParamsMutation();

  // Get query parameter "authtoken" from URL
  const queryParams = new URLSearchParams(location.search);
  const ordervalue = queryParams.get('ordervalue');
  const session_id = queryParams.get('session_id');
  const items = queryParams.get('items');

  // When anonymous user has no onboarding app, redirect to onboarding
  useEffect(() => {
    if (user && user.isAnonymous && !navigation.onboarding.appId) {
      history.push('/onboarding');
    }
  }, [user, navigation]);

  // push history change to google analytics
  useEffect(() => {
    return history.listen((location) => {
      // Ensure the GTM function exists
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'app_route', // the event name
          pageTitle: document.title, // set page title
          pagePath: location.pathname, // set page path
          pageLocation: window.location.href, // set complete url
        });
      }
    });
  }, [history]);

  // send utm parameters to backend for future conversion tracking
  useEffect(() => {
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      .map((param) => {
        const value = queryParams.get(param);
        return value ? `${param}=${encodeURIComponent(value)}` : null;
      })
      .filter(Boolean)
      .join('&');

    if (utmParams) {
      updateUtmParams({
        variables: {
          utmParams,
        },
      }).catch((error) => {
        console.error('Error updating UTM params:', error);
      });
    }
  }, [location.search, updateUtmParams]);

  useEffect(() => {
    if (!ordervalue || !user || !session_id || conversionTracked) return;
    // Convert ordervalue to integer
    const value = parseInt(ordervalue || '0', 10);
    let ga4items = [];
    try {
      ga4items = JSON.parse(items || '[]');
    } catch (e) {
      console.error('Error parsing items: ', items);
    }

    // Function to push the custom event to GA4
    const pushToDataLayer = () => {
      if (window.dataLayer && value) {
        window.dataLayer.push({
          event: 'ga4-event-order-success',
          ordervalue: value / 100,
          email: user?.email,
          items: ga4items,
          session_id,
        });
      }
    };

    const pushToMatomo = () => {
      if (window._paq && value) {
        window._paq.push(['trackEvent', 'funnel', 'order success', 'order success', value / 100]);
      }
    };

    const pushToTracify = () => {
      if (window.tracify && value) {
        console.log('Capturing purchase event with Tracify');
        window.tracify.capturePurchase({
          amount: (value / 100).toFixed(2),
          // create random uuid
          orderId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          currency: 'EUR',
        });
      }
    };

    // Retry every second until dataLayer is found
    const intervalId = setInterval(() => {
      if (window.dataLayer) {
        pushToDataLayer();
        clearInterval(intervalId); // Clear the interval once the dataLayer is found and event is pushed
      }
    }, 1000);

    // Retry every second until _paq is found
    const intervalIdMatomo = setInterval(() => {
      if (window._paq) {
        pushToMatomo();
        clearInterval(intervalIdMatomo); // Clear the interval once the dataLayer is found and event is pushed
      }
    }, 1000);

    // Retry every second until tracify is found
    const intervalIdTracify = setInterval(() => {
      if (window.tracify) {
        pushToTracify();
        clearInterval(intervalIdTracify); // Clear the interval once the dataLayer is found and event is pushed
      }
    }, 1000);

    setConversionTracked(true); // Mark as tracked
  }, [ordervalue, user, session_id]); // Dependency array to rerun the effect if ordervalue changes

  // manage consent state in navigation context
  useEffect(() => {
    window.addEventListener('ucEvent', function (e: Event) {
      const ucEvent = e as UCEvent;
      if (ucEvent.detail && ucEvent.detail.event == 'consent_status') {
        setNavigation((nav) => void (nav.consent.hubspot = ucEvent.detail['HubSpot'] as boolean));
        setNavigation((nav) => void (nav.consent.sentry = ucEvent.detail['Sentry'] as boolean));
      }
    });
  }, []);

  return (
    <>
      {!isEmbed && (
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag('location', 'header');
          }}
        >
          <Header />
        </ErrorBoundary>
      )}
      <ErrorBoundary
        fallback={Error}
        beforeCapture={(scope) => {
          scope.setTag('location', 'main');
        }}
      >
        {navigation.scope && navigation.scope !== 'onboarding' && <Navigation />}
        {!isHealthy ? (
          <Unavailable />
        ) : loading ? (
          <div className="mt-24">
            <Loading />
          </div>
        ) : (
          // Finally get to the app
          // <Suspense fallback={<Loading />}>
          <Switch>
            <SentryRoute path="/pricing">
              <Pricing />
            </SentryRoute>
            <SentryRoute path="/onboarding/:appTitleUrl?">
              <Onboarding />
            </SentryRoute>
            <SentryRoute path="/reset">
              <ResetPassword />
            </SentryRoute>
            <SentryRoute path="/confirm">
              <Confirmation user={user} />
            </SentryRoute>
            <SentryRoute path="/terms">
              <Terms />
            </SentryRoute>
            {/* <SentryRoute path="/dsgvo" children={<Gdpr />} /> */}
            <SentryRoute path="/share">
              <DocumentShare />
            </SentryRoute>
            <SentryRoute path="/file">
              <FileShare />
            </SentryRoute>
            <SentryRoute path="/embed">
              <DocumentEmbed />
            </SentryRoute>
            <Route
              path="/privacy"
              component={() => {
                window.location.href = 'https://www.dieter-datenschutz.de/datenschutz/';
                return null;
              }}
            />
            <Route
              path="/imprint"
              component={() => {
                window.location.href = 'https://www.dieter-datenschutz.de/impressum/';
                return null;
              }}
            />

            <PrivateRoute path="/password">
              <Password />
            </PrivateRoute>
            <PrivateRoute path="/mfa">
              <MFASetup />
            </PrivateRoute>

            <PrivateRoute path="/docs/:questionnaireId">
              <Questionnaire />
            </PrivateRoute>
            <PrivateRoute path="/admin">
              <Admin user={user} />
            </PrivateRoute>
            <PrivateRoute path="/whitelabel">
              <WhitelabelConfigForm />
            </PrivateRoute>
            <PrivateRoute path="/userconfig">
              <UserConfiguration />
            </PrivateRoute>
            <PrivateRoute path="/usermanagement">
              <UserManagement />
            </PrivateRoute>
            <PrivateRoute path="/print/:docId">
              <Print />
            </PrivateRoute>
            <PrivateRoute path="/glossary/:glossaryItem">
              <Glossary />
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Redirect to="/dashboard" />
            </PrivateRoute>
            <SentryRoute path="*">
              <Error />
            </SentryRoute>
          </Switch>
          // </Suspense>
        )}
        {/* {navigation.scope && <NavigationMobile />} */}
      </ErrorBoundary>
      <HubSpotChat />
    </>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

function PrivateRoute({ children, ...rest }: RouteProps) {
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  // find out if query parameter "token" is set
  const query = useQueryParams();
  const token = query.get('token') || '';
  const authtoken = query.get('authtoken') || '';
  const [login, { loading: loginLoading }] = useUserLoginMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: shareTokenJWT, loading } = useGetShareTokenJWTQuery({
    variables: { id: token },
  });
  const jwt = shareTokenJWT?.getShareTokenJWT?.jwt;

  // Get first two letters of language code
  const langString = i18n.language.slice(0, 2) as Locales;

  useEffect(() => {
    // If authtoken is present, use it to log in the user
    if (authtoken && authtoken !== 'without') {
      login({
        variables: {
          email: '',
          password: '',
          token: authtoken,
          locale: langString,
        },
      }).catch((e) =>
        // TODO/WM/ASK: it will be better for "multi lang" to map error codes with messages
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack })
      );
    }
  }, [authtoken]);

  return token ? (
    <SentryRoute {...rest}>
      {loading ? (
        <Loading />
      ) : jwt ? (
        children
      ) : (
        <Error error={{ name: '', message: t('common.error.invalid_auth_token', 'Ungültiger Zugriffstoken') }} />
      )}
    </SentryRoute>
  ) : (
    <SentryRoute {...rest}>
      {!user ? (
        <Login />
      ) : !user.mfaVerified ? (
        <MFAVerify />
      ) : (
        // ) : !user.confirmed && !user.isAnonymous ? (
        //   <Confirmation user={user} />
        // ) : (user.confirmed && !user.conditionsConsent) || ((!user.email || !user.hasPassword) && !onboardingAppId) ? (
        //   <Password />
        children
      )}
    </SentryRoute>
  );
}

import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { downloadFile } from '@app/hooks/useDownload';
import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useCreateQuestionnaireMutation } from '@dieterApi/questionnaire/useCreateQuestionnaireMutation';
import { useDeleteQuestionnaireMutation } from '@dieterApi/questionnaire/useDeleteQuestionnaireMutation';
import { useOpenQuestionnaireMutation } from '@dieterApi/questionnaire/useOpenQuestionnaireMutation';
import { useLegalOSMutation } from '@legalosApi/graphql/mutations';
import { useState } from 'react';

export type Dashboard = ReturnType<typeof useDashboard>;

export type Scope = [ScopeType, string];
export type ScopeType = 'create' | 'download' | 'open' | 'remove' | 'update';

export function useDashboard() {
  const { navigateToPath } = useQuestionaireRoute();

  const [create] = useCreateQuestionnaireMutation();
  const [open] = useOpenQuestionnaireMutation();
  const [remove] = useDeleteQuestionnaireMutation();

  // const [download, { loading }] = useDownloadQuestionnaireMutation();
  const [downloadDocx] = useLegalOSMutation('questionnaireDownloadDocx');
  const [downloadPdf] = useLegalOSMutation('questionnaireDownloadPdf');
  const { enqueueSnackbar } = useSnackbar();
  const tasks = useTasks();
  const { user } = useUser();

  const [current, setCurrent] = useState<Scope | null>(null);

  function process(scope: Scope, callback: () => Promise<any>) {
    if (current) return;
    setCurrent(scope);
    callback()
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
        console.error(e);
      })
      .finally(() => setCurrent(null));
  }

  return {
    isBusy: current !== null,
    affects(type?: ScopeType, id?: string) {
      return !!(current && (!type || current[0] === type) && (!id || current[1] === id));
    },
    create(
      appId: string,
      section?: number,
      navigate: boolean = true,
      userValueIndex?: string,
      token?: string,
      urlToken?: string,
      name?: string // name of external user working anonymously with token on the questionnaire (Datenschutz Grundschulung)
    ) {
      process(['create', userValueIndex || appId], async () => {
        const { data } = await create({ variables: { appId: appId, userValueIndex, token } });

        const questionnaireId = data?.createQuestionnaire.questionnaireId;
        if (data && navigate) {
          navigateToPath({ questionnaireId, section, token: urlToken, name });
        }
      });
    },
    download(localQuestionnaireId: string, fileName?: string, type: 'pdf' | 'docx' = 'docx') {
      process(['download', localQuestionnaireId], async function () {
        // return download({ variables: { documentId: documentId } });

        const { data } = await open({ variables: { localQuestionnaireId } });
        if (data) {
          switch (type) {
            case 'pdf':
              return downloadPdf({
                variables: {
                  questionnaireId: data.openQuestionnaire.questionnaireId,
                },
              }).then((result) => {
                const downloadData = result?.data?.questionnaireDownloadPdf;
                if (fileName && downloadData) {
                  // the original downloadData.filename needs to be overwritten with the new filename leaving the ending intact
                  downloadData.fileName =
                    fileName + downloadData.fileName.substring(downloadData.fileName.lastIndexOf('.'));
                }
                downloadData && downloadFile(downloadData);
              });

            case 'docx':
              return downloadDocx({
                variables: {
                  input: { questionnaireId: data.openQuestionnaire.questionnaireId },
                },
              }).then((result) => {
                const downloadData = result?.data?.questionnaireDownloadDocx;
                if (fileName && downloadData) {
                  // the original downloadData.filename needs to be overwritten with the new filename leaving the ending intact
                  downloadData.fileName =
                    fileName + downloadData.fileName.substring(downloadData.fileName.lastIndexOf('.'));
                }
                downloadData && downloadFile(downloadData);
              });
            default:
              return;
          }
        }
      });
    },
    open(localQuestionnaireId: string, section?: number, revisit: boolean = false) {
      process(['open', localQuestionnaireId], async function () {
        const { data } = await open({ variables: { localQuestionnaireId } });
        if (data) {
          const questionnaireId = data?.openQuestionnaire.questionnaireId;
          navigateToPath({ questionnaireId, section, revisit });
        }
      });
    },
    remove(localQuestionnaireId: string) {
      process(['remove', localQuestionnaireId], async () => {
        const thisDoc = user?.questionnaires?.find((q) => q.id === localQuestionnaireId);
        const task = tasks?.find((task) => thisDoc?.application.id === task.application?.id);
        task?.setStatus(false);

        return remove({ variables: { localQuestionnaireId } });
      });
    },
  };
}

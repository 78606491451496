import Autocomplete from '@components/ui/Autocomplete/Autocomplete';
import { Tickbox } from '@components/ui/Tickbox/Tickbox';
import React, { useMemo } from 'react';
import { Props } from './QuestionInput';

export function SelectSome(props: Props<string[]>) {
  const ref = React.createRef<HTMLInputElement>();
  React.useEffect(() => {
    const { current } = ref;
    if (current) current.focus();
  });

  function onOptionChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    const newValue = props.value.filter((value) => value !== target.value);
    if (target.checked) {
      newValue.push(target.value);
    }

    props.onChange(newValue);
  }

  const value = useMemo(
    () => props.question.options.filter((option) => props.value.indexOf(option.value) !== -1),
    [props.value, props.question.options]
  );

  return (
    <div className="flex flex-col">
      {props.question.options.length > 9 ? (
        <Autocomplete
          multiple
          options={props.question.options}
          value={value}
          onChange={(_, value) => props.onChange(value.map((o) => o.value))}
          disabled={props.disabled}
        />
      ) : (
        props.question.options.map((option) => (
          <Tickbox
            key={option.value}
            checked={props.value.indexOf(option.value) !== -1}
            label={option.label}
            onChange={onOptionChange}
            type="checkbox"
            value={option.value}
          />
        ))
      )}
    </div>
  );
}

import {
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from "@apollo/client";
import {
  LegalOSMutation,
  MutationInputType,
  MutationOutputType,
  MutationMap,
} from "./types/mutationNameToMutationAndTypes";

export const useLegalOSMutation = <T extends LegalOSMutation>(
  queryName: T,
  options?: MutationHookOptions<MutationOutputType<T>, MutationInputType<T>>,
): MutationTuple<MutationOutputType<T>, MutationInputType<T>> =>
  useMutation<MutationOutputType<T>, MutationInputType<T>>(
    MutationMap[queryName],
    options,
  );

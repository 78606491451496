import { formatNumber } from "@legalosApi/helpers/formatNumber";
import AllowedValueTypes from "@legalosApi/types/AllowedValueTypes";
import ISimpleDateObject from "@legalosApi/types/ISimpleDateObject";
import QuestionType from "@legalosApi/types/QuestionType";
import { translations } from "@locales/translations";
import { format } from "date-fns/esm";
import { de, enUS } from "date-fns/esm/locale";
import { TFunction } from "react-i18next";

export function localizeValue({
  value,
  type,
  locale,
  t,
}: {
  value: AllowedValueTypes | AllowedValueTypes[];
  type: QuestionType;
  locale: string;
  t: TFunction<"translation", undefined>;
}): string {
  if (value === null || value === undefined || value === "") {
    return "";
  }
  switch (type) {
    case QuestionType.DATE:
      const simpleDate = value as ISimpleDateObject;
      if (simpleDate) {
        const dateValue = new Date(simpleDate.year, simpleDate.month, simpleDate.day);
        return format(dateValue, "P", {
          locale: (locale || "de_DE").toLowerCase().startsWith("de") ? de : enUS,
        });
      }
      return "";
    case QuestionType.CURRENCY:
      return formatNumber(Number(value), locale, true);
    case QuestionType.FLOAT:
      return formatNumber(value as number, locale, false);
    case QuestionType.BOOLEAN:
      // @ts-expect-error
      return t(value ? translations.inputs.yes : translations.inputs.no);
    default:
      return String(value);
  }
}

import { gql, useMutation } from '@apollo/client';
import { EMPLOYEE_FIELDS, Employee } from '@dieterApi/user/useUserQuery';

// Define the GraphQL mutation
const ADD_STAKEHOLDER = gql`
  ${EMPLOYEE_FIELDS}
  mutation AddStakeholder($name: String!, $type: StakeholderType!, $token: String!) {
    addStakeholder(name: $name, type: $type, token: $token) {
      ...UserEmployeeFields
    }
  }
`;

// TypeScript interfaces for input and result
export interface AddStakeholderInput {
  name: string;
  type: StakeholderType;
  token: string;
}

export enum StakeholderType {
  Employee = 'Employee',
  Applicant = 'Applicant',
  ContractPartner = 'ContractPartner',
}

export interface AddStakeholderResult {
  addStakeholder: Employee;
}

// React Hook
export function useAddStakeholderMutation() {
  return useMutation<AddStakeholderResult, AddStakeholderInput>(ADD_STAKEHOLDER);
}

import { Reference, StoreObject, gql, isReference, useMutation } from '@apollo/client';
import { FILE_FIELDS, USER_FIELDS, User, UserFile } from '@dieterApi/user/useUserQuery';

const DELETE_FILE = gql`
  ${FILE_FIELDS}
  mutation DeleteFile($id: String!) {
    deleteFile(id: $id) {
      ...UserFileFields
    }
  }
`;

export interface FileDeleteInput {
  id: string;
}

export interface FileDeleteResult {
  deleteFile: UserFile;
}

export function useFileDeleteMutation() {
  return useMutation<FileDeleteResult, FileDeleteInput>(DELETE_FILE, {
    optimisticResponse: (data) => {
      return {
        deleteFile: {
          id: data.id,
          name: '',
          size: 0,
          uri: '',
          url: '',
          __typename: 'File',
        },
      };
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          getUser(existingUser: User | Reference | StoreObject) {
            // In the case that the existingUser is not even an object, we return it unmodified.
            if (!existingUser || typeof existingUser !== 'object') {
              return existingUser;
            }

            // If the existingUser is a reference, we retrieve the full fragment.
            let user: User | null = null;
            if (isReference(existingUser)) {
              user = cache.readFragment({
                id: existingUser.__ref,
                fragment: USER_FIELDS,
                fragmentName: "UserFields",
              });
            }

            // If existingUser is not a reference or the fragment could not be found, we use the existing object.
            if (!user) {
              user = existingUser as User;
            }

            const newUser = {
              ...user,
              topics: user.topics?.map((topic) => {
                return {
                  ...topic,
                  files: topic.files.filter((file) => file.id !== data?.deleteFile.id),
                };
              }),
            };
            return newUser;
          },
        },
      });
    },
  });
}

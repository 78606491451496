import { useSnackbar } from '@app/hooks/useSnackbar';
import { Button, NotificationModal } from '@components/ui';
import { UserCompany } from '@dieterApi/user/useUserQuery';
import { ModalProps, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { ZipPattern, validatePattern } from '@app/utils/validatePattern';
import { useAddTenantMutation } from '@dieterApi/user/useUserAddTenantMutation';
import { useUpdateTenantMutation } from '@dieterApi/user/useUserUpdateTenantMutation';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<ModalProps, 'children'> {
  tenant?: UserCompany;
}

export function MutateTenantModal({ tenant, ...props }: Props) {
  const { t } = useTranslation();
  const isEditMode = !!tenant;
  const { enqueueSnackbar } = useSnackbar();
  const [addTenant, { loading: addLoading }] = useAddTenantMutation();
  const [updateTenant, { loading: updateLoading }] = useUpdateTenantMutation();
  const emptyFormValues = {
    phone: '',
    company: '',
    website: '',
    street: '',
    zip: '',
    city: '',
  };
  const [formValues, setFormValues] = useState(emptyFormValues);

  const loading = addLoading || updateLoading;

  useEffect(() => {
    if (tenant) {
      setFormValues({
        phone: tenant.phone,
        company: tenant.name,
        website: tenant.website,
        street: tenant.street,
        zip: tenant.zip,
        city: tenant.city,
      });
    } else {
      setFormValues(emptyFormValues);
    }
  }, [tenant]);

  const [validationError, setValidationError] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false);
    const { name, value } = e.target;
    if (name === 'zip') {
      if (!validatePattern(value, /^\d{0,5}$/)) {
        return;
      }
    }
    if (name === 'phone') {
      if (!validatePattern(value, /^[+49|0]?([1-9]\d{0,15})?$/)) {
        return;
      }
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateInput = () => {
    const { phone, company, street, zip, city } = formValues;
    return (
      // validatePattern(phone, PhonePattern) &&
      company && street && validatePattern(zip, ZipPattern) && city
    );
  };

  const handleSubmit = () => {
    if (validateInput()) {
      if (isEditMode) {
        updateTenant({
          variables: {
            companyId: tenant?.id,
            companyName: formValues.company,
            phone: formValues.phone,
            website: formValues.website,
            street: formValues.street,
            zip: formValues.zip,
            city: formValues.city,
          },
        })
          .then(() => {
            props.onClose && props.onClose({}, 'backdropClick');
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
          });
      } else {
        addTenant({
          variables: {
            companyName: formValues.company,
            phone: formValues.phone,
            website: formValues.website,
            street: formValues.street,
            zip: formValues.zip,
            city: formValues.city,
          },
        })
          .then(() => {
            props.onClose && props.onClose({}, 'backdropClick');
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
          });
      }
    } else {
      enqueueSnackbar(t('dashboard.multitenanttable.mutatemodal.error'), { variant: 'error' });
      setValidationError(true);
    }
  };

  return (
    <NotificationModal {...props}>
      <div className="flex flex-col gap-5 p-3 md:p-12">
        <div className="h3">
          {isEditMode
            ? t('dashboard.multitenanttable.mutatemodal.edit')
            : t('dashboard.multitenanttable.mutatemodal.add')}
        </div>
        <div className="flex gap-5 w-full">
          <TextField
            className="w-full"
            id="company-input"
            name="company"
            label={t('route.admin.documents-table.company-name-label')}
            variant="filled"
            size="small"
            required={true}
            error={validationError && !formValues.company}
            onChange={handleInputChange}
            value={formValues.company}
          />
          <TextField
            className="w-full"
            id="website-input"
            name="website"
            label={t('dashboard.multitenanttable.mutatemodal.website')}
            variant="filled"
            size="small"
            onChange={handleInputChange}
            value={formValues.website}
          />
        </div>
        <div className="flex gap-5">
          <TextField
            className="w-full"
            id="street-input"
            name="street"
            label={t('dashboard.multitenanttable.mutatemodal.street')}
            variant="filled"
            size="small"
            required={true}
            error={validationError && !formValues.street}
            onChange={handleInputChange}
            value={formValues.street}
          />
          <TextField
            className="w-full"
            id="phone-input"
            name="phone"
            label={t('dashboard.multitenanttable.mutatemodal.phone')}
            variant="filled"
            size="small"
            required={false}
            onChange={handleInputChange}
            value={formValues.phone}
          />
        </div>
        <div className="flex gap-5">
          <TextField
            className="w-full"
            id="zip-input"
            name="zip"
            label={t('dashboard.multitenanttable.mutatemodal.zip')}
            variant="filled"
            size="small"
            required={true}
            error={validationError && (!formValues.zip || !validatePattern(formValues.zip, ZipPattern))}
            onChange={handleInputChange}
            value={formValues.zip}
          />
          <TextField
            className="w-full"
            id="city-input"
            name="city"
            label={t('dashboard.multitenanttable.mutatemodal.city')}
            variant="filled"
            size="small"
            required={true}
            error={validationError && !formValues.city}
            onChange={handleInputChange}
            value={formValues.city}
          />
        </div>

        <div>
          <Button onClick={handleSubmit} disabled={loading} spinner={loading}>
            {t('common.save')}
          </Button>
        </div>
      </div>
    </NotificationModal>
  );
}

import { Textarea } from '@components/ui/Textarea/Textarea';
import React from 'react';
import { Props } from './QuestionInput';

export function MultilineText(props: Props<string>) {
  const ref = React.createRef<HTMLTextAreaElement>();
  React.useEffect(() => {
    const { current } = ref;
    if (current) current.focus();
  });

  return (
    <Textarea
      disabled={props.disabled}
      onChange={(e) => props.onChange(e.target.value)}
      ref={ref}
      value={props.value}
      onKeyUp={(e) => e.stopPropagation()}
    />
  );
}

import { gql, useMutation } from '@apollo/client';
import { APPLICATION_FIELDS, QUESTIONNAIRE_FIELDS, UserQuestionnaireApplication } from '@dieterApi/user/useUserQuery';

export const OPEN_QUESTIONNAIRE = gql`
  ${QUESTIONNAIRE_FIELDS}
  ${APPLICATION_FIELDS}
  mutation OpenQuestionnaire($localQuestionnaireId: String!, $token: String) {
    openQuestionnaire(id: $localQuestionnaireId, token: $token) {
      ...UserQuestionnaireFields
      application {
        ...UserAppFields
      }
    }
  }
`;

export interface OpenQuestionnaireInput {
  localQuestionnaireId: string;
  token?: string;
}

export interface OpenQuestionnaireResult {
  openQuestionnaire: UserQuestionnaireApplication;
}

export function useOpenQuestionnaireMutation() {
  return useMutation<OpenQuestionnaireResult, OpenQuestionnaireInput>(OPEN_QUESTIONNAIRE, {
    refetchQueries: ['GetUser', 'questionnaireDoc'],
    update(cache, { data }) {
      cache.modify({
        id: `QuestionnaireSummary:${data?.openQuestionnaire.id}`,
        fields: {
          questionnaireId: () => data?.openQuestionnaire.questionnaireId || '',
        },
      });
    },
  });
}

import { useNavigation } from '@app/hooks/useNavigation';
import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useTags } from '@app/hooks/useTags';
import { useUserValues } from '@app/hooks/useUserValues';
import { Locales } from '@components/block/Header/LangSelect';
import { Loading } from '@components/ui';
import { AnimatedInfoButton } from '@components/ui/AnimatedInfo/AnimatedInfo';
import { Button } from '@components/ui/Button/Button';
import { Markdown } from '@components/ui/Markdown/Markdown';
import { Shortcut } from '@components/ui/Shortcut/Shortcut';
import AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';
import { Collapse } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../Input/QuestionInput';
import { QuestionStep as Step } from '../../utils';
import { Props as BaseProps } from '../Step';

export type Props = BaseProps<Step>;

export function QuestionStep(props: Props) {
  const { t, i18n } = useTranslation();
  const { steps, step, stepIndex } = props;
  const { question } = step;
  const {
    navigation: { isMobile },
    setNavigation,
  } = useNavigation();
  const [isAnswered, setAnswered] = useState(false);
  const { updateFromUserValue } = useUserValues();
  const { jwt } = useQuestionaireRoute();

  const [value, setValue] = useState<AllowedValueTypes>(question.value ? question.value : '');
  const tooltip = question.tags.process.some((tag) => tag === 'TOOLTIP');
  const [showInfo, setShowInfo] = useState(!tooltip);

  const isLast = steps[steps.length - 1] === step;
  const [tagAction] = useTags();
  const { enqueueSnackbar } = useSnackbar();

  const onChange = useCallback(
    (value: any) => {
      setValue(value);
    },
    [setValue]
  );
  const tags = props.step.question.tags;

  // disable the next button if the user has not answered the question or only whitespace
  const isDisabled =
    !value ||
    (typeof value === 'string' && !value.replace(/\s/g, '').length) || // only whitespace
    (Array.isArray(value) && value.length === 0); // empty array

  async function onNext() {
    if (isDisabled) return;
    // we run all tag actions and only proceed on success
    tagAction(tags, value)
      .then(async (proceed) => {
        if (proceed) {
          setAnswered(true);
          await props
            .onUpdateQuestion(question._id, value, true, jwt, jwt ? (i18n.language.slice(0, 2) as Locales) : undefined)
            .then(() => {
              // show "saved" animation
              setNavigation((nav) => void (nav.showSavedAnimation = true));
            });
          props.onAdvance(props.step.id);
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
      });
  }

  const nextButton = (
    <Button disabled={isAnswered || isDisabled} onClick={onNext} googleTriggerClassName="ga4-checkup-step">
      {isLast ? t('route.docs.step_next_button_isLast') : t('common.continue')}
    </Button>
  );

  // this handles the automatic answering of AUTO questions while displaying the Loading component
  const [blocked, setBlocked] = useState(true);
  useEffect(() => {
    // we disable automatic update from uservalues if questionnaire is edited with a token
    // because the editing user is not the owner of the questionnaire, hence his userValues would
    // be invalid
    if (jwt) {
      setBlocked(false);
    } else {
      setBlocked(updateFromUserValue(step, props.questionnaire, props.onUpdateQuestion, props.onAdvance, setValue));
    }
  }, []);
  if (blocked) return <Loading />;

  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="dtQuestStep flex gap-20">
      <div className="flex-1 hidden md:block" />
      <div className="dtQuestStep__content flex-4">
        {/* <StepInfo step={props.step} /> */}
        <div className="flex gap-20 mb-20">
          <div className="flex-4 text-break">
            <Markdown
              className="dtQuestStep__question questionTitle"
              text={question.questionText}
              data-google={stepIndex}
            />
            {question.description && (
              <Collapse in={showInfo}>
                <Markdown text={question.description} />
              </Collapse>
            )}
          </div>
          <div className="flex-1 hidden md:block">
            {question.description && tooltip && <AnimatedInfoButton onClick={handleInfoClick} />}
          </div>
        </div>
        {question.description && !showInfo && (
          <div className="block md:hidden self-end">
            <AnimatedInfoButton onClick={handleInfoClick} />
          </div>
        )}
        <Input disabled={isAnswered} onAction={onNext} onChange={onChange} question={question} value={value} />
        <div className="flex flex-col align-items-start gap-20 mt-20 mb-20">
          {isMobile ? (
            nextButton
          ) : (
            <Shortcut didTrigger={isAnswered} disabled={isDisabled} onAction={() => null} shortcutKey="Enter">
              {nextButton}
            </Shortcut>
          )}
        </div>
      </div>
      <div className="flex-1 hidden md:block" />
    </div>
  );
}

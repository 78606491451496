import { gql, useMutation } from '@apollo/client';

export const CONFIRM_DOCUMENT = gql`
  mutation ConfirmDocument($name: String!, $token: String!, $questionnaireId: String) {
    confirmDocument(name: $name, token: $token, questionnaireId: $questionnaireId) {
      name
      createdAt
      documentNumber
    }
  }
`;

export interface ConfirmDocumentResult {
  getQuestionnaireShareToken: {
    name: string;
    createdAt: Date;
    documentNumber: number;
  };
}

export interface ConfirmDocumentInput {
  name: string;
  token: string;
  questionnaireId?: string;
}

export function useQuestionnaireDocumentConfirmMutation() {
  return useMutation<ConfirmDocumentResult, ConfirmDocumentInput>(CONFIRM_DOCUMENT);
}

import { gql, useMutation } from '@apollo/client';
import { NavigationScope } from '@app/context/NavigationContext';
import { ChatMessage } from '@app/hooks/types';

export const SEND_CHAT_MESSAGE_MUTATION = gql`
  mutation SendChatMessage(
    $chatId: String!
    $messages: [ChatMessageInput]!
    $questionnaireId: String
    $scope: NavigationScope!
  ) {
    sendChatMessage(chatId: $chatId, messages: $messages, questionnaireId: $questionnaireId, scope: $scope)
  }
`;

export interface SendChatMessageMutationResult {
  sendChatMessage: boolean;
}

export interface SendChatMessageMutationVariables {
  chatId: string;
  messages: ChatMessage[];
  questionnaireId?: string; // remote id of the questionnaire
  scope: NavigationScope;
}

export const useSendChatMessageMutation = () => {
  return useMutation<SendChatMessageMutationResult, SendChatMessageMutationVariables>(SEND_CHAT_MESSAGE_MUTATION);
};

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { ForwardedRef, forwardRef } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { CompanySubscription } from '@dieterApi/company/useCompanyQuery.ts';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import OuterElementType, { OuterElementContext } from './OuterElement';
import useResetCache from './use-reset-cache';

const LISTBOX_PADDING = 8;

function $VirtualizedListbox(
  { children, ...other }: React.HTMLAttributes<HTMLElement>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const gridRef = useResetCache(itemCount);

  const getHeight = () => (itemCount > 8 ? 8 * itemSize : itemData.length * itemSize);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const [elmProps, option, state, search, selected] = dataSet;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <li
      {...elmProps}
      className={classNames(elmProps?.className, 'w-full flex !items-center !justify-between')}
      style={inlineStyle}
    >
      <div className="grow flex items-center gap-x-2">
        <Highlighter
          autoEscape
          searchWords={splitStringInDecreasingChunks(search).filter((str) => str === search || str.length > 2)}
          textToHighlight={option.label}
        />
        <SubscriptionBadge subscription={option.subscription} />
      </div>
      {selected?.value === option.value && <CheckIcon />}
    </li>
  );
}

function splitStringInDecreasingChunks(str?: string) {
  const chunks: string[] = [];

  if (!str) return chunks;

  const length = str.length;

  for (let chunkSize = length; chunkSize > 0; chunkSize--) {
    for (let i = 0; i <= length - chunkSize; i++) {
      chunks.push(str.substring(i, i + chunkSize));
    }
  }

  return chunks;
}

interface ISubscriptionBadgeProps {
  subscription: CompanySubscription;
}
function SubscriptionBadge({ subscription }: ISubscriptionBadgeProps) {
  return (
    <span
      className={classNames('inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium', {
        'bg-yellow-100 text-yellow-800': subscription === CompanySubscription.Ticket,
        'bg-green-100 text-green-700': subscription === CompanySubscription.Premium,
        'bg-blue-100 text-blue-700': subscription === CompanySubscription.Basic,
      })}
    >
      <svg
        className={classNames('h-1.5 w-1.5', {
          'fill-yellow-500': subscription === CompanySubscription.Ticket,
          'fill-green-500': subscription === CompanySubscription.Premium,
          'fill-blue-500': subscription === CompanySubscription.Basic,
        })}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        {subscription && <circle cx="3" cy="3" r="3" />}
      </svg>
      {subscription}
    </span>
  );
}

const VirtualizedListbox = forwardRef($VirtualizedListbox);
export default VirtualizedListbox;

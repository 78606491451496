import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function useTutorialSteps() {
  const { t } = useTranslation();
  const basicSteps = [
    {
      label: t('tutorial.basic.step1.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 m-2 md:m-8 md:mr-30">
          <img className="md:flex-1" src="/assets/images/dieter_winkt.svg" style={{ height: '300px' }} />
          <div className="md:flex-2 flex-col gap-20">
            <strong>{t('tutorial.basic.step1.hi')}</strong>
            <p>{t('tutorial.basic.step1.whatsnext')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.basic.step2.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-9">
            <img className="w-full shadow-lg hidden md:block" src="/assets/images/tutorial1.png" />
            <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-mobile1.png" />
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.basic.step2.strong')}</strong>
            <p>{t('tutorial.basic.step2.p1')}</p>
            <p>{t('tutorial.basic.step2.p2')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.basic.step3.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-2">
            <img className="w-full shadow-lg hidden md:block" src="/assets/images/tutorial2.png" />
            <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-mobile2.png" />
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.basic.step3.strong')}</strong>
            <p>{t('tutorial.basic.step3.p1')}</p>
            <p>{t('tutorial.basic.step3.p2')}</p>
            <p>{t('tutorial.basic.step3.p3')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.basic.step4.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-5">
            <img className="w-full shadow-lg hidden md:block" src="/assets/images/tutorial3.png" />
            <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-mobile3.png" />
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.basic.step4.strong')}</strong>
            <p>{t('tutorial.basic.step4.p1')}</p>
            <p>{t('tutorial.basic.step4.p2')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.basic.step5.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-5">
            <img className="w-full shadow-lg hidden md:block" src="/assets/images/tutorial4.png" />
            <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-mobile4.png" />
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.basic.step5.strong')}</strong>
            <p>{t('tutorial.basic.step5.p1')}</p>
            <p>{t('tutorial.basic.step5.p2')}</p>
          </div>
        </div>
      ),
    },
  ];

  const dseSteps = [
    {
      label: t('tutorial.dse.step1.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 m-2 md:m-8 md:mr-30">
          <img className="md:flex-1" src="/assets/images/dieter_winkt.svg" style={{ height: '300px' }} />
          <div className="md:flex-2 flex-col gap-20">
            <strong>{t('tutorial.dse.step1.strong')}</strong>
            <p>{t('tutorial.dse.step1.p1')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.dse.step2.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-9">
            <img className="w-full shadow-lg hidden md:block" src="/assets/images/tutorial-dse1.png" />
            <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-dse-mobile1.png" />
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.dse.step2.strong')}</strong>
            <p>{t('tutorial.dse.step2.p1')}</p>
            <p>{t('tutorial.dse.step2.p2')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.dse.step3.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-2">
            <img className="w-full h-72" src="/assets/images/dieter_schaf_badman.svg" />
            {/* <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-mobile2.png" /> */}
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.dse.step3.strong')}</strong>
            <p>
              <Trans t={t} i18nKey={'tutorial.dse.step3.p1'}>
                Damit du deine Datenschutzerklärung rechtssicher auf deiner Website einbinden kannst, buche jetzt die
                Einzelleistung <Link to="/pricing?option=ticket&product=dse">ab nur 7 €/Monat</Link> oder entscheide
                dich direkt für eine unserer Datenschutz-Komplettlösungen.
              </Trans>
            </p>
            <p>{t('tutorial.dse.step3.p2')}</p>
          </div>
        </div>
      ),
    },
  ];

  const imprintSteps = [
    {
      label: t('tutorial.dse.step1.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 m-2 md:m-8 md:mr-30">
          <img className="md:flex-1" src="/assets/images/dieter_winkt.svg" style={{ height: '300px' }} />
          <div className="md:flex-2 flex-col gap-20">
            <strong>{t('tutorial.imprint.step1.strong')}</strong>
            <p>{t('tutorial.imprint.step1.p1')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.dse.step2.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-9">
            <img className="w-full shadow-lg hidden md:block" src="/assets/images/tutorial-dse1.png" />
            <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-dse-mobile1.png" />
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.imprint.step2.strong')}</strong>
            <p>{t('tutorial.imprint.step2.p1')}</p>
            <p>{t('tutorial.imprint.step2.p2')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.dse.step3.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-2">
            <img className="w-full h-72" src="/assets/images/dieter_schaf_badman.svg" />
            {/* <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-mobile2.png" /> */}
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.imprint.step3.strong')}</strong>
            <p>{t('tutorial.imprint.step3.p1')}</p>
            <p>{t('tutorial.imprint.step3.p2')}</p>
          </div>
        </div>
      ),
    },
  ];

  const tomsSteps = [
    {
      label: t('tutorial.toms.step1.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 m-2 md:m-8 md:mr-30">
          <img className="md:flex-1" src="/assets/images/dieter_winkt.svg" style={{ height: '300px' }} />
          <div className="md:flex-2 flex-col gap-20">
            <strong>{t('tutorial.toms.step1.strong')}</strong>
            <p>{t('tutorial.toms.step1.p1')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.toms.step2.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-9">
            <img className="w-full shadow-lg hidden md:block" src="/assets/images/tutorial-toms1.png" />
            <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-toms-mobile1.png" />
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.toms.step2.strong')}</strong>
            <p>{t('tutorial.toms.step2.p1')}</p>
            <p>{t('tutorial.toms.step2.p2')}</p>
          </div>
        </div>
      ),
    },
    {
      label: t('tutorial.toms.step3.label'),
      content: (
        <div className="flex flex-col md:flex-row gap-8 md:m-8 m-2">
          <div className="md:flex-1 md:px-2">
            <img className="w-full h-72" src="/assets/images/dieter_schaf_badman.svg" />
            {/* <img className="w-full shadow-lg block md:hidden" src="/assets/images/tutorial-mobile2.png" /> */}
          </div>
          <div className="flex-1 flex flex-col gap-20">
            <strong>{t('tutorial.toms.step3.strong')}</strong>
            <p>
              <Trans t={t} i18nKey={'tutorial.toms.step3.p1'}>
                Damit du deine TOMs rechtssicher für dein Unternehmen verwenden kannst, buche jetzt die Einzelleistung{' '}
                <Link to="/pricing?option=ticket&product=toms">ab nur 7 €/Monat</Link> oder entscheide dich direkt für
                eine unserer Datenschutz-Komplettlösungen.
              </Trans>
            </p>
            <p>{t('tutorial.toms.step3.p2')}</p>
          </div>
        </div>
      ),
    },
  ];

  return { basicSteps, dseSteps, tomsSteps, imprintSteps };
}

import { useNavigation } from '@app/hooks/useNavigation';

export enum ConsentService {
  Hubspot = 'ry0QcNodoWQ',
  Sentry = 'rH1vNPCFR',
}

export const useConsent = () => {
  const { setNavigation } = useNavigation();

  const giveConsent = (service: ConsentService) => {
    if (window.UC_UI) {
      window.UC_UI.acceptService(service);
      if (service === ConsentService.Hubspot) {
        setNavigation((nav) => void (nav.consent.hubspot = true));
      }
      if (service === ConsentService.Sentry) {
        setNavigation((nav) => void (nav.consent.sentry = true));
      }
    }
  };

  return { giveConsent };
};

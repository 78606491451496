import { gql, useMutation } from '@apollo/client';
import { Locales } from '@components/block/Header/LangSelect';
import { USER_FIELDS, User, UserRole } from './useUserQuery';

const INVITE_USER = gql`
  ${USER_FIELDS}
  mutation InviteUser($email: String!, $role: String!, $locale: String, $enabledCompanies: [String!]!) {
    inviteUser(email: $email, role: $role, locale: $locale, enabledCompanies: $enabledCompanies) {
      ...UserFields
    }
  }
`;

export interface UserInviteInput {
  email: string;
  role: UserRole;
  locale?: Locales;
  enabledCompanies: string[];
}

export interface UserInviteResult {
  inviteUser: User;
}

export function useInviteUserMutation() {
  return useMutation<UserInviteResult, UserInviteInput>(INVITE_USER, {
    refetchQueries: ['GetUser'],
  });
}

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { HTMLAttributes, useState } from 'react';
import { LinkProps } from 'react-router-dom';

import './ui-threedots.sass';

interface Props extends HTMLAttributes<HTMLDivElement> {
  items: ThreeDotItem[];
  'data-testid'?: string;
}

export interface ThreeDotItem extends Omit<LinkProps, 'to'> {
  label: string | JSX.Element;
  onClick: () => void;
  icon?: JSX.Element;
  tooltip?: string;
  disabled?: boolean;
  testlabel?: string;
}

export function ThreeDots({ items, 'data-testid': dataTestId, ...props }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div {...props}>
      <IconButton data-testid={dataTestId} onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        className="dtAccountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {items.map(
          ({ label, disabled, icon, tooltip, onClick, testlabel, ...linkProps }) =>
            !disabled && (
              <MenuItem key={JSON.stringify(label)}>
                <Tooltip title={tooltip} arrow placement="left">
                  <button className="flex gap-10 items-center w-full" onClick={onClick} data-testid={testlabel}>
                    {icon}

                    {label}
                  </button>
                </Tooltip>
              </MenuItem>
            )
        )}
      </Menu>
    </div>
  );
}

import { TaskType } from '@dieterApi/task/useTaskQuery';
import { FormItem } from '../Topic/Forms/FormItem';
import { ProcessItem } from '../Topic/Process/ProcessItem';
import { useDashboard } from '../useDashboard';
import { TaskWithApp } from './TaskList';
import { SingleTaskSkeleton } from './TaskSkeleton';

export interface Props {
  task: TaskWithApp;
  isFocusTask?: boolean;
  indentedLeft?: boolean;
  indentedRight?: boolean;
}

export function TaskItem({ task, isFocusTask, indentedLeft, indentedRight }: Props) {
  const dashboard = useDashboard();

  let content: JSX.Element;

  switch (task.type) {
    case TaskType.QUESTIONNAIRE:
    case TaskType.REVISIT:
      content = task.application ? (
        <FormItem
          app={task.application}
          index={task.section ? task.section - 1 : 0}
          dashboard={dashboard}
          task={task}
          isFocusTask={isFocusTask}
        />
      ) : (
        <SingleTaskSkeleton />
      );
      break;

    case TaskType.SIMPLEPROCESS:
    case TaskType.PUBLISHPROCESS:
    case TaskType.SHAREQUESTIONNAIRE:
    case TaskType.LINKQUESTIONNAIRE:
      content = (
        <ProcessItem task={task} indentedLeft={indentedLeft} indentedRight={indentedRight} isFocusTask={isFocusTask} />
      );
      break;
    default:
      const _exhaustiveCheck: never = task.type;
      content = <div>Unknown task type</div>;
      break;
  }

  return content;
}

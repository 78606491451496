import { ProductFeature, User, UserRole, useUserQuery } from '@dieterApi/user/useUserQuery';
import React, { createContext, useContext, useEffect } from 'react';
import { useApolloClient } from './ApolloClientContext';
import Values from 'values.js';

interface IUserContext {
  user: User | undefined;
  userLoading: boolean;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

interface Props {
  children: React.ReactNode;
}

export function UserProvider({ children }: Props) {
  const { data: userData, loading: userLoading } = useUserQuery();
  const { wsClient } = useApolloClient();

  const user = userData?.getUser;

  let userWithMethods: User | undefined = undefined;

  // add custom methods to user object
  if (user) {
    // copy user object but allow extension
    userWithMethods = { ...user };
    userWithMethods.getValue = function(key: string, index?: string) {
      const userValue = index
        ? this.userValues.find((uv) => uv.key === key && uv.index === index)
        : this.userValues.find((uv) => uv.key === key);
      return userValue?.value;
    };
    userWithMethods.hasFeature = function(feature: ProductFeature) {
      return this.company?.features.includes(feature) || false;
    };
    userWithMethods.isReadOnly = user.role === UserRole.ReadOnly;
  }

  useEffect(() => {
    if (user?.company.stripeCustomerId && window.tap) {
      try {
        window.tap('trial', user?.company.stripeCustomerId);
      } catch (error) {
        console.log(error);
      }
    }
  }, [user?.company.stripeCustomerId, window.tap]);

  // Apply whitelabel colors if available, or reset to default on logout.
  useEffect(() => {
    const principalCompany = user?.companies?.find((company) => company.isPrincipal === true);
    if (user && principalCompany?.features.includes('Whitelabel')) {
      if (principalCompany?.whitelabelConfig) {
        const primaryColor = principalCompany.whitelabelConfig.primaryColor;
        const secondaryColor = principalCompany.whitelabelConfig.secondaryColor;
        if (primaryColor && secondaryColor) {
          applyCustomTheme(primaryColor, secondaryColor);
        }
      }
    } else {
      //applyCustomTheme(fallbackPrimaryColor, fallbackSecondaryColor);
      applyDieterColors();
    }
  }, [user]);

  // on user change, we need to refetch the websocket token and terminate the connection
  // so it can be re-established with the new token
  useEffect(() => {
    const fetchSecureToken = async () => {
      const response = await fetch('/api/websockettoken', {
        credentials: 'include', // Include cookies in the request
      });
      const data = (await response.json()) as { token: string };
      sessionStorage.setItem('websocketToken', data.token);
    };
    fetchSecureToken().then(() => {
      wsClient?.terminate();
    });
  }, [user?.id]);

  return <UserContext.Provider value={{ user: userWithMethods || user, userLoading }}>{children}</UserContext.Provider>;
}
// eslint-disable-next-line react-refresh/only-export-components -- required for context, will not hurt HMR
export function useUser() {
  return useContext(UserContext);
}

const applyCustomTheme = (primaryColor: string, secondaryColor: string) => {
  const tintSteps = ["50", "100", "200", "300", "400"];
  const shadeSteps = ["600", "700", "800", "900"];

  // Primary color
  let contrast = "18";
  for (let i = 0; i < tintSteps.length; i++) {
    let color = "#" + new Values(primaryColor).tint((tintSteps.length - i) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--primary-${tintSteps[i]}`, color);
  }
  document.documentElement.style.setProperty("--primary-root", primaryColor);
  for (let i = 0; i < shadeSteps.length; i++) {
    let color = "#" + new Values(primaryColor).shade((i + 1) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--primary-${shadeSteps[i]}`, color);
  }
  document.documentElement.style.setProperty("--primary-root-t20", primaryColor + "33");

  // Secondary color
  contrast = "12";
  for (let i = 0; i < tintSteps.length; i++) {
    let color = "#" + new Values(secondaryColor).tint((tintSteps.length - i) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--secondary-${tintSteps[i]}`, color);
  }
  document.documentElement.style.setProperty("--secondary-root", secondaryColor);
  for (let i = 0; i < shadeSteps.length; i++) {
    let color = "#" + new Values(secondaryColor).shade((i + 1) * parseInt(contrast)).hex;
    document.documentElement.style.setProperty(`--secondary-${shadeSteps[i]}`, color);
  }
  document.documentElement.style.setProperty("--secondary-root-t20", secondaryColor + "33");
};

const applyDieterColors = () => {
  const setProp = (prop: string, color: string) => {
    document.documentElement.style.setProperty(prop, color);
  };
  setProp("--primary-50", "#f5ecfb");
  setProp("--primary-100", "#eddcf8");
  setProp("--primary-200", "#e0c1f1");
  setProp("--primary-300", "#cc99e7");
  setProp("--primary-400", "#b871db");
  setProp("--primary-root", "#ab5fce");
  setProp("--primary-600", "#8e40b0");
  setProp("--primary-700", "#773891");
  setProp("--primary-800", "#612f74");
  setProp("--primary-900", "#612f74");
  setProp("--secondary-50", "#fefce8");
  setProp("--secondary-100", "#fefce8");
  setProp("--secondary-200", "#fffbc2");
  setProp("--secondary-300", "#fff388");
  setProp("--secondary-400", "#ffe545");
  setProp("--secondary-root", "#fdd213");
  setProp("--secondary-600", "#edb905");
  setProp("--secondary-700", "#cd8e01");
  setProp("--secondary-800", "#a36505");
  setProp("--secondary-900", "#874f0c");
};

import { IQuestionnaire } from '@legalosApi/types/IQuestionnaire';
import { AdvanceCallback, UpdateQuestionCallback } from '../Quest';
import { AnyStep, IStep } from '../utils';
import { ChoiceStep } from './ChoiceStep/ChoiceStep';
import { ListStep } from './ListStep/ListStep';
import { MessageStep } from './MessageStep/MessageStep';
import './quest-step.sass';
import { QuestionStep } from './QuestionStep/QuestionStep';

export interface Props<T extends IStep = AnyStep> {
  onAdvance: AdvanceCallback;
  onUpdateQuestion: UpdateQuestionCallback;
  questionnaire: IQuestionnaire;
  step: T;
  stepIndex: number;
  steps: AnyStep[];
}

export function Step(props: Props) {
  switch (props.step.type) {
    case 'choice':
      return <ChoiceStep {...(props as any)} />;
    case 'question':
      return <QuestionStep {...(props as any)} />;
    case 'list':
      return <ListStep {...(props as any)} />;
    case 'message':
      return <MessageStep {...(props as any)} />;
  }
}

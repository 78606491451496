import { useUser } from '@app/context/UserContext';
import { Button, EmailField, Field, Input } from '@components/ui';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Modal, Paper } from '@mui/material';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import zxcvbn from 'zxcvbn';

import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useUserUpdatePasswordMutation } from '@dieterApi/user/useUserUpdatePasswordMutation';
import { Trans, useTranslation } from 'react-i18next';
import { Balancer } from 'react-wrap-balancer';
import { Slide } from '../Animations/Slide';

export function PasswordModal() {
  const { t } = useTranslation();
  const { user } = useUser();
  const minStrength = 3;
  const thresholdLength = 7;

  const [updatePassword] = useUserUpdatePasswordMutation();
  const {
    navigation: { passwordModalOpen, dashboardLocked, onboardingApp },
    setNavigation,
    navigateDashboard,
  } = useNavigation();

  const setPasswordModalOpen = (open: boolean) => {
    // permit closing of the modal when the dashboard is still locked
    if (!open && dashboardLocked) return;
    setNavigation((nav) => void (nav.passwordModalOpen = open));
  };

  const { enqueueSnackbar } = useSnackbar();
  const [variables, setVariables] = useState({
    email: user?.email || '',
    password: '',
    password2: '',
    strength: 0,
  });
  const [validationError, setValidationError] = useState(false);
  const [accepted, setAccepted] = useState({
    conditions: true,
    marketing: false,
  });

  const passwordLength = variables.password.length;
  const passwordStrong = variables.strength >= minStrength;
  const passwordLong = passwordLength > thresholdLength;
  const userIsLocal = user?.source === 'local';

  // password strength meter is only visible when password is not empty
  const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
  const passwordNeeded = userIsLocal; // && !user.hasPassword;
  const passwordOk = (passwordLong && passwordStrong) || !passwordNeeded;
  const passwordSame = passwordOk && variables.password === variables.password2;
  const validEntry = passwordOk && passwordSame && accepted.conditions;

  const onboardingAppIsPublic = onboardingApp?.tags?.map((t) => t.tag).includes('public');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVariables({
      ...variables,
      password: e.target.value,
      strength: zxcvbn(e.target.value).score,
    });
  };

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVariables({
      ...variables,
      password2: e.target.value,
    });
  };

  useEffect(() => {
    user &&
      setAccepted({
        conditions: true, //user.conditionsConsent || true,
        marketing: user.marketingConsent,
      });
  }, [user]);

  const handleConditionsCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false);
    setAccepted({
      ...accepted,
      conditions: e.target.checked,
    });
  };

  const handleMarketingCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccepted({
      ...accepted,
      marketing: e.target.checked,
    });
  };

  const handleClick = () => {
    if (!passwordOk) {
      enqueueSnackbar(t('route.dashboard.password-modal.snackbar.password-is-weak', 'Das Passwort ist zu schwach.'), {
        variant: 'error',
      });
      return;
    }

    if (!passwordSame) {
      enqueueSnackbar(
        t('route.dashboard.password-modal.snackbar.passwords-do-not-match', 'Passwörter stimmen nicht überein.'),
        {
          variant: 'error',
        }
      );
      return;
    }
    if (validEntry) {
      updatePassword({
        variables: {
          password: variables.password,
          conditionsConsent: accepted.conditions,
        },
      })
        .then(() => {
          setNavigation((nav) => {
            nav.passwordModalOpen = false;
            // nav.roadBlock.modalOpen = !user?.isSubscriber && !onboardingAppIsPublic;
            nav.preferredTab = 'Documents';
          });
          // track matomo event
          window._paq?.push(['trackEvent', 'funnel', 'account_created', 'password_set']);
          // track google analytics event
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'ga4-event-password-submit',
              email: user?.email,
            });
          }
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
        });
    } else {
      enqueueSnackbar(t('route.dashboard.password-modal.snackbar.agbs-not-accepted', 'Bitte die AGBs akzeptieren.'), {
        variant: 'error',
      });
      setValidationError(true);
    }
  };

  return (
    <Modal open={passwordModalOpen} onClose={() => setPasswordModalOpen(false)} sx={{ zIndex: 50 }}>
      <Slide in={passwordModalOpen}>
        <Paper
          className="w-full md:w-2/3 -translate-x-1/2 -translate-y-1/2  h-full md:h-auto max-w-[800px] absolute top-1/2 left-1/2 overflow-y-auto pointer-events-auto"
          elevation={10}
        >
          <div className="p-11 flex flex-col gap-5 relative">
            {/* <img
              src="/assets/images/dieter_kopf.svg"
              className="absolute -top-[120px] z-50 h-28 left-1/2 -translate-x-1/2"
            /> */}
            <h1 className="text-2xl font-bold text-center">
              <Balancer>
                {t('route.dashboard.password-modal.title', 'Du bist auf dem besten Weg um Bußgelder zu vermeiden!')}
              </Balancer>
            </h1>
            <p className="text-center">
              <Balancer>
                {t(
                  'route.dashboard.password-modal.subtitle',
                  'Vergib noch schnell ein Passwort, um deinen Account zu vervollständigen.'
                )}
              </Balancer>
            </p>

            <form>
              {
                <EmailField
                  className="hidden"
                  disabled={Boolean(user?.email)}
                  onChange={(e) => {
                    setVariables({ ...variables, email: e.target.value });
                  }}
                  value={variables.email}
                  onEnter={handleClick}
                  autoFocus={!user?.email}
                />
              }

              <Field className="mb-20" htmlFor="password1" label={t('common.password', 'Passwort')}>
                <Input
                  id="password1"
                  autoFocus={!!user?.email}
                  disabled={false}
                  required
                  onChange={handleChange}
                  onKeyDown={(e) => (e.key === 'Enter' ? handleClick() : null)}
                  type="password"
                  autoComplete="new-password"
                  value={variables.password}
                  icon={passwordOk ? <CheckCircleOutlineIcon className="text-success" fontSize="small" /> : undefined}
                  data-testid="password-field1"
                />
              </Field>
              <Field
                className="mb-20"
                htmlFor="password2"
                label={t('route.password.form_field_confirm_password_label', 'Passwortwiederholung')}
              >
                <Input
                  id="password2"
                  disabled={false}
                  required
                  onChange={handleChange2}
                  onKeyDown={(e) => (e.key === 'Enter' ? handleClick() : null)}
                  type="password"
                  autoComplete="new-password"
                  value={variables.password2}
                  icon={passwordSame ? <CheckCircleOutlineIcon className="text-success" fontSize="small" /> : undefined}
                  data-testid="password-field2"
                />
              </Field>
              {/** Render the password strength meter **/}
              <div className={strengthClass}>
                <div className="strength-meter-fill" data-strength={variables.strength}></div>
              </div>

              {!user?.conditionsConsent && (
                <>
                  <div className="flex gap-20 align-items-start mb-20">
                    {/* <Checkbox
                      disableRipple={true}
                      onChange={handleConditionsCheckbox}
                      value={accepted.conditions}
                      required={true}
                    /> */}
                    <div className="text-sm text-gray-600">
                      <Trans t={t} i18nKey="route.dashboard.password-modal.conditions-consent-statement">
                        <span className={cx({ validationError })}>
                          »Dieter macht den Datenschutz« ist ein Service der simply Legal GmbH. Bei der Nutzung
                          derDatenschutz-Management-Plattform gelten unsere{' '}
                          <Link to="/terms" target="_blank">
                            Allgemeinen Geschäftsbedingungen
                          </Link>{' '}
                          und die{' '}
                          <Link to="/privacy" target="_blank">
                            Datenschutzrichtlinie
                          </Link>
                          .
                        </span>
                      </Trans>
                    </div>
                  </div>
                  {/* <div className="flex gap-20 align-items-start mb-20">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple={true}
                          onChange={handleMarketingCheckbox}
                          value={accepted.marketing}
                          required={false}
                        />
                      }
                      label={
                        <div className="dtRoutePassword__checkbox-label">
                          <span>
                            Ich möchte immer aktuelle Informationen zum Datenschutz und Angebote über Produkte der
                            simply Legal GmbH per E-Mail erhalten. Ich kann diese Einwilligung jederzeit durch eine
                            E-Mail an{' '}
                            <a href="mailto:unsubscribe@dieter-datenschutz.de">unsubscribe@dieter-datenschutz.de</a>{' '}
                            widerrufen.
                          </span>
                        </div>
                      }
                    />
                  </div> */}
                </>
              )}
            </form>
            <div className="flex justify-between flex-wrap dtRoutePassword__btn_container mb-24 md:mb-0">
              <Button
                className={cx({ btnDisabled: !passwordOk }, 'mb-20')}
                disabled={!passwordOk}
                onClick={() => handleClick()}
                googleTriggerClassName="ga4-event-password-submit"
                data-testid="button-submit"
              >
                {t('common.continue', 'Weiter')}
              </Button>
            </div>
          </div>
        </Paper>
      </Slide>
    </Modal>
  );
}

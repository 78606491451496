import { gql, useMutation } from '@apollo/client';

const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export interface ResetPasswordResult {
  resetPassword: boolean;
}

export function useUserResetPasswordMutation() {
  return useMutation<ResetPasswordResult>(RESET_PASSWORD);
}

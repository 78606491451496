import { useUser } from '@app/context/UserContext';
import { UserTopicApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import { Switch } from '@mui/material';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';

import { getTopicStates } from '@app/utils/getTopicStates';

import { Loading } from '@components/ui';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { extractPartnerData } from '../PartnerListEntry/extractPartnerData';
import { ContractDirection } from '../PartnerListEntry/typesAndEnums';
import './subcontracts.sass';

interface Props {
  topic: UserTopicApplicationsQuestionnaires;
  topicStates: ReturnType<typeof getTopicStates>;
}

type UserValueIndex = string;

export interface SubContractTableMeta extends MUIDataTableMeta {
  rowData: [string, boolean, UserValueIndex | null];
}

export function SubContracts(props: Props) {
  const { user } = useUser();

  const [addUserValue] = useUserValueAddMutation();

  const partnerData =
    (user && extractPartnerData(user).filter((partner) => partner.direction == ContractDirection.Client)) || [];

  const handleChange = (value: boolean, tableMeta: SubContractTableMeta) => {
    const variables = {
      key: 'DATA_PROCESSOR_ISSUBCONTRACTOR',
      value: value.toString(),
      index: tableMeta.rowData[2] as string,
    };

    addUserValue({ variables, context: { userId: user?.id || '' } });
  };

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'partnerName',
      label: 'Partnername',
    },
    {
      name: 'isSubContractor',
      label: 'Ist Subunternehmen?',
      options: {
        customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
          return (
            <Switch
              checked={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.checked, tableMeta as SubContractTableMeta)
              }
            />
          );
        },
      },
    },
    {
      name: 'index',
      options: {
        display: 'excluded',
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: true,
    print: false,
    elevation: 0,

    // onRowClick: handleClick,
    selectableRows: 'none',
    selectableRowsHeader: false,
    // selectableRowsOnClick: true,
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    downloadOptions: {
      filename: 'Subunternehmen.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    // onRowsDelete: handleDelete,
    textLabels: {
      body: {
        noMatch: 'Keine Einträge vorhanden',
      },
    },
  };

  return (
    <div className="dtListEntry">
      <p>
        In dieser Liste stehen lediglich jene Vertragspartner die du als "Auftragnehmer" markiert hast. Diese können von
        dir zusätzlich als datenschutzrechtliches "Subunternehmen" gekennzeichnet werden.
      </p>
      {partnerData ? (
        <MUIDataTable columns={columns} data={partnerData} title="Deine Subunternehmen" options={options} />
      ) : (
        <Loading />
      )}
    </div>
  );
}

import { gql, useMutation } from '@apollo/client';
import { USERVALUE_FIELDS, UserValue } from './useUserQuery';
const DELETE_USER_VALUE = gql`
  ${USERVALUE_FIELDS}
  mutation DeleteUserValue($key: String!, $index: String, $value: String) {
    deleteUserValue(key: $key, value: $value, index: $index) {
      ...UserValueFields
    }
  }
`;

export interface UserValueDeleteInput {
  key: string;
  value?: string;
  index?: string;
}

export interface UserValueDeleteResult {
  deleteUserValue: UserValue; // the index of the deleted value is returned
}

export interface UserValueDeleteContext {
  userId: string;
}

export function useUserValueDeleteMutation() {
  return useMutation<UserValueDeleteResult, UserValueDeleteInput, UserValueDeleteContext>(DELETE_USER_VALUE, {
    refetchQueries: ['GetUser'],
    // update(cache, { data }, { context }) {
    //   if (data) {
    //     if (!data.deleteUserValue) return;
    //     cache.modify({
    //       id: cache.identify({ __typename: "User", id: context?.userId }),
    //       fields: {
    //         userValues(cachedUserValueRefs: Reference[], { readField }) {
    //           return cachedUserValueRefs.filter((userValueRef) => {
    //             return data?.deleteUserValue.id !== readField("id", userValueRef);
    //           });
    //         },
    //       },
    //     });
    //   }
    // },
  });
}

import { useSnackbar } from '@app/hooks/useSnackbar';

import { useNavigation } from '@app/hooks/useNavigation';

import { useTranslation } from 'react-i18next';
import { Button, NotificationModal } from '..';

export function ExpertModeModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const { setNavigation } = useNavigation();

  const { enqueueSnackbar } = useSnackbar();

  const handleActivate = () => {
    setNavigation((nav) => void (nav.expertModeEnabled = true));
    onClose();
    enqueueSnackbar(t('layout.global.header.account_dropdown_option.expert_mode_modal.success'), {
      variant: 'success',
    });
  };

  return (
    <NotificationModal open={open} onClose={onClose} withCloseButton={false}>
      <div className="p-9 flex flex-col gap-8 " data-testid="expert-mode-dialog">
        <h2 className="text-center font-bold text-xl">
          {t('layout.global.header.account_dropdown_option.expert_mode_modal.title')}
        </h2>
        <span className="">{t('layout.global.header.account_dropdown_option.expert_mode_modal.desc')}</span>

        <div className="flex justify-center gap-4 mt-4">
          <button className="hover:underline" onClick={onClose}>
            {t('components.ui.alert_dialog.actions.cancel')}
          </button>
          <Button onClick={handleActivate} data-testid="delete-account-btn">
            {t('route.mfaverify.activate')}
          </Button>
        </div>
      </div>
    </NotificationModal>
  );
}

import { useNavigation } from '@app/hooks/useNavigation';
import { useQueryParams } from '@app/hooks/useQuestionaireRoute';
import OutputView from '@components/block/OutputView/OutputView';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const fullPath = '/print/:localQuestionnaireId';

export interface RouteParams {
  localQuestionnaireId?: string;
}

export interface RouteParamsWithQuery extends RouteParams {
  documentNumber?: number;
}

export function Print() {
  const { t } = useTranslation();
  const match = useRouteMatch<RouteParams>(fullPath);
  const query = useQueryParams();
  const { navigation, setNavigation } = useNavigation();
  const documentNumber = parseInt(query?.get('documentNumber') || '0') || undefined;

  const currentParams: RouteParamsWithQuery = Object.assign(
    {
      documentId: undefined,
      documentNumber: null,
    },
    {
      ...(match?.params || {}),
      documentNumber,
    } as RouteParamsWithQuery
  );

  // There is a timing problem in setting this to true
  // apparently the navigation context is not yet set
  useEffect(() => {
    if (navigation.isPrintView) return;
    setNavigation((nav) => void (nav.isPrintView = true));
  }, [navigation]);

  useEffect(() => {
    return () => {
      setNavigation((nav) => void (nav.isPrintView = false));
    };
  }, []);

  return (
    <div className="">
      {currentParams.localQuestionnaireId ? (
        <OutputView
          localQuestionnaireId={currentParams.localQuestionnaireId}
          documentNumber={documentNumber}
          triggerPrint={true}
        />
      ) : (
        <p>{t('route.print.not-found-error-message')}</p>
      )}
    </div>
  );
}

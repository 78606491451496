import { User } from '@dieterApi/user/useUserQuery';
import { useSwitchTenantMutation } from '@dieterApi/user/useUserSwitchTenantMutation';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// code-snippet from https://mui.com/material-ui/react-menu/

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  user?: User | null;
}

export default function TenantMenu({ anchorEl, handleClose, user }: Props) {
  const { t } = useTranslation();
  const [switchTenant] = useSwitchTenantMutation();
  const open = Boolean(anchorEl);

  const handleTenantClick = (id: string) => {
    switchTenant({
      variables: {
        companyId: id,
      },
    });
  };

  if (!user?.companies) return null;
  // create a copy of the companies array
  const companies = [...user.companies];

  companies.sort((a, b) => {
    return a?.isPrincipal ? -1 : a?.name < b?.name ? -1 : 1;
  });

  return (
    <React.Fragment>
      <Menu
        className="dtAccountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            // overflow: 'visible',
            maxHeight: '600px',
            overflowY: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {companies.map((c) => {
          const isActive = c.id === user?.company.id;
          return (
            <MenuItem key={c.id}>
              <button
                onClick={() => handleTenantClick(c.id)}
                className={cx('flex items-center gap-2')}
                disabled={isActive}
              >
                <div className={cx('rounded-md w-2 h-2', isActive ? 'bg-primary-root' : '')} />
                <span className={cx(isActive ? 'text-gray-400' : '')}>{c.name}</span>
                {c.isPrincipal && (
                  <div className="bg-secondary-root rounded-md px-1 text-xs">{t('common.primary')}</div>
                )}
              </button>
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}

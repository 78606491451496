interface HasCreatedAt {
  createdAt: string;
}

export function pickLatest<T extends HasCreatedAt>(questionnaires: T[] | undefined): T | undefined {
  if (!questionnaires) {
    return undefined;
  }
  if (questionnaires.length === 0) {
    return undefined;
  }
  return questionnaires.reduce((latest, questionnaire) => {
    if (latest === undefined) {
      return questionnaire;
    }
    if (questionnaire.createdAt > latest.createdAt) {
      return questionnaire;
    }
    return latest;
  });
}

interface PartnerCategory {
  category: string;
  services: Service[];
}

interface Service {
  service: string;
  category: string;
  contractType: string;
  url?: string | null;
}

export const partnerList: PartnerCategory[] = [
  {
    category: 'Advertising',
    services: [
      {
        service: 'Google Ads',
        category: 'Advertising',
        contractType: 'SCC',
        url: 'https://business.safety.google/adsprocessorterms/',
      },
      {
        service: 'adgoal',
        category: 'Advertising',
        contractType: 'AVV',
        url: 'https://www.adgoal.de/_files/adgoal-DSGVO-ADV.pdf',
      },
      {
        service: 'Blogfoster',
        category: 'Advertising',
        contractType: 'AVV',
        url: null,
      },
    ],
  },
  {
    category: 'Analytics',
    services: [
      {
        service: 'Google Analytics',
        category: 'Analytics',
        contractType: 'SCC',
        url: 'https://business.safety.google/adsprocessorterms/',
      },
      {
        service: 'Google Tag Manager',
        category: 'Analytics',
        contractType: 'SCC',
        url: 'https://business.safety.google/adsprocessorterms/',
      },
      {
        service: 'HotJar',
        category: 'Analytics',
        contractType: 'AVV',
        url: 'https://www.hotjar.com/legal/support/dpa/',
      },
      {
        service: 'Matomo (Innocraft)',
        category: 'Analytics',
        contractType: 'AVV',
        url: 'https://matomo.org/matomo-cloud-dpa/',
      },
      {
        service: 'PIWIK PRO',
        category: 'Analytics',
        contractType: 'AVV',
        url: 'https://piwikpro.de/datenschutz-sicherheit/',
      },
      {
        service: 'eTracker',
        category: 'Analytics',
        contractType: 'AVV',
        url: null,
      },
    ],
  },
  {
    category: 'Cloud-Speicher',
    services: [
      {
        service: 'Amazon AWS',
        category: 'Cloud-Speicher',
        contractType: 'SCC',
        url: 'https://d1.awsstatic.com/Controller_to_Processor_SCCs.pdf',
      },
      {
        service: 'Dropbox',
        category: 'Cloud-Speicher',
        contractType: 'SCC',
        url: null,
      },
      {
        service: 'Google Workspace',
        category: 'Cloud-Speicher',
        contractType: 'SCC',
        url: null,
      },
      {
        service: 'Sync',
        category: 'Cloud-Speicher',
        contractType: 'SCC',
        url: null,
      },
      {
        service: 'LeitzCloud',
        category: 'Cloud-Speicher',
        contractType: 'AVV',
        url: null,
      },
      {
        service: 'SecureSafe',
        category: 'Cloud-Speicher',
        contractType: 'AVV',
        url: null,
      },
    ],
  },
  {
    category: 'Collaboration',
    services: [
      {
        service: 'Slack',
        category: 'Collaboration',
        contractType: 'SCC',
        url: 'https://www.slack.axdraft.com/',
      },
      {
        service: 'Threema Work',
        category: 'Collaboration',
        contractType: 'AVV',
        url: null,
      },
      {
        service: 'Trello',
        category: 'Collaboration',
        contractType: 'SCC',
        url: 'https://www.atlassian.com/legal/data-processing-addendum',
      },
      {
        service: 'Asana',
        category: 'Collaboration',
        contractType: 'SCC',
        url: null,
      },
      {
        service: 'Microsoft Teamdrive',
        category: 'Collaboration',
        contractType: 'SCC',
        url: null,
      },
    ],
  },
  {
    category: 'CRM-System',
    services: [
      {
        service: 'HubSpot',
        category: 'CRM-System',
        contractType: 'SCC',
        url: 'https://742851.fs1.hubspotusercontent-na1.net/hubfs/742851/Downloadable_Legal_Docs/HubSpot_Signed_DPA_SCCs-_2Sep2022.pdf',
      },
      {
        service: 'Salesforce',
        category: 'CRM-System',
        contractType: 'SCC',
        url: 'https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/EU-Data-Transfer-Mechanisms-FAQ.pdf',
      },
      {
        service: 'Pipedrive',
        category: 'CRM-System',
        contractType: 'AVV',
        url: null,
      },
      {
        service: 'Freshsales',
        category: 'CRM-System',
        contractType: 'SCC',
        url: null,
      },
      {
        service: 'Monday sales',
        category: 'CRM-System',
        contractType: 'SCC',
        url: 'https://monday.com/l/de/privatsphaere/standardvertragsbedingungen-fuer-kunden-scc-datenverantwortlicher-zu-datenverarbeiter/',
      },
      {
        service: 'TecArt',
        category: 'CRM-System',
        contractType: 'AVV',
        url: 'https://www.tecart.de/agb-avv-verfuegbarkeitsregelung#agb',
      },
    ],
  },
  {
    category: 'Homepage-Baukasten',
    services: [
      {
        service: 'WordPress.com',
        category: 'Homepage-Baukasten',
        contractType: 'SCC',
        url: 'https://wordpress.com/support/data-processing-agreements/',
      },
      {
        service: '1&1 IONOS - MyWebsite Creator',
        category: 'Homepage-Baukasten',
        contractType: 'AVV',
        url: null,
      },
      {
        service: 'WIX - Unlimited',
        category: 'Homepage-Baukasten',
        contractType: 'AVV',
        url: 'https://de.wix.com/about/privacy-dpa-users',
      },
      {
        service: 'Jimdo (Dolphin) - Grow',
        category: 'Homepage-Baukasten',
        contractType: 'AVV',
        url: 'https://www.jimdo.com/de/info/agb/',
      },
      {
        service: 'WebGo',
        category: 'Homepage-Baukasten',
        contractType: 'AVV',
        url: null,
      },
      {
        service: 'Weebly',
        category: 'Homepage-Baukasten',
        contractType: 'SCC',
        url: null,
      },
      {
        service: 'Squarespace',
        category: 'Homepage-Baukasten',
        contractType: 'SCC',
        url: null,
      },
    ],
  },
  {
    category: 'Newsletter-Tool',
    services: [
      {
        service: 'CleverReach',
        category: 'Newsletter-Tool',
        contractType: 'AVV',
        url: 'https://cloud-files.crsend.com/docs/Vereinbarung_Auftragsverarbeitung_Muster.pdf',
      },
      {
        service: 'Mailchimp',
        category: 'Newsletter-Tool',
        contractType: 'SCC',
        url: 'https://mailchimp.com/legal/Processor-Processor-SCC-2021/',
      },
      {
        service: 'Mailjet',
        category: 'Newsletter-Tool',
        contractType: 'SCC',
        url: 'https://www.mailjet.com/de/rechtliches/av-vertrag/',
      },
      {
        service: 'rapidmail',
        category: 'Newsletter-Tool',
        contractType: 'AVV',
        url: 'https://www.rapidmail.de/hilfe/datenschutzvertrag-nach-eu-dsgvo-abschliessen',
      },
      {
        service: 'sendinblue',
        category: 'Newsletter-Tool',
        contractType: 'AVV',
        url: 'https://de.sendinblue.com/wp-content/uploads/sites/3/2022/09/DPA-Sendinblue-V9.pdf',
      },
      {
        service: 'ActiveCampaign',
        category: 'Newsletter-Tool',
        contractType: 'SCC',
        url: null,
      },
    ],
  },
  {
    category: 'Webhosting',
    services: [
      {
        service: 'Hostinger',
        category: 'Webhosting',
        contractType: 'AVV',
        url: null,
      },
      {
        service: 'bluehost',
        category: 'Webhosting',
        contractType: 'SCC',
        url: null,
      },
      {
        service: 'Hetzner',
        category: 'Webhosting',
        contractType: 'AVV',
        url: null,
      },
      {
        service: 'HostEurope',
        category: 'Webhosting',
        contractType: 'AVV',
        url: 'https://www.hosteurope.de/download/Auftragsverarbeitung_DE_02.pdf',
      },
      {
        service: 'IONOS by 1 & 1',
        category: 'Webhosting',
        contractType: 'AVV',
        url: 'https://www.ionos.de/hilfe/datenschutz/allgemeine-informationen-zur-datenschutz-grundverordnung-dsgvo/auftragsverarbeitung/',
      },
      {
        service: 'United Domains',
        category: 'Webhosting',
        contractType: 'AVV',
        url: 'https://www.united-domains.de/help/faq-article/wie-erhalte-ich-den-auftragsverarbeitungs-vertrag-avv-nach-dsgvo',
      },
      {
        service: 'Strato',
        category: 'Webhosting',
        contractType: 'AVV',
        url: 'https://www.strato.de/faq/vertrag/fragen-zur-auftragsverarbeitungsvertrag-avv-und-der-neuen-eu-datenschutzgrundverordnung-dsgvo/#avv_abschliessen',
      },
      {
        service: 'all-inkl.com',
        category: 'Webhosting',
        contractType: 'AVV',
        url: 'https://all-inkl.com/wichtig/faq/#faq_auftragsverarbeitung',
      },
      {
        service: 'DomainFactory',
        category: 'Webhosting',
        contractType: 'AVV',
        url: 'https://www.df.eu/fileadmin/media/doc/ADV_Kunden_Domainfactory.pdf',
      },
    ],
  },
  {
    category: 'Zahlungsdienstleister',
    services: [
      {
        service: 'Stripe',
        category: 'Zahlungsdienstleister',
        contractType: 'SCC',
        url: null,
      },
    ],
  },
  {
    category: 'Lohnbuchhaltung/ Rechnungswesen',
    services: [
      {
        service: 'DATEV',
        category: 'Lohnbuchhaltung/ Rechnungswesen',
        contractType: 'AVV',
        url: 'https://www.datev.de/web/de/m/ueber-datev/datenschutz/vereinbarung-zur-auftragsverarbeitung/',
      },
    ],
  },
  {
    category: 'Buchhaltung',
    services: [
      {
        service: 'billomat',
        category: 'Buchhaltung',
        contractType: 'AVV',
        url: 'https://www.billomat.com/ext/uploads/2021/08/AVV20210519.pdf',
      },
    ],
  },
];

import { gql, useMutation } from '@apollo/client';
import { EMPLOYEE_FIELDS, Employee } from '@dieterApi/user/useUserQuery';

const DELETE_EMPLOYEE = gql`
  ${EMPLOYEE_FIELDS}
  mutation deleteEmployee($employeeId: String!) {
    deleteEmployee(employeeId: $employeeId) {
      ...UserEmployeeFields
    }
  }
`;

export interface DeleteEmployeeResult {
  deleteEmployee: Employee;
}

export interface DeleteEmployeeInput {
  employeeId: string;
}

interface DeleteEmployeeContext {
  companyId: string;
}

export function useEmployeeDeleteMutation() {
  return useMutation<DeleteEmployeeResult, DeleteEmployeeInput, DeleteEmployeeContext>(DELETE_EMPLOYEE, {
    refetchQueries: ['GetUser'],
    // update(cache, { data }, { context }) {
    //   cache.modify({
    //     id: cache.identify({ __typename: "Company", id: context?.companyId }),
    //     fields: {
    //       employees(cachedEmployeeRefs: Reference[], { readField }) {
    //         return cachedEmployeeRefs.filter((employeeRef) => {
    //           return data?.deleteEmployee.id !== readField("id", employeeRef);
    //         });
    //       },
    //     },
    //   });
    // },
  });
}

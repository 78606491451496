import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';

import { useUserDeleteMutation } from '@dieterApi/user/useUserDeleteMutation';
import { useUserLogoutMutation } from '@dieterApi/user/useUserLogoutMutation';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NotificationModal } from '..';

export function DeleteAccountModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const { user } = useUser();
  const [deleteUser] = useUserDeleteMutation();
  const [logout] = useUserLogoutMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [pin, setPin] = useState<string>(Math.floor(1000 + Math.random() * 9000).toString());

  const [confirmationText, setConfirmationText] = useState('');

  const handleDelete = () => {
    if (confirmationText === pin) {
      deleteUser({ variables: { userId: user?.id || '' } })
        .then(() => {
          enqueueSnackbar(t('components.ui.delete_account.success'), { variant: 'success' });
          onClose();
          logout();
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error' });
        });
    }
  };

  return (
    <NotificationModal open={open} onClose={onClose} withCloseButton={false}>
      <div className="p-9 border-spacing-2 border-5 border-red-600" data-testid="delete-account-dailog">
        <h2 className="text-center text-red-600 font-bold text-xl mb-4">
          {t('layout.global.header.account_dropdown_option.delete_account')}
        </h2>
        <p className="text-center text-red-500 mb-4">{t('components.ui.delete_account.really')}</p>
        <p>{t('components.ui.delete_account.nowayback')}</p>
        {user?.isSubscriber && (
          <div>
            <p>
              <Trans t={t} i18nKey="components.ui.delete_account.subscription">
                Du hast ein existierendes <strong className="italic">Dieter</strong> Abonnement. Du behältst dein Recht{' '}
                <strong className="italic">Dieter</strong> im gebuchten Funktionsumfang bis zum Ende der
                Vertragslaufzeit zu nutzen. Die Löschung des Accounts führt nicht zur Kündigung des Vertrags.
              </Trans>
            </p>
            <p>
              <Trans t={t} i18nKey="components.ui.delete_account.subscription2">
                Falls du deinen Vertrag zum Ende der Laufzeit kündigen möchtest, schreibe bitte eine formlose E-Mail an{' '}
                <a href="mailto:info@dieter-datenschutz.de">info@dieter-datenschutz.de</a>.
              </Trans>
            </p>
          </div>
        )}

        <p className="text-center text-red-500">{t('components.ui.delete_account.pleaseenter')}</p>
        <div
          className="text-center text-red-500 mb-4 text-lg font-semibold tracking-widest"
          data-testid="delete-account-pin"
        >
          {pin}
        </div>

        <div className="flex justify-center gap-4 mb-4">
          <input
            type="text"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            className="border border-red-500 p-2 rounded w-full focus:border-primary-root focus:ring-0"
            placeholder={pin.toString()}
            data-testid="delete-account-pin-input"
          />
        </div>
        <div className="flex justify-center gap-4">
          <button className="hover:underline" onClick={onClose}>
            {t('components.ui.alert_dialog.actions.cancel')}
          </button>
          <button
            className=" bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded cursor-pointer disabled:bg-slate-400 disabled:cursor-not-allowed"
            onClick={handleDelete}
            disabled={confirmationText !== pin}
            data-testid="delete-account-btn"
          >
            {t('layout.global.header.account_dropdown_option.delete_account')}
          </button>
        </div>
      </div>
    </NotificationModal>
  );
}

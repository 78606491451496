import { useUser } from '@app/context/UserContext';
import { AlertDialog, Button, Loading } from '@components/ui';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import { useMemo, useState } from 'react';

import { useNavigation } from '@app/hooks/useNavigation';
import { useDashboard } from '@app/routes/Dashboard/useDashboard';
import { ThreeDotItem, ThreeDots } from '@components/ui/ThreeDots/ThreeDots';
import {
  ApplicationType,
  TagValues,
  User,
  UserQuestionnaireApplicationTopic,
  UserTopicApplicationsQuestionnaires,
} from '@dieterApi/user/useUserQuery';
import { Link } from 'react-router-dom';

import { getTopicStates } from '@app/utils/getTopicStates';
import { useUserValueDeleteMutation } from '@dieterApi/user/useUserValueDeleteMutation';
import './requests.sass';

interface Props {
  topic: UserTopicApplicationsQuestionnaires;
  topicStates: ReturnType<typeof getTopicStates>;
}

const titleTagMap = {
  'Recht auf Berichtigung': 'RIGHT_TO_RECTIFICATION',
  'Recht auf Einschränkung': 'RIGHT_TO_RESTRICTION',
  'Recht auf Datenübertragbarkeit': 'RIGHT_TO_DATA_PORTABILITY',
  'Recht auf Widerspruch': 'RIGHT_TO_OBJECTION',
  'Recht auf Widerruf der Einwilligung': 'RIGHT_TO_REVOCATION',
  Auskunftsrecht: 'RIGHT_TO_INFORMATION',
  'Recht auf Löschung': 'RIGHT_TO_DELETION',
  'Recht keiner automatischen Entscheidung unterworfen zu werden': 'RIGHT_TO_DENY_AUTOMATED_DECISION',
};

const extensionTag = 'EXTEND_DEADLINE';

export interface RequestTableMeta extends MUIDataTableMeta {
  rowData: [
    string,
    string,
    string,
    keyof typeof titleTagMap,
    UserQuestionnaireApplicationTopic, // the questionnaire of the request
    UserQuestionnaireApplicationTopic, // the questionnaire of the response
  ];
}
export function Requests({ topic, topicStates }: Props) {
  const { user } = useUser();
  const dashboard = useDashboard();
  const {
    setNavigation,
    navigation: { roadBlock },
  } = useNavigation();

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<MUIDataTableMeta | null>(null);

  const addRequestApp = topic.applications.find((app) => app.type === ApplicationType.ListEntryShort);

  const requestData = useMemo(() => {
    const parsedData = extractRequestsData(user);
    return parsedData;
  }, [user]);

  const [deleteUserValue] = useUserValueDeleteMutation();

  const handleDelete = (tableMeta: MUIDataTableMeta) => {
    const index = tableMeta.rowData?.[0] as string;
    if (index === 'new') return;
    const keys = ['REQUEST_TIME_OF_ENTRY', 'REQUEST_TYPE_OF_RIGHT', 'REQUEST_DATE'];
    keys.forEach((key) => deleteUserValue({ variables: { key, index }, context: { userId: user?.id || '' } }));
  };

  const handleAdd = () => {
    addRequestApp && dashboard.create(addRequestApp.id);
  };

  const handleProcess = (tableMeta: RequestTableMeta) => {
    const index = tableMeta.rowData?.[0];
    const typeOfRight = tableMeta.rowData?.[3];
    const contract = tableMeta.rowData?.[5];
    if (!contract) {
      const tag = titleTagMap[typeOfRight];
      const app = topic.applications.find(
        (app) => app.type === ApplicationType.ListContract && app.tags?.map((t) => t.tag).includes(tag as TagValues)
      );
      if (!app) return;
      dashboard.create(app.id, undefined, true, index);
    } else {
      dashboard.open(contract.id);
    }
  };

  const handleExtension = (tableMeta: RequestTableMeta) => {
    const index = tableMeta.rowData?.[0];
    const app = topic.applications.find(
      (app) => app.type === ApplicationType.ListContract && app.tags?.map((t) => t.tag).includes(extensionTag)
    );
    if (!app) return;
    dashboard.create(app.id, undefined, true, index);
  };

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'index',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'timeOfEntry',
      label: 'Eingang',
      options: {
        customBodyRender: (value) => {
          return value ? parseLegalOsDate(value).toLocaleDateString() : '';
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Erfasst am',
      options: {
        customBodyRender: (value) => {
          return value ? parseLegalOsDate(value).toLocaleDateString() : '';
        },
      },
    },
    {
      name: 'typeOfRight',
      label: 'Art des Rechts',
    },
    {
      name: 'request',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'contract',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'extension',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'documents',
      label: 'Dokumente',
      options: {
        customBodyRender: (value, tableMeta) => {
          const request = tableMeta.rowData[4] as UserQuestionnaireApplicationTopic;
          const contract = tableMeta.rowData[5] as UserQuestionnaireApplicationTopic;
          const extension = tableMeta.rowData[6] as UserQuestionnaireApplicationTopic;

          return (
            <ul>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    setNavigation((nav) => {
                      nav.documentPaper = {
                        modalOpen: true,
                        questionnaire: request,
                        index: 0,
                      };
                    });
                  }}
                >
                  Eingangsbestätigung
                </Link>
              </li>
              {contract && (
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      setNavigation((nav) => {
                        nav.documentPaper = {
                          modalOpen: true,
                          questionnaire: contract,
                          index: 0,
                        };
                      });
                    }}
                  >
                    Antwortschreiben
                  </Link>
                </li>
              )}
              {extension && (
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      setNavigation((nav) => {
                        nav.documentPaper = {
                          modalOpen: true,
                          questionnaire: extension,
                          index: 0,
                        };
                      });
                    }}
                  >
                    Fristverlängerung
                  </Link>
                </li>
              )}
            </ul>
          );
        },
      },
    },
    {
      name: 'actions',
      options: {
        customHeadRender: () => '',
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const contract = tableMeta.rowData[5] as UserQuestionnaireApplicationTopic;
          const extension = tableMeta.rowData[6] as UserQuestionnaireApplicationTopic;
          const threeDotItems: ThreeDotItem[] = [
            {
              label: contract ? 'Antwortschreiben bearbeiten' : 'Antwortschreiben erstellen',
              onClick: () => {
                handleProcess(tableMeta as RequestTableMeta);
              },
              icon: contract ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />,
            },
            {
              label: extension ? 'Fristverlängerung bearbeiten' : 'Fristverlängerung beantragen',
              onClick: () => {
                handleExtension(tableMeta as RequestTableMeta);
              },
              icon: extension ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />,
            },
            {
              label: 'Anfrage löschen',
              onClick: () => {
                setDeleteRow(tableMeta);
                setDeleteAlertOpen(true);
              },
              icon: <DeleteIcon fontSize="small" />,
            },
          ];

          return <ThreeDots items={threeDotItems} />;
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    sortOrder: {
      name: 'name',
      direction: 'asc',
    },
    download: false,
    print: false,
    elevation: 0,
    // onRowClick: handleClick,
    selectableRows: 'none',
    selectableRowsHeader: false,
    // selectableRowsOnClick: true,

    customToolbar: () => (
      <Button className="text-base mb-3" onClick={handleAdd} icon={<AddIcon />} disabled={user?.isReadOnly}>
        Hinzufügen
      </Button>
    ),
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    textLabels: {
      selectedRows: {
        text: 'Zeilen ausgewählt',
        delete: 'Löschen',
        deleteAria: 'Ausgewählte Zeilen löschen',
      },
      body: {
        noMatch: 'Keine Einträge vorhanden',
      },
    },
  };

  return (
    <div className="dtRequests">
      {requestData ? (
        <MUIDataTable columns={columns} data={requestData} title="Deine Anfragen" options={options} />
      ) : (
        <Loading />
      )}
      <AlertDialog
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        message={
          <>
            Möchtest du die Anfrage zum <b>{deleteRow?.rowData[3]}</b> vom{' '}
            <b>{parseLegalOsDate(deleteRow?.rowData[1]).toLocaleDateString()}</b> wirklich löschen?
          </>
        }
        onAccept={() => deleteRow && handleDelete(deleteRow)}
      />
    </div>
  );
}

interface RequestsData {
  index: string;
  timeOfEntry: string;
  typeOfRight: string;
  createdAt: string;
  request?: UserQuestionnaireApplicationTopic;
  contract?: UserQuestionnaireApplicationTopic;
  extension?: UserQuestionnaireApplicationTopic;
}

const extractRequestsData = (user: User | undefined): RequestsData[] => {
  if (!user) return [];
  const requestData: RequestsData[] = [];
  user.userValues.forEach((userValue) => {
    if (userValue.key === 'REQUEST_TIME_OF_ENTRY') {
      const timeOfEntry = userValue.value;
      const typeOfRight =
        user.userValues.find((uv) => uv.key === 'REQUEST_TYPE_OF_RIGHT' && uv.index === userValue.index)?.value || '';
      const createdAt =
        user.userValues.find((uv) => uv.key === 'REQUEST_DATE' && uv.index === userValue.index)?.value || '';
      const request = user.questionnaires?.find((q) => q.id === userValue.index);
      const contract = user.questionnaires?.find(
        (q) =>
          q.userValueIndex === userValue.index &&
          q.application.tags?.map((t) => t.tag).some((t) => Object.values(titleTagMap).includes(t))
      );
      const extension = user.questionnaires?.find(
        (q) => q.userValueIndex === userValue.index && q.application.tags?.map((t) => t.tag).includes(extensionTag)
      );

      requestData.push({
        index: userValue.index,
        timeOfEntry,
        typeOfRight,
        createdAt,
        request,
        contract,
        extension,
      });
    }
  });
  // only return partners where contractType is filled and which has not None
  return requestData;
};

interface LegalOsDate {
  year: number;
  month: number;
  day: number;
}

function parseLegalOsDate(date: string) {
  let dateObject: LegalOsDate = {} as LegalOsDate;
  try {
    dateObject = JSON.parse(date) as LegalOsDate;
  } catch (error) {
    return new Date();
  }
  return new Date(dateObject.year, dateObject.month, dateObject.day);
}

import { gql, useMutation } from '@apollo/client';
import { useUser } from '@app/context/UserContext';

const END_USER_TRIAL = gql`
  mutation EndUserTrial {
    endTrial
  }
`;

export interface EndUserTrialResult {
  endTrial: boolean;
}

export function useUserEndTrialMutation() {
  const { user } = useUser();
  return useMutation<EndUserTrialResult>(END_USER_TRIAL, {
    // refetchQueries: ['GetUser'],
    update(cache, { data }, { variables }) {
      cache.writeFragment({
        id: 'User:' + user?.id,
        fragment: gql`
          fragment _ on User {
            isTrialing
          }
        `,
        data: {
          isTrialing: false,
        },
      });
    },
  });
}

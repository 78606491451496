import { Spinner } from '@components/ui/Spinner/Spinner';

export function Loading() {
  //  flex-grow-1; got rid of that?!
  return (
    <div className="flex items-center justify-center">
      <Spinner />
    </div>
  );
}

import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { Button, EmailField, Field, Input } from '@components/ui';
import { useUserUpdateEmailMutation } from '@dieterApi/user/useUserUpdateEmailMutation';
import { useUserUpdatePasswordMutation } from '@dieterApi/user/useUserUpdatePasswordMutation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Checkbox } from '@mui/material';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import './route-password.sass';

export default function Password() {
  const { t } = useTranslation();
  const { user } = useUser();
  const minStrength = 3;
  const thresholdLength = 7;
  const history = useHistory();
  const [updatePassword] = useUserUpdatePasswordMutation();
  const [updateEmail] = useUserUpdateEmailMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [variables, setVariables] = useState({
    email: user?.email || '',
    password: '',
    password2: '',
    strength: 0,
  });
  const [validationError, setValidationError] = useState(false);
  const [accepted, setAccepted] = useState({
    conditions: false,
    marketing: false,
  });

  const passwordLength = variables.password.length;
  const passwordStrong = variables.strength >= minStrength;
  const passwordLong = passwordLength > thresholdLength;
  const userIsLocal = user?.source === 'local';

  // password strength meter is only visible when password is not empty
  const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
  const passwordNeeded = userIsLocal; // && !user.hasPassword;
  const passwordOk = (passwordLong && passwordStrong) || !passwordNeeded;
  const passwordSame = passwordOk && variables.password === variables.password2;
  const validEntry = passwordOk && passwordSame && accepted.conditions;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVariables({
      ...variables,
      password: e.target.value,
      strength: zxcvbn(e.target.value).score,
    });
  };

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVariables({
      ...variables,
      password2: e.target.value,
    });
  };

  useEffect(() => {
    user &&
      setAccepted({
        conditions: user.conditionsConsent,
        marketing: user.marketingConsent,
      });
  }, [user]);

  const handleConditionsCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false);
    setAccepted({
      ...accepted,
      conditions: e.target.checked,
    });
  };

  const handleMarketingCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccepted({
      ...accepted,
      marketing: e.target.checked,
    });
  };

  const handleClick = () => {
    if (!passwordSame) {
      enqueueSnackbar(t('route.password.error_passwords_should_match', 'Passwörter stimmen nicht überein.'), {
        variant: 'error',
      });
      return;
    }
    if (validEntry) {
      updateEmail({
        variables: {
          email: variables.email,
        },
      })
        .then(() => {
          updatePassword({
            variables: {
              password: variables.password,
              conditionsConsent: accepted.conditions,
            },
          });
        })
        .then(() => {
          // passwordNeeded && enqueueSnackbar('Passwort wurde erfolgreich geändert.', { variant: 'success' });
          history.push('/');
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
        });
    } else {
      enqueueSnackbar(t('route.password.error_user_must_accept_agb', 'Bitte die AGBs akzeptieren.'), {
        variant: 'error',
      });
      setValidationError(true);
    }
  };

  return (
    <div className="dtRoutePassword flex justify-center items-center">
      <div className="dtRoutePassword__panel flex flex-col">
        <p className="mb-3">
          <Trans
            t={t}
            i18nKey="route.password.heading"
            context={!user?.email ? 'isNewUser' : !user.hasPassword ? 'hasPassword' : undefined}
          >
            {!user?.email ? (
              <span>
                Um die Ergebnisse des Check-Ups einzusehen, benötigst du noch ein <strong>kostenloses</strong>{' '}
                Kundenkonto bei uns.
              </span>
            ) : !user.hasPassword ? (
              'Bitte gib ein Passwort ein, um dein Konto zu aktivieren.'
            ) : (
              'Bitte gib ein neues Passwort ein.'
            )}
          </Trans>
        </p>
        <form>
          {
            <EmailField
              disabled={Boolean(user?.email)}
              onChange={(e) => {
                setVariables({ ...variables, email: e.target.value });
              }}
              value={variables.email}
              onEnter={handleClick}
              autoFocus={!user?.email}
            />
          }

          <Field className="mb-20" htmlFor="password1" label={t('common.password', 'Passwort')}>
            <Input
              id="password1"
              autoFocus={!!user?.email}
              disabled={false}
              required
              onChange={handleChange}
              onKeyPress={(e) => (validEntry && e.key === 'Enter' ? handleClick() : null)}
              type="password"
              autoComplete="new-password"
              value={variables.password}
              icon={passwordOk ? <CheckCircleOutlineIcon className="text-success" fontSize="small" /> : undefined}
              data-testid="password-field1"
            />
          </Field>
          <Field
            className="mb-20"
            htmlFor="password2"
            label={t('route.password.form_field_confirm_password_label', 'Passwortwiederholung')}
          >
            <Input
              id="password2"
              disabled={false}
              required
              onChange={handleChange2}
              onKeyPress={(e) => (validEntry && e.key === 'Enter' ? handleClick() : null)}
              type="password"
              autoComplete="new-password"
              value={variables.password2}
              icon={passwordSame ? <CheckCircleOutlineIcon className="text-success" fontSize="small" /> : undefined}
              data-testid="password-field2"
            />
          </Field>
          {/** Render the password strength meter **/}
          <div className={strengthClass}>
            <div className="strength-meter-fill" data-strength={variables.strength}></div>
          </div>

          {!user?.conditionsConsent && (
            <>
              <div className="flex gap-20 align-items-start mb-20">
                <Checkbox
                  disableRipple={true}
                  onChange={handleConditionsCheckbox}
                  value={accepted.conditions}
                  required={true}
                />
                <div className="dtRoutePassword__checkbox-label">
                  <span className={cx({ validationError })}>
                    <Trans
                      t={t}
                      i18nKey="route.password.form_field_accept_conditions_checkbox_label"
                      context={!user?.isSubscriber ? 'isNotSubscriber' : undefined}
                    >
                      <>
                        Ich akzeptiere die{' '}
                        <Link to="/terms" target="_blank">
                          Allgemeinen Geschäftsbedingungen
                        </Link>{' '}
                        der Datenschutz-Management-Plattform »DIETER« (ein Service der simply Legal GmbH) und die{' '}
                        <Link to="/privacy" target="_blank">
                          Datenschutzrichtlinie
                        </Link>
                        {!user?.isSubscriber && ' (es entstehen noch keine Kosten)'}
                      </>
                    </Trans>
                    .
                  </span>
                </div>
              </div>
              <div className="flex gap-20 align-items-start mb-20">
                <Checkbox
                  disableRipple={true}
                  onChange={handleMarketingCheckbox}
                  value={accepted.marketing}
                  required={false}
                />
                <div className="dtRoutePassword__checkbox-label">
                  <Trans t={t} i18nKey="route.password.form_field_accept_marketing_checkbox_label">
                    <>
                      <span>
                        Meine E-Mail-Adresse darf für Direktwerbung und Marketing für die von der simply Legal GmbH
                        angebotenen Produkte und Dienstleistungen verwendet werden. Dieser Nutzung meiner E-Mail-Adresse
                        zu Werbezwecken kann ich jederzeit über
                        <a href="mailto:unsubscribe@dieter-datenschutz.de"> unsubscribe@dieter-datenschutz.de</a>{' '}
                        widersprechen.
                      </span>
                      <span>
                        {' '}
                        Siehe{' '}
                        <Link to="/privacy" target="_blank">
                          Datenschutzrichtlinie
                        </Link>
                        .
                      </span>
                    </>
                  </Trans>
                </div>
              </div>
            </>
          )}
        </form>
        <div className="flex justify-between flex-wrap dtRoutePassword__btn_container">
          <Button
            className={cx({ btnDisabled: !passwordOk }, 'mb-20')}
            disabled={!passwordOk}
            onClick={() => handleClick()}
            googleTriggerClassName="ga4-event-password-submit"
            data-testid="button-submit"
          >
            {t('common.continue', 'Weiter')}
          </Button>
        </div>
      </div>
    </div>
  );
}

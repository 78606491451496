import { useUser } from '@app/context/UserContext';
import { useQuery } from '@app/hooks/useQuery';
import { PricingTable } from '@components/ui/PricingTable/PricingTable';
import {
  UpgradeSubscriptionResult,
  useUpgradeSubscriptionMutation,
} from '@dieterApi/subscription/useSubscriptionUpgradeMutation';
import { useWixPriceQuery } from '@dieterApi/wix/useWixPriceQuery';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Balancer } from 'react-wrap-balancer';
import { UpgradeModal } from './UpgradeModal';

export interface UpgradeProps {
  product: ProductValues;
  topic?: TopicValues;
  mode: ModeValues;
  interval: IntervalValues;
  promocode?: string;
}

export type ProductValues = 'basic' | 'premium' | 'ticket';
export type TopicValues = 'toms' | 'dse' | 'vvt' | 'avv';
export type ModeValues = 'companies' | 'agencies' | 'dpos';
export type IntervalValues = 'month' | 'year';

export function Pricing() {
  const { user } = useUser();
  const { t, i18n } = useTranslation();
  const { data } = useWixPriceQuery();
  const [previewSubscription, { data: previewData }] = useUpgradeSubscriptionMutation();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [upgradeData, setUpgradeData] = useState<UpgradeProps>();
  const [upgradeError, setUpgradeError] = useState<string>();
  const wixPrice = data?.getWixPrice;

  const searchParams = useQuery();

  const instance = searchParams.get('instance');

  let instanceId = !user?.isSubscriber ? user?.company.wixAppInstanceId : undefined;

  // if this parameter is set, then the user came from a click on Wix's upgrade button
  // the parameter is a JWT which we need to decode and extract the wix application instance id
  if (instance) {
    ({ instanceId } = jwtDecode(instance) as any);
  }
  const isWixUser = !!instanceId;

  const handleUpgradePreview = ({ product, topic, mode, interval, promocode }: UpgradeProps) => {
    setUpgradeModalOpen(true);
    setUpgradeData({ product, topic, mode, interval, promocode });
    previewSubscription({
      variables: {
        product,
        topic,
        mode,
        interval,
        promocode,
        preview: true,
      },
    })
      .then(() => setUpgradeError(undefined))
      .catch((e) => setUpgradeError(t('route.pricing.upgrade.error1')));
  };

  return (
    <div className="flex flex-col gap-3 mt-5 text-center justify-center items-center">
      <div className="max-w-[600px] flex flex-col gap-2 items-center">
        <Trans t={t} i18nKey="route.pricing.header">
          <h1>
            <Balancer>Die perfekte Lösung für Deinen Datenschutz</Balancer>
          </h1>
          <p className="hidden md:block">
            <Balancer>
              Geprüft von TÜV-zertifizierten Datenschutzbeauftragten, Juristen & Legal-Tech-Engineers.
            </Balancer>
          </p>
        </Trans>
      </div>

      {isWixUser && !wixPrice ? (
        <div>Loading...</div>
      ) : (
        <PricingTable
          queryoption={searchParams.get('option')}
          user={user}
          selectedProductValue={isWixUser ? 'dse' : (searchParams.get('product') as TopicValues) ?? undefined}
          locale={i18n.language.slice(0, 2)}
          querymode={searchParams.get('mode')}
          inApp={true}
          wixAppInstanceId={instanceId || undefined}
          wixPrice={wixPrice}
          onUpgrade={handleUpgradePreview}
        />
      )}
      <UpgradeModal
        open={upgradeModalOpen}
        onClose={() => setUpgradeModalOpen(false)}
        previewData={previewData as any as UpgradeSubscriptionResult<'InvoicePreview'>}
        updatePreview={handleUpgradePreview}
        upgradeInput={upgradeData}
        error={upgradeError}
        setError={setUpgradeError}
      />
    </div>
  );
}

import { useUser } from '@app/context/UserContext';
import { Button } from '@components/ui/Button/Button';
import { UserTopicApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { AddPartnerMenu } from './AddPartnerMenu';
import { PartnerData } from './PartnerListEntry';

interface AddPartnerButtonProps {
  topic: UserTopicApplicationsQuestionnaires;
  setNewEntry: (newEntry: PartnerData) => void;
}

export function AddPartnerButton({ topic, setNewEntry }: AddPartnerButtonProps) {
  const { user } = useUser();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        className="text-base"
        onClick={handleClick}
        icon={<AddIcon />}
        disabled={user?.isReadOnly}
        data-testid="button-add-partner"
      >
        Hinzufügen
      </Button>
      <AddPartnerMenu topic={topic} anchorEl={anchorEl} handleClose={handleClose} setNewEntry={setNewEntry} />
    </>
  );
}

import { gql, useMutation } from '@apollo/client';
import { EMPLOYEE_FIELDS, Employee } from '@dieterApi/user/useUserQuery';

const ADD_EMPLOYEE = gql`
  ${EMPLOYEE_FIELDS}
  mutation addEmployee($name: String!) {
    addEmployee(name: $name) {
      ...UserEmployeeFields
    }
  }
`;

export interface AddEmployeeResult {
  addEmployee: Employee;
}

export interface AddEmployeeInput {
  name: string;
}

export interface AddEmployeeContext {
  companyId: string;
}

export function useEmployeeAddMutation() {
  return useMutation<AddEmployeeResult, AddEmployeeInput, AddEmployeeContext>(ADD_EMPLOYEE, {
    refetchQueries: ['GetUser'],
    // update(cache, { data }, { context }) {
    //   cache.modify({
    //     id: cache.identify({ __typename: "Company", id: context?.companyId }),
    //     fields: {
    //       employees(cachedEmployeeRefs: Employee[]) {
    //         const newEmployeeRef = cache.writeFragment({
    //           data: data?.addEmployee,
    //           fragment: gql`
    //             fragment NewEmployee on Employee {
    //               id
    //               name
    //             }
    //           `,
    //         });

    //         return [...cachedEmployeeRefs, newEmployeeRef];
    //       },
    //     },
    //   });
    // },
  });
}

import { useUser } from '@app/context/UserContext';
import { downloadFile } from '@app/hooks/useDownload';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { ThreeDots } from '@components/ui';
import { ThreeDotItem } from '@components/ui/ThreeDots/ThreeDots';
import {
  ISignatureRequest,
  useCreateSignatureRequestMutation,
} from '@dieterApi/signature/useSignatureRequestCreateMutation';
import { useDeleteSignatureRequestMutation } from '@dieterApi/signature/useSignatureRequestDeleteMutation';
import { useDownloadSignatureRequestMutation } from '@dieterApi/signature/useSignatureRequestDownloadMutation';
import { useListSignatureRequestQuery } from '@dieterApi/signature/useSignatureRequestQuery';
import { UserQuestionnaireApplicationTopic } from '@dieterApi/user/useUserQuery';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import { Chip, Collapse, Skeleton, TextField } from '@mui/material';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useImmer } from 'use-immer';

interface Props {
  questionnaire: UserQuestionnaireApplicationTopic | null;
  partnerName: string;
  contractType: string;
  colSpan?: number;
  onClose?: () => void;
}

interface CreateRequestFormValues {
  firstName1: string;
  lastName1: string;
  email1: string;
  firstName2: string;
  lastName2: string;
  email2: string;
}

export function SignatureRequests({ questionnaire, partnerName, contractType, colSpan, onClose }: Props) {
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [createRequest, { loading: createLoading }] = useCreateSignatureRequestMutation();
  const [downloadConfirmation] = useDownloadSignatureRequestMutation();
  const { data, loading } = useListSignatureRequestQuery({ questionnaireId: questionnaire?.id || '' });
  const [deleteRequest] = useDeleteSignatureRequestMutation();
  const emptyForm: CreateRequestFormValues = {
    firstName1: '',
    lastName1: '',
    email1: '',
    firstName2: '',
    lastName2: '',
    email2: '',
  };
  const [createNew, setCreateNew] = useState(false);
  const [formValues, setFormValues] = useImmer<CreateRequestFormValues>(emptyForm);
  const signatureRequests = data?.getSignatureRequests;

  const handleCreate = () => {
    if (!createNew) {
      setFormValues((draft) => {
        draft.firstName1 = user?.firstName || '';
        draft.lastName1 = user?.lastName || '';
        draft.email1 = user?.email || '';
      });
      setCreateNew(true);
    } else {
      setCreateNew(false);
    }
  };

  const handleSend = () => {
    createRequest({
      variables: {
        questionnaireId: questionnaire?.id || '',
        label: user?.company?.name + ' - ' + partnerName + ' - ' + contractType,
        signer: [
          {
            email: formValues.email1,
            first_name: formValues.firstName1,
            last_name: formValues.lastName1,
            locale: 'de',
          },
          {
            email: formValues.email2,
            first_name: formValues.firstName2,
            last_name: formValues.lastName2,
            locale: 'de',
          },
        ],
      },
    }).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
    });
    setCreateNew(false);
    setFormValues(emptyForm);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const threeDotItems = (signatureRequest: ISignatureRequest): ThreeDotItem[] => {
    const { id, status } = signatureRequest;

    const downloadConfirmationItem = {
      label: 'Bestätigung herunterladen',
      onClick: () => {
        downloadConfirmation({ variables: { id } })
          .then((result) => {
            const downloadData = result?.data?.downloadSignatureConfirmation;
            downloadData && downloadFile(downloadData);
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
          });
      },
      icon: <DownloadIcon fontSize="small" />,
    };

    const deleteItem = {
      label: 'Signiervorgang löschen',
      onClick: () => {
        deleteRequest({ variables: { id: signatureRequest.id } }).catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
        });
      },
      icon: <DeleteForeverIcon fontSize="small" />,
    };

    // if (status === 'done') we want to add the downloadConfirmationItem to the threeDotItems
    return status === 'done' ? [downloadConfirmationItem, deleteItem] : [deleteItem];
  };

  return (
    <TableRow className="dtSignatureRequests">
      <TableCell colSpan={colSpan}>
        <div className="flex flex-col gap-10">
          <div className="flex justify-between">
            <div className="flex gap-10 items-center">
              {!createNew && (
                <Link to="#" onClick={handleCreate}>
                  Neuer Signiervorgang
                </Link>
              )}
            </div>
            <Link to="#" onClick={onClose}>
              Ausblenden
            </Link>
          </div>
          <Collapse in={createNew}>
            <div className="newSignatureRequest flex gap-10 flex-col">
              <div>
                <span className="signer-label">Erste unterzeichnende Person</span>
                <div className="flex gap-10 items-center">
                  <TextField
                    className="flex-1"
                    name="firstName1"
                    label="Vorname"
                    size="small"
                    required={true}
                    variant="standard"
                    onChange={handleInputChange}
                    value={formValues.firstName1}
                  />
                  <TextField
                    className="flex-1"
                    name="lastName1"
                    label="Nachname"
                    size="small"
                    required={true}
                    variant="standard"
                    onChange={handleInputChange}
                    value={formValues.lastName1}
                  />
                  <TextField
                    className="flex-2"
                    name="email1"
                    label="E-Mail"
                    size="small"
                    required={true}
                    variant="standard"
                    onChange={handleInputChange}
                    value={formValues.email1}
                  />
                </div>
              </div>
              <div>
                <span className="signer-label">Zweite unterzeichnende Person</span>
                <div className="flex gap-10 items-center">
                  <TextField
                    className="flex-1"
                    name="firstName2"
                    label="Vorname"
                    size="small"
                    required={true}
                    variant="standard"
                    onChange={handleInputChange}
                    value={formValues.firstName2}
                  />
                  <TextField
                    className="flex-1"
                    name="lastName2"
                    label="Nachname"
                    size="small"
                    required={true}
                    variant="standard"
                    onChange={handleInputChange}
                    value={formValues.lastName2}
                  />
                  <TextField
                    className="flex-2"
                    name="email2"
                    label="E-Mail"
                    size="small"
                    required={true}
                    variant="standard"
                    onChange={handleInputChange}
                    value={formValues.email2}
                  />
                </div>
              </div>
            </div>
          </Collapse>

          {createNew && (
            <div className="flex gap-10 align-self-end">
              <Link to="#" onClick={handleCreate}>
                Abbrechen
              </Link>
              <Link to="#" onClick={handleSend}>
                Versenden
              </Link>
            </div>
          )}

          {loading ? (
            <Skeleton />
          ) : (
            <>
              {createLoading && <Skeleton />}
              {signatureRequests?.map((signatureRequest) => (
                <div key={signatureRequest.id} className="flex gap-10 justify-between items-center">
                  <div className="flex-1">{signatureRequest.name}</div>
                  <div>{new Date(signatureRequest.created_at).toLocaleDateString()}</div>
                  <div>
                    <RequestStatus signatureRequest={signatureRequest} />
                  </div>
                  <ThreeDots items={threeDotItems(signatureRequest)} />
                </div>
              ))}
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

interface RequestStatusProps {
  signatureRequest: ISignatureRequest;
}

function RequestStatus({ signatureRequest }: RequestStatusProps) {
  const { status, signers } = signatureRequest;

  const total = signers.length;
  const signed = signers.filter((signer) => signer.status === 'signed').length;

  const label =
    status === 'done'
      ? 'Unterschrieben'
      : status === 'ongoing'
      ? `In Bearbeitung (${signed}/${total})`
      : status === 'expired'
      ? 'Abgelaufen'
      : 'Unbekannt';

  const chipColor =
    status === 'done' ? 'success' : status === 'ongoing' ? 'primary' : status === 'expired' ? 'error' : 'default';
  return <Chip label={label} color={chipColor} size="small" sx={{ color: 'white' }} />;
}

import { Skeleton } from '@mui/material';

const InvoiceSkeleton = () => {
  const skeletonLine = (width: string) => <Skeleton variant="text" width={width} height={24} />;

  return (
    <div className=" p-6">
      <h2 className="text-2xl font-bold mb-4">
        <Skeleton variant="text" width="50%" />
      </h2>
      <p className="mb-2">{skeletonLine('80%')}</p>
      <p className="mb-2">{skeletonLine('60%')}</p>
      <p className="mb-2">{skeletonLine('70%')}</p>
      <p className="mb-4">{skeletonLine('90%')}</p>

      <div className="border-t border-b py-4 mb-4">
        <h3 className="text-xl font-semibold mb-2">
          <Skeleton variant="text" width="40%" />
        </h3>
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left py-2">
                <Skeleton variant="text" width="80%" />
              </th>
              <th className="text-right py-2">
                <Skeleton variant="text" width="60%" />
              </th>
              <th className="text-right py-2">
                <Skeleton variant="text" width="60%" />
              </th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3].map((_, index) => (
              <tr key={index} className="border-b">
                <td className="py-2">
                  <Skeleton variant="text" width="90%" />
                  <Skeleton variant="text" width="50%" />
                </td>
                <td className="py-2 text-right">
                  <Skeleton variant="text" width="50%" />
                </td>
                <td className="py-2 text-right">
                  <Skeleton variant="text" width="50%" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-4">
        <div className="flex justify-between">
          <div className="w-1/2">
            <p className="text-lg">{skeletonLine('60%')}</p>
          </div>
          <div className="w-1/2 text-right">
            <p className="text-lg">{skeletonLine('40%')}</p>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-1/2">
            <p className="text-lg">{skeletonLine('60%')}</p>
          </div>
          <div className="w-1/2 text-right">
            <p className="text-lg">{skeletonLine('40%')}</p>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="w-1/2">
            <p className="text-xl font-bold">{skeletonLine('80%')}</p>
          </div>
          <div className="w-1/2 text-right">
            <p className="text-xl font-bold">{skeletonLine('40%')}</p>
          </div>
        </div>
      </div>

      <div className="bg-yellow-100 p-4 rounded-md">
        <h4 className="text-lg font-semibold mb-2">
          <Skeleton variant="text" width="60%" />
        </h4>
        <p>
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="70%" />
        </p>
      </div>
    </div>
  );
};

export default InvoiceSkeleton;

// Usage example
// <InvoiceSkeleton />

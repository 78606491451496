import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { Locales } from '@components/block/Header/LangSelect';
import { useLegalOSMutation } from '@legalosApi/graphql/mutations';
import { QUESTIONNAIRE } from '@legalosApi/graphql/queries/gql/questionnaire';
import { useValueToLocalizedString } from '@legalosApi/helpers/localize_helpers';
import { IQuestionsUpdateInput, useDebouncedUpdate } from '@legalosApi/hooks/useDebouncedUpdate';
import AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';
import { CalculatedState } from '@legalosApi/types/CalculatedState';
import { IQuestion } from '@legalosApi/types/IQuestion';
import { DocumentNode } from '@legalosApi/types/IQuestionnaire';
import { IQuestionnaireData } from '@legalosApi/types/IQuestionnaireData';
import { ISection } from '@legalosApi/types/ISection';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import QuestionType from '@legalosApi/types/QuestionType';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import * as React from 'react';

/*
  This hook allows you to update a single question while still maintaining a consistent apollo cache state of all questions.
  Internally, this method debounces updates (so it only sends the mutation if the user did not change anything for an amount of time)
  - when it fires, it aborts a potentially previously active in-flight mutation and collects all question answers from the apollo cache
  (single source of truth) and fires a new mutation updating all questions to the values currently in the apollo cache state.

  Because the updateQuestion / updateQuestions mutation returns all questions and their values, this complexity is necessary to avoid
  older in-flight mutations from overwriting newer state. For example:
  User types an answer for Question 1.
  The mutation for Question 1 is invoked.
  User types an answer for Question 2.
  The mutation for Question 2 is invoked.
  The mutation for Question 1 returns -
    it overwrites the state of question 2 to the state on the server before the mutation for question 2 was started.

  To avoid this, we maintain the apollo cache as the single source of truth and with every update send all answers to all questions.
  Whenever a new update is sent, it aborts previous requests and sends a new one.
*/
export const useUpdateSingleQuestion = ({
  questionnaireData,
  refetchQuestionnaire,
}: {
  questionnaireData: IQuestionnaireData | undefined;
  refetchQuestionnaire: (variables?: Record<string, any> | undefined) => Promise<ApolloQueryResult<IQuestionnaireData>>;
}): {
  updateSingleQuestion: (_id: string, value: AllowedValueTypes, immediate?: boolean) => Promise<void>;
  isUpdateInProgress: boolean;
} => {
  const [updateQuestions] = useLegalOSMutation('questionsUpdate');

  const apolloClient = useApolloClient();

  const valueToLocalizedString = useValueToLocalizedString({
    entities: questionnaireData?.questionnaire.entities || [],
    entityTypeDefinitions: questionnaireData?.questionnaire.entityTypeDefinitions || [],
  });

  const optimisticUpdateFunction = React.useCallback(
    (input: IQuestionsUpdateInput) => {
      const questionnaire = apolloClient.readQuery<IQuestionnaireData>({
        query: QUESTIONNAIRE,
        variables: { input: { _id: questionnaireData?.questionnaire._id } },
      })?.questionnaire;
      if (!questionnaire) return;
      const allQuestions = getQuestions(questionnaire?.sections);
      const { questions: updatedQuestionIdsAndValues } = input;

      const updatedValuesByQuestionId = new Map(
        updatedQuestionIdsAndValues.map(({ _id, value }) => [_id, Array.isArray(value) ? [...value] : value])
      );
      const questionByQuestionId = new Map<string, IQuestion>(
        Object.entries(
          keyBy(
            allQuestions
              .filter(({ calculatedState }) => calculatedState !== CalculatedState.CALCULATED)
              .map((question) =>
                updatedValuesByQuestionId.has(question._id)
                  ? {
                      ...question,
                      value: updatedValuesByQuestionId.get(question._id) || null,
                      ...(question.value !== updatedValuesByQuestionId.get(question._id) || null
                        ? {
                            state:
                              updatedValuesByQuestionId.get(question._id) !== undefined &&
                              updatedValuesByQuestionId.get(question._id) !== null &&
                              updatedValuesByQuestionId.get(question._id) !== ''
                                ? QuestionnaireState.COMPLETE
                                : question.state,
                          }
                        : {}),
                    }
                  : question
              ),
            '_id'
          )
        )
      );

      apolloClient.writeQuery({
        query: QUESTIONNAIRE,
        variables: { input: { _id: questionnaire._id } },
        data: {
          questionnaire: {
            ...questionnaire,
            sections: questionnaire?.sections?.map((section) => ({
              ...section,
              ...(section.questions
                ? {
                    questions: section.questions.map((question) => ({
                      ...question,
                      ...(questionByQuestionId.has(question._id)
                        ? {
                            value: questionByQuestionId.get(question._id)!.value,
                            state: questionByQuestionId.get(question._id)!.state,
                          }
                        : {}),
                    })),
                  }
                : {}),
              ...(section.children
                ? {
                    children: section.children.map((childSection) => ({
                      ...childSection,
                      ...(childSection.questions
                        ? {
                            questions: childSection.questions.map((question) => ({
                              ...question,
                              ...(questionByQuestionId.has(question._id)
                                ? {
                                    value: questionByQuestionId.get(question._id)!.value,
                                    state: questionByQuestionId.get(question._id)!.state,
                                  }
                                : {}),
                            })),
                          }
                        : {}),
                    })),
                  }
                : {}),
            })),
            ...(questionnaire?.document
              ? {
                  document: updateQuestionsInDocument({
                    document: questionnaire.document,
                    questionByQuestionId,
                    valueToLocalizedString,
                  }),
                }
              : {}),
          },
        },
      });
    },
    [apolloClient, questionnaireData?.questionnaire._id, getQuestions, valueToLocalizedString]
  );

  const { debouncedUpdate: updateQuestionsDebounced, isUpdateInProgress } = useDebouncedUpdate(
    updateQuestions,
    optimisticUpdateFunction,
    refetchQuestionnaire
  );

  const updateSingleQuestion = React.useCallback(
    (_id: string, value: AllowedValueTypes, immediate?: boolean, token?: string, locale?: Locales) => {
      const oldQuestionnaire = apolloClient.readQuery<IQuestionnaireData>({
        query: QUESTIONNAIRE,
        variables: { input: { _id: questionnaireData?.questionnaire._id } },
      })?.questionnaire;
      const oldValues = getQuestions(oldQuestionnaire?.sections)
        .filter(
          // MH: I added a filter on the QuestionType.TEXT and MULTILINE_TEXT since the cached value of 'null' would always
          // overwrite the result which comes back, when a value is automatically entered by Dieter Backend when the process tag 'auto' is used.
          // This is not a problem, since text entry values cannot be skipped quickly by spamming 'enter'.
          ({ calculatedState, type, value }) =>
            !(Array.isArray(value) && value.length == 0) &&
            value &&
            calculatedState !== CalculatedState.CALCULATED &&
            ![QuestionType.TEXT, QuestionType.MULTILINE_TEXT].includes(type)
        )
        .map((question) => pick(question, ['_id', 'value']));
      const allQuestionsWithUpdate = Object.values({
        // For all questions, select the _id and value properties.
        // Create a dictionary _id: { _id, value}.
        ...keyBy(oldValues, '_id'),
        // In this dictionary, overwrite the entry for the to be update question with the supplied values
        [_id]: { _id, value },
      });
      // Now we can send all current values to the server with a debounced mutation - aborting previously in-flight mutations of the same
      // kind. As we always supply all questions in our Apollo cache, we never loose any data.
      return updateQuestionsDebounced(
        {
          questionnaireId: questionnaireData?.questionnaire._id || '',
          questions: allQuestionsWithUpdate,
          token,
          locale,
        },
        immediate
      );
    },
    [getQuestions, apolloClient, questionnaireData?.questionnaire._id, updateQuestionsDebounced]
  );

  return { updateSingleQuestion, isUpdateInProgress };
};

export function updateQuestionInSections(
  sections: ISection[],
  questionId: string,
  updateFunction: (question: IQuestion) => IQuestion
): ISection[] {
  return sections.map((section) => {
    if (section.children?.length) {
      return {
        ...section,
        children: updateQuestionInSections(section.children, questionId, updateFunction),
      };
    }
    return {
      ...section,
      questions: section.questions!.map((q) => {
        if (q._id === questionId) {
          return updateFunction(q);
        }
        return q;
      }),
    } as ISection;
  });
}

export function updateQuestionsInDocument({
  document,
  questionByQuestionId,
  valueToLocalizedString,
  currentQuestionId,
  currentEntityPropertyName,
}: {
  document: DocumentNode[];
  questionByQuestionId: Map<string, IQuestion>;
  valueToLocalizedString: (
    value: AllowedValueTypes,
    questionType: QuestionType,
    entityPropertyName?: string | undefined
  ) => string | null;
  currentQuestionId?: string;
  currentEntityPropertyName?: string;
}): DocumentNode[] {
  return document.map((node) => {
    return {
      ...node,
      ...(node.type === 'text' && !!currentQuestionId
        ? {
            text:
              valueToLocalizedString(
                questionByQuestionId.get(currentQuestionId!)?.value || '',
                questionByQuestionId.get(currentQuestionId!)!.type as QuestionType,
                currentEntityPropertyName
              ) || '[…]',
          }
        : {}),
      ...(node.type !== 'text' && node.children
        ? {
            children: updateQuestionsInDocument({
              document: node.children || [],
              questionByQuestionId,
              valueToLocalizedString,
              currentQuestionId:
                node.questionId && questionByQuestionId.has(node.questionId!) ? node.questionId : currentQuestionId,
              currentEntityPropertyName:
                node.questionId && questionByQuestionId.has(node.questionId!)
                  ? node.entityPropertyName
                  : currentEntityPropertyName,
            }),
          }
        : {}),
    };
  });
}

// recursively extract questions from nested sections
export const getQuestions = (sections?: ISection[]): IQuestion[] =>
  sections?.flatMap((section) => [
    ...(section.questions || []),
    ...(section.children ? getQuestions(section.children) : []),
  ]) || [];

import cx from 'classnames';
import React, { forwardRef } from 'react';

import './ui-input.sass';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  unit?: string;
  textSize?: 'small' | 'medium' | 'large';
  icon?: React.ReactElement;
}

export const Input = forwardRef<HTMLInputElement, Props>(function X(
  { className, type = 'text', unit, textSize, icon, ...props },
  ref
) {
  if (unit) {
    return (
      <label className={cx('dtUiInput__border flex flex-row items-center', className)}>
        <form className="relative">
          <input {...props} className={cx('dtUiInput__input flex-grow-1')} ref={ref} type={type} />
          <div className="absolute right-6 top-1/2 -translate-y-1/2 ">{icon}</div>
        </form>
        <div className="dtUiInput__unit">{unit}</div>
      </label>
    );
  }

  return (
    <div className="relative">
      <input {...props} className={cx('dtUiInput', className, textSize)} ref={ref} type={type} />
      <div className="absolute right-6 top-1/2 -translate-y-1/2 ">{icon}</div>
    </div>
  );
});

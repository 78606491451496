import './quest-step-choice.sass';

import type AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';
import type { ISelectOption } from '@legalosApi/types/ISelectOption';

import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';

import { Option } from '@components/block/Quest/Step/ChoiceStep/Option.tsx';
import { AnimatedInfoButton } from '@components/ui/AnimatedInfo/AnimatedInfo';
import { Markdown } from '@components/ui/Markdown/Markdown';

export interface QuestStepChoiceProps {
  tooltip?: boolean;
  options: ISelectOption[];
  questionText: string;
  description?: string;
  value?: AllowedValueTypes;

  onSelect?(option: string): void;
}

export function QuestStepChoice(props: QuestStepChoiceProps) {
  const { tooltip, options, onSelect, questionText, description, value } = props;

  const [showInfo, setShowInfo] = useState(!tooltip);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedOptionIndex((prevIndex) =>
          prevIndex === null || prevIndex === 0 ? options.length - 1 : prevIndex - 1
        );
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedOptionIndex((prevIndex) =>
          prevIndex === null || prevIndex === options.length - 1 ? 0 : prevIndex + 1
        );
      } else if (event.key === 'Enter' && selectedOptionIndex !== null) {
        event.preventDefault();
        const selectedOptionValue = options[selectedOptionIndex].value;
        onSelect?.(selectedOptionValue);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [options, onSelect, selectedOptionIndex]);

  return (
    <div className="dtQuestStep flex gap-20">
      <div className="flex-1 hidden md:block" />
      <div className="flex-5 flex flex-col gap-20">
        <div className="flex gap-20">
          <div className="flex-4 text-break">
            <Markdown className="dtQuestStep__question questionTitle" text={questionText} />

            {description && (
              <Collapse in={showInfo}>
                <Markdown text={description} />
              </Collapse>
            )}
          </div>
          <div className="flex-1 hidden md:block">
            {description && tooltip && <AnimatedInfoButton onClick={() => setShowInfo(!showInfo)} />}
          </div>
        </div>
        {description && !showInfo && (
          <div className="block md:hidden self-end">
            <AnimatedInfoButton onClick={() => setShowInfo(!showInfo)} />
          </div>
        )}

        <div className="flex flex-col align-items-start gap-20 mb-20">
          {options.map((option, index) => (
            <Option
              key={index}
              option={option}
              onClick={() => onSelect?.(option.value)}
              shortcutKey={(index + 1).toString()}
              isSelected={selectedOptionIndex ? false : value === option.value}
              isHighlighted={selectedOptionIndex === index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default QuestStepChoice;

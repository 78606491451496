import { Provider as BalancerProvider } from 'react-wrap-balancer';

import { ApolloProvider } from '@apollo/client';
// import client from '@app/client.ts';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import { useApolloClient } from './context/ApolloClientContext';
import { FlowProvider } from './context/FlowContext';
import { MessageProvider } from './context/MessageContext';
import { MessageQueueProvider } from './context/MessageQueueContext';
import { NavigationProvider } from './context/NavigationContext';
import { OrderProvider } from './context/OrderContext';
import { TaskProvider } from './context/TaskContext';
import { UserProvider, useUser } from './context/UserContext';

const makeTheme = (primaryColor: string, secondaryColor: string) => createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: '#91ab46;',
    },
  },
  typography: {
    fontFamily: ['DieterRubik'].join(','),
  },
  zIndex: {
    snackbar: 10000,
  },
});

interface IProps {
  children: ReactNode;
}

function AppProvider({ children }: IProps) {
  const { client } = useApolloClient();
  if (!client) return null;
  return (
    <ApolloProvider client={client}>
      <SnackbarProvider maxSnack={5} classes={{ containerRoot: 'z-alert' }}>
        <UserProvider>
          <ThemeWrapper>
            <NavigationProvider>
              <MessageQueueProvider>
                <MessageProvider>
                  <TaskProvider>
                    <FlowProvider>
                      <OrderProvider>
                        <BalancerProvider>{children}</BalancerProvider>
                      </OrderProvider>
                    </FlowProvider>
                  </TaskProvider>
                </MessageProvider>
              </MessageQueueProvider>
            </NavigationProvider>
          </ThemeWrapper>
        </UserProvider>
      </SnackbarProvider>
    </ApolloProvider>
  );
}

// ThemeWrapper wraps around MUIs ThemeProvider to add dynamic color capabilities.
function ThemeWrapper({ children }: IProps) {
  const { user } = useUser();
  const [theme, setTheme] = useState(makeTheme('#ab5fce', '#fdd213'));
  useEffect(() => {
    if (!user) return;
    const principalCompany = user.companies.find((company) => company.isPrincipal === true);
    if (!principalCompany) return;
    if (!principalCompany?.features.includes('Whitelabel')) return;
    if (principalCompany?.whitelabelConfig) {
      const primaryColor = principalCompany.whitelabelConfig.primaryColor;
      const secondaryColor = principalCompany.whitelabelConfig.secondaryColor;
      setTheme(makeTheme(primaryColor, secondaryColor));
    }
  }, [user]);
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default AppProvider;

import { gql } from "@apollo/client";

export const ENTITY_CREATE = gql`
  mutation entityCreate($input: EntityCreateInput) {
    entityCreate(input: $input) {
      _id
      userId
      type
      contractInstanceIds
      matterIds
      data
    }
  }
`;

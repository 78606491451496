import { NavigationState } from './NavigationContext';
import {
  NavigationSection,
  NavigationSubsection,
  Topics,
  navSectionPathMapping,
  subSectionPathMapping,
  topicPathMapping,
} from './constants';

export const parseNavState = (navState: string) => {
  const state: NavigationState = JSON.parse(navState);

  // check if newState.lastUpdate is a string and parse to Date
  if (typeof state.lastUpdate === 'string') {
    state.lastUpdate = new Date(state.lastUpdate as string);
  }
  return state;
};

export function sectionUrl(section: NavigationSection | string) {
  return navSectionPathMapping[section as NavigationSection] || navSectionPathMapping[NavigationSection.Overview];
}

export function topicUrl(topic: Topics) {
  return topicPathMapping[topic] || topicPathMapping[Topics.CHECKUP];
}

export function subSectionUrl(subSection: NavigationSubsection) {
  return subSectionPathMapping[subSection] || '';
}

export function topicKey(topic: Topics) {
  return topicPathMapping[topic]?.replace('/', '');
}

export function navigationSubsectionKey(subsection: NavigationSubsection) {
  return subSectionPathMapping[subsection]?.replace('/', '');
}

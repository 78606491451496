import cx from 'classnames';

import { ISection } from '@legalosApi/types/ISection';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import { Section } from './Section';

export interface Props {
  activeIds: Array<string>;
  depth: number;
  onSection: (section: ISection) => void;
  sections: Array<ISection>;
}

export function SectionList({ activeIds, depth, onSection, sections }: Props) {
  let index = sections.length;
  // count index down until we find the first COMPLETE question
  while (
    index > 1 &&
    sections[index - 1].state === QuestionnaireState.UNTOUCHED &&
    (index < 2 || sections[index - 2].state !== QuestionnaireState.COMPLETE)
  ) {
    index -= 1;
  }

  return (
    <ul className={cx('dtQuestOutline__list', `depth-${depth}`)}>
      {sections.map((section, idx) => (
        <Section
          activeIds={activeIds}
          depth={depth}
          key={section._id}
          onSection={onSection}
          section={section}
          index={idx}
        />
      ))}
    </ul>
  );
}

import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { pickLatest } from '@app/utils/pickLatest';
import { AllProcessTasks } from '@dieterApi/task/useTaskQuery';
import { ApplicationType } from '@dieterApi/user/useUserQuery';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserFlowItemType } from '../../constants';
import { ProgressListItem } from './ProgressListItem';
import './progresslist.sass';

export function ProgressList() {
  const { t } = useTranslation();
  const { user } = useUser();
  const tasks = useTasks();

  const nQuestionnaires =
    user?.topics.flatMap((topic) =>
      topic.applications
        .filter((app) => [ApplicationType.Primary, ApplicationType.Secondary].includes(app.type))
        .flatMap((app) =>
          app.sectionLabels.filter((label, idx) => pickLatest(app.questionnaires)?.sectionProgress[idx] === 1)
        )
    ).length || 0;

  const questionnaires = user?.questionnaires?.filter((q) => q.progress === 1);
  const nDocuments =
    questionnaires
      ?.filter((q) => q.application.type === ApplicationType.Primary)
      .flatMap((q) => q.application.documentLabels).length || 0;

  const nTasks = tasks?.filter((task) => AllProcessTasks.includes(task.type) && task.done).length || 0;

  return (
    <div className="dtProgressList flex flex-col gap-10">
      <div className="dtProgressListHeader flex justify-between items-center">
        <span>{t('dashboard.info.progresslist.header')}</span>
        {/* <CollapseToggle onClick={handleClick} open={open} /> */}
      </div>
      <Divider />

      <div className="dtProgressListBody flex flex-col">
        <ProgressListItem
          title={t('dashboard.topic.documentsummary.header')}
          type={UserFlowItemType.questionnaire}
          count={nQuestionnaires}
        />
        {/* <ProgressListItem
          title={t('dashboard.topic.documentsummary.header')}
          type={UserFlowItemType.document}
          count={nDocuments}
        /> */}
        <ProgressListItem title={t('dashboard.summary.titles.tasks')} type={UserFlowItemType.task} count={nTasks} />
        {/* <ProgressListItem title="Noch offen" type={OpenTaskType.openTask} count={0} /> */}
        <Divider className="collapseDivider" />
      </div>
    </div>
  );
}

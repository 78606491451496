import { useApolloClient } from '@app/context/ApolloClientContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { Button, EmailField } from '@components/ui';
import { useUserLoginMutation } from '@dieterApi/user/useUserLoginMutation';
import { useUserResetPasswordMutation } from '@dieterApi/user/useUserResetPasswordMutation';
import cx from 'classnames';
import { validate } from 'email-validator';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import './route-resetpassword.sass';

export default function ResetPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const { client } = useApolloClient();
  const [email, setEmail] = useState('');
  const [mailSent, setMailSent] = useState(false);
  const [login] = useUserLoginMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [sendMail, { loading }] = useUserResetPasswordMutation();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  // extract the token from the query parameters when the link in the E-Mail was clicked
  useEffect(() => {
    const token = query.get('token');
    // const token = tokenparam && extractId(tokenparam);
    if (token) {
      login({ variables: { email: '', password: '', token } })
        .then(() => client?.refetchQueries({ include: ['GetUser'] }))
        .then(() => history.push('/password'))
        .catch((e) => {
          // TODO/WM/ASK: it will be better for "multi lang" to map error codes with messages
          enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
        });
    }
  }, [query]);

  const handleClick = () => {
    sendMail({ variables: { email } });
    setMailSent(true);
  };

  const buttonDisabled = !validate(email);

  return (
    <div className="dtRouteResetPassword flex flex-col justify-center items-center">
      <div className="dtRouteResetPassword__panel">
        {loading ? (
          // Sending E-Mail was triggered but is still loading
          <div>
            <Trans t={t} i18nKey="common.loading">
              Loading...
            </Trans>
          </div>
        ) : mailSent ? (
          // E-Mail has been sent
          <div data-testid="password-reset-message">
            <Trans t={t} i18nKey="route.reset_password.instruction.check_email">
              <strong>Bitte überprüfe dein Postfach. </strong>
              <p>
                Wenn diese E-Mail Adresse in unserer Datenbank existiert, haben wir dir einen Link zum Zurücksetzen
                deines Passworts versendet.
              </p>
              <p>Der Link ist für 10 Minuten gültig.</p>
            </Trans>
          </div>
        ) : (
          // E-Mail has not been sent yet
          <>
            <Trans t={t} i18nKey="route.reset_password.instruction.send_email">
              <strong>Hier kannst du dein Passwort zurücksetzen!</strong>
              <div className="mb-20">
                Bitte trage die E-Mail Adresse ein, mit der du dich registriert hast. Wir senden dir einen Link zum
                Zurücksetzen deines Passworts.
              </div>
            </Trans>
            <EmailField onChange={(e) => setEmail(e.target.value)} onEnter={handleClick} />
            <Button
              className={cx({ btnDisabled: buttonDisabled }, 'mb-20 w-50')}
              data-testid="button-submit"
              onClick={handleClick}
              disabled={buttonDisabled}
            >
              {t('route.reset_password.action.send', 'Abschicken')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

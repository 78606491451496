import { FileItem, FileUpload, Icon } from '@components/ui';
import { UserTopic } from '@dieterApi/user/useUserQuery';
import { useTranslation } from 'react-i18next';

interface Props {
  topic: UserTopic;
}

export function UploadSection({ topic }: Props) {
  const { t } = useTranslation();
  return (
    <div className="dtRouteDashboard__item flex flex-col gap-10" key={topic.id}>
      <div className="dtRouteDashboard__itemTitle flex flex-row items-center gap-10">
        <Icon type="file-24" />
        <div className="dtRouteDashboard__itemTitleInput flex-grow-1">{t('dashboard.topic.uploadsection.header')}</div>
      </div>
      <p className="mt-4 !text-xs mb-4 text-gray-500">{t('dashboard.topic.uploadsection.description')}</p>
      <FileUpload topicId={topic.id} />
      {topic.files.map((file) => {
        return file && <FileItem key={file.id} file={file} />;
      })}
    </div>
  );
}

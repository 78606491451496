import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { AlertDialog, Button, ThreeDots } from '@components/ui';
import { ThreeDotItem } from '@components/ui/ThreeDots/ThreeDots';
import { useDeleteTenantMutation } from '@dieterApi/user/useUserDeleteTenantMutation';
import { UserCompany } from '@dieterApi/user/useUserQuery';
import { useSwitchTenantMutation } from '@dieterApi/user/useUserSwitchTenantMutation';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MaxReachedModal } from './MaxReachedModal';
import { MultiTenantInfo } from './MultiTenantInfo';
import { MutateTenantModal } from './MutateTenantModal';
import './multitenanttable.sass';

export function MultiTenantTable() {
  const { t } = useTranslation();
  const { user } = useUser();

  const maxTenantsReached = (user?.companies.length || 1) >= (user?.maxTenants || 1);

  const { enqueueSnackbar } = useSnackbar();
  const [deleteTenant] = useDeleteTenantMutation();
  const [switchTenant] = useSwitchTenantMutation();

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [mutateModelOpen, setMutateModelOpen] = useState(false);
  const [maxReachedModalOPen, setMaxReachedModalOpen] = useState(false);
  const [tenantForEdit, setTenantForEdit] = useState<UserCompany | undefined>();
  const [deleteRow, setDeleteRow] = useState<MUIDataTableMeta | null>(null);

  const tenantData = user?.companies || [];

  const handleDelete = (tableMeta: MUIDataTableMeta) => {
    const tenantId = tableMeta.rowData[0];

    deleteTenant({
      variables: {
        companyId: tenantId,
      },
    })
      .then(() => {
        enqueueSnackbar(t('dashboard.multitenanttable.deletesuccess'), { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
      });
  };

  const handleAddTenant = () => {
    if (maxTenantsReached) {
      setMaxReachedModalOpen(true);
    } else {
      setMutateModelOpen(true);
    }
  };

  const handleEditTenant = (tableMeta: MUIDataTableMeta) => {
    setTenantForEdit(user?.companies.find((c) => c.id === tableMeta.rowData[0]));
    setMutateModelOpen(true);
  };

  const handleCloseModal = () => {
    setMutateModelOpen(false);
    setTenantForEdit(undefined);
  };

  const handleSwitchTenant = (tableMeta: MUIDataTableMeta) => {
    const tenantId = tableMeta.rowData[0];
    switchTenant({
      variables: {
        companyId: tenantId,
      },
    }).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
    });
  };

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: t('dashboard.multitenanttable.tenant'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const companyId = tableMeta.rowData[0];
          const company = user?.companies.find((c) => c.id === companyId);
          if (!company?.isPrincipal) {
            return value;
          } else {
            return (
              <div className="flex gap-2 items-center">
                <span>{value}</span>
                <div className="bg-secondary-root rounded-md px-1 text-xs">{t('common.primary')}</div>
              </div>
            );
          }
        },
      },
    },
    {
      name: 'city',
      label: t('dashboard.multitenanttable.location'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const companyId = tableMeta.rowData[0];
          const company = user?.companies.find((c) => c.id === companyId);
          // tooltip should show full address with phone number
          const tooltip = (
            <div className="flex flex-col">
              <span>{company?.street}</span>
              <span>
                {company?.zip} {company?.city}
              </span>
              <span>{company?.phone}</span>
              <span>{company?.website}</span>
            </div>
          );

          return (
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={tooltip}>
              <div>{value}</div>
            </Tooltip>
          );
        },
      },
    },

    {
      name: 'switchTenant',
      label: t('dashboard.multitenanttable.change'),
      options: {
        customHeadLabelRender: () => '',
        customBodyRender: (value, tableMeta) => {
          // if the row is the current tenant, don't show the link, but an "active" chip
          let content: JSX.Element;
          if (user?.company?.id === tableMeta.rowData[0]) {
            content = <Chip size="small" label={t('common.active')} color="primary" />;
          } else {
            content = (
              <Link to="#" onClick={() => handleSwitchTenant(tableMeta)}>
                {t('dashboard.multitenanttable.changetenant')}
              </Link>
            );
          }
          return <div className="flex items-center justify-center">{content}</div>;
        },
      },
    },
    {
      name: 'actions',
      label: 'Aktionen',
      options: {
        customHeadLabelRender: () => '',
        customBodyRender: (value, tableMeta) => {
          const items: ThreeDotItem[] = [
            {
              label: (
                <div className="flex gap-1 items-center">
                  <EditIcon fontSize="inherit" />
                  <span className="grow">{t('common.edit')}</span>
                </div>
              ),
              onClick: () => handleEditTenant(tableMeta),
            },
            {
              label: (
                <div className="flex gap-1 items-center">
                  <DeleteIcon fontSize="inherit" />
                  <span className="grow">{t('components.ui.alert_dialog.actions.delete')}</span>
                </div>
              ),
              onClick: () => {
                setDeleteRow(tableMeta);
                setDeleteAlertOpen(true);
              },
            },
          ];
          return <ThreeDots items={items} />;
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    // sortOrder: {
    //   name: 'name',
    //   direction: 'asc',
    // },
    download: false,
    print: false,
    elevation: 0,

    selectableRows: 'none',
    selectableRowsHeader: false,
    // selectableRowsOnClick: true,
    // expandableRows: true,

    customToolbar: () => (
      <Tooltip title={maxTenantsReached ? t('dashboard.multitenanttable.maxtenantsreached') : undefined}>
        <span>
          <Button className="text-base mb-3" onClick={handleAddTenant} icon={<AddIcon />} disabled={user?.isReadOnly}>
            {t('dashboard.training.employee.table.toolbar.actions.add')}
          </Button>
        </span>
      </Tooltip>
    ),
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    textLabels: {
      selectedRows: {
        text: 'Zeilen ausgewählt',
        delete: 'Löschen',
        deleteAria: 'Ausgewählte Zeilen löschen',
      },
      body: {
        noMatch: 'Keine Einträge vorhanden',
      },
    },
  };

  return (
    <div className="dtMultiTenantTable">
      {tenantData && <MUIDataTable columns={columns} data={tenantData} title="Deine Mandanten" options={options} />}
      <AlertDialog
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        message={
          <Trans t={t} i18nKey={'dashboard.multitenanttable.delete_alert'} values={{ value: deleteRow?.rowData[1] }}>
            Möchtest du den Mandanten <b>{deleteRow?.rowData[1]}</b> wirklich löschen? Dabei werden sämtliche verknüpfte
            Daten gelöscht. Dazu gehören beantwortete Fragebögen, hochgeladene Dateien und dokumentierte Prozesse.
          </Trans>
        }
        onAccept={() => deleteRow && handleDelete(deleteRow)}
      />
      <MutateTenantModal open={mutateModelOpen} onClose={handleCloseModal} tenant={tenantForEdit} />
      <MaxReachedModal open={maxReachedModalOPen} onClose={() => setMaxReachedModalOpen(false)} />
      <MultiTenantInfo />
    </div>
  );
}

import {
  AutocompleteProps,
  Checkbox,
  Chip,
  ChipTypeMap,
  Autocomplete as MuiAutocomplete,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import React from 'react';

import { ISelectOption } from '@legalosApi/types/ISelectOption';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    AutocompleteProps<ISelectOption, Multiple, DisableClearable, false, ChipComponent>,
    'renderInput' | 'freeSolo'
  > {
  InputProps?: TextFieldProps<'outlined'>;
}

function Autocomplete<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({ InputProps, ...other }: IAutocompleteProps<Multiple, DisableClearable, ChipComponent>) {
  return (
    <MuiAutocomplete
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
        return <StyledTextField {...params} {...InputProps} variant="outlined" hiddenLabel />;
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip title={option.label} label={option.label} {...getTagProps({ index })} />)
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} className="mr-2 flex-grow-0" />
          {option.label}
        </li>
      )}
      {...other}
    />
  );
}

const StyledTextField = styled(TextField)`
  width: 100%;

  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid rgb(229, 229, 229);
  border-radius: 6px;

  outline: none;
  transition: border 100ms;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiInputBase-root {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 20px !important;
    gap: ${({ theme }) => theme.spacing(1)};
  }

  & .MuiChip-root {
    flex-grow: 0;
    flex-shrink: 1;
    max-width: min(90%, calc(100% - 46px));
  }

  & input {
    min-width: max(30px, calc(10% - 16px));
    box-sizing: border-box;

    font-size: 1.5em !important;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.25em !important;
    }
  }
`;

export default Autocomplete;

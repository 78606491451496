import StoreIcon from '@mui/icons-material/Store';

import { useUser } from '@app/context/UserContext';
import { useTranslation } from 'react-i18next';
import { MultiTenantTable } from './MultiTenantTable';
import './multitenant.sass';

export function MultiTenant() {
  const { user } = useUser();
  const { t } = useTranslation();
  return (
    <div className="dtMultiTenant flex flex-col gap-10">
      <div className="dtRouteDashboard__itemTitle flex flex-row items-center gap-10">
        <StoreIcon />
        <div className="dtRouteDashboard__itemTitleInput grow">{t('dashboard.multitenant')}</div>
        <span className="whitespace-nowrap text-2xl">
          {user?.companies.length} / {user?.maxTenants}
        </span>
      </div>
      <MultiTenantTable />
    </div>
  );
}

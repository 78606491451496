import { useNavigation } from '@app/hooks/useNavigation';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';

export const SavedHint = () => {
  const { t } = useTranslation();
  const {
    navigation: { showSavedAnimation },
    setNavigation,
  } = useNavigation();

  const fade = useSpring({
    opacity: showSavedAnimation ? 1 : 0,
    onRest: () => {
      if (showSavedAnimation) {
        setNavigation((nav) => void (nav.showSavedAnimation = false));
      }
    },
  });

  useEffect(() => {
    // This effect will run when 'showSavedAnimation' changes.
    // You can implement additional logic here if needed.
  }, [showSavedAnimation, setNavigation]);

  return (
    <animated.span
      style={fade}
      className="text-sm font-semibold text-center rounded text-success mr-3 fixed bottom-4 left-4 z-50 md:relative md:bottom-0 md:right-0"
    >
      <div className="flex items-center gap-1">
        <SaveIcon /> <span className="hidden md:block">{t('common.saved')}</span>
      </div>
    </animated.span>
  );
};

import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { Button, NotificationModal } from '@components/ui';

import { AllProcessTasks, TaskType } from '@dieterApi/task/useTaskQuery';
import { ApplicationStatus, UserTopicStatusApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';

import { Divider, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Dashboard } from '../useDashboard';

import { UploadSection } from './UploadSection';

import { NavigationSection } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { getTopicStates } from '@app/utils/getTopicStates';
import { Trans, useTranslation } from 'react-i18next';
import { TaskItem } from '../Tasks/TaskItem';
import { TopicTaskList } from './Tasks/TopicTaskList';
import './topic.sass';

interface Props {
  topic: UserTopicStatusApplicationsQuestionnaires;
  dashboard: Dashboard;
  topicStates: ReturnType<typeof getTopicStates>;
  setCollapseOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function TopicContent({ topic, dashboard, topicStates, setCollapseOpen }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const tasks = useTasks();
  const { navigation, setNavigation, navigateDashboard } = useNavigation();
  const {
    apps,
    primaryApp,
    noApps,
    isExperimental,
    isAllOpen,
    hasFiles,
    noQuestionnaires,
    latestPrimaryQuestionnaire,
    questionnairesExisting,
    primaryQuestionnaireDone,
    anyQuestionnaireDone,
    allQuestionnairesDone,
    showTeaser,
    isCheckUp,
    isLocked,
  } = topicStates;

  const { showTopicCompleteConfirmation, newDocuments, roadBlock } = navigation;

  const [teaserOpen, setTeaserOpen] = useState(showTeaser);
  const [value, setValue] = useState('Forms');

  const showSuccess = showTopicCompleteConfirmation[topic.id];

  const topicTasks = tasks?.filter((t) => t.topic.id === topic?.id) || [];
  const topicProcessTasks = topicTasks.filter((t) => AllProcessTasks.includes(t.type));

  const handleChange = (_: React.SyntheticEvent | null, newValue: string) => {
    setValue(newValue);
  };

  // this controls the teaser text collapse and if the questionnaire pane should be selected on open
  // this can be controlled by the navigation context with the preferredTab property
  // currently this is set, after a questionnaire was finished
  useEffect(() => {
    setCollapseOpen(teaserOpen);
    if (navigation.preferredTab && anyQuestionnaireDone) {
      setValue(navigation.preferredTab);
      setNavigation((nav) => void (nav.preferredTab = undefined));
    } else {
      allQuestionnairesDone ? setValue('Documents') : undefined;
    }
  }, [allQuestionnairesDone, teaserOpen, navigation.preferredTab]);

  // when the questionnaires are done, trigger a success message
  // useEffect(() => {
  //   showSuccess === undefined &&
  //     allQuestionnairesDone &&
  //     setNavigation((nav) => void (nav.showTopicCompleteConfirmation[topic.id] = true));
  // }, [allQuestionnairesDone]);

  // --> and reset on unmount (so it only shows once)
  useEffect(() => {
    return () => setNavigation((nav) => void (nav.showTopicCompleteConfirmation[topic.id] = false));
  }, []);

  // initially open the first app of this topic
  const handleClickBegin = () => {
    // if (!topic?.tags?.map((tag) => tag.tag).includes('public') && !user?.isSubscriber) {
    //   setRoadblockOpen({ open: true, topic: topic });
    // } else {
    if (questionnairesExisting && latestPrimaryQuestionnaire) {
      dashboard.open(latestPrimaryQuestionnaire.id);
    } else {
      primaryApp && dashboard.create(primaryApp.id, primaryApp.splitApplication ? 1 : undefined);
    }
    // }
  };

  const nextTopic = user?.topics.filter((t) => !t.tags.map((t) => t.tag).includes('experimental'))[
    user?.topics?.findIndex((t) => t.id === topic.id) + 1
  ];

  const handleClickNextTopic = () => {
    if (nextTopic?.status == ApplicationStatus.DISABLED) {
      roadBlock.open(nextTopic);
    } else {
      navigateDashboard(NavigationSection.Topic, nextTopic?.id);
    }
  };

  const newForms = topicTasks.filter((t) => [TaskType.QUESTIONNAIRE].includes(t.type)).some((t) => t.isNew && !t.done);
  const hasNewDocuments = apps
    .flatMap((app) => app.questionnaires?.flatMap((q) => newDocuments[q.id]?.some(Boolean)))
    ?.some(Boolean);
  const newProcesses = topicTasks.filter((t) => AllProcessTasks.includes(t.type)).some((t) => t.isNew && !t.done);

  const openTasksCount = topicTasks.filter((t) => !t.done).length;

  const topicFocusTask = tasks?.find((t) => t.isTopicFocus && t.topic.id === topic.id);

  return (
    <>
      {(allQuestionnairesDone || noApps || true) && (
        <>
          {!teaserOpen && (
            <div className="text-xs mb-4 text-gray-500">
              <Trans t={t} i18nKey={'dashboard.topic.topiccontent.taskintro'} values={{ 'topic?.title': topic?.title }}>
                Hier siehst du alle Aufgaben zum Thema {topic?.title}. Falls nötig kannst du diese auch erneut
                bearbeiten oder deine bereits gemachten Angaben verändern.
              </Trans>
            </div>
          )}
          {showSuccess && isCheckUp && (
            <div className="flex flex-col md:flex-row gap-8 md:gap-5 items-center">
              <img src="/assets/images/individualisierung_04.svg" alt="Info" />
              <p>
                <strong>Yeahhhh:</strong> {t('dashboard.topic.topiccontent.congrats')}
              </p>
            </div>
          )}
          {
            <div className="flex flex-col gap-8">
              {topicFocusTask && !teaserOpen && (
                <>
                  <TaskItem task={topicFocusTask} isFocusTask={true} />{' '}
                  <div
                    // key={topicFocusTask.id + 'A'}
                    id={`portal-container-${topicFocusTask.id}`}
                    className="col-span-full hidden -mt-9"
                  />
                </>
              )}
              {!tasks?.length ? (
                <Skeleton height={190} variant="rectangular" />
              ) : openTasksCount > 0 ? (
                <p>
                  <Trans t={t} i18nKey={'dashboard.topic.topiccontent.opentaskscount'} values={{ openTasksCount }}>
                    Du hast noch <span className="taskCount"> openTasks </span> offene Aufgaben in diesem Bereich.
                  </Trans>
                </p>
              ) : (
                <p>{t('dashboard.topic.topiccontent.nofurthertasks')}</p>
              )}
              {nextTopic && openTasksCount === 0 && (
                <div className="nextTopicButton mt-2">
                  <Button data-testid="button-next-topic" btnColor="primary" onClick={handleClickNextTopic}>
                    {t('dashboard.topic.topiccontent.nextstep')} <strong>{nextTopic?.title}</strong>
                  </Button>
                </div>
              )}
            </div>
          }
        </>
      )}

      {teaserOpen ? (
        <Button
          disabled={dashboard.isBusy || user?.isReadOnly}
          onClick={() => setTeaserOpen(false)}
          spinner={dashboard.affects('create', apps[0]?.id) || dashboard.affects('open', apps[0]?.id)}
          data-testid="button-questionnaire-start"
        >
          {isLocked ? t('dashboard.topic.topiccontent.testnow') : t('dashboard.topic.topiccontent.start')}
        </Button>
      ) : (
        <>
          {(!noApps && !isExperimental) || isAllOpen ? (
            <div>
              <TopicTaskList topic={topic} isLocked={isLocked} />
            </div>
          ) : (
            <AppTeaser />
          )}

          {hasFiles && (
            <div className="mt-8 flex flex-col gap-4">
              <Divider />
              <UploadSection topic={topic} />
            </div>
          )}
        </>
      )}
      {!isCheckUp && (
        <SuccessMessage
          open={showSuccess}
          onClose={() => setNavigation((nav) => void (nav.showTopicCompleteConfirmation[topic.id] = false))}
          topic={topic}
        />
      )}
    </>
  );
}

interface MessageProps {
  open: boolean;
  onClose: () => void;
  topic: UserTopicStatusApplicationsQuestionnaires | null;
}

export function SuccessMessage({ open, onClose, topic }: MessageProps) {
  const { t } = useTranslation();
  return (
    <NotificationModal open={open} onClose={onClose}>
      <div className="dtRoadblock flex flex-col gap-20">
        <div className="dtRoadblock__header">
          <img src="/assets/images/dieter_super.svg" alt="Info" />
          <span>
            {t('dashboard.topic.topiccontent.donetopic')}: <strong> {topic?.title}</strong>
          </span>
        </div>

        <div className="dtRoadblock__body flex flex-col gap-10 items-center">
          <p>
            <strong>Yeahhhh,</strong>
            <Trans t={t} i18nKey={'dashboard.topic.topiccontent.alldone'} values={{ 'topic?.title': topic?.title }}>
              Glückwunsch! Du hast alle Fragebögen im Bereich <strong>{topic?.title}</strong> abgeschlossen. Du kannst
              die daraus entstandenen Dokumente im entsprechenden Tab einsehen oder in der Druckansicht öffnen.
            </Trans>
          </p>
          <Button onClick={onClose} data-testid={'button-close-SuccessMessage'}>
            Ok
          </Button>
        </div>
      </div>
    </NotificationModal>
  );
}

export function AppTeaser() {
  const { t } = useTranslation();
  return (
    <div className="dtAppTeaser flex  items-center gap-20">
      <div className="text">
        <p>{t('dashboard.topic.topiccontent.workinprogress')}</p>
      </div>
      <div className="image">
        <img src="/assets/images/ssl_03.svg" alt="Info" />
      </div>
    </div>
  );
}

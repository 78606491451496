import { gql, useMutation } from '@apollo/client';
import { USER_FIELDS, User } from './useUserQuery';

const UPDATE_USER_PASSWORD = gql`
  ${USER_FIELDS}
  mutation UpdateUserPassword($password: String!, $conditionsConsent: Boolean) {
    updateUserPassword(password: $password, conditionsConsent: $conditionsConsent) {
      ...UserFields
    }
  }
`;

export interface UpdateUserPasswordResult {
  updateUserPassword: User;
}

export interface UpdateUserPasswordInput {
  password: string;
  conditionsConsent: boolean;
}

export function useUserUpdatePasswordMutation() {
  return useMutation<UpdateUserPasswordResult, UpdateUserPasswordInput>(UPDATE_USER_PASSWORD, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => (data && data.updateUserPassword ? data.updateUserPassword : null),
        },
      });
    },
  });
}

import { gql, useMutation } from '@apollo/client';

export const SIGNATURE_REQUEST_FIELDS = gql`
  fragment SignatureRequestFields on SignatureRequest {
    id
    status
    name
    created_at
    expiration_date
    signers {
      id
      status
      signature_link
    }
    email_custom_note
    timezone
    external_id
  }
`;

const CREATE_SIGNATURE_REQUEST = gql`
  ${SIGNATURE_REQUEST_FIELDS}
  mutation CreateSignatureRequest($questionnaireId: String!, $label: String!, $signer: [SignerInput!]!) {
    createSignatureRequest(questionnaireId: $questionnaireId, label: $label, signer: $signer) {
      ...SignatureRequestFields
    }
  }
`;

export interface CreateSignatureRequestInput {
  questionnaireId: string;
  label: string;
  signer: SignerInput[];
}

export interface SignerInput {
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  locale: 'de' | 'en';
}

export interface ISignatureRequest {
  id: string;
  status: ISignatureRequestStatus;
  name: string;
  created_at: string;
  expiration_date: string;
  signers: ISigner[];
  email_custom_note: string;
  timezone: string;
  external_id: string;
}

export type ISignatureRequestStatus = 'done' | 'ongoing' | 'expired' | 'cancelled';

export interface ISigner {
  id: string;
  status: string;
  signature_link: string;
}

export interface CreateSignatureRequestResult {
  createSignatureRequest: ISignatureRequest;
}

export function useCreateSignatureRequestMutation() {
  return useMutation<CreateSignatureRequestResult, CreateSignatureRequestInput>(CREATE_SIGNATURE_REQUEST, {
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          fields: {
            getSignatureRequests(existingSignatureRequestRefs = []) {
              const newSignatureRequests = [
                ...existingSignatureRequestRefs,
                cache.writeFragment({
                  data: data.createSignatureRequest,
                  fragment: SIGNATURE_REQUEST_FIELDS,
                }),
              ];
              return newSignatureRequests;
            },
          },
        });
      }
    },
  });
}

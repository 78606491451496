enum QuestionType {
  TEXT = "Text",
  FLOAT = "Float",
  DATE = "Date",
  CURRENCY = "Currency",
  SELECT_SOME = "SelectSome",
  SELECT_ONE = "SelectOne",
  BOOLEAN = "Boolean",
  MULTILINE_TEXT = "MultilineText",
  PERSON = "Person",
  COMPANY = "Company",
}

export default QuestionType;

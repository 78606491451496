import { DocumentNode } from '@legalosApi/types/IQuestionnaire';

export const getNodeText = (node: DocumentNode): string | undefined => {
  // traverses recursively through children until node of type="text" is found and
  // then returns the text of that node
  if (node.type === 'text') {
    return node.text;
  }
  if (node.children) {
    for (const child of node.children) {
      const text = getNodeText(child);
      if (text) {
        return text;
      }
    }
  }
};

export function findParentNodes(
  targetKey: string,
  nodes: DocumentNode[],
  path: DocumentNode[] = []
): DocumentNode[] | null {
  for (const node of nodes) {
    // Check if the current node is the target node
    if (node.key === targetKey) {
      return [...path, node];
    }

    // Recursively search in children nodes, if they exist
    if ('children' in node && node.children && node.children.length > 0) {
      const result = findParentNodes(targetKey, node.children, [...path, node]);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

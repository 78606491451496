import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { NavigationSection } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { calculateTopicStatus } from '@app/utils/topicStatus';
import { Button, Icon } from '@components/ui';
import { CloseButton } from '@components/ui/CloseButton/CloseButton';
import { UserTopicStatusApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import './status.sass';

export function Status() {
  const { t } = useTranslation();
  const { user } = useUser();
  const tasks = useTasks();
  const { navigateDashboard, navigation } = useNavigation();
  const checkUpTopic = user?.topics.find((topic) => topic.id === 'topic-checkup');
  const internalTopics =
    user?.topics.filter(
      (topic) =>
        topic.tags.some((tag) => tag.tag === 'internal') && topic.tags.every((tag) => tag.tag !== 'experimental')
    ) || [];
  const externalTopics =
    user?.topics.filter(
      (topic) =>
        topic.tags.some((tag) => tag.tag === 'external') && topic.tags.every((tag) => tag.tag !== 'experimental')
    ) || [];

  const handleClose = () => {
    navigateDashboard(NavigationSection.Overview);
  };

  // download the file /assets/images/dieter_badge_de.svg
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `/assets/images/dieter_badge_de.svg`;
    link.download = 'dieter_badge_de.svg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="dtRouteDashboard__status">
      <h3>{t('dashboard.status.header')}</h3>
      <div className="flex gap-20">
        <div className="flex flex-col gap-20 flex-3">
          <p>{t('dashboard.status.description')}</p>
          <img src="/assets/images/dieter_badge_de_temp.png" alt="Info" />
          <div className="flex flex-1 flex-col justify-between">
            <div>
              <Button disabled={navigation.progress !== 1} onClick={handleDownload}>
                {t('dashboard.status.download')}
              </Button>
            </div>
          </div>
        </div>
        <div className="flex-1" />
        <div className="flex-5">
          {user && (
            <div className="flex flex-col gap-10">
              <StatusSection topics={checkUpTopic ? [checkUpTopic] : []} lines={false} />
              <hr className="dashed-2" />
              <StatusSection
                topics={externalTopics}
                description={t('dashboard.status.outer.description')}
                title={t('dashboard.status.outer.title')}
                lines={externalTopics.some(
                  (topic) => calculateTopicStatus(topic, undefined, user.userValues, tasks) === 'COMPLETED'
                )}
              />
              <hr className="dashed-2" />
              <StatusSection
                topics={internalTopics}
                description={t('dashboard.status.inner.description')}
                title={t('dashboard.status.inner.title')}
                lines={internalTopics.some(
                  (topic) => calculateTopicStatus(topic, undefined, user.userValues, tasks) === 'COMPLETED'
                )}
              />
            </div>
          )}
        </div>
      </div>
      <CloseButton onClick={handleClose} data-testid="status-close-button" />
    </div>
  );
}

interface Props {
  topics: UserTopicStatusApplicationsQuestionnaires[];
  description?: string;
  title?: string;
  lines: boolean;
}

export function StatusSection({ topics, description, title, lines }: Props) {
  const { user } = useUser();
  const tasks = useTasks();
  const completeTopics = topics.filter(
    (topic) => calculateTopicStatus(topic, undefined, user?.userValues, tasks) === 'COMPLETED'
  );
  const incompleteTopics = topics.filter(
    (topic) => calculateTopicStatus(topic, undefined, user?.userValues, tasks) !== 'COMPLETED'
  );

  const topicDisplay = (topic: UserTopicStatusApplicationsQuestionnaires, idx: number) => {
    const topicIsComplete = calculateTopicStatus(topic, undefined, user?.userValues, tasks) === 'COMPLETED';
    // const isFirst = idx === 0;
    return (
      <div className="statusSectionItem flex gap-10" key={topic.id}>
        {/* {lines && topicIsComplete && !isFirst && <div className="vl vl-start" />} */}
        <div
          className={cx('statusSection__indicator flex', {
            indicatorDone: topicIsComplete,
          })}
          data-testid={`status-section-indicator-${topic.id}`}
        >
          <Icon type="check-50" scale={0.45} size={20} />
        </div>
        <div className="flex-1">{topic.title}</div>
      </div>
    );
  };

  return (
    <div className="dtRouteDashboard__statusSection flex gap-20">
      {/* {lines && <div className="vl vl-start" />} */}
      <div className="flex-1">
        <strong>{title}</strong>
        <div className="flex flex-col gap-20 mt-20">{completeTopics.map(topicDisplay)}</div>
        <div className="flex flex-col gap-20 mt-20">{incompleteTopics.map(topicDisplay)}</div>
      </div>
      <div className="flex-1">
        <p>{description}</p>
      </div>
    </div>
  );
}

import { useUserLogoutMutation } from '@dieterApi/user/useUserLogoutMutation';
import { useUserUpdateCompanyMutation } from '@dieterApi/user/useUserUpdateCompanyMutation';
import AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';
import { Tags } from '@legalosApi/types/Tags';
import { useQuestionaireRoute } from './useQuestionaireRoute';

export function useTags(): [(tags: Tags, value?: AllowedValueTypes) => Promise<boolean>] {
  const {
    jwt,
    currentParams: { questionnaireId },
  } = useQuestionaireRoute();
  const [updateCompany] = useUserUpdateCompanyMutation();
  const [logout] = useUserLogoutMutation();

  return [
    async (tags: Tags, value?: AllowedValueTypes): Promise<boolean> => {
      const proceed = tags.process.map((process) => {
        switch (process) {
          case 'CALENDLY':
          case 'ABORT':
            logout();
            return false;
          default:
            return true;
        }
      });
      if (proceed.includes(false)) {
        return false;
      }

      switch (tags.variable) {
        case 'COMPANY_NAME':
          // we do not update the company name if the questionnaire is edited via token
          value &&
            !jwt &&
            (await updateCompany({
              variables: { companyName: String(value), triggeredFromQuestionnaireId: questionnaireId },
            }));
          break;
        default:
          break;
      }
      return true;
    },
  ];
}

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  message: string | JSX.Element;
  title?: string | JSX.Element;
  acceptText?: string;
  onAccept: () => void;
  onCancel?: () => void;
}

export function AlertDialog({ open, setOpen, title, message, onAccept, onCancel, acceptText }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.ui.alert_dialog' });

  const handleClose = () => {
    onCancel && onCancel();
    setOpen(false);
  };

  const handleAccept = () => {
    onAccept();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="alert-dialog"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="alert-dialog-action-cancel" onClick={handleClose}>
            {t('actions.cancel')}
          </Button>
          <Button data-testid="alert-dialog-action-proceed" onClick={handleAccept} autoFocus>
            {acceptText || t('actions.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

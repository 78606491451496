import { User, UserFile } from '@dieterApi/user/useUserQuery';
import { PartnerData } from './PartnerListEntry';
import { ContractDirection, contractTypeMapping } from './typesAndEnums';

export const extractPartnerData = (user: User): PartnerData[] => {
  const partnerData: PartnerData[] = [];
  user.userValues.forEach((userValue) => {
    if (userValue.key === 'DATA_PROCESSOR_NAME') {
      const partnerName = userValue.value;
      const contractLocked =
        user.userValues.find((uv) => uv.key === 'DATA_PROCESSOR_CONTRACT_LOCKED' && uv.index === userValue.index)
          ?.value === 'true';
      const contractSigned =
        user.userValues.find((uv) => uv.key === 'DATA_PROCESSOR_CONTRACT_SIGNED' && uv.index === userValue.index)
          ?.value === 'true';
      const contractType = user.userValues.find(
        (uv) => uv.key === 'DATA_PROCESSOR_TYPE' && uv.index === userValue.index
      )?.value;
      const contractFileId = user.userValues.find(
        (uv) => uv.key === 'DATA_PROCESSOR_CONTRACTFILE' && uv.index === userValue.index
      )?.value;
      const direction = user.userValues.find(
        (uv) => uv.key === 'DATA_PROCESSOR_DIRECTION' && uv.index === userValue.index
      )?.value as ContractDirection | undefined;
      const isSubContractor = (user.userValues.find(
        (uv) => uv.key === 'DATA_PROCESSOR_ISSUBCONTRACTOR' && uv.index === userValue.index
      )?.value === 'true') as boolean | undefined;
      const tomsFileId = user.userValues.find(
        (uv) => uv.key === 'DATA_PROCESSOR_TOMS' && uv.index === userValue.index
      )?.value;

      const signedContractFileId = user.userValues.find(
        (uv) => uv.key === 'DATA_PROCESSOR_SIGNED_CONTRACT' && uv.index === userValue.index
      )?.value;

      let signedContractFile: UserFile | undefined;
      if (signedContractFileId) {
        const allFiles = user.topics.flatMap((topic) => topic.files);
        signedContractFile = allFiles.find((file) => file.id === signedContractFileId);
      }
      let contractFile: UserFile | undefined;
      if (contractFileId) {
        const allFiles = user.topics.flatMap((topic) => topic.files);
        contractFile = allFiles.find((file) => file.id === contractFileId);
      }
      let toms: UserFile | undefined;
      if (tomsFileId) {
        const allFiles = user.topics.flatMap((topic) => topic.files);
        toms = allFiles.find((file) => file.id === tomsFileId);
      }
      const contract = user.questionnaires?.find((q) => q.userValueIndex === userValue.index);
      partnerData.push({
        partnerName,
        contractType,
        contractLocked,
        contractSigned,
        contractFile,
        signedContractFile,
        contract,
        direction,
        index: userValue.index,
        isSubContractor,
        toms,
      });
    }
  });
  // only return partners where contractType is filled and which has not None
  return partnerData.filter((pd) => pd.contractType && Object.keys(contractTypeMapping).includes(pd.contractType));
};

// mutation for creating an error

import { gql, useMutation } from '@apollo/client';

const CREATE_ERROR = gql`
  mutation CreateError($message: String!, $stack: String!) {
    createError(message: $message, stack: $stack) {
      id
      createdAt
      message
      stack
    }
  }
`;

export interface ErrorCreateInput {
  message: string;
  stack: string;
}

export interface ErrorCreateResult {
  createError: UserError;
}

export interface UserError {
  id: string;
  createdAt: string;
  message: string;
  stack: string;
}

export function useErrorCreateMutation() {
  return useMutation<ErrorCreateResult, ErrorCreateInput>(CREATE_ERROR);
}

import {
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

import {
  QueryMap,
  QueryInputType,
  QueryOutputType,
  LegalOSQuery,
} from './types/queryNameToQueryAndTypes';

export const useLegalOSQuery = <T extends LegalOSQuery>(
  queryName: T,
  options?: QueryHookOptions<QueryOutputType<T>, QueryInputType<T>>
): QueryResult<QueryOutputType<T>, QueryInputType<T>> =>
  useQuery<QueryOutputType<T>, QueryInputType<T>>(QueryMap[queryName], options);

export const useLegalOSLazyQuery = <T extends LegalOSQuery>(
  queryName: T,
  options?: QueryHookOptions<QueryOutputType<T>, QueryInputType<T>>
): QueryTuple<QueryOutputType<T>, QueryInputType<T>> =>
  useLazyQuery<QueryOutputType<T>, QueryInputType<T>>(
    QueryMap[queryName],
    options
  );

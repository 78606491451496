import { gql, useMutation } from '@apollo/client';
import { USERVALUE_FIELDS, UserValue } from './useUserQuery';

const ADD_USER_VALUE = gql`
  ${USERVALUE_FIELDS}
  mutation AddUserValue($key: String!, $value: String!, $index: String, $createIndex: Boolean, $token: String) {
    addUserValue(key: $key, value: $value, index: $index, createIndex: $createIndex, token: $token) {
      ...UserValueFields
    }
  }
`;

export interface UserValueAddInput {
  key: string;
  value: string;
  index?: string;
  createIndex?: boolean;
  token?: string;
}

export interface UserValueAddResult {
  addUserValue: UserValue;
}

export interface UserValueAddContext {
  userId: string;
}

export function useUserValueAddMutation() {
  return useMutation<UserValueAddResult, UserValueAddInput, UserValueAddContext>(ADD_USER_VALUE, {
    refetchQueries: ['GetUser'],
    //   update(cache, { data }, { context }) {
    //     cache.modify({
    //       id: cache.identify({ __typename: "User", id: context?.userId }),
    //       fields: {
    //         userValues(cachedUserValueRefs: Reference[]) {
    //           const newUserValueRef = cache.writeFragment({
    //             data: data?.addUserValue,
    //             fragment: gql`
    //               fragment NewUserValue on UserValue {
    //                 id
    //                 key
    //                 value
    //                 index
    //                 createdAt
    //               }
    //             `,
    //           });
    //           return [...cachedUserValueRefs, newUserValueRef];
    //         },
    //       },
    //     });
    //   },
  });
}

import { useSnackbar } from '@app/hooks/useSnackbar';
import { AlertDialog, Icon } from '@components/ui';
import { useFileDeleteMutation } from '@dieterApi/file/useFileDeleteMutation';
import { useFileDownloadQuery } from '@dieterApi/file/useFileDownloadQuery';
import { UserFile } from '@dieterApi/user/useUserQuery';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './ui-fileitem.sass';

export interface Props {
  file: UserFile;
  withActions?: boolean;
}

export function FileItem({ file, withActions = true }: Props) {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [deleteFile, { data: deleteData }] = useFileDeleteMutation();
  const [downloadFile, { data: downloadData }] = useFileDownloadQuery();

  function handleDownload() {
    downloadFile({
      variables: {
        id: file.id,
      },
    });
  }

  function handleDelete() {
    deleteFile({ variables: { id: file.id } })
      .then(() => {
        setAlertOpen(false);
        enqueueSnackbar(t('components.ui.fileitem.success'), {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
          stack: e.stack,
        });
      });
  }

  return (
    <div className="dtUiFileItem flex flex-row gap-10">
      <div className="flex-1">{file.name}</div>
      <div className="flex">{formatBytes(file.size)} </div>
      {withActions && (
        <>
          <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={t('components.ui.fileitem.download')}>
            <button
              className="dtRouteDashboard__itemTitleAction"
              onClick={handleDownload}
              data-testid="file-download-button"
            >
              <Icon type="download-16" />
            </button>
          </Tooltip>
          <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={t('components.ui.fileitem.delete')}>
            <button
              className="dtRouteDashboard__itemTitleAction"
              onClick={() => setAlertOpen(true)}
              data-testid="file-delete-button"
            >
              <Icon type="trash-16" />
            </button>
          </Tooltip>
        </>
      )}
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        message={
          <Trans t={t} i18nKey="components.ui.fileitem.confirmdialog" values={{ 'file.name': file.name }}>
            Die Datei <strong>{file.name} </strong>
            wirklich löschen?
          </Trans>
        }
        onAccept={handleDelete}
      />
    </div>
  );
}

function formatBytes(bytes: number, decimals: number = 1): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

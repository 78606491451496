import { gql } from '@apollo/client';
import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';

export const QUESTIONNNAIRE_APP_CONTRACT_INSTANCE_CREATE = gql`
  mutation questionnaireAppCreateInstance($input: QuestionnaireAppCreateInstanceInput) {
    questionnaireAppCreateInstance(input: $input) {
      ...QuestionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

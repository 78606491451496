import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';

export function SwiperNavButtons() {
  const swiper = useSwiper();
  const [canSlidePrev, setCanSlidePrev] = useState(false);
  const [canSlideNext, setCanSlideNext] = useState(true);

  useEffect(() => {
    swiper.on('slideChange', (curr) => {
      if (curr.activeIndex === 0) {
        setCanSlidePrev(false);
      } else {
        setCanSlidePrev(true);
      }

      if (curr.activeIndex === curr.slides.length - 1) {
        setCanSlideNext(false);
      } else {
        setCanSlideNext(true);
      }
    });

    return () => swiper.off('slideChange');
  }, []);

  return (
    <div className="sm:hidden absolute inset-x-0 top-0 z-10 flex items-center justify-between">
      <IconButton size="large" onClick={() => swiper.slidePrev()} disabled={!canSlidePrev || !swiper.allowSlidePrev}>
        <ChevronLeftIcon fontSize="large" />
      </IconButton>
      <IconButton size="large" onClick={() => swiper.slideNext()} disabled={!canSlideNext || !swiper.allowSlideNext}>
        <ChevronRightIcon fontSize="large" />
      </IconButton>
    </div>
  );
}

import { CreateTypes } from 'canvas-confetti';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const canvasStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 9999,
};

interface ConfettiProps {
  shouldFire: boolean;
  bigBlast?: boolean;
  onAnimationStart?: () => void;
  onAnimationComplete?: () => void;
}

const Confetti: React.FC<ConfettiProps> = ({ shouldFire, bigBlast = false, onAnimationComplete, onAnimationStart }) => {
  const refAnimationInstance = useRef<CreateTypes | null>(null);

  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback(
    (particleRatio: number, opts: object) => {
      refAnimationInstance.current?.({
        ...opts,
        origin: { y: Math.random(), x: Math.random() }, // Randomize x origin for a wider spread
        particleCount: Math.floor(bigBlast ? 500 * particleRatio : 200 * particleRatio),
      });
    },
    [bigBlast]
  );

  const fire = useCallback(() => {
    if (shouldFire) {
      onAnimationStart?.();
      const options = bigBlast
        ? {
            spread: 180,
            startVelocity: 75,
            scalar: 1.2,
          }
        : {
            spread: 120,
            startVelocity: 45,
          };

      makeShot(0.25, options);
      makeShot(0.2, options);
      makeShot(0.35, options);
      makeShot(0.1, options);
      makeShot(0.1, options);

      if (onAnimationComplete) {
        setTimeout(onAnimationComplete, bigBlast ? 6000 : 4000);
      }
    }
  }, [shouldFire, bigBlast, onAnimationComplete, makeShot]);

  useEffect(() => {
    fire();
  }, [fire]);

  return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
};

export default Confetti;

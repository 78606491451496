import { OnboardingApps } from '@app/context/constants';
import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button } from '@components/ui';
import { UserQuestionnaireApplicationTopic } from '@dieterApi/user/useUserQuery';
import CheckIcon from '@mui/icons-material/Check';
import { Divider, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  questionnaire: UserQuestionnaireApplicationTopic;
}

type Snippet = { status_label: React.ReactElement; intro: React.ReactElement };

function TicketCallToAction({ questionnaire }: Props) {
  const { user } = useUser();
  const history = useHistory();
  const {
    setNavigation,
    navigation: { onboarding },
  } = useNavigation();
  const { t } = useTranslation();

  const handleClick = () => {
    setNavigation((nav) => {
      nav.documentPaper = {
        modalOpen: true,
        questionnaire,
        index: 0,
      };
    });
  };

  const appId = onboarding.appId || OnboardingApps.CHECKUP;

  const Snippets: Record<OnboardingApps, Snippet> = {
    [OnboardingApps.CHECKUP]: {
      intro: <></>,
      status_label: <></>,
    },
    [OnboardingApps.DSE]: {
      intro: (
        <Trans t={t} i18nKey="dashboard.topic.TicketCallToAction.dse.intro">
          <p>
            Du befindest dich nun in deinem Dashboard. Schalte diesen Bereich jetzt frei, um
            <Link to="#" onClick={handleClick}>
              deine Datenschutzerklärung
            </Link>
            einzubinden und im Anschluss das Impressum für deine Website zu erstellen.
          </p>
        </Trans>
      ),
      status_label: (
        <Trans
          t={t}
          i18nKey="dashboard.topic.TicketCallToAction.dse.status_label"
          components={{ bold: <strong /> }}
          shouldUnescape
        />
      ),
    },
    [OnboardingApps.TOMs]: {
      intro: (
        <Trans t={t} i18nKey="dashboard.topic.TicketCallToAction.toms.intro">
          <p>
            Du befindest dich nun in deinem Dashboard. Schalte diesen Bereich jetzt frei, um{' '}
            <Link to="#" onClick={handleClick}>
              die Dokumentation deiner TOMs
            </Link>{' '}
            freizuschalten und im Anschluss das Berechtigungskonzept für dein Unternehmen zu erstellen.
          </p>
        </Trans>
      ),
      status_label: (
        <Trans
          t={t}
          i18nKey="dashboard.topic.TicketCallToAction.toms.status_label"
          components={{ bold: <strong /> }}
          shouldUnescape
        />
      ),
    },
    [OnboardingApps.IMPRINT]: {
      intro: <></>,
      status_label: <></>,
    },
    [OnboardingApps.VVT]: {
      intro: (
        <Trans t={t} i18nKey="dashboard.topic.TicketCallToAction.vvt.intro">
          <p>
            Du befindest dich nun in deinem Dashboard. Schalte diesen Bereich jetzt frei, um{' '}
            <Link to="#" onClick={handleClick}>
              die Dokumentation deiner TOMs
            </Link>{' '}
            freizuschalten und im Anschluss das Berechtigungskonzept für dein Unternehmen zu erstellen.
          </p>
        </Trans>
      ),
      status_label: (
        <Trans
          t={t}
          i18nKey="dashboard.topic.TicketCallToAction.vvt.status_label"
          components={{ bold: <strong /> }}
          shouldUnescape
        />
      ),
    },
    [OnboardingApps.AVV]: {
      intro: (
        <Trans t={t} i18nKey="dashboard.topic.TicketCallToAction.avv.intro">
          <p>
            Du befindest dich nun in deinem Dashboard. Schalte diesen Bereich jetzt frei, um{' '}
            <Link to="#" onClick={handleClick}>
              deinen Auftragsverarbeitungsvertrag
            </Link>{' '}
            freizuschalten.
          </p>
        </Trans>
      ),
      status_label: (
        <Trans
          t={t}
          i18nKey="dashboard.topic.TicketCallToAction.avv.status_label"
          components={{ bold: <strong /> }}
          shouldUnescape
        />
      ),
    },
    [OnboardingApps.INFO]: {
      intro: (
        <Trans t={t} i18nKey="dashboard.topic.TicketCallToAction.info.intro">
          <p>
            Du befindest dich nun in deinem Dashboard. Schalte diesen Bereich jetzt frei, um{' '}
            <Link to="#" onClick={handleClick}>
              deine Datenschutzhinweise
            </Link>{' '}
            freizuschalten.
          </p>
        </Trans>
      ),
      status_label: (
        <Trans
          t={t}
          i18nKey="dashboard.topic.TicketCallToAction.info.status_label"
          components={{ bold: <strong /> }}
          shouldUnescape
        />
      ),
    },
  };

  const pricingProduct: Record<OnboardingApps, string> = {
    [OnboardingApps.DSE]: 'dse',
    [OnboardingApps.TOMs]: 'toms',
    [OnboardingApps.CHECKUP]: '',
    [OnboardingApps.IMPRINT]: 'dse',
    [OnboardingApps.VVT]: 'vvt',
    [OnboardingApps.AVV]: 'avv',
    [OnboardingApps.INFO]: '',
  };

  const handleBuyClick = () => {
    if (user?.company.shopifyCallbackUrl) {
      // Redirect to Shopify
      window.location.href = user?.company.shopifyCallbackUrl;
    } else {
      const product = pricingProduct[appId];
      history.push(`/pricing?option=${product ? 'ticket' : 'basic'}&product=${product}`);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {Snippets[appId].intro}

      {/* centerpiece with img */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7 place-items-center bg-body px-12 pt-5">
        <div className="flex flex-column items-center gap-y-6 pb-5">
          <Typography variant="h5" color="text.secondary" align="center">
            {Snippets[appId].status_label}
          </Typography>
          <div className="h-10 w-10 rounded-full grid place-items-center bg-success text-white">
            <CheckIcon />
          </div>
          <Button onClick={handleBuyClick}>{t('dashboard.topic.TicketCallToAction.actions.unlock_document')}</Button>
        </div>
        <img src="/assets/images/DSE_erledigt_01.png" alt="dse erledigt" className="h-[200px] !w-auto" />
      </div>
      <Divider />
    </div>
  );
}

export default TicketCallToAction;

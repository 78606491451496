import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

export function MFANotice() {
  const { user } = useUser();
  const { t } = useTranslation();
  const { navigation, setNavigation } = useNavigation();
  const shouldShow = !user?.mfaEnabled && !navigation.mfaNotificationDismissed && user?.isSubscriber;
  // show message if user is a subscriber and has not enabled mfa and has not dismissed the message
  const [show, setShow] = useState(false);

  const props = useSpring({
    height: show ? 40 : 0, // Adjust the height based on 'show'
    opacity: show ? 1 : 0,
    overflow: 'hidden',
  });

  // show the message after 15 seconds
  useEffect(() => {
    if (shouldShow) {
      setTimeout(() => {
        setShow(true);
      }, 15000);
    }
  }, []);

  const handleClose = () => {
    setNavigation((nav) => void (nav.mfaNotificationDismissed = true));
    setShow(false);
  };

  return (
    <animated.div
      style={props}
      className="absolute bg-secondary-root top-0 px-2 md:px-9 flex flex-col items-center w-full justify-center"
    >
      <span>
        <Trans t={t} i18nKey={'route.dashboard.mfanotice'}>
          Jetzt Konto absichern und <Link to="/mfa">Multi-Faktor-Authentifizierung</Link> einrichten.
        </Trans>
      </span>
      <div className="absolute right-2 top-1/2 -translate-y-1/2">
        <button
          className="text-gray-600 hover:text-gray-700"
          onClick={() => handleClose()} // Set show to false to trigger the animation
        >
          <CloseIcon fontSize="small" />
        </button>
      </div>
    </animated.div>
  );
}

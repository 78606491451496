import { UserTopicStatusApplicationsQuestionnaires } from '@dieterApi/user/useUserQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Props {
  topic: UserTopicStatusApplicationsQuestionnaires;
}

export function MarketingTile({ topic }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    let pricingParam = '';
    switch (topic.id) {
      case 'topic-dse':
        pricingParam = '?option=ticket&product=dse';
        break;
      case 'topic-toms':
        pricingParam = '?option=ticket&product=toms';
        break;
      default:
        pricingParam = '?option=basic';
    }

    history.push('/pricing' + pricingParam);
  };

  return (
    <div
      className="group w-full h-full bg-secondary-50 border-secondary-root border-4 relative min-h-[190px] p-8 flex flex-col justify-center cursor-pointer hover:border-secondary-600 transition duration-300"
      onClick={handleClick}
    >
      <img
        src="/assets/images/upgradebanner.png"
        className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 !w-[180px]"
      />
      <span className="text-lg text-secondary-800">
        <Trans t={t} i18nKey="components.ui.marketingtile">
          Schalte jetzt diesen Bereich vollständig frei um dein Unternehmen <strong>DSGVO-konform</strong> aufzustellen!
        </Trans>
      </span>
      <div className="h-10 w-10 bg-secondary-root flex items-center justify-center rounded-tl-md text-white absolute -right-1 -bottom-1 group-hover:bg-secondary-600 transition duration-300">
        <ArrowForwardIosIcon />
      </div>
    </div>
  );
}

import { gql, useMutation } from '@apollo/client';
import { User, USER_FIELDS } from './useUserQuery';

const UPDATE_USER_NAVSTATE = gql`
  ${USER_FIELDS}
  mutation UpdateUserNavState($navState: String!) {
    updateUserNavState(navState: $navState) {
      ...UserFields
    }
  }
`;

export interface UpdateUserNavStateResult {
  updateUserNavState: User;
}

export interface UpdateUserNavStateInput {
  navState: string;
}

export function useUserUpdateNavStateMutation() {
  return useMutation<UpdateUserNavStateResult, UpdateUserNavStateInput>(UPDATE_USER_NAVSTATE, {
    // fetchPolicy: 'no-cache',
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => (data && data.updateUserNavState ? data.updateUserNavState : null),
        },
      });
    },
  });
}

import { AlertDialog, Button, Loading, ThreeDots } from '@components/ui';
import { CheckCircle, HourglassEmpty } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';

import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { ThreeDotItem } from '@components/ui/ThreeDots/ThreeDots.tsx';
import { UserRoleChip } from '@components/ui/UserRoleChip/UserRoleChip';
import { useUserDeleteMutation } from '@dieterApi/user/useUserDeleteMutation';
import { useInviteUserMutation } from '@dieterApi/user/useUserInviteMutation';
import { UserRole } from '@dieterApi/user/useUserQuery';
import { useUpdateUserMutation } from '@dieterApi/user/useUserUpdateMutation';
import Cancel from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Trans, useTranslation } from 'react-i18next';
import UserManagementCreateModal from './UserManagementCreateModal';
import UserManagementUpdateModal from './UserManagementUpdateModal';

interface UserData {
  id: string;
  createdAt: string;
  email: string;
  confirmed: boolean;
  role: UserRole;
  lastLogin: string;
  companies?: { id: string; name: string }[];
}

function UserManagementTable() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();

  // only show unique users (by id)
  const data: UserData[] =
    user?.companies.flatMap((c) => c.users).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i) || [];
  // now for every user add a subitem .companies with [{id, name}] for each company this user belongs to
  data?.forEach((u, i) => {
    data[i] = {
      ...u,
      companies: user?.companies
        .filter((c) => c.users.some((cu) => cu.id === u.id))
        .map((c) => ({ name: c.name, id: c.id })),
    };
  });

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<any[] | null>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState<any[] | null>(null);

  const [inviteUser] = useInviteUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useUserDeleteMutation();

  const handleAdd = useAsyncCallback(async (values: { email: string; role: UserRole; enabledCompanies: string[] }) => {
    inviteUser({
      variables: {
        email: values.email,
        role: values.role,
        enabledCompanies: values.enabledCompanies,
      },
    })
      .then(() => {
        enqueueSnackbar(t('route.user-management.invite-success'), { variant: 'success' });
        setOpenCreateModal(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  });

  const handleEdit = useAsyncCallback(async (role: UserRole, enabledCompanies: string[]) => {
    updateUser({
      variables: {
        userId: openUpdateModal?.[0],
        role,
        enabledCompanies,
      },
    })
      .then(() => {
        enqueueSnackbar(t('route.user-management.update-success'), { variant: 'success' });
        setOpenUpdateModal(null);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  });
  const handleDelete = useAsyncCallback(async () => {
    deleteUser({
      variables: {
        userId: openDeleteModal?.[0],
      },
    })
      .then(() => {
        enqueueSnackbar(t('route.user-management.delete-success'), { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });

    setOpenDeleteModal(null);
  });

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'id',
      label: 'ID',
      options: { display: 'excluded' },
    },
    {
      name: 'email',
      label: t('route.user-management.table.header.email', 'Email'),
    },
    {
      name: 'role',
      label: t('route.user-management.table.header.role', 'Role'),
      options: {
        customBodyRender: (value: UserRole) => {
          return <UserRoleChip role={value} />;
        },
      },
    },
    {
      name: 'companies',
      label: t('route.user-management.table.header.activecompanies', 'Aktive Mandanten'),
      options: {
        customBodyRender: (value: { id: string; name: string }[]) => {
          const hasAll = value.length === user?.companies.length;
          return hasAll ? t('common.all') : value.map((c) => c.name).join(', ');
        },
      },
    },
    {
      name: 'confirmed',
      label: t('route.user-management.table.header.inviteStatus', 'Invite Status'),
      options: {
        customBodyRender: (value: boolean) => {
          const opts = (
            {
              pending: {
                label: t('route.user-management.inviteStatus.Pending', 'Pending'),
                hint: t('route.user-management.invite-status-hint.pending', 'waiting for user to accept the invite'),
                icon: HourglassEmpty,
              },
              active: {
                label: t('route.user-management.inviteStatus.Active', 'Active'),
                hint: t('route.user-management.invite-status-hint.active', 'user has already accepted the invite'),
                icon: CheckCircle,
              },
            } as const
          )[value ? 'active' : 'pending'];

          return (
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={opts.hint} placement="bottom">
              <div className="inline-flex items-center gap-1 cursor-help">
                <opts.icon fontSize="small" color={value ? 'success' : 'warning'} />
                <span className="font-medium underline text-sm">{opts.label}</span>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'mfaEnabled',
      label: 'MFA',
      options: {
        customBodyRender: (value: boolean) => {
          const opts = (
            {
              disabled: {
                label: t('common.inactive'),
                hint: t('route.user-management.mfa.inactive'),
                icon: Cancel,
              },
              enabled: {
                label: t('route.user-management.inviteStatus.Active', 'Active'),
                hint: t('route.user-management.mfa.active'),
                icon: CheckCircle,
              },
            } as const
          )[value ? 'enabled' : 'disabled'];

          return (
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={opts.hint} placement="bottom">
              <div className="inline-flex items-center gap-1 cursor-help">
                <opts.icon fontSize="small" color={value ? 'success' : 'warning'} />
                <span className="font-medium underline text-sm">{opts.label}</span>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'lastLogin',
      label: t('route.user-management.table.header.lastLogin', 'Last Login'),
      options: {
        customBodyRender: (value: string) =>
          value ? DateTime.fromJSDate(new Date(value)).setLocale(i18n.language.slice(0, 2)).toRelative() : '-',
      },
    },
    {
      name: 'createdAt',
      label: t('route.user-management.table.header.createdAt', 'Created At'),
      options: {
        customBodyRender: (value) => {
          return new Date(value).toLocaleString();
        },
      },
    },
    {
      name: 'actions',
      label: t('route.user-management.table.header.actions', 'Aktionen'),
      options: {
        customHeadLabelRender: () => '',
        customBodyRender: (value, tableMeta) => {
          const userId = tableMeta.rowData[0];
          if (user?.id === userId) {
            return null;
          }
          const items: ThreeDotItem[] = [
            {
              label: (
                <div className="flex gap-1 items-center">
                  <EditIcon fontSize="inherit" />
                  <span className="grow">{t('common.edit')}</span>
                </div>
              ),
              onClick: () => setOpenUpdateModal(tableMeta.rowData),
            },
            {
              label: (
                <div className="flex gap-1 items-center">
                  <DeleteIcon fontSize="inherit" />
                  <span className="grow">{t('common.delete')}</span>
                </div>
              ),
              onClick: () => setOpenDeleteModal(tableMeta.rowData),
            },
          ];

          return <ThreeDots items={items} />;
        },
      },
    },
  ];
  const options: MUIDataTableOptions = {
    customToolbar: () => (
      <Button
        className="font-normal m-2.5 max-w-[60%]"
        onClick={() => setOpenCreateModal(true)}
        icon={<AddIcon />}
        disabled={user?.role !== UserRole.Admin}
      >
        {t('common.add', 'hinzufügen')}
      </Button>
    ),
    sortOrder: { name: 'createdAt', direction: 'asc' },
    download: false,
    print: false,
    elevation: 0,
    selectableRows: 'none',
    selectableRowsHeader: false,
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
  };

  return (
    <>
      <div>{data ? <MUIDataTable columns={columns} data={data} title="" options={options} /> : <Loading />}</div>
      <UserManagementCreateModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onSubmit={handleAdd.execute}
      />
      {openUpdateModal && (
        <UserManagementUpdateModal
          open={!!openUpdateModal}
          userValues={openUpdateModal}
          onClose={() => setOpenUpdateModal(null)}
          onSubmit={handleEdit.execute}
        />
      )}
      <AlertDialog
        open={!!openDeleteModal}
        setOpen={() => setOpenDeleteModal(null)}
        message={
          <Trans
            t={t}
            i18nKey="route.user-management.delete-confirmation-message"
            values={{ email: openDeleteModal?.[1] }}
          >
            Möchten Sie diesen Benutzer{' '}
            <b>
              {{
                // @ts-ignore
                email: openDeleteModal?.[1],
              }}
            </b>{' '}
            wirklich löschen?
          </Trans>
        }
        onAccept={handleDelete.execute}
      />
    </>
  );
}

export default UserManagementTable;

import { gql, useQuery } from '@apollo/client';

export const GET_WIX_PRICE = gql`
  query GetWixPrice {
    getWixPrice {
      monthly
      yearly
    }
  }
`;

export interface WixPrice {
  monthly: number;
  yearly: number;
}

export interface GetWixPriceResult {
  getWixPrice: WixPrice;
}

export function useWixPriceQuery() {
  return useQuery<GetWixPriceResult>(GET_WIX_PRICE);
}

import { gql } from '@apollo/client';
import { questionnaireFragment } from '@legalosApi/graphql/fragments/fragments';

export const QUESTIONS_UPDATE = gql`
  mutation questionsUpdate($input: QuestionsUpdateInput) {
    questionsUpdate(input: $input) {
      ...QuestionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

import { useUser } from "@app/context/UserContext";
import { NavigationSection, Topics } from "@app/context/constants";
import { sectionUrl, topicUrl } from "@app/context/utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Dashboard } from "../useDashboard";
import { Topic } from "./Topic";
import SentryRoute from "@components/SentryRoute.ts";

interface Props {
  dashboard: Dashboard;
}

export function TopicRoutes({ dashboard }: Props) {
  const { path } = useRouteMatch();
  const { user } = useUser();

  return (
    <Switch>
      {Object.entries(Topics).map(([key, value]) => {
        const thisTopic = user?.topics.find((t) => t.id === value);
        if (!thisTopic) return null;
        return (
          <SentryRoute key={key} path={`${path}${topicUrl(value)}`}>
            <Topic topic={thisTopic} dashboard={dashboard} />
          </SentryRoute>
        );
      })}
      <SentryRoute path={`${path}`}>
        <Redirect to={`/dashboard/${sectionUrl(NavigationSection.Overview)}`} />
      </SentryRoute>
    </Switch>
  );
}

import { Input } from '@components/ui/Input/Input';
import React from 'react';
import { Props } from './QuestionInput';

interface CurrencyProps extends Props<number> {
  unit?: string;
}

export function Currency({ disabled, onChange, value, unit = '€' }: CurrencyProps) {
  const ref = React.createRef<HTMLInputElement>();
  React.useEffect(() => {
    const { current } = ref;
    if (current) current.focus();
  });

  return (
    <Input
      disabled={disabled}
      onChange={(e) => onChange(parseFloat(e.target.value))}
      ref={ref}
      type="number"
      unit={unit}
      value={value}
    />
  );
}

import { gql, useMutation } from '@apollo/client';
import { USER_FIELDS, User } from './useUserQuery';

const UPDATE_USER = gql`
  ${USER_FIELDS}
  mutation UpdateUser($userId: ID!, $role: String, $enabledCompanies: [String!]!) {
    updateUser(userId: $userId, role: $role, enabledCompanies: $enabledCompanies) {
      ...UserFields
    }
  }
`;

export interface UserUpdateInput {
  userId: string;
  role?: string;
  enabledCompanies: string[];
}

export interface UserUpdateResult {
  updateUser: User;
}

export function useUpdateUserMutation() {
  return useMutation<UserUpdateResult, UserUpdateInput>(UPDATE_USER, {
    refetchQueries: ['GetUser'],
  });
}

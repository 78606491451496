import { useUser } from '@app/context/UserContext';
import { AdvanceCallback, UpdateQuestionCallback } from '@components/block/Quest/Quest';
import { MessageStep, QuestionStep } from '@components/block/Quest/utils';
import AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';
import { IQuestionnaire } from '@legalosApi/types/IQuestionnaire';
import { ISelectOption } from '@legalosApi/types/ISelectOption';
import QuestionType from '@legalosApi/types/QuestionType';
import { useNavigation } from './useNavigation';

export function useUserValues() {
  const { user } = useUser();
  const {
    navigation: { expertModeEnabled },
  } = useNavigation();
  return {
    updateFromUserValue(
      step: QuestionStep | MessageStep,
      questionnaire: IQuestionnaire,
      onUpdateQuestion: UpdateQuestionCallback,
      onAdvance: AdvanceCallback,
      setValue: (value: AllowedValueTypes) => void
    ) {
      const thisStep = step;
      const question = thisStep.question;
      const variable = question.tags.variable;
      const isAuto = question.tags.process.includes('AUTO');
      const isExpertMode = question.tags.process.includes('INFO') && expertModeEnabled;
      const isSemiAuto = question.tags.process.includes('SEMIAUTO');

      if (!user) return false;

      if (isExpertMode) {
        onUpdateQuestion(question._id, question.options[0].value, true).then(() => onAdvance(step.id));
        return true;
      }

      if (!variable) return false;
      if (!isAuto && !isSemiAuto) return false;

      let index: string | undefined;
      if (question.tags.process.includes('INDEXED_VARIABLE')) {
        index = user?.questionnaires?.find((q) => q.questionnaireId === questionnaire._id)?.userValueIndex;
      }
      const answer = user && variable && user.getValue(variable, index);

      console.log('answer', answer);

      if (!answer) return false;

      switch (question.type) {
        case QuestionType.TEXT:
        case QuestionType.MULTILINE_TEXT:
          isAuto && onUpdateQuestion(question._id, answer, true).then(() => onAdvance(step.id));
          isSemiAuto && setValue(answer);
          break;
        case QuestionType.SELECT_ONE:
          const option = question.options.find((o) => o.label === answer || o.origLabel === answer);
          if (option) {
            isAuto && onUpdateQuestion(question._id, option.value, true).then(() => onAdvance(step.id));
            isSemiAuto && setValue(option.value);
          } else {
            return false;
          }
          break;
        case QuestionType.SELECT_SOME:
          let answers: string[] = [];
          try {
            answers = JSON.parse(answer);
          } catch (error) {
            return false;
          }
          const options = (
            answers
              .map((a) => question.options.find((o) => o.label === a || o.origLabel === a))
              .filter(Boolean) as ISelectOption[]
          ).map((o) => o.value);
          if (options.length) {
            isAuto && onUpdateQuestion(question._id, options, true).then(() => onAdvance(step.id));
            isSemiAuto && setValue(options);
          } else {
            return false;
          }
          break;
        case QuestionType.DATE:
          // check if the date is a string of the following form with regex
          // {"day":8,"month":1,"year":2024}
          const dateMatch = answer.match(/{"day":\d+,"month":\d+,"year":\d+}/);

          if (dateMatch) {
            isAuto && onUpdateQuestion(question._id, dateMatch[0], true).then(() => onAdvance(step.id));
            isSemiAuto && setValue(JSON.parse(dateMatch[0]));
          } else {
            return false;
          }
          break;
        default:
          const type = question.type;
          break;
      }
      return isSemiAuto ? false : true;
    },
  };
}

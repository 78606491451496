import { DocumentNode } from '@apollo/client';
import { QUESTION_LIST_REMOVE_ELEMENT } from '@legalosApi/hooks/questionListRemoveElement';
import { IQuestionsUpdateInput } from '@legalosApi/hooks/useDebouncedUpdate';
import { ENTITY_CREATE } from '../gql/entityCreate';
import { ENTITY_UPDATE } from '../gql/entityUpdate';
import { QUESTION_LIST_INSERT_ELEMENT } from '../gql/questionListInsertElement';
import { QUESTIONNNAIRE_APP_CONTRACT_INSTANCE_CREATE } from '../gql/questionnaireAppContractInstanceCreate';
import { QUESTIONNAIRE_DOWNLOAD_DOCX } from '../gql/questionnaireDownloadDocx';
import { QUESTIONNAIRE_DOWNLOAD_PDF } from '../gql/questionnaireDownloadPdf';
import { QUESTIONNAIRE_UPDATE } from '../gql/questionnaireUpdate';
import { QUESTIONS_UPDATE } from '../gql/questionsUpdate';
import { TOGGLE_CALCULATED_QUESTION_STATE } from '../gql/toggleCalculatedQuestionState';
import { TOGGLE_SHARED_QUESTION_STATE } from '../gql/toggleSharedQuestionState';
import type IEntityCreateData from './data/IEntityCreateData';
import type IEntityUpdateData from './data/IEntityUpdateData';
import type IQuestionCalculatedStateUpdateData from './data/IQuestionCalculatedStateUpdateData';
import type IQuestionListInsertElementData from './data/IQuestionListInsertElementData';
import type IQuestionListRemoveElementData from './data/IQuestionListRemoveElementData';
import type IQuestionnaireAppContractInstanceCreateData from './data/IQuestionnaireAppContractInstanceCreateData';
import type IQuestionnaireDownloadDocxData from './data/IQuestionnaireDownloadDocxData';
import IQuestionnaireDownloadPdfData from './data/IQuestionnaireDownloadPdfData';
import type IQuestionnaireUpdateData from './data/IQuestionnaireUpdateData';
import type IQuestionSharedStateUpdateData from './data/IQuestionSharedStateUpdateData';
import type IEntityCreateInput from './input/IEntityCreateInput';
import type IEntityUpdateInput from './input/IEntityUpdateInput';
import type IQuestionCalculatedStateUpdateInput from './input/IQuestionCalculatedStateUpdateInput';
import type IQuestionListInsertElementInput from './input/IQuestionListInsertElementInput';
import type IQuestionListRemoveElementInput from './input/IQuestionListRemoveElementInput';
import type IQuestionnaireAppContractInstanceCreateInput from './input/IQuestionnaireAppContractInstanceCreateInput';
import type IQuestionnaireDownloadDocxInput from './input/IQuestionnaireDownloadDocxInput';
import IQuestionnaireDownloadPdfInput from './input/IQuestionnaireDownloadPdfInput';
import type IQuestionnaireUpdateInput from './input/IQuestionnaireUpdateInput';
import type IQuestionSharedStateUpdateInput from './input/IQuestionSharedStateUpdateInput';

export const ALL_MUTATIONS = [
  'entityCreate',
  'entityUpdate',
  'questionCalculatedStateUpdate',
  'questionSharedStateUpdate',
  'questionListInsertElement',
  'questionListRemoveElement',
  'questionsUpdate',
  'questionnaireDownloadDocx',
  'questionnaireDownloadPdf',
  'questionnaireUpdate',
  'questionnaireAppContractInstanceCreate',
] as const;

export type LegalOSMutation = typeof ALL_MUTATIONS[number];

export type MutationInputType<T extends LegalOSMutation> = T extends 'entityCreate'
  ? IEntityCreateInput
  : T extends 'entityUpdate'
  ? IEntityUpdateInput
  : T extends 'questionCalculatedStateUpdate'
  ? IQuestionCalculatedStateUpdateInput
  : T extends 'questionSharedStateUpdate'
  ? IQuestionSharedStateUpdateInput
  : T extends 'questionListInsertElement'
  ? IQuestionListInsertElementInput
  : T extends 'questionListRemoveElement'
  ? IQuestionListRemoveElementInput
  : T extends 'questionsUpdate'
  ? IQuestionsUpdateInput
  : T extends 'questionnaireDownloadDocx'
  ? IQuestionnaireDownloadDocxInput
  : T extends 'questionnaireDownloadPdf'
  ? IQuestionnaireDownloadPdfInput
  : T extends 'questionnaireUpdate'
  ? IQuestionnaireUpdateInput
  : T extends 'questionnaireAppContractInstanceCreate'
  ? IQuestionnaireAppContractInstanceCreateInput
  : never;

export type MutationOutputType<T extends LegalOSMutation> = T extends 'entityCreate'
  ? IEntityCreateData
  : T extends 'entityUpdate'
  ? IEntityUpdateData
  : T extends 'questionCalculatedStateUpdate'
  ? IQuestionCalculatedStateUpdateData
  : T extends 'questionSharedStateUpdate'
  ? IQuestionSharedStateUpdateData
  : T extends 'questionListInsertElement'
  ? IQuestionListInsertElementData
  : T extends 'questionListRemoveElement'
  ? IQuestionListRemoveElementData
  : T extends 'questionsUpdate'
  ? IQuestionsUpdateInput
  : T extends 'questionnaireDownloadDocx'
  ? IQuestionnaireDownloadDocxData
  : T extends 'questionnaireDownloadPdf'
  ? IQuestionnaireDownloadPdfData
  : T extends 'questionnaireUpdate'
  ? IQuestionnaireUpdateData
  : T extends 'questionnaireAppContractInstanceCreate'
  ? IQuestionnaireAppContractInstanceCreateData
  : never;

export const MutationMap: Record<LegalOSMutation, DocumentNode> = {
  entityCreate: ENTITY_CREATE,
  entityUpdate: ENTITY_UPDATE,
  questionCalculatedStateUpdate: TOGGLE_CALCULATED_QUESTION_STATE,
  questionSharedStateUpdate: TOGGLE_SHARED_QUESTION_STATE,
  questionListInsertElement: QUESTION_LIST_INSERT_ELEMENT,
  questionListRemoveElement: QUESTION_LIST_REMOVE_ELEMENT,
  questionsUpdate: QUESTIONS_UPDATE,
  questionnaireDownloadDocx: QUESTIONNAIRE_DOWNLOAD_DOCX,
  questionnaireDownloadPdf: QUESTIONNAIRE_DOWNLOAD_PDF,
  questionnaireUpdate: QUESTIONNAIRE_UPDATE,
  questionnaireAppContractInstanceCreate: QUESTIONNNAIRE_APP_CONTRACT_INSTANCE_CREATE,
};

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './terms.sass';

// TODO/WM/ASK: it seems that event the links content should be dynamic based on the curr lang

export function Terms() {
  const { t } = useTranslation();
  return (
    <div className="2xl:container p-6">
      <h1 className="text-3xl font-medium" data-testid="header-terms">
        {t('route.terms.header')}
      </h1>

      <p>{t('route.terms.p1')}</p>
      <p>
        {t('route.terms.p2')}{' '}
        <Link to={{ pathname: 'https://www.bitkom.org/Bitkom/Ueber-uns' }} target="_blank">
          https://www.bitkom.org/Bitkom/Ueber-uns
        </Link>
        )
      </p>
      <ul className="list-disc">
        <li>
          <a
            href="https://dieterfilestorage.blob.core.windows.net/public/AGBs%20SaaS_Bitkom.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('route.terms.a1')}
          </a>
        </li>
        <li>
          <a
            href="https://dieterfilestorage.blob.core.windows.net/public/AGBs%20Dieter.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('route.terms.a2')}
          </a>
        </li>
        <li>
          <a
            href="https://dieterfilestorage.blob.core.windows.net/public/Dieter%20Hauptvertrag.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('route.terms.a3')}
          </a>
        </li>
        <li>
          <a
            href="https://dieterfilestorage.blob.core.windows.net/public/Dieter%20Leistungsbeschreibung.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('route.terms.a4')}
          </a>
        </li>
      </ul>
    </div>
  );
}

import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useDashboard } from '@app/routes/Dashboard/useDashboard';
import { ThreeDots } from '@components/ui';
import { ThreeDotItem } from '@components/ui/ThreeDots/ThreeDots';
import {
  ApplicationType,
  UserFile,
  UserQuestionnaireApplicationTopic,
  UserTopicStatusApplicationsQuestionnaires,
} from '@dieterApi/user/useUserQuery';
import ApprovalIcon from '@mui/icons-material/Approval';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import EjectIcon from '@mui/icons-material/Eject';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControl, IconButton, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import cx from 'classnames';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { AlertDialog } from '../../../../../components/ui/AlertDialog/AlertDialog';
import { Loading } from '../../../../../components/ui/Loading/Loading';
import { Spinner } from '../../../../../components/ui/Spinner/Spinner';

import { getTopicStates } from '@app/utils/getTopicStates';
import { useFileDownloadQuery } from '@dieterApi/file/useFileDownloadQuery';
import { useFileShareTokenQuery } from '@dieterApi/file/useFileShareTokenQuery';
import { useAddDieterAVVMutation } from '@dieterApi/questionnaire/useQuestionnaireAddDieterAVVMutation';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { useUserValueDeleteMutation } from '@dieterApi/user/useUserValueDeleteMutation';
import { AddContractModal } from './AddContractModal';
import { AddPartnerButton } from './AddPartnerButton';
import { RequestTomsModal } from './RequestTomsModal';
import { SignatureRequests } from './SignatureRequests';
import { SignedContractModal } from './SignedContractModal';
import { extractPartnerData } from './extractPartnerData';
import './listentry.sass';
import {
  ContractDirection,
  ContractType,
  ListEntryContractType,
  PartnerTableMeta,
  contractDirectionMapping,
  contractTypeMapping,
  validContractTypes,
} from './typesAndEnums';

interface Props {
  topic: UserTopicStatusApplicationsQuestionnaires;
  topicStates: ReturnType<typeof getTopicStates>;
}

export function PartnerListEntry({ topic, topicStates }: Props) {
  const { user } = useUser();
  const dashboard = useDashboard();
  const blankEntry = { partnerName: '', contractType: undefined, index: '', contractLocked: false };
  const [newEntry, setNewEntry] = useImmer<PartnerData>(blankEntry);
  const {
    navigation: { roadBlock },
    setNavigation,
  } = useNavigation();

  const [downloadFile] = useFileDownloadQuery();
  const [deleteRow, setDeleteRow] = useState<MUIDataTableMeta | null>(null);
  const [showSignaturesRow, setShowSignaturesRow] = useState<MUIDataTableMeta | null>(null);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [requestTomsModalOpen, setRequestTomsModalOpen] = useState(false);
  const [signedContractModalOpen, setSignedContractModalOpen] = useState(false);
  const [currentTableMeta, setCurrentTableMeta] = useState<PartnerTableMeta>();
  const contractApps = topic.applications.filter((app) => app.type === ApplicationType.ListContract);
  const [addDieterAVV] = useAddDieterAVVMutation();
  const [deleteUserValue] = useUserValueDeleteMutation();
  const [addUserValue] = useUserValueAddMutation();
  const [dieterAVVIndex, setDieterAVVIndex] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [getFileUploadToken, { data: uploadTokenData }] = useFileShareTokenQuery();

  const partnerData =
    (user && (newEntry.index ? [...extractPartnerData(user), newEntry] : extractPartnerData(user))) || [];

  const dieterAVVExisting = !!user?.userValues?.find(
    (uv) => uv.key === 'DATA_PROCESSOR_NAME' && uv.value === 'simply Legal GmbH'
  );

  const handleCreateContract = (tableMeta: PartnerTableMeta) => {
    const index = tableMeta.rowData[2];
    const contractType = tableMeta.rowData[1];
    const contractApp =
      contractType &&
      contractApps.find((app) => app.tags?.map((tag) => tag.tag).includes(contractType as ContractType));

    if (contractApp) {
      index &&
        dashboard.create(
          contractApp.id,
          undefined,
          true,
          index // this is the link to the table row (partner)
        );
    } else {
      enqueueSnackbar('Vertragstyp wird noch nicht unterstützt.', { variant: 'error' });
      setCreateModalOpen(false);
    }
  };

  const [signLoading, setSignLoading] = useState(false);
  const handleSignContract = (index: string | null) => {
    if (!index) return;
    setSignLoading(true);
    addUserValue({
      variables: {
        key: 'DATA_PROCESSOR_CONTRACT_SIGNED',
        value: 'true',
        index,
      },
    }).finally(() => {
      setSignLoading(false);
      setDieterAVVIndex(null);
    });
  };

  const [dieterAvvAddLoading, setDieterAvvAddLoading] = useState(false);
  const handleAddDieterAVV = () => {
    setDieterAvvAddLoading(true);
    addDieterAVV().finally(() => {
      setDieterAvvAddLoading(false);
    });
  };

  const handleAddPartner = () => {
    const variables = { key: 'DATA_PROCESSOR_NAME', value: newEntry.partnerName, createIndex: true };
    newEntry.partnerName &&
      newEntry.contractType &&
      addUserValue({
        variables,
        context: { userId: user?.id || '' },
      })
        .then((result) => {
          const index = result.data?.addUserValue.index;
          if (newEntry.contractType) {
            const variables = { key: 'DATA_PROCESSOR_TYPE', value: newEntry.contractType, index };
            addUserValue({
              variables,
              context: { userId: user?.id || '' },
            });
          }
          if (newEntry.direction) {
            const variables = { key: 'DATA_PROCESSOR_DIRECTION', value: newEntry.direction, index };
            addUserValue({
              variables,
              context: { userId: user?.id || '' },
            });
          }
        })
        .then(() => {
          setNewEntry({ partnerName: '', contractType: undefined, index: '', contractLocked: false });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
        });
  };

  useEffect(() => {
    if (uploadTokenData?.getFileShareToken) {
      const token = uploadTokenData.getFileShareToken;
    }
  }, [uploadTokenData]);

  const handleShowContract = (tableMeta: PartnerTableMeta) => {
    const contractLocked = tableMeta.rowData[9];
    // we allow viewing the simply Legal contract
    if (topicStates.isLocked && !contractLocked) {
      roadBlock.open(topic);
    }
    const index = tableMeta.rowData[2] as string;
    const partnerName = tableMeta.rowData[0] as string;

    // only temporary in here, before we have proper UI
    getFileUploadToken({
      variables: {
        type: 'upload',
        topicId: topic.id,
        description: `${partnerName} - Technische und Organisatorische Maßnahmen`,
        userValueIndex: index,
      },
    });

    const questionnaire = tableMeta.rowData[4];

    questionnaire &&
      setNavigation((nav) => {
        nav.documentPaper = {
          modalOpen: true,
          questionnaire,
          index: 0,
        };
      });
  };

  const handleOpenContract = (tableMeta: PartnerTableMeta) => {
    const questionnaire = tableMeta.rowData[4];
    questionnaire && dashboard.open(questionnaire.id);
  };

  const handleDownloadToms = (tableMeta: PartnerTableMeta): void => {
    if (topicStates.isLocked) {
      roadBlock.open(topic);
      return;
    }
    const toms = tableMeta.rowData[5]!;
    downloadFile({ variables: { id: toms.id } });
  };

  const handleRemoveFileAttachment = (tableMeta: PartnerTableMeta) => {
    const index = tableMeta.rowData[2];
    index &&
      deleteUserValue({
        variables: { key: 'DATA_PROCESSOR_CONTRACTFILE', index },
        context: { userId: user?.id || '' },
      });
  };

  const handleDownloadFile = (file: UserFile) => {
    if (topicStates.isLocked) {
      roadBlock.open(topic);
      return;
    }
    downloadFile({ variables: { id: file.id } });
  };

  const columns: MUIDataTableProps['columns'] = [
    {
      name: 'partnerName',
      label: 'Partnername',
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const isManual = tableMeta.rowData[2] == 'MANUAL';
          return isManual ? (
            <input
              autoFocus
              // placeholder="Partner name"
              value={newEntry?.partnerName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddPartner();
                }
              }}
              onChange={(e) =>
                setNewEntry((draft) => {
                  draft.partnerName = e.target.value;
                })
              }
              data-testid="input-partner-name"
            />
          ) : (
            value
          );
        },
      },
    },
    {
      name: 'contractType',
      label: 'Vertragstyp',
      options: {
        customBodyRender: (value: Partial<ContractType>, tableMeta: MUIDataTableMeta) => {
          const isManual = tableMeta.rowData[2] == 'MANUAL';
          return isManual ? (
            <FormControl variant="standard">
              <Select
                value={newEntry?.contractType}
                onChange={(e) => setNewEntry((draft) => void (draft && (draft.contractType = e.target.value)))}
              >
                {Object.entries(contractTypeMapping).map(([short, long]) => {
                  return (
                    <MenuItem key={short} value={short}>
                      {long}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={contractTypeMapping[value] || value}>
              <span>{value}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'index',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'contractFile',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'contract',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'toms',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'direction',
      label: 'Richtung',
      options: {
        customBodyRender: (value: ContractDirection | undefined, tableMeta: MUIDataTableMeta) => {
          const name = tableMeta.rowData[0];
          const index = tableMeta.rowData[2];
          const isLockedForEditing = !!tableMeta.rowData[9];
          const isManual = index == 'MANUAL';
          return !isLockedForEditing || !value ? (
            <FormControl variant="standard" error={!value}>
              <Select
                value={(isManual ? newEntry?.direction : value) || ''}
                onChange={
                  isManual
                    ? (e) =>
                        setNewEntry((draft) => void (draft && (draft.direction = e.target.value as ContractDirection)))
                    : (e) => {
                        const variables = {
                          key: 'DATA_PROCESSOR_DIRECTION',
                          value: e.target.value,
                          index,
                        };
                        addUserValue({
                          variables,

                          context: {
                            userId: user?.id || '',
                          },
                        });
                      }
                }
                data-testid={`select-direction-${name}`}
              >
                {Object.entries(contractDirectionMapping).map(([long, short]) => {
                  return (
                    <MenuItem key={short} value={long}>
                      {long}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={value}>
              <span>{contractDirectionMapping[value] || value}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'actions',
      label: 'Vertrag',
      options: {
        setCellHeaderProps(columnMeta) {
          return { style: { fontSize: '30px' } };
        },
        customBodyRender: (value, tableMeta: MUIDataTableMeta) => {
          const contractType = tableMeta.rowData[1];
          const index = tableMeta.rowData[2];
          const isManual = index === 'MANUAL';
          const contractFile = tableMeta.rowData[3] as UserFile;
          const hasContractFile = contractFile && contractFile.id;
          const contract = tableMeta.rowData[4] as unknown as UserQuestionnaireApplicationTopic;
          const hasDirection = tableMeta.rowData[6] as ContractDirection;
          const isLockedForEditing = !!tableMeta.rowData[9];

          return hasContractFile ? (
            <>
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={`Vertrag herunterladen (${contractFile.name})`}
              >
                <IconButton size="small" onClick={() => handleDownloadFile(contractFile)}>
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={`Vertrag entfernen (${contractFile.name})`}>
                <IconButton size="small" onClick={() => handleRemoveFileAttachment(tableMeta as PartnerTableMeta)}>
                  <EjectIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          ) : isManual ? (
            <Link to="#" onClick={() => setNewEntry(blankEntry)}>
              Abbrechen
            </Link>
          ) : (contract?.id && dashboard.affects(undefined, contract?.id)) || dashboard.affects(undefined, index) ? (
            <Spinner size="small" />
          ) : contract ? (
            contract.progress == 1 ? (
              <>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Vertrag anzeigen">
                  <IconButton size="small" onClick={() => handleShowContract(tableMeta as PartnerTableMeta)}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Vertrag herunterladen">
                  <IconButton size="small" onClick={() => handleDownloadContract(tableMeta as PartnerTableMeta)}>
                    <Icon type="doc-16" size={16} />
                  </IconButton>
                </Tooltip> */}
                {!isLockedForEditing && (
                  <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Vertrag bearbeiten">
                    <IconButton size="small" onClick={() => handleOpenContract(tableMeta as PartnerTableMeta)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ) : (
              <Link to="#" onClick={() => handleOpenContract(tableMeta as PartnerTableMeta)}>
                Fortsetzen
              </Link>
            )
          ) : (
            hasDirection &&
            validContractTypes.includes(contractType) && (
              <Link
                to="#"
                onClick={() => {
                  setCreateModalOpen(true);
                  setCurrentTableMeta(tableMeta as PartnerTableMeta);
                }}
              >
                Jetzt anlegen
              </Link>
            )
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Löschen',
      options: {
        customBodyRender: (value, tableMeta: MUIDataTableMeta) => {
          const partnerName = tableMeta.rowData[0];
          const index = tableMeta.rowData[2];
          const isManual = tableMeta.rowData[2] == 'MANUAL';
          const isLockedForEditing = !!tableMeta.rowData[9];
          const isSigned = !!tableMeta.rowData[10];
          if (isLockedForEditing) {
            if (isSigned)
              return (
                <Tooltip title="Vertrag akzeptiert">
                  <CheckCircleIcon color="success" fontSize="small" />
                </Tooltip>
              );
            return signLoading ? (
              <Spinner size="small" />
            ) : (
              <button
                className="underline text-primary-root hover:text-primary-700"
                onClick={() => setDieterAVVIndex(index)}
              >
                Akzeptieren
              </button>
            );
          }
          if (isManual)
            return (
              <div className="flex">
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="Vertragspartner speichern">
                  <IconButton
                    className="dtListEntry__deleteButton"
                    color="info"
                    size="small"
                    onClick={handleAddPartner}
                    data-testid={`buton-save-partner-${partnerName}`}
                  >
                    <SaveIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            );

          const hasContract = tableMeta.rowData[4] || tableMeta.rowData[3];
          const items: ThreeDotItem[] = [
            {
              label: 'Vertragspartner löschen',
              onClick: () => {
                setDeleteRow(tableMeta);
                setDeleteAlertOpen(true);
              },
              icon: <DeleteIcon fontSize="small" />,
              testlabel: `button-delete-partner`,
            },
            // {
            //   label: 'Digitale Signierung',
            //   onClick: () => setShowSignaturesRow(tableMeta),
            //   icon: <ApprovalIcon fontSize="small" />,
            //   disabled: !user?.hasFeature('eSignatures'),
            // },
            {
              label: 'Unterschriebenen Vertrag verwalten',
              onClick: () => {
                setCurrentTableMeta(tableMeta as PartnerTableMeta);
                setSignedContractModalOpen(true);
              },
              icon: <ApprovalIcon fontSize="small" />,
              disabled: !hasContract,
            },
            {
              label: 'Partner TOMS verwalten',
              onClick: () => {
                setCurrentTableMeta(tableMeta as PartnerTableMeta);
                setRequestTomsModalOpen(true);
              },
              icon: <ShareIcon fontSize="small" />,
              // disabled: hasToms,
            },

            // {
            //   label: 'Partner TOMs herunterladen',
            //   onClick: () => handleDownloadToms(tableMeta as PartnerTableMeta),
            //   icon: <DownloadIcon fontSize="small" />,
            //   disabled: !hasToms,
            // },
          ];

          return <ThreeDots items={items} data-testid={`threedots-${partnerName}`} />;
        },
        customHeadLabelRender: () => '',
      },
    },
    {
      name: 'contractLocked',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'contractSigned',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'signedContractFile',
      options: {
        display: 'excluded',
      },
    },
  ];

  const handleDelete = (tableMeta: MUIDataTableMeta) => {
    const [partnerName, contractType, index] = tableMeta.rowData as [string, string, string];
    const keys = [
      'DATA_PROCESSOR_NAME',
      'DATA_PROCESSOR_TYPE',
      'DATA_PROCESSOR_CONTRACTFILE',
      'DATA_PROCESSOR_DIRECTION',
      'DATA_PROCESSOR_ISSUBCONTRACTOR',
    ];
    keys.forEach((key) => deleteUserValue({ variables: { key, index }, context: { userId: user?.id || '' } }));
  };

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    elevation: 0,

    // onRowClick: handleClick,
    selectableRows: 'none',
    selectableRowsHeader: false,
    // selectableRowsOnClick: true,
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      const thisRow = row as PartnerTableMeta['rowData'];
      const contractTypePretty = thisRow[1] as string; // this is the already decoded type, we need to map this back to the original
      const [contractType] =
        Object.entries(contractTypeMapping).find(([key, value]) => value == contractTypePretty) || [];
      const contractFile = thisRow[3];
      const questionnaire = thisRow[4];
      const questionnaireCompleted = questionnaire && questionnaire.progress == 1;
      const doesNotNeedContract = contractType && !validContractTypes.includes(contractType as ListEntryContractType);
      const completed = Boolean(contractFile || questionnaireCompleted || doesNotNeedContract);
      return {
        className: cx({
          completed,
          inProgress: questionnaire && !questionnaireCompleted,
          hasNothing: !completed && !questionnaire,
        }),
      };
    },
    onTableChange: (action, tableState) => {
      // console.log(action, tableState, currentTableMeta);
      if (currentTableMeta) {
        // compare each entry of the currentTableMeta.rowData with the tableState.data
        // if it is different, update the currentTableMeta.rowData
        const currentRow = tableState.data[currentTableMeta.rowIndex];
        const currentRowString = JSON.stringify(currentRow.data);
        const tableRowString = JSON.stringify(currentTableMeta.rowData);

        if (currentRowString !== tableRowString) {
          setCurrentTableMeta({ ...currentTableMeta, rowData: currentRow.data });
        }
      }
    },
    customToolbar: () => {
      return (
        <div className="flex gap-4 items-center">
          <AddPartnerButton topic={topic} setNewEntry={setNewEntry} />
          <Tooltip title="Du kannst einen Partner aus einer Liste der häufigsten Anbieter auswählen (Schnellauswahl), dir von Dieter bei der Wahl des richtigen Vertrages helfen lassen (Geführter Dialog) oder einen Partner selbst anlegen (Manuelle Eingabe).">
            <InfoIcon className="text-gray-400" />
          </Tooltip>
        </div>
      );
    },
    // onRowsDelete: handleDelete,
    textLabels: {
      selectedRows: {
        text: 'Zeilen ausgewählt',
        delete: 'Löschen',
        deleteAria: 'Ausgewählte Zeilen löschen',
      },
      body: {
        noMatch: 'Keine Einträge vorhanden',
      },
    },
    expandableRows: true,
    isRowExpandable(dataIndex, expandedRows) {
      const contract = partnerData?.[dataIndex]?.contract;
      return contract?.progress == 1;
    },
    renderExpandableRow(rowData, rowMeta) {
      const colSpan = rowData.length + 1;
      const contract = rowData[4] as unknown as UserQuestionnaireApplicationTopic | null;
      const partnerName = rowData[0] as string;
      const contractType = partnerData[rowMeta.rowIndex].contractType || 'Vertrag';
      return (
        <SignatureRequests
          questionnaire={contract}
          partnerName={partnerName}
          contractType={contractType}
          colSpan={colSpan}
          onClose={() => setShowSignaturesRow(null)}
        />
      );
    },
    rowsExpanded: [showSignaturesRow?.rowIndex],
    expandableRowsOnClick: false,
    expandableRowsHeader: false,
  };

  return (
    <div className="dtListEntry">
      {!dieterAVVExisting && ['Basic', 'Premium', 'Ticket'].includes(user?.subscription || '') && (
        <div className="bg-secondary-100 rounded-md p-5 text-secondary-700 text-sm flex flex-col gap-5 mb-8 items-start">
          <span>
            Neben deinen anderen Vertragspartnern benötigst du ebenfalls einen Auftrags&shy;verarbeitungs&shy;vertrag
            mit der <strong>simply Legal GmbH</strong>, den Betreibern der Dieter Datenschutz Plattform. Den nötigen
            Vertrag kannst du jetzt mit einem Klick deiner Partnerliste hinzufügen und anschließend akzeptieren.
          </span>
          {dieterAvvAddLoading ? (
            <Spinner size="small" />
          ) : (
            <button onClick={handleAddDieterAVV} className="underline text-primary-root hover:text-primary-700">
              Jetzt hinzufügen
            </button>
          )}
        </div>
      )}
      {partnerData ? (
        <MUIDataTable columns={columns} data={partnerData} title="Deine Vertragspartner" options={options} />
      ) : (
        <Loading />
      )}
      <AddContractModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreate={handleCreateContract}
        topic={topic}
        tableMeta={currentTableMeta}
      />
      <SignedContractModal
        open={signedContractModalOpen}
        onClose={() => setSignedContractModalOpen(false)}
        topic={topic}
        tableMeta={currentTableMeta}
      />
      <RequestTomsModal
        open={requestTomsModalOpen}
        onClose={() => setRequestTomsModalOpen(false)}
        topic={topic}
        tableMeta={currentTableMeta}
      />
      <AlertDialog
        open={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        message={
          <>
            Möchten Sie den Vertragspartner <b>{deleteRow?.rowData[0]}</b> wirklich löschen? Dadurch werden ebenfalls
            die erzeugten Verträge und Signiervorgänge gelöscht.
          </>
        }
        onAccept={() => deleteRow && handleDelete(deleteRow)}
      />
      <AlertDialog
        open={!!dieterAVVIndex}
        setOpen={() => setDieterAVVIndex(null)}
        message={
          <>
            Akzeptierst du den AV Vertrag mit der simply Legal GmbH (den Betreibern der Dieter-Platform) zur
            Verarbeitung personenbezogener Daten?
          </>
        }
        onAccept={() => handleSignContract(dieterAVVIndex)}
        acceptText="Ja"
      />
    </div>
  );
}

export interface PartnerData {
  partnerName: string;
  contractType?: string;
  contractFile?: UserFile;
  signedContractFile?: UserFile;
  contractLocked?: boolean;
  contractSigned?: boolean;
  contract?: UserQuestionnaireApplicationTopic;
  direction?: ContractDirection;
  index: string;
  isSubContractor?: boolean;
  toms?: UserFile;
}

import cx from 'classnames';
import React from 'react';
import { on } from 'tyny/lib/utils/dom/event/on';
import { Spinner } from '../Spinner/Spinner';
import './ui-shortcut.sass';

export interface Props {
  children: React.ReactChild;
  didTrigger?: boolean;
  disabled?: boolean;
  onAction: () => void;
  shortcutKey: string;
  buttonWidth?: number;
}

export function Shortcut({ children, didTrigger, disabled, onAction, shortcutKey, buttonWidth = 1 }: Props) {
  const [isPressed, setPressed] = React.useState(false);

  function onTrigger() {
    if (!disabled) {
      onAction();
    }
  }

  React.useEffect(() => {
    const offs = [
      on(window, 'keyup', (event: KeyboardEvent) => {
        if (event.key === shortcutKey) {
          onTrigger();
          setPressed(false);
        }
      }),
      on(window, 'keydown', (event: KeyboardEvent) => {
        if (!disabled && event.key === shortcutKey) setPressed(true);
      }),
    ];
    return () => offs.forEach((off) => off());
  }, []);

  let symbol = shortcutKey;
  let symbolClass = 'key';
  if (symbol === 'Enter') {
    symbol = '⏎';
    symbolClass = 'plain';
  }

  return (
    <dl
      className={cx('dtUiShortcut flex gap-20 items-center w-100', {
        didTrigger,
        disabled,
        isPressed,
      })}
    >
      <dt className={cx('dtUiShortcut__child', `flex-${buttonWidth}`, 'font-normal')}>{children}</dt>
      {didTrigger ? (
        <dd className="dtUiShortcut__hint">
          <Spinner size="small" />
        </dd>
      ) : (
        <dd className="dtUiShortcut__hint" onClick={onTrigger}>
          {/* <span>oder mit </span> */}
          <span className={symbolClass}>{symbol}</span>
        </dd>
      )}
    </dl>
  );
}

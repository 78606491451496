import cx from 'classnames';
import React from 'react';

import './ui-field.sass';

export interface Props extends React.HTMLAttributes<HTMLDListElement> {
  htmlFor: string;
  label?: string;
}

export function Field({ children, className, htmlFor, label, ...props }: Props) {
  return (
    <dl {...props} className={cx('dtUiField', className)}>
      <dt className="dtUiField__caption">
        {label && (
          <label className="dtUiField__captionLabel" htmlFor={htmlFor}>
            {label}
          </label>
        )}
      </dt>
      <dd className="dtUiField__content">{children}</dd>
    </dl>
  );
}

import { useMessages } from '@app/context/MessageContext';
import { Button, NotificationModal } from '@components/ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function MultiTenantInfo() {
  const { t } = useTranslation();
  const { multitenant } = useMessages();
  useEffect(() => {
    multitenant.setContext('multitenant');
  }, []);

  return (
    <NotificationModal open={multitenant.isOpen} onClose={multitenant.close}>
      <div className="flex flex-col p-[30px]">
        <div className="bg-primary-root -mx-[40px] px-9 py-7 -mt-[40px] flex flex-col text-center gap-4">
          <img src="/assets/images/dieter_banane.svg" alt="Info" className="md:h-[350px] h-[200px]" />
          <span className="text-white font-medium text-lg">{t('dashboard.messages.multitenant.title')}</span>
        </div>

        <div className="pointer-events-auto bg-white my-5">
          <div className="flex flex-col items-center text-center gap-3">
            <p>{t('dashboard.messages.multitenant.p1')}</p>
            <p>{t('dashboard.messages.multitenant.p2')}</p>
            <p>{t('dashboard.messages.multitenant.p3')}</p>
            <Button onClick={multitenant.close} data-testid="button-close" className="mt-20">
              {t('dashboard.messages.multitenant.button')}
            </Button>
          </div>
        </div>
      </div>
    </NotificationModal>
  );
}

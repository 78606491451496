import { useMutation, useQuery } from '@apollo/client';
import { ADD_TECHNOLOGY, AddTechnologyInput, AddTechnologyResult } from '@app/api/usercentrics/addTechnology';
import { GET_SETTING, GetSettingInput, GetSettingResult } from '@app/api/usercentrics/getSetting';
import { GET_TEMPLATES, GetConsentTemplatesInput, GetConsentTemplatesResult } from '@app/api/usercentrics/getTemplates';
import {
  UPDATE_SETTING,
  UpdateSettingInput,
  UpdateSettingResult,
  UserFacingUpdateSettingInput,
} from '@app/api/usercentrics/updateSetting';
import {
  CategorySlug,
  MUTATE_TEMPLATES,
  MutateTemplatesInput,
  MutateTemplatesResult,
} from '@app/api/usercentrics/updateTechnology';
import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';

import { useState } from 'react';

export function useUserCentrics() {
  const { user } = useUser();
  const website = user?.getValue('WEBSITE') || '';
  const settingsId = user?.company.usercentricsSettingsId || '';

  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState<string>(website);
  const [error, setError] = useState<string>('');
  const [advancedError, setAdvancedError] = useState<string>('');

  // URL validation regex
  const urlRegex = /^(https?:\/\/)[\w-]+(\.[\w-]+)+.*$/;

  const [setUserValue] = useUserValueAddMutation();

  const { data: settingData } = useQuery<GetSettingResult, GetSettingInput>(GET_SETTING, {
    variables: {
      settingsId,
      language: user?.locale || 'en',
    },
    skip: !settingsId,
  });

  const { data: templatesData, loading: templatesLoading } = useQuery<
    GetConsentTemplatesResult,
    GetConsentTemplatesInput
  >(GET_TEMPLATES, {
    variables: {
      includeDeprecated: false,
    },
  });

  const [updateSettingMutation, { data, loading: updateInProgress }] = useMutation<
    UpdateSettingResult,
    UpdateSettingInput
  >(UPDATE_SETTING, {
    refetchQueries: ['GetSetting'],
  });

  const [addTechnologiesMutation, { data: addTechnologiesData, loading: addTechnologiesLoading }] = useMutation<
    AddTechnologyResult,
    AddTechnologyInput
  >(ADD_TECHNOLOGY);

  const [mutateTechnologies] = useMutation<MutateTemplatesResult, MutateTemplatesInput>(MUTATE_TEMPLATES, {
    refetchQueries: ['GetSetting'],
  });

  const addTechnologies = () => {
    const urlWithProtocol = ensureProtocol(url);
    if (urlRegex.test(urlWithProtocol) && settingsId) {
      setError('');
      setUrl(urlWithProtocol);
      setUserValue({ variables: { key: 'WEBSITE', value: urlWithProtocol } });
      addTechnologiesMutation({
        variables: {
          settingsId,
          url: urlWithProtocol,
          isSitemap: false,
        },
        refetchQueries: ['GetSetting'],
      });
    } else {
      setError('Bitte geben Sie eine gültige URL ein.');
    }
  };

  const updateSetting = (input: UserFacingUpdateSettingInput & { smartDataProtection: boolean }) => {
    if (!settingsId) return;

    const { imprintUrl, privacyPolicyUrl } = input;
    const safeImprintUrl = ensureProtocol(imprintUrl || '');
    const safePrivacyPolicyUrl = ensureProtocol(privacyPolicyUrl || '');

    if (imprintUrl && !urlRegex.test(safeImprintUrl)) {
      setAdvancedError('Bitte geben Sie eine gültige URL für das Impressum ein.');
      return;
    } else if (privacyPolicyUrl && !urlRegex.test(safePrivacyPolicyUrl)) {
      setAdvancedError('Bitte geben Sie eine gültige URL für die Datenschutzerklärung ein.');
      return;
    }

    setAdvancedError('');

    setUserValue({
      variables: { key: 'UC_SMART_DATA_PROTECTION', value: input.smartDataProtection ? 'true' : 'false' },
    });

    updateSettingMutation({
      variables: {
        settingsId,
        language: user?.locale || 'en',
        imprintUrl: imprintUrl ? safeImprintUrl : undefined,
        privacyPolicyUrl: privacyPolicyUrl ? safePrivacyPolicyUrl : undefined,
        buttonDisplayLocation: input.buttonDisplayLocation,
        googleConsentMode: input.googleConsentMode,
        firstLayer: input.firstLayer,
        styles: input.styles,
        customization: input.customization,
      },
    })
      .then(() => {
        enqueueSnackbar('Einstellungen gespeichert.', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Einstellungen konnten nicht gespeichert werden.', { variant: 'error' });
      });
  };

  const changeCategory = (templateId: string, categorySlug: CategorySlug) => {
    if (settingsId) {
      mutateTechnologies({
        variables: {
          settingsId,
          language: user?.locale || 'en',
          consentTemplates: {
            updated: [{ templateId, categorySlug }],
          },
        },
        optimisticResponse: {
          mutateTemplates: {
            consentTemplates:
              settingData?.setting?.consentTemplates.map((t) =>
                t.templateId === templateId ? { ...t, categorySlug } : t
              ) || [],
          },
        },
        update: (cache, { data }) => {
          const currentSetting = settingData?.setting;
          currentSetting &&
            cache.writeQuery<GetSettingResult, GetSettingInput>({
              query: GET_SETTING,
              variables: {
                settingsId,
                language: user?.locale || 'en',
              },
              data: {
                setting: {
                  ...currentSetting,
                  consentTemplates: currentSetting.consentTemplates.map((t) =>
                    t.templateId === templateId ? { ...t, categorySlug } : t
                  ),
                },
              },
            });
        },
      });
    }
  };

  const removeTechnology = (templateId: string) => {
    if (settingsId) {
      mutateTechnologies({
        variables: {
          settingsId,
          language: user?.locale || 'en',
          consentTemplates: {
            removed: [templateId],
          },
        },
        optimisticResponse: {
          mutateTemplates: {
            consentTemplates: settingData?.setting?.consentTemplates.filter((t) => t.templateId !== templateId) || [],
          },
        },
        update: (cache, { data }) => {
          const currentSetting = settingData?.setting;
          currentSetting &&
            cache.writeQuery<GetSettingResult, GetSettingInput>({
              query: GET_SETTING,
              variables: {
                settingsId,
                language: user?.locale || 'en',
              },
              data: {
                setting: {
                  ...currentSetting,
                  consentTemplates: currentSetting.consentTemplates.filter((t) => t.templateId !== templateId),
                },
              },
            });
        },
      });
    }
  };

  const addTechnology = (templateId: string) => {
    if (settingsId) {
      mutateTechnologies({
        variables: {
          settingsId,
          language: user?.locale || 'en',
          consentTemplates: {
            added: [{ templateId }],
          },
        },
        update: (cache, { data }) => {
          const currentSetting = settingData?.setting;
          currentSetting &&
            cache.writeQuery<GetSettingResult, GetSettingInput>({
              query: GET_SETTING,
              variables: {
                settingsId,
                language: user?.locale || 'en',
              },
              data: {
                setting: {
                  ...currentSetting,
                  consentTemplates: data?.mutateTemplates.consentTemplates || currentSetting.consentTemplates,
                },
              },
            });
        },
      });
    }
  };

  return {
    setting: settingData?.setting,
    templates: templatesData?.consentTemplates,
    templatesLoading,
    scan: addTechnologies,
    scanInProgress: addTechnologiesLoading,
    updateSetting,
    changeCategory,
    updateInProgress,
    removeTechnology,
    addTechnology,
    url,
    setUrl: setUrl,
    error,
    advancedError,
  };
}

// Function to ensure URLs start with http:// or https://
const ensureProtocol = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

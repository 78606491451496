import { gql } from "@apollo/client";
export const QUESTIONNAIRE_DOWNLOAD_DOCX = gql`
  mutation questionnaireDownloadDocx($input: QuestionnaireDownloadDocxInput) {
    questionnaireDownloadDocx(input: $input) {
      mimeType
      fileName
      fileBase64
    }
  }
`;

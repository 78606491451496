import { gql, useMutation } from '@apollo/client';
import { USER_FIELDS, User } from './useUserQuery';

const ADD_TENANT = gql`
  ${USER_FIELDS}
  mutation AddTenant(
    $companyName: String!
    $website: String
    $phone: String!
    $street: String!
    $zip: String!
    $city: String!
  ) {
    addTenant(companyName: $companyName, website: $website, phone: $phone, street: $street, zip: $zip, city: $city) {
      ...UserFields
    }
  }
`;

export interface AddTenantInput {
  companyName: string;
  website?: string;
  phone: string;
  street: string;
  zip: string;
  city: string;
}

export interface AddTenantResult {
  addTenant: User;
}

export function useAddTenantMutation() {
  return useMutation<AddTenantResult, AddTenantInput>(ADD_TENANT, {
    refetchQueries: ['GetUser'],
  });
}

import { gql, useLazyQuery } from '@apollo/client';
import { APPLICATION_FIELDS, ApplicationStatus, TOPIC_FIELDS, UserApplication, UserTopic } from '../user/useUserQuery';

export const TASK_FIELDS = gql`
  ${TOPIC_FIELDS}
  ${APPLICATION_FIELDS}
  fragment TaskFields on Task {
    id
    persistentId
    label
    type
    section
    status
    resettable
    priority
    alwaysOpen
    isUpsell
    application {
      ...UserAppFields
    }
    topic {
      ...UserTopicFields
    }
    linkedApplication {
      ...UserAppFields
    }
    done
    duration
    automated
    tutorialSteps
    actionText
    confirmationText
    navStateOnComplete
    callbackOnComplete
    documentRequirement
  }
`;

export const GET_TASKS = gql`
  ${TASK_FIELDS}
  query GetTasks {
    getTasks {
      ...TaskFields
    }
  }
`;

export enum TaskType {
  SIMPLEPROCESS = 'SimpleProcess',
  PUBLISHPROCESS = 'PublishProcess',
  SHAREQUESTIONNAIRE = 'ShareQuestionnaire',
  QUESTIONNAIRE = 'Questionnaire',
  REVISIT = 'RevisitQuestionnaire',
  LINKQUESTIONNAIRE = 'LinkQuestionnaire',
}

export const AllProcessTasks = [TaskType.PUBLISHPROCESS, TaskType.SIMPLEPROCESS, TaskType.SHAREQUESTIONNAIRE];

// 'Prozess / Handlung' | 'Fragebogen' | 'Upload';

export interface Task {
  id: string;
  persistentId: string;
  label: string;
  type: TaskType;
  section: number;
  status: ApplicationStatus;
  resettable: boolean;
  priority: number;
  alwaysOpen: boolean;
  isUpsell: boolean;
  application?: UserApplication;
  linkedApplication?: UserApplication;
  topic: UserTopic;
  done?: boolean;
  progress?: number;
  dueDate?: Date;
  duration: number; // in minutes
  automated: boolean;
  tutorialSteps: string[];
  actionText: string;
  confirmationText?: string;
  navStateOnComplete?: string;
  callbackOnComplete?: string;
  documentRequirement: boolean;
  isTopicFocus: boolean; // this is added after backend response by the frontend (TaskContext)
  isOverallFocus: boolean; // this is added after backend response by the frontend (TaskContext)
  isNew?: boolean; // this is added after backend response by the frontend (TaskContext)
  isDismissed?: boolean; // this is added after backend response by the frontend (TaskContext)
  setStatus: (status: boolean) => void; // this is added after backend response by the frontend (TaskContext)
  setIgnore: (ignore: boolean) => void;
  isIgnored?: boolean; // this is added after backend response by the frontend (TaskContext)
}

export type Tasks = Task[];

export interface GetTasksResult {
  getTasks: Task[];
}

export function useTaskQuery() {
  return useLazyQuery<GetTasksResult>(GET_TASKS, {
    fetchPolicy: 'cache-and-network',
  });
}

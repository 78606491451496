import { Input } from '@components/ui/Input/Input';
import React from 'react';
import { Props } from './QuestionInput';

export function Text(props: Props<string>) {
  const ref = React.createRef<HTMLInputElement>();
  React.useEffect(() => {
    const { current } = ref;
    if (current) current.focus();
  });

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') props.onAction();
    e.stopPropagation();
  };

  return (
    <Input
      disabled={props.disabled}
      onChange={(e) => props.onChange(e.target.value)}
      onKeyPress={handleKeyPress}
      onKeyUp={(e) => e.stopPropagation()}
      ref={ref}
      value={props.value}
      placeholder={props.placeholder}
    />
  );
}

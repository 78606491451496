import { animated, useSpring } from 'react-spring';
import { Icon } from '../Icon/Icon';

interface Props {
  onClick: () => void;
}
export function AnimatedInfoButton({ onClick }: Props) {
  const style = useSpring({
    to: async (next, cancel) => {
      while (1) {
        await next({ transform: 'translateY(0px)', color: '#6c757d' });
        await next({ transform: 'translateY(-5px)', color: '#fdd213' });
        await next({ transform: 'translateY(0px)', color: '#6c757d' });
      }
    },
    from: { transform: 'translateY(0px)', color: '#6c757d' },
    config: { duration: 400 },
    delay: 1000,
    reset: true,
  });

  return (
    <button className="text-gray-600 text-sm relative" onClick={onClick}>
      <animated.div style={style}>
        <Icon type="info-36" size={24} />
      </animated.div>
    </button>
  );
}

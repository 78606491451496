import { UserFlowItemType } from '@app/routes/Dashboard/constants';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';
import './ui-userflowitem.sass';
interface Props {
  title?: JSX.Element | string;
  type: UserFlowItemType;
  progress?: number;
  isFocusTask?: boolean;
  topRightElements?: JSX.Element;
  bodyElements: JSX.Element;
  weak?: boolean; // if true, the item will be displayed half transparent
  bodyClassName?: string;
  indentedLeft?: boolean;
  indentedRight?: boolean;
  done?: boolean;
  broken?: boolean;
  noBottom?: boolean;
  isIgnored?: boolean;
  onIgnore?: (ignore: boolean) => void;
  // isNew?: boolean;
  // isNewVersion?: boolean;
}

export function UserFlowItem({
  title,
  type,
  progress,
  topRightElements,
  bodyElements,
  bodyClassName,
  indentedLeft = false,
  indentedRight = false,
  isFocusTask = false,
  // isNew = false,
  // isNewVersion = false,

  done = false,
  broken = false,
  weak = false,
  noBottom = false,
  isIgnored = false,
  onIgnore,
}: Props) {
  const iconMap = {
    [UserFlowItemType.questionnaire]: <Icon type="form-24" size={32} />,
    [UserFlowItemType.document]: <Icon type="file-done-1024" size={32} />,
    [UserFlowItemType.task]: <Icon type="task-32" size={32} />,
  };
  const bgMap = {
    [UserFlowItemType.questionnaire]: 'bg-primary-100',
    [UserFlowItemType.document]: 'bg-secondary-100',
    [UserFlowItemType.task]: 'bg-gray-100',
  };

  const { t } = useTranslation();

  return (
    <div
      className={cx(
        'dtUserFlowItem relative shadow-md flex flex-col h-full bg-gray-100',
        {
          'indented-left': indentedLeft,
          'indented-right': indentedRight,
        }
        // bgMap[type]
      )}
      data-testid={`userflow-item-${type}`}
    >
      <div className={cx('dtUserFlowItem__transparentLayer', { weak })} />
      <div
        className={cx(
          'dtUserFlowItem__header flex flex-nowrap gap-10',
          { done, broken },
          isFocusTask && 'bg-secondary-root'
        )}
      >
        <div className={cx('icon text-primary-root aspect-[1]', type, { done, isFocusTask })}>{iconMap[type]}</div>
        <div className="w-full items-center flex-row flex pr-2">
          <div
            className={cx(
              'grow line-clamp-2 leading-3  mr-3',
              isFocusTask ? 'text-base font-medium text-black' : 'text-xs'
            )}
          >
            {isFocusTask ? t('components.ui.userflowitem.next_task') : title}
          </div>
          {!indentedLeft && !indentedRight && (
            <Tooltip
              title={isIgnored ? t('components.ui.userflowitem.unignore') : t('components.ui.userflowitem.ignore')}
            >
              <button
                className={cx(isIgnored ? 'text-red-500 hover:text-gray-400' : 'text-gray-400 hover:text-red-500')}
                onClick={() => onIgnore?.(!isIgnored)}
              >
                <PowerSettingsNewIcon fontSize="small" />
              </button>
            </Tooltip>
          )}
          {/* {isNewVersion ? <NewVersionBadge /> : isNew && !done && <NewBadge />} */}
        </div>
      </div>
      <FlowItemProgress progress={!done ? progress : undefined} isFocusTask={isFocusTask} />
      <div
        className={cx(
          'text-sm bg-gray-50 flex gap-10 p-3 h-full border-b-[1px] drop-shadow-sm transition-all',
          isFocusTask && 'bg-secondary-100',
          bodyClassName
        )}
      >
        {bodyElements}
      </div>

      {!noBottom && <div className="flex flex-row bg-white p-2 text-sm text-gray-400">{topRightElements}</div>}
    </div>
  );
}

interface FlowItemProgressProps {
  progress?: number;
  isFocusTask?: boolean;
}
function FlowItemProgress({ progress, isFocusTask }: FlowItemProgressProps) {
  return (
    <div className={cx('dtFlowItemProgress', !progress && (isFocusTask ? 'bg-secondary-100' : 'bg-gray-50'))}>
      <div
        className={cx('dtFlowItemProgress__bar', { done: progress === 1 })}
        style={{ width: (progress ? progress * 100 : 0).toFixed(2) + '%' }}
      ></div>
    </div>
  );
}

/**
 * Copies a string to the clipboard.
 *
 * @param value The string to be copied.
 * @throws Error if both primary and fallback methods fail.
 * @returns A promise that resolves when the text is successfully copied.
 */
export async function copyToClipboard(value: string): Promise<void> {
  // Function to handle the fallback copying method
  const fallbackCopy = (text: string): boolean => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    try {
      const successful = document.execCommand('copy');
      return successful;
    } catch (err) {
      console.error('Fallback copy to clipboard failed:', err);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  };

  // Use Clipboard API if available
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(value);
    } catch {
      // Fallback if Clipboard API fails
      const fallbackSuccess = fallbackCopy(value);
      if (!fallbackSuccess) {
        throw new Error('Copying to clipboard failed.');
      }
    }
  } else {
    // Fallback if Clipboard API is not available
    const fallbackSuccess = fallbackCopy(value);
    if (!fallbackSuccess) {
      throw new Error('Copying to clipboard failed.');
    }
  }
}

import { gql, useMutation } from '@apollo/client';

const NOTIFY_SPECIAL_CASE = gql`
  mutation NotifySpecialCase {
    notifySpecialCase
  }
`;

export interface NotifySpecialCaseResult {
  notifySpecialCase: boolean;
}

export function useUserNotifySpecialCaseMutation() {
  return useMutation<NotifySpecialCaseResult>(NOTIFY_SPECIAL_CASE);
}

import { useSnackbar } from '@app/hooks/useSnackbar';
import { Spinner } from '@components/ui/Spinner/Spinner';
import { useFileUploadMutation } from '@dieterApi/file/useFileUploadMutation';
import { UserFile } from '@dieterApi/user/useUserQuery';
import { Tooltip } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';
import './ui-fileupload.sass';
interface Props {
  topicId: string;
  onComplete?: (file: UserFile) => void;
  token?: string;
}

export const FileUpload = ({ topicId, onComplete, token }: Props) => {
  const { t } = useTranslation();
  const [uploadFile, { data, loading }] = useFileUploadMutation();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t('components.ui.fileupload.success'), {
        variant: 'success',
      });
    }
  }, [data]);

  const handleUpload = (files: File[]) => {
    uploadFile({
      variables: { file: files[0], size: files[0].size, topicId, token },
    })
      .then((result) => onComplete && result.data?.uploadFile && onComplete(result.data.uploadFile))
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  };

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      enqueueSnackbar(t('components.ui.fileupload.invalid'), {
        variant: 'error',
      });
      return;
    }

    if (acceptedFiles[0].size > 10000000) {
      enqueueSnackbar(t('components.ui.fileupload.toolarge'), {
        variant: 'error',
      });
      return;
    }

    handleUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,text/plain',
  });

  return (
    <>
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        // placement="left"
        title={
          <dl>
            <dt>{t('components.ui.fileupload.allowed')}</dt>
            <dl>.pdf, .doc, .docx, .txt, .jpg, .png</dl>
            <dt>{t('components.ui.fileupload.maximum')}</dt>
            <dd>10 MB</dd>
          </dl>
        }
      >
        <section className="dtUIFileUpload__section">
          <div className="dtUIFileUpload__root" {...getRootProps()}>
            <Icon className="icon col-2" type="upload-16" size={20} />
            <input className="absolute w-0 !block !sr-only" {...getInputProps()} data-testid="input-file" />
            <div className="col-9">{t('components.ui.fileupload.hint')}</div>
            <div className="col-1">{loading && <Spinner size="small" />} </div>
          </div>
        </section>
      </Tooltip>
    </>
  );
};

import cx from 'classnames';

import './ui-spinner.sass';

export interface Props {
  centered?: boolean;
  className?: string;
  color?: 'primary';
  size?: 'small' | 'large';
}

export function Spinner({ centered, className, color, size = 'large' }: Props) {
  return (
    <div className={cx('dtUiSpinner', size, color, className, { centered })} />
  );
}

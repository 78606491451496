import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { AdminChats } from './AdminChats';
import { AdminDocuments } from './AdminDocuments';

import { TabPanel } from '@components/ui/TabPanel/TabPanel';
import { User } from '@dieterApi/user/useUserQuery';
import { useTranslation } from 'react-i18next';
import './route-admin.sass';

interface Props {
  user?: User | null;
}

export default function Admin({ user }: Props) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('Chats');
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <div className="2xl:container p-6">
      <h3 className="text-xl font-medium">{t('route.admin.title', 'Administration')}</h3>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs onChange={handleChange} aria-label="lab API tabs example" value={tabValue}>
          <Tab label={t('route.admin.documents-tap-label', 'Dokumente')} value="Documents" />
          <Tab label={t('route.admin.chats-tab-label', 'Chats')} value="Chats" />
        </Tabs>
      </Box>
      <TabPanel index="Documents" value={tabValue}>
        <AdminDocuments user={user} />
      </TabPanel>
      <TabPanel index="Chats" value={tabValue}>
        <AdminChats user={user} />
      </TabPanel>
    </div>
  );
}

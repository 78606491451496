import { gql } from '@apollo/client';
import { questionFragment } from '@legalosApi/graphql/fragments/fragments';

export const QUESTIONNAIRE_RESULTS = gql`
  query questionnaireResults($input: QuestionnaireInput) {
    questionnaireResults(input: $input) {
      questionsList {
        ...QuestionFragment
      }
      nodesList {
        _id
        persistentId
        isActive
        children {
          connectionType
          nodeIds
        }
        consumedQuestionIds
        isAutomated
        automation {
          connectionType
          questionIds
          nodeIds
        }
      }
      treeData {
        _id
        persistentId
        isActive
        children {
          connectionType
          nodes
        }
        consumedQuestions {
          ...QuestionFragment
        }
        isAutomated
        automation {
          connectionType
          questions {
            ...QuestionFragment
          }
          nodes
        }
      }
    }
  }
  ${questionFragment}
`;

export const QUESTIONNAIRE_RESULTS_QUESTIONS = gql`
  query questionnaireResults($input: QuestionnaireInput) {
    questionnaireResults(input: $input) {
      questionsList {
        ...QuestionFragment
      }
    }
  }
  ${questionFragment}
`;

export const QUESTIONNAIRE_RESULTS_NODES = gql`
  query questionnaireResults($input: QuestionnaireInput) {
    questionnaireResults(input: $input) {
      nodesList {
        _id
        persistentId
        isActive
        children {
          connectionType
          nodeIds
        }
        consumedQuestionIds
        isAutomated
        automation {
          connectionType
          questionIds
          nodeIds
        }
      }
    }
  }
`;

export const QUESTIONNAIRE_RESULTS_TREE = gql`
  query questionnaireResults($input: QuestionnaireInput) {
    questionnaireResults(input: $input) {
      treeData {
        _id
        persistentId
        isActive
        children {
          connectionType
          nodes
        }
        consumedQuestions {
          ...QuestionFragment
        }
        isAutomated
        automation {
          connectionType
          questions {
            ...QuestionFragment
          }
          nodes
        }
      }
    }
  }
  ${questionFragment}
`;

import { gql, useQuery } from '@apollo/client';

export const GET_COMPANIES = gql`
  query GetCompanies($onlyActive: Boolean) {
    getCompanies(onlyActive: $onlyActive) {
      id
      name
      subscription
    }
  }
`;

export enum CompanySubscription {
  Ticket = 'Ticket',
  Basic = 'Basic',
  Premium = 'Premium',
}

export interface GetCompaniesInput {
  onlyActive?: boolean;
}

export interface GetCompaniesResult {
  getCompanies: {
    id: string;
    name: string;
    subscription: CompanySubscription;
  }[];
}

export function useCompaniesQuery({ variables }: { variables: GetCompaniesInput }) {
  return useQuery<GetCompaniesResult, GetCompaniesInput>(GET_COMPANIES, { variables });
}

import { gql, useMutation } from '@apollo/client';
import { useNavigation } from '@app/hooks/useNavigation';

import { useHistory } from 'react-router-dom';

const LOGOUT_USER = gql`
  mutation LogoutUser {
    logoutUser
  }
`;

export interface UserLogoutResult {
  logoutUser: boolean;
}

export function useUserLogoutMutation() {
  const { setNavigation } = useNavigation();
  const history = useHistory();
  return useMutation<UserLogoutResult>(LOGOUT_USER, {
    optimisticResponse: () => {
      history.push('/');
      return {
        logoutUser: true,
      };
    },

    update(cache) {
      cache.modify({
        fields: {
          getUser: () => null,
          getTasks: () => null,
        },
      });
    },
  });
}

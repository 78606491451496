// https://stackoverflow.com/questions/23199909/using-tolocalestring-in-node-js/23200062

export const formatNumber = (
  number: number,
  locale: string = "de_DE",
  isCurrency: boolean = false,
  useGrouping: boolean = true,
  fillUpDecimal: boolean = true,
) => {
  const decimalSeparator = locale.split("_")[0] === "en" ? "." : ",";
  const thousandsSeparator = locale.split("_")[0] === "en" ? "," : ".";
  let integer = number.toString().split(".")[0];
  let decimal = getDecimal(number);
  if (useGrouping) {
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
  }
  if (isCurrency) {
    if (decimal.length === 1 && fillUpDecimal) {
      decimal += "0";
    } else if (decimal.length > 2) {
      decimal = decimal.substr(0, 2);
    }
  } else {
    if (decimal.length > 6) {
      decimal = decimal.substr(0, 6);
    }
  }
  return "" + integer + (decimal.length > 0 ? decimalSeparator : "") + decimal;
};
const getDecimal = (number: number) => {
  return number.toString().split(".").length > 1
    ? number.toString().split(".")[1]
    : "";
};

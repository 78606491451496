import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cx from 'classnames';
import { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Spinner } from '..';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnColor?: BtnColors;
  spinner?: boolean;
  icon?: JSX.Element;
  animate?: boolean;
}

type BtnColors = 'gray' | 'primary' | 'secondary' | 'simple';

type BtnColorMapType = {
  [K in BtnColors]: {
    border: string;
    bg: string;
    hoverbg: string;
  };
};

export function CtaButton({ children, btnColor = 'secondary', icon, spinner, animate = false, ...props }: Props) {
  const btnColorMap: BtnColorMapType = {
    primary: { border: 'border-primary-root', bg: 'bg-primary-root', hoverbg: 'hover:bg-primary-100' },
    secondary: {
      border: 'border-secondary-root',
      bg: 'bg-secondary-root',
      hoverbg: 'hover:bg-secondary-100',
    },
    gray: { border: 'border-gray-300', bg: 'bg-gray-300', hoverbg: 'hover:bg-gray-100' },
    simple: { border: 'border-gray-300', bg: 'bg-gray-300', hoverbg: 'hover:bg-gray-100' },
  };
  const isSimple = btnColor === 'simple';

  // Configure the heartbeat animation
  const [{ scale }, api] = useSpring(() => ({ scale: 1 }));
  useEffect(() => {
    if (animate && !props.disabled) {
      // If the animate prop is true, start the animation
      api.start({
        from: { scale: 1 },
        to: async (next) => {
          while (1) {
            await next({ scale: 1.2, config: { duration: 200 } });
            await next({ scale: 1, config: { duration: 200 } });
            // add delay
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        },
      });
    } else {
      // If the animate prop is false, reset the scale
      api.start({ scale: 1 });
      api.stop();
    }
  }, [animate, api]);

  return (
    <animated.button
      style={animate ? { transform: scale.to((s) => `scale(${s})`) } : undefined}
      {...props}
      className={cx(
        'border-2 w-full rounded-md  transition duration-300  !p-0',
        !props.disabled ? 'hover:scale-[1.02] bg-white' : 'cursor-not-allowed bg-gray-100 text-gray-400',

        btnColorMap[btnColor].border
      )}
    >
      <div
        className={cx('flex items-center transition duration-300', !props.disabled && btnColorMap[btnColor].hoverbg)}
      >
        <span className="grow">{children}</span>
        {!isSimple ? (
          <div
            className={cx(
              'w-8 h-8 rounded-sm flex items-center text-center justify-center self-end text-white transition-transform duration-300',
              btnColorMap[btnColor].bg
            )}
          >
            {spinner ? <Spinner size="small" /> : icon || <ArrowForwardIosIcon />}
          </div>
        ) : (
          <div className="h-8 flex items-center px-2">{spinner && <Spinner size="small" />}</div>
        )}
      </div>
    </animated.button>
  );
}

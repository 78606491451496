import cx from 'classnames';
import { forwardRef, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Spinner } from '../Spinner/Spinner';
import './ui-button.sass';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  spinner?: boolean;
  invisible?: boolean;
  btnColor?: 'primary' | 'secondary' | 'white' | 'gray' | 'primary-dark' | 'primary-border' | 'success';
  icon?: JSX.Element;
  googleTriggerClassName?: string;
  animate?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, Props>(function X(
  {
    children,
    animate = false,
    className,
    disabled,
    onClick,
    spinner,
    googleTriggerClassName,
    invisible = false,
    btnColor = 'secondary',
    icon,
    ...props
  },
  ref
) {
  // Configure the heartbeat animation
  const [{ scale }, api] = useSpring(() => ({ scale: 1 }));
  useEffect(() => {
    if (animate) {
      // If the animate prop is true, start the animation
      api.start({
        from: { scale: 1 },
        to: async (next) => {
          while (1) {
            await next({ scale: 1.1, config: { duration: 200 } });
            await next({ scale: 1, config: { duration: 200 } });
            // add delay
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        },
      });
    } else {
      // If the animate prop is false, reset the scale
      api.start({ scale: 1 });
      api.stop();
    }
  }, [animate, api]);

  return (
    <animated.button
      {...props}
      style={{ transform: scale.to((s) => `scale(${s})`) }}
      className={cx(
        'dtUiButton',
        className,
        { btnDisabled: disabled, btnInvisible: invisible },
        btnColor,
        googleTriggerClassName
      )}
      disabled={disabled || spinner}
      onClick={disabled || spinner ? undefined : onClick}
      ref={ref}
    >
      <div className={cx('flex gap-10 justify-between items-center', googleTriggerClassName)}>
        {icon && <div className={cx('dtUiButton__icon', googleTriggerClassName)}>{icon}</div>}
        <div className={cx('flex-1', googleTriggerClassName)}>
          {spinner ? <Spinner centered color="primary" size="small" /> : null}
          {children}
        </div>

        {icon && <div className={googleTriggerClassName}></div>}
      </div>
    </animated.button>
  );
});

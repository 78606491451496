import { gql, useMutation } from '@apollo/client';

const DELETE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export interface DeleteUserResult {
  deleteUser: boolean;
}

export interface DeleteUserInput {
  userId: string;
}

export function useUserDeleteMutation() {
  return useMutation<DeleteUserResult, DeleteUserInput>(DELETE_USER, {
    fetchPolicy: 'no-cache',
    update(cache, { data }, { variables }) {
      // Depending on your needs, you can update the cache after deleting a user.
      // The following logic removes the user from the cache after deletion.
      if (data && data.deleteUser) {
        cache.evict({ id: `User:${variables?.userId}` });
        cache.gc();
      }
    },
  });
}

import { useUser } from '@app/context/UserContext';
import { useSendChatMessageMutation } from '@dieterApi/chat/useChatSendMessageMutation';
import { useChatStreamSubscription } from '@dieterApi/chat/useChatStreamSubscription.ts';
import { useInitiateChatMutation } from '@dieterApi/chat/useInitiateChatMutation';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { ChatMessage, UseChatStreamChatMessage, UseChatStreamInput } from './types';
import { useNavigation } from './useNavigation';
import { useQuestionaireRoute } from './useQuestionaireRoute';

const BOT_ERROR_MESSAGE = 'Ich bin leider gerade nicht verfügbar. Probiere es später noch einmal.';

function useChat(input: UseChatStreamInput) {
  const [isStreaming, setIsStreaming] = useState(false);
  const { user } = useUser();
  const {
    currentParams: { questionnaireId },
  } = useQuestionaireRoute();
  const {
    navigation: { scope },
  } = useNavigation();
  const onData = () => {
    setIsStreaming(true);
  };

  const makeId = () => {
    // we need a fallback to crypto.randomUUID() here, since it is not available in all contexts
    if (crypto.randomUUID) {
      return crypto.randomUUID();
    }
    return Math.random().toString(36).substring(2);
  };

  const [addChat, { data }] = useInitiateChatMutation();
  const { data: chatData, loading, error } = useChatStreamSubscription({ chatId: input.chatId, onData });

  const thisChat = user?.chats.find((chat) => chat.id === input.chatId);
  const thisChatMessagesWithId = thisChat?.messages.map((m) => ({ ...m, id: makeId() as string }));

  const [sendMessage] = useSendChatMessageMutation();
  const [messages, setMessages] = useState<UseChatStreamChatMessage[]>([]);
  const [formInput, setFormInput] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    setFormInput(e.target.value);
  };

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    await resetInputAndGetResponse();
  };

  const addMessage = (message: ChatMessage) => {
    const messageWithId = { ...message, id: makeId() as string };
    setMessages((messages) => [...messages, messageWithId]);

    return messageWithId;
  };

  const appendMessageToChat = (message: string) => {
    setMessages((messages) => {
      const latestMessage = messages[messages.length - 1];

      return [...messages.slice(0, -1), { ...latestMessage, content: message }];
    });
  };

  const fetchAndUpdateAIResponse = async (message: string) => {
    const messagesInput = [
      ...messages.map((m) => ({ content: m.content, role: m.role })),
      { role: 'user', content: message } as ChatMessage,
    ];
    addMessage({ content: '', role: 'assistant' });
    await sendMessage({
      variables: {
        chatId: input.chatId,
        messages: messagesInput,
        questionnaireId,
        scope: scope || 'dashboard',
      },
    });
  };

  useEffect(() => {
    if (chatData) {
      if (chatData.chatStream.content.includes('---END---')) {
        setIsStreaming(false);
        return;
      }
      appendMessageToChat(chatData.chatStream.content);
      input.handlers.onMessageAdded();
    }
  }, [chatData]);

  // reset chat messages when chatId changes
  useEffect(() => {
    setMessages(thisChatMessagesWithId || []);
  }, [input.chatId]);

  const submitMessage = async (message: string) => resetInputAndGetResponse(message);

  const resetInputAndGetResponse = async (message?: string) => {
    // setIsStreaming(true);
    const addedMessage = addMessage({
      content: message ?? formInput,
      role: 'user',
    });
    await input.handlers.onMessageAdded?.(addedMessage);
    setFormInput('');

    try {
      await fetchAndUpdateAIResponse(message ?? formInput);
    } catch {
      appendMessageToChat(BOT_ERROR_MESSAGE);

      await input.handlers.onMessageAdded?.(addedMessage);
    }
  };

  return {
    addChat,
    messages,
    setMessages,
    input: formInput,
    setInput: setFormInput,
    handleInputChange,
    handleSubmit,
    submitMessage,
    isStreaming,
  };
}

export default useChat;

import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { UserFlowItem } from '@components/ui/UserFlowItem/UserFlowItem';

import { getTopicStates } from '@app/utils/getTopicStates';
import { CtaButton } from '@components/ui/CtaButton/CtaButton';
import { NewBadge } from '@components/ui/NewBadge/NewBadge';
import { DocumentConfirmation, UserQuestionnaireApplicationTopic } from '@dieterApi/user/useUserQuery';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { UserFlowItemType } from '../../constants';
import { Dashboard } from '../../useDashboard';
import './documentitem.sass';

interface Props {
  questionnaire: UserQuestionnaireApplicationTopic;
  dashboard: Dashboard;
  index: number;
}

export function DocumentItem({ questionnaire, dashboard, index }: Props) {
  const { t } = useTranslation();
  const tasks = useTasks();
  const { navigation, setNavigation } = useNavigation();
  const { user } = useUser();

  const TOPIC_LOCKED_TEXT = t('dashboard.topic.forms.formitem.locked');

  // when the corresponding topic is disabled (when a user is not a subscriber), we want to open the CallToAction
  const topic = user?.topics.find((topic) => topic.id === questionnaire.application.topic.id);
  const { isLocked } = topic ? getTopicStates(topic, user) : { isLocked: false };
  const isPublic = questionnaire.application.tags?.map((t) => t.tag).includes('public');
  const justFinished = navigation.justFinishedQuestionnaireRemoteId === questionnaire.questionnaireId;
  const thisModifiedAt = questionnaire.updatedAt;

  const openDocument = () => {
    if (isLocked && !isPublic) {
      topic && navigation.roadBlock.open(topic);
    } else {
      setNavigation((nav) => {
        nav.justFinishedQuestionnaireRemoteId = null;
        nav.preferredTab = undefined;
      });
    }
    setNavigation((nav) => {
      nav.documentPaper = {
        modalOpen: true,
        questionnaire,
        index,
      };
    });
  };

  const documentConfirmations = questionnaire.documentConfirmations.filter(
    (dc) => !dc.documentNumber || dc.documentNumber === index + 1
  );
  const hasConfirmations = documentConfirmations.length > 0;

  const requiredTasks =
    tasks?.filter((task) => task.application?.id === questionnaire.application.id && task.documentRequirement) || [];
  const requiredTasksDone = requiredTasks.length == 0 || requiredTasks.every((task) => task.done);
  const isNew =
    (navigation.newDocuments[questionnaire.id] && navigation.newDocuments[questionnaire.id][index]) || false;

  return (
    <>
      <UserFlowItem
        type={UserFlowItemType.document}
        title={t('common.section') + ': ' + questionnaire.application.topic.title}
        // isNew={(navigation.newDocuments[questionnaire.id] && navigation.newDocuments[questionnaire.id][index]) || false}
        topRightElements={
          <div className="flex gap-3 items-center  w-full">
            <div className="text-xs grow">
              {thisModifiedAt &&
                t('dashboard.topic.forms.formitem.lastchanged') +
                  DateTime.fromJSDate(new Date(thisModifiedAt))
                    .setLocale(user?.locale || 'en')
                    .toRelative()}
            </div>
            {hasConfirmations && (
              <div className="dtDocumentItemHeader__printButton">
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  title={<DocumentConfirmationList documentConfirmations={documentConfirmations} />}
                >
                  <PeopleIcon />
                </Tooltip>
              </div>
            )}
            {isNew && <NewBadge />}
          </div>
        }
        bodyElements={
          <div className="w-full flex flex-col gap-2">
            <div className="grow flex flex-col gap-2">
              {/* <span className="text-gray-400 text-[0.8em] leading-3">{t('common.section') + ': ' + topic?.title}</span> */}
              <div className="text-lg md:text-lg">
                <div>{questionnaire.application.documentLabels[index]}</div>
              </div>
            </div>
            {/* <div className="grow"></div> */}
            <div className="flex items-center gap-2 w-full">
              {isLocked && !isPublic && (
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={TOPIC_LOCKED_TEXT}>
                  <LockIcon className="text-secondary-600" fontSize="small" />
                </Tooltip>
              )}
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={t('dashboard.topic.documents.documentitem.tooltip')}
                disableHoverListener={requiredTasksDone}
              >
                <div className="w-full">
                  <CtaButton
                    disabled={!requiredTasksDone}
                    onClick={openDocument}
                    btnColor="secondary"
                    data-testid={`show-document-button-${index + 1}`}
                    animate={justFinished}
                  >
                    {t('common.show')}
                  </CtaButton>
                </div>
              </Tooltip>
            </div>
          </div>
        }
      />
    </>
  );
}

export const DocumentConfirmationList = ({
  documentConfirmations,
}: {
  documentConfirmations: DocumentConfirmation[];
}) => {
  const { t } = useTranslation();
  return (
    <div className="dtDocumentConfirmationList">
      <p>{t('dashboard.topic.documents.documentconfirmationlist')}</p>
      <div className="dcList">
        {documentConfirmations.map((dc) => (
          <span className="dcEntry" key={dc.createdAt}>
            {dc.name}
          </span>
        ))}
      </div>
    </div>
  );
};

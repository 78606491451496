import ISimpleDateObject from '@legalosApi/types/ISimpleDateObject';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Props } from './QuestionInput';
import './quest-input-date.scss';

export function DateInput(props: Props<ISimpleDateObject>) {
  const ref = React.createRef<HTMLInputElement>();
  React.useEffect(() => {
    const { current } = ref;
    if (current) current.focus();
  });

  const handleChange = (value: Date) => {
    if (value instanceof Date) {
      props.onChange({
        day: value.getDate(),
        month: value.getMonth(),
        year: value.getFullYear(),
      });
    }
  };

  useEffect(() => {
    if (!props.value) {
      handleChange(new Date());
    }
  }, []);

  const selected = new Date();

  if (props.value) {
    selected.setUTCFullYear(props.value.year, props.value.month, props.value.day);
  }

  return <DatePicker className="dtUiInput" dateFormat="dd.MM.yyyy" selected={selected} onChange={handleChange} />;
}

export async function hashObject(obj: any) {
  // Convert the object to a JSON string
  const str = JSON.stringify(obj);

  // Encode the string as a Uint8Array
  const buffer = new TextEncoder().encode(str);

  // Compute the hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);

  // Convert the hash to a hexadecimal string
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}

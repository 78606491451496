import { Skeleton } from '@mui/material';

export function DocumentSkeleton() {
  const NLINES = 50;
  return (
    <>
      <Skeleton width={60} className="mt-20" />
      <Skeleton width={100} className="mt-20" />
      <Skeleton className="mt-20" />
      {[...Array(NLINES).keys()].map((i) => {
        return <Skeleton key={i} />;
      })}
    </>
  );
}

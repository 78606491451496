import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import CloseIcon from '@mui/icons-material/Close';
import cx from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function LimitedCouponOffer() {
  const { user } = useUser();
  const {
    navigation: { limitedOfferDismissed: dismissed },
    setNavigation,
  } = useNavigation();
  const { t } = useTranslation();

  const coupons = user?.company.coupons;
  // a coupon is active when it was created with the last 3 days
  const activeCoupon = coupons?.find((coupon) => {
    const created = new Date(coupon.createdAt);
    const now = new Date();
    const diff = now.getTime() - created.getTime();
    const days = diff / (1000 * 60 * 60 * 24);
    return days < 3;
  });

  const timeUntilExpiration = activeCoupon
    ? new Date(activeCoupon?.createdAt).getTime() + 1000 * 60 * 60 * 24 * 3 - new Date().getTime()
    : 0;

  const [timeLeft, setTimeLeft] = useState(timeUntilExpiration);

  // Convert milliseconds into hours, minutes, and seconds
  const formatTimeLeft = (time: number): string => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor(time / (1000 * 60 * 60));

    const _hours = hours < 10 ? '0' + hours : hours;
    const _minutes = minutes < 10 ? '0' + minutes : minutes;
    const _seconds = seconds < 10 ? '0' + seconds : seconds;

    return ` ${_hours}h ${_minutes}m ${_seconds}s`;
  };

  // Update the countdown every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleDismiss = () => {
    setNavigation((nav) => void (nav.limitedOfferDismissed = true));
  };

  const handleOpen = () => {
    setNavigation((nav) => void (nav.limitedOfferDismissed = false));
  };

  if (!activeCoupon) return null;

  return (
    <ShimmerEffect>
      <div
        className={cx(
          'bg-transparent w-full lg:rounded-md shadow-md relative border-[10px] border-primary-300',
          dismissed ? 'p-1 cursor-pointer hover:shadow-lg transition-all' : 'p-4'
        )}
        onClick={handleOpen}
      >
        {!dismissed && (
          <button
            className="absolute top-0 right-0 text-gray-500 hover:bg-secondary-500 rounded-full p-1 transition-all"
            onClick={(e) => {
              e.stopPropagation();
              handleDismiss();
            }}
          >
            <CloseIcon />
          </button>
        )}
        <div className={cx('text-center', dismissed ? 'text-sm font-normal' : 'text-2xl mb-4')}>
          {t('dashboard.info.coupon.header')}
        </div>
        {!dismissed && (
          <>
            <p className="text-lg text-center">
              {t('dashboard.info.coupon.offer')} <strong>50%</strong>!
            </p>
            <h2 className="text-center text-primary-root !mb-4">{activeCoupon.code}</h2>
            <p className="text-gray-600 text-sm text-center">{t('dashboard.info.coupon.description')}</p>
            {/* Countdown Timer */}
            <div className="text-lg text-center">{t('dashboard.info.coupon.expires')}:</div>
          </>
        )}
        <div className="text-xl text-center">{formatTimeLeft(timeLeft)}</div>
      </div>
    </ShimmerEffect>
  );
}

const ShimmerEffect = ({ children }: { children: ReactElement }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating((prev) => !prev); // Toggle the animation state
    }, 5000); // Change the animation every 5 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className={`bg-white rounded-lg shadow-md relative overflow-hidden   ${isAnimating ? 'animate-shimmer' : ''}`}>
      {children}
      <style>{`
        @keyframes shimmerAnimation {
          0% {
            background-position: -100% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
        .animate-shimmer {
          background-image: linear-gradient(
            45deg,
            transparent 25%,
            rgba(33, 33, 33, 0.1) 50%,
            transparent 75%,
            transparent 100%
          );
          background-color: white;
          background-size:
            250% 250%,
            100% 100%;
          background-repeat: no-repeat;
          transition: background-position 0s ease;
          animation: shimmerAnimation 800ms ease-in-out forwards;
        }
      `}</style>
    </div>
  );
};

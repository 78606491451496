import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { Tooltip } from '@mui/material';

import { useMessages } from '@app/context/MessageContext';
import { useTranslation } from 'react-i18next';
import { TaskList } from './Tasks/TaskList';
import { TasksSkeleton } from './Tasks/TaskSkeleton';
import './route-dashboard.sass';

export function Overview() {
  const { t } = useTranslation();
  const { tutorial } = useMessages();
  const { user } = useUser();
  const tasks = useTasks();

  // only tasks which are enabled for the user
  const filteredTasks = tasks
    ?.filter((task) =>
      user?.topics
        // .filter((app) => app.status !== 'DISABLED')
        .map((topic) => topic.id)
        .includes(task.topic.id)
    )
    .map((task) => ({
      ...task,
      app: user?.topics
        .find((topic) => topic.id === task.topic.id)
        ?.applications.find((app) => app.id === task.application?.id),
    }))
    .sort((a, b) => (a.app?.order || 0) - (b.app?.order || 0));
  const noTasks = !tasks || tasks.length === 0;

  return (
    <div className="">
      <div className="flex justify-between">
        <h2 className="text-xl text-gray-500">{t('dashboard.overview.welcome')}</h2>
        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={t('dashboard.overview.tooltip')}>
          <button
            className="flex items-center gap-1 text-gray-400 hover:text-primary-root cursor-pointer"
            onClick={tutorial.open}
            data-testid="tutorial-open-button"
          >
            <span>{t('dashboard.overview.tutorial')}</span>

            <HelpOutlineRoundedIcon />
          </button>
        </Tooltip>
      </div>
      <p className="mt-4 text-base">{t('dashboard.overview.description')}</p>
      {noTasks ? <TasksSkeleton /> : tasks && <TaskList tasks={filteredTasks} />}
    </div>
  );
}

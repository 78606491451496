import { Icon } from '@components/ui/Icon/Icon';
import { useTranslation } from 'react-i18next';

export interface Props {
  duration: number;
}

export function Timer({ duration }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'components.block.navigation.timer' });

  return (
    <div className="flex-shrink self-center">
      <div className="flex border-[1.5px] border-gray-400 text-xs whitespace-nowrap py-[2px] text-gray-500 items-center gap-1 rounded-full h-full min-h-5 justify-content-around px-2">
        <Icon type="clock-16" />
        <span className="max-md:text-xs">{t('duration', { duration })}</span>
      </div>
    </div>
  );
}

import { ApolloQueryResult, MutationFunction } from '@apollo/client';
import AllowedValueTypes from '@legalosApi/types/AllowedValueTypes';
import pDebounce from 'p-debounce';
import * as React from 'react';

export type UpdateUserInputValueHandlerType = (
  userInputId: string,
  value: AllowedValueTypes | AllowedValueTypes[]
) => void;

export type UpdateQuestionDebounced = (
  questionnaireId: string,
  questionId: string,
  value: AllowedValueTypes | AllowedValueTypes[]
) => void;

export interface IQuestionsUpdateInput {
  questionnaireId: string;
  questions: { _id: string; value: AllowedValueTypes }[];
  token?: string;
  locale?: string;
}

export interface ISharedQuestionsUpdateInput {
  workspaceId: string;
  sharedQuestions: { _id: string; value: AllowedValueTypes }[];
}

export interface IQuestionUpdateInput {
  questionnaireId: string;
  _id: string;
  value: AllowedValueTypes | AllowedValueTypes[];
  token?: string;
}
interface IUserInputUpdateOptions {
  _id: string;
  value: AllowedValueTypes | AllowedValueTypes[];
}

export interface ISharedQuestionUpdateInput {
  _id: string;
  workspaceId: string;
  value: AllowedValueTypes | AllowedValueTypes[];
}
type UpdateInput =
  | IQuestionUpdateInput
  | IQuestionsUpdateInput
  | IUserInputUpdateOptions
  | ISharedQuestionUpdateInput
  | ISharedQuestionsUpdateInput;

function isQuestionOptions(options: UpdateInput): options is IQuestionUpdateInput {
  return (
    (options as IQuestionUpdateInput).questionnaireId !== undefined &&
    (options as IQuestionUpdateInput).value !== undefined
  );
}

export const errorHandlers: Array<{ (message: string): void }> = [(message) => console.error(message)];

// const showAlert = (message: string) => errorHandlers.forEach((handler) => handler(message));

function isQuestionsOptions(options: UpdateInput): options is IQuestionsUpdateInput {
  return (options as IQuestionsUpdateInput).questions !== undefined;
}

function isSharedQuestionOptions(options: UpdateInput): options is ISharedQuestionUpdateInput {
  return (
    (options as ISharedQuestionUpdateInput).workspaceId !== undefined &&
    (options as ISharedQuestionUpdateInput).value !== undefined
  );
}

function isSharedQuestionsOptions(options: UpdateInput): options is ISharedQuestionsUpdateInput {
  return (options as ISharedQuestionsUpdateInput).sharedQuestions !== undefined;
}

/**
 * Function for debouncing any user input that is directly bound to apollo data.
 * Useful for reducing number of calls to the server when a user is typing etc.
 *
 * Why is this so complicated? Debouncing means that apollo doesn't get notified about
 * any changes until the user has stopped typing and the debounce delay has passed. This
 * in turn means that some features of apollo won't work as expected.
 *
 * - Auto abort of inflight mutations no longer work when the same mutation is called again.
 *   This is important because the timing/order of the response can be different from the order
 *   that the requests were made in. We don't want a mutation response that should've been cancelled
 *   to overwrite the current value.
 * - Optimistic responses declared on the actual mutation won't work because the mutation call
 *   itself is delayed. The update of the local cache needs to happen outside of the debounce
 *
 * @param mutationFunction the main apollo mutation call
 * @param refetch apollo query for re-fetching latest value
 * @param optimisticCacheUpdate writes the value to cache before the debounced
 *   mutation is performed to provide direct feedback to the user
 */

export const useDebouncedUpdate = <TData, TInput extends UpdateInput>(
  mutationFunction: MutationFunction<TData, TInput>,
  optimisticCacheUpdate: (options: TInput) => void,
  refetch?: (options?: {
    input: {
      _id: string;
    };
  }) => Promise<ApolloQueryResult<any>>
) => {
  const [isUpdateInProgress, setIsUpdateInProgress] = React.useState<boolean>(false);
  const abortController = React.useRef<AbortController>();

  const update = React.useCallback(
    (input: UpdateInput) => {
      let updateInput: UpdateInput | undefined;
      let refetchInput: { _id: string } | undefined;
      if (isQuestionsOptions(input)) {
        updateInput = input;
        refetchInput = { _id: input.questionnaireId };
      } else if (isQuestionOptions(input)) {
        updateInput = {
          _id: input._id,
          questionnaireId: input.questionnaireId,
          value: input.value,
        };
        refetchInput = { _id: input.questionnaireId };
      } else if (isSharedQuestionOptions(input)) {
        updateInput = {
          _id: input._id,
          workspaceId: input.workspaceId,
          value: input.value,
        };
        refetchInput = { _id: input.workspaceId };
      } else if (isSharedQuestionsOptions(input)) {
        updateInput = input;
        refetchInput = { _id: input.workspaceId };
      }

      if (!updateInput || !refetchInput) {
        throw new Error('Unsupported input type in debounced update');
      }

      const controller = new AbortController();
      abortController.current = controller;
      return mutationFunction({
        variables: {
          input: updateInput,
        } as any,
        context: { fetchOptions: { signal: controller.signal } },
      })
        .then(() => {
          setIsUpdateInProgress(false);
        })
        .catch((reason) => {
          console.log('🐞 GraphQL: update failed', reason);
          // showAlert(String(reason));
          // we have to refetch here, as the change is not undone by Apollo in case the mutation fails
          setIsUpdateInProgress(false);
          // refetch
          //   ? refetch({
          //       input: refetchInput!,
          //     }).then(() => {
          //       setIsUpdateInProgress(false);
          //     })
          //   : setIsUpdateInProgress(false);
        });
    },
    [mutationFunction, optimisticCacheUpdate, setIsUpdateInProgress, abortController, refetch]
  );

  const debouncedUpdate = React.useCallback(pDebounce(update, 50), [update]);

  return {
    debouncedUpdate: React.useCallback(
      (input: TInput, immediate?: boolean) => {
        if (abortController.current) {
          abortController.current.abort();
        }
        setIsUpdateInProgress(true);
        optimisticCacheUpdate(input);
        return immediate ? update(input) : debouncedUpdate(input);
      },
      [abortController, setIsUpdateInProgress, optimisticCacheUpdate, debouncedUpdate]
    ),
    isUpdateInProgress,
  };
};

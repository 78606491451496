import { useWhitelabelConfigLogoDeleteMutation } from "@dieterApi/whitelabel/useWhitelabelConfigLogoDeleteMutation";
import { useWhitelabelConfigLogoUploadMutation } from "@dieterApi/whitelabel/useWhitelabelConfigLogoUploadMutation";
import React from "react";
import { Button } from "../Button/Button";
import { t } from "i18next";
import { useSnackbar } from "@app/hooks/useSnackbar";

interface Props {
  currentLogoB64: string | null;
}

export const LogoUpload = ({ currentLogoB64: currentLogoB64 }: Props) => {
  const [file, setFile] = React.useState<File | null>(null);
  const [uploadCompanyLogo, { loading: ulLoading, error: ulError, data: ulData }] = useWhitelabelConfigLogoUploadMutation();
  const [deleteCompanyLogo, { loading: delLoading, error: delError }] = useWhitelabelConfigLogoDeleteMutation();

  // When deleting an old logo before uploading a new logo, we need a deletion mutation that does not refetch the user
  // query, lest we do two refetches in a row, leading to a state race.
  // Using a separate mutation allows us to tie its loading state independently to the upload button.
  const [deleteCompanyLogoNoRefetch, { loading: delNoRefetchLoading, error: delNoRefetchError }] = useWhitelabelConfigLogoDeleteMutation(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const maxSize = 1024 * 1024 * 5; // 5MB
      if (file.size > maxSize) {
        enqueueSnackbar(t('route.whitelabel.logoSizeError', 'File size exceeds 5MB.'), { variant: 'error' });
        return;
      }
      try {
        if (currentLogoB64) deleteCompanyLogoNoRefetch();
        await uploadCompanyLogo({
          variables: {
            file,
            size: file.size, // NOTE: this is apparently in bytes
          },
        });
      } catch (err) {
        enqueueSnackbar(t('route.whitelabel.logoUploadError', 'Error uploading logo.'), { variant: 'error' });
      }
    }
  };

  const handleDelete = async (noError: boolean) => {
    try {
      await deleteCompanyLogo();
    } catch (err) {
      if (!noError)
        enqueueSnackbar(t('route.whitelabel.logoDeleteError', 'Error deleting logo.'), { variant: 'error' });
    }
  }

  const handleSetLogoBlank = async () => {
    // the b64 for a transparent 1x1 png image
    const base64Data = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9bpVIqDlZQcchQneyiUhxrFYpQIdQKrTqYXPoFTRqSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxdnBSdJES/5cUWsR4cNyPd/ced+8Af7PKVLMnAaiaZWRSSSGXXxWCrwhhCGGMIC4xU58TxTQ8x9c9fHy9i/Es73N/jn6lYDLAJxAnmG5YxBvE8U1L57xPHGFlSSE+J5406ILEj1yXXX7jXHLYzzMjRjYzTxwhFkpdLHcxKxsq8QxxVFE1yvfnXFY4b3FWq3XWvid/YbigrSxzneYYUljEEkQIkFFHBVVYiNGqkWIiQ/tJD/+o4xfJJZOrAkaOBdSgQnL84H/wu1uzOD3lJoWTQO+LbX+MA8FdoNWw7e9j226dAIFn4Err+GtNYPaT9EZHix4BA9vAxXVHk/eAyx1g+EmXDMmRAjT9xSLwfkbflAcGb4HQmttbex+nD0CWukrfAAeHwESJstc93t3X3du/Z9r9/QDObXLLp4f6dgAAAAZiS0dEAPwA0gAM8zyrVgAAAAlwSFlzAAAN1wAADdcBQiibeAAAAAd0SU1FB+gKFQ0HHEgs1ZgAAAALSURBVAjXY2AAAgAABQAB4iYFmwAAAABJRU5ErkJggg==';

    // base64 to binary file
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    setFile(new File([new Blob([byteArray], { type: 'image/png' })], 'blank.png', { type: 'image/png' }));

    // NOTE: not sure if we should upload it straight away or let the user decide.
    // await handleUpload();
  }

  return (
    <div className="bg-white p-6 rounded-lg space-y-6">
      {/* Current Logo and Preview Section */}
      <div className="flex gap-8">
        <div className="flex-1">
          <h3 className="text-lg font-bold mb-2">
            {t('route.whitelabel.currentLogo', 'Current Logo')}
          </h3>
          {currentLogoB64 ? (
            <img
              className="w-36 h-auto rounded-md"
              src={currentLogoB64}
              alt="Current logo"
            />
          ) : (
            <div className="text-gray-600 italic">
              {t('route.whitelabel.noLogo', 'No logo')}
            </div>
          )}
        </div>

        <div className="flex-1">
          <h3 className="text-lg font-bold mb-2">
            {t('route.whitelabel.preview', 'Preview')}
          </h3>
          {file ? (
            <img
              className="w-36 h-auto rounded-md"
              src={URL.createObjectURL(file)}
              alt="Preview"
            />
          ) : (
            <div className="text-gray-600 italic">
              {t('route.whitelabel.noPreview', 'Nothing to preview')}
            </div>
          )}
        </div>
      </div>

      {/* Upload Section with Load Blank */}
      <div className="mb-6">
        <label htmlFor="logo-upload" className="block text-sm font-medium text-gray-700 mb-2">
          {t('route.whitelabel.uploadLabel', 'Upload a new logo')}
        </label>
        <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
          <input
            id="logo-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full sm:flex-grow text-sm text-gray-500
                     file:mr-4 file:py-2 file:px-4
                     file:rounded-md file:border-0
                     file:bg-primary-light file:text-primary-dark
                     hover:file:bg-primary-hover cursor-pointer"
          />
          <button
            onClick={handleSetLogoBlank}
            className="text-sm text-primary-dark text-left sm:text-center underline hover:text-primary-dark/80"
            disabled={ulLoading || delNoRefetchLoading || delLoading}
            title={t('route.whitelabel.blankLogoTooltip', 'Choose this if you don’t want any logo displayed.')}
          >
            {t('route.whitelabel.setBlank', 'Load Blank Logo')}
          </button>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="space-y-3">
        <Button
          onClick={handleUpload}
          className="w-full text-base font-semibold"
          spinner={ulLoading || delNoRefetchLoading}
          disabled={ulLoading || delNoRefetchLoading || !file}
        >
          {t('route.whitelabel.upload', 'Save Custom Logo')}
        </Button>

        <Button
          onClick={() => handleDelete(false)}
          className="w-full text-base font-semibold"
          spinner={delLoading}
          disabled={delLoading || ulLoading || delNoRefetchLoading || !currentLogoB64}
        >
          {t('route.whitelabel.delete', 'Delete Custom Logo')}
        </Button>
      </div>
    </div>
  );

};


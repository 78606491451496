interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      data-testid={`tabpanel-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

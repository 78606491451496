import { useEffect, useState } from 'react';

export function useHealthCheck(): boolean {
  const [isHealthy, setIsHealthy] = useState(true);

  const checkHealth = async () => {
    try {
      const response = await fetch(`/api/health`);
      setIsHealthy(response.status == 200);
    } catch (error) {
      setIsHealthy(false);
    }
  };

  useEffect(() => {
    checkHealth();
  }, []);

  return isHealthy;
}

import cx from 'classnames';

interface Props {
  active?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Toggle({ active, onChange }: Props) {
  return (
    <label htmlFor="toggleTwo" className="flex cursor-pointer select-none items-center">
      <div className="relative">
        <input type="checkbox" id="toggleTwo" className="sr-only" checked={active} onChange={onChange} />
        <div className={cx('block h-8 w-14 rounded-full', active ? 'bg-primary-root' : 'bg-gray-500')}></div>
        <div
          className={`absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition ${active ? 'translate-x-6' : ''}`}
        ></div>
      </div>
    </label>
  );
}

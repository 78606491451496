import './route-error.sass';
import { Trans, useTranslation } from 'react-i18next';

export function Unavailable() {
  const { t } = useTranslation();
  return (
    <div className="dtRoute__unavailable container flex flex-col md:flex-row mx-auto px-7">
      <div className="flex-1">
        <img src="/assets/images/dieter_banane.png" />
      </div>
      <div className="flex-2 flex flex-col gap-10 justify-center">
        <Trans t={t} i18nKey="route.unavailable">
          <h2 className="mt-20 text-2xl font-semibold">
            Dieter ist gerade mit Wartungsarbeiten total beschäftigt.
          </h2>
          <p>Bald kümmert er sich wieder um deinen Datenschutz.</p>
        </Trans>
      </div>
    </div>
  );
}

import '@locales/i18n';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';

import App from '@app/app.tsx';
import './styles/setup.sass';
import './styles/tailwind.css';

// export const stripePromise = loadStripe(
//   import.meta.env.VITE_STRIPE_PUBLIC_KEY || 'STRIPE_KEY_NOT_SET'
// );

const history = createBrowserHistory();

ReactDOM.createRoot(document.getElementById('root')!).render(
  // @ts-ignore
  <Router history={history}>
    <App />
  </Router>
);

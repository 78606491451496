import { gql } from "@apollo/client";

export const ENTITY_UPDATE = gql`
  mutation entityUpdate($input: EntityUpdateInput) {
    entityUpdate(input: $input) {
      _id
      userId
      type
      contractInstanceIds
      matterIds
      data
    }
  }
`;

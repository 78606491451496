import qs from 'qs';
import * as React from 'react';
import { generatePath as routerGeneratePath, useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { FULL_OPTIONAL_PATH } from '../constants/routes';

/**
 * A React hook to query and update the route parameters based on the currently active parameters.
 * @return {object} containing
 * - The method `generatePath` allows to generate a new path and patch individual route parameters.
 *   Individual parameters can be set to new values or be removed (setting them to null or undefined).
 * - The method `navigateToPath` generates a new path using `generatePath` and navigates the browser to it.
 * - The object `currentParams`The current route parameters extracted from the route, the location hash, and the query parameters:
 * ```
 * currentParams: { appId, questionnaireId, sectionId, focusedSubSectionId, activeQuestionId },
  ```
**/
const useLegalOSRoutePath = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{
    appId?: string;
    questionnaireId?: string;
    sectionId?: string;
  }>(FULL_OPTIONAL_PATH);
  const {
    appId: currentAppId,
    questionnaireId: currentQuestionnaireId,
    sectionId: currentSectionId,
  } = match?.params || {};
  const currentSubSectionId = location.hash?.slice(1) || undefined;
  const currentActiveQuestionId = qs
    .parse(location.search, {
      ignoreQueryPrefix: true,
    })
    ?.activeQuestion?.toString();
  /**
   * Generates a route path patched with the supplied arguments. Fills in the current route parameters if one is not supplied.
   * You can disable this interpolation with the current values for individual parameters by setting them to null or undefined
   * explicitly.
   */
  const generatePath = React.useCallback(
    ({
      appId = currentAppId,
      questionnaireId = currentQuestionnaireId,
      sectionId = currentSectionId,
      focusedSubSectionId = currentSubSectionId,
      activeQuestionId = currentActiveQuestionId,
    }: {
      appId?: string | null;
      questionnaireId?: string | null;
      sectionId?: string | null;
      focusedSubSectionId?: string | null;
      activeQuestionId?: string | null;
    }) =>
      routerGeneratePath(FULL_OPTIONAL_PATH, {
        appId: appId || undefined,
        questionnaireId: questionnaireId || undefined,
        sectionId: sectionId || undefined,
      }) +
      // Add active question ID query parameter, if set
      (activeQuestionId
        ? qs.stringify(
            {
              activeQuestion: activeQuestionId,
            },
            { addQueryPrefix: true }
          )
        : '') +
      // Add focused sub section ID to query hash, if set
      (focusedSubSectionId ? '#' + focusedSubSectionId : ''),

    [currentAppId, currentQuestionnaireId, currentSectionId, currentSubSectionId, currentActiveQuestionId]
  );
  /**
   * Navigates to a new path patched with the arguments. Fills in the current route parameters if one is not supplied.
   * You can disable this interpolation with the current values for individual parameters by setting them to null or undefined
   * explicitly.
   */
  const navigateToPath = React.useCallback(
    (parameters: {
      appId?: string | null;
      questionnaireId?: string | null;
      sectionId?: string | null;
      focusedSubSectionId?: string | null;
      activeQuestionId?: string | null;
    }) => {
      history.push(generatePath(parameters));
    },
    [history, generatePath]
  );

  return {
    generatePath,
    navigateToPath,
    /**
     * The currently active route parameters
     */
    currentParams: {
      appId: currentAppId,
      questionnaireId: currentQuestionnaireId,
      sectionId: currentSectionId,
      focusedSubSectionId: currentSubSectionId,
      activeQuestionId: currentActiveQuestionId,
    },
  };
};

export default useLegalOSRoutePath;

import { NotificationModal } from '@components/ui';
import { ModalProps } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

interface Props extends Omit<ModalProps, 'children'> {}

export function MaxReachedModal({ ...props }: Props) {
  const { t } = useTranslation();

  return (
    <NotificationModal {...props}>
      <div className="flex flex-col gap-5 p-5 md:p-12">
        <div className="h3">{t('dashboard.multitenanttable.maxreachedmodal.title')} </div>
        <p>
          <Trans t={t} i18nKey={'dashboard.multitenanttable.maxreachedmodal.body'}>
            Sie haben die maximale Anzahl an Mandanten erreicht. Bitte kontaktieren Sie uns über die Chatfunktion oder
            per <a href="mailto:info@dieter-datenschutz.de">E-mail</a>, um weitere Mandanten hinzuzufügen.
          </Trans>
        </p>
      </div>
    </NotificationModal>
  );
}

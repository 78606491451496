import { gql, useMutation } from '@apollo/client';
import { Task, TASK_FIELDS } from './useTaskQuery';

export const SET_TASK_STATUS = gql`
  ${TASK_FIELDS}
  mutation SetTaskStatus($taskId: String!, $status: Boolean!) {
    setTaskStatus(taskId: $taskId, status: $status) {
      ...TaskFields
    }
  }
`;

export interface SetTaskStatusResult {
  setTaskStatus: Task;
}

export interface SetTaskStatusInput {
  taskId: string;
  status: boolean;
}

export function useTaskSetStatusMutation() {
  return useMutation<SetTaskStatusResult, SetTaskStatusInput>(SET_TASK_STATUS, {
    refetchQueries: ['GetTasks'],
  });
}

import { gql, useMutation } from '@apollo/client';
import { ConsentFrom } from './useQuestionnaireDocumentShareQuery';

export const PROCESSING_CONSENT_FIELDS = gql`
  fragment ProcessingConsentFields on ProcessingConsent {
    id
    name
    createdAt
    localQuestionnaireId
    consent
    purpose
    dataCategories
    withdrawn
  }
`;

// Define the GraphQL mutation
const ADD_PROCESSING_CONSENT = gql`
  ${PROCESSING_CONSENT_FIELDS}
  mutation AddProcessingConsent(
    $name: String!
    $token: String!
    $questionnaireId: String
    $consent: Boolean!
    $purpose: String!
    $consentFrom: ConsentFrom!
    $dataCategories: [String!]!
  ) {
    addProcessingConsent(
      name: $name
      token: $token
      questionnaireId: $questionnaireId
      consent: $consent
      purpose: $purpose
      consentFrom: $consentFrom
      dataCategories: $dataCategories
    ) {
      ...ProcessingConsentFields
    }
  }
`;

// TypeScript interfaces for input and result
export interface AddProcessingConsentInput {
  name: string;
  token: string;
  questionnaireId?: string;
  consent: boolean;

  purpose: string;
  consentFrom: ConsentFrom; // Replace with appropriate enum or string type
  dataCategories: string[];
}

export interface ProcessingConsent {
  id: string;
  name: string;
  createdAt: string;
  localQuestionnaireId: string;
  consent: boolean;
  purpose: string;
  withdrawn: boolean;
  dataCategories: string[];
}

export interface AddProcessingConsentResult {
  addProcessingConsent: ProcessingConsent;
}

// React Hook
export function useAddProcessingConsentMutation() {
  return useMutation<AddProcessingConsentResult, AddProcessingConsentInput>(ADD_PROCESSING_CONSENT);
}

import { useQuestionaireRoute } from '@app/hooks/useQuestionaireRoute';
import { Icon, IconType } from '@components/ui/Icon/Icon';
import { getQuestions } from '@legalosApi/hooks/useUpdateSingleQuestion';
import { ISection } from '@legalosApi/types/ISection';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import { Tooltip } from '@mui/material';
import cx from 'classnames';

export interface Props {
  activeIds: Array<string>;
  depth: number;
  onSection: (section: ISection) => void;
  section: ISection;
  index: number;
}

export function Section({ activeIds, depth, onSection, section, index }: Props) {
  const {
    currentParams: { revisit },
  } = useQuestionaireRoute();
  const isActive = activeIds.indexOf(section._id) !== -1;
  const isClickable = section.state !== QuestionnaireState.UNTOUCHED;

  let icon: IconType | null = null;
  if (section.state !== QuestionnaireState.UNTOUCHED) {
    icon = section.state === QuestionnaireState.COMPLETE ? 'check-16' : 'hourglass-16';
  }

  const questions = getQuestions([section]);
  const nQuestionsIncomplete = questions.filter((q) => q.state !== QuestionnaireState.COMPLETE).length;

  return (
    <li className="dtQuestOutline__listItem">
      <div
        className={cx('dtQuestOutline__section flex', section.state.toLowerCase(), {
          isActive,
          isClickable,
        })}
        onClick={isClickable ? () => onSection(section) : undefined}
      >
        <div>
          {nQuestionsIncomplete && revisit ? (
            <OpenQuestionsBadge n={nQuestionsIncomplete} />
          ) : icon ? (
            <Icon type={icon} />
          ) : null}
        </div>
        <div className="sectionItem flex ">
          <div className="sectionNumber">{index + 1}.</div>
          <div className="sectionTitle">{section.title}</div>
        </div>
        {/* <span>
          {index + 1}. {section.title}
        </span> */}
      </div>
    </li>
  );

  /**
   *       {isActive && section.children ? (
        <SectionList
          activeIds={activeIds}
          depth={depth + 1}
          onSection={onSection}
          sections={section.children}
        />
      ) : null}
   */
}

export function OpenQuestionsBadge({ n }: { n: number }) {
  return (
    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={`${n} offene Fragen in diesem Abschnitt`}>
      <div className="flex rounded-full bg-danger text-white px-1 absolute -left-6 w-4 h-4 text-xs font-extralight align-middle justify-center top-0.5 ">
        <span>{n}</span>
      </div>
    </Tooltip>
  );
}

import { gql } from "@apollo/client";

export const ENTITY_QUERY = gql`
  query entity($input: GetEntityInput) {
    entity(input: $input) {
      _id
      type
      data
    }
  }
`;

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { QUESTIONNAIRE_FIELDS, UserQuestionnaire } from '@dieterApi/user/useUserQuery';
import { OPEN_QUESTIONNAIRE } from './useOpenQuestionnaireMutation';

const UPGRADE_QUESTIONNAIRE = gql`
  ${QUESTIONNAIRE_FIELDS}
  mutation UpgradeQuestionnaire($localQuestionnaireId: String!) {
    upgradeQuestionnaire(id: $localQuestionnaireId) {
      ...UserQuestionnaireFields
    }
  }
`;

export interface UpgradeQuestionnaireInput {
  localQuestionnaireId: string;
}

export interface UpgradeQuestionnaireResult {
  upgradeQuestionnaire: UserQuestionnaire;
}

export function useUpgradeQuestionnaireMutation() {
  const client = useApolloClient();
  return useMutation<UpgradeQuestionnaireResult, UpgradeQuestionnaireInput>(UPGRADE_QUESTIONNAIRE, {
    update(cache, { data }) {
      if (!data) return;
      const { upgradeQuestionnaire } = data;
      cache.modify({
        id: `QuestionnaireSummary:${upgradeQuestionnaire.id}`,
        fields: {
          version: () => upgradeQuestionnaire.applicationVersion?.version || null,
          isBroken: () => upgradeQuestionnaire.isBroken,
          versionDate: () => upgradeQuestionnaire.applicationVersion?.versionDate || null,
          questionnaireId: () => upgradeQuestionnaire.questionnaireId,
        },
      });
    },
    onCompleted: async (data) => {
      await client.mutate({
        mutation: OPEN_QUESTIONNAIRE,
        variables: { localQuestionnaireId: data.upgradeQuestionnaire.id },
      });
    },
  });
}

interface ErrorFallbackProps {
  error?: Error;
}

export default function Error(props: ErrorFallbackProps) {
  return (
    <div className="2xl:container mx-auto">
      <div className="flex items-center">
        <img src="/assets/images/dieter_frage.svg" alt="Dieter grübelt" style={{ width: 200 }} />
        <div className="flex flex-col gap-6">
          <h1>Ups! Da ist wohl etwas schief gelaufen!</h1>

          <p className="">{props.error?.message}</p>
          <p className="font-semibold max-w-[400px] text-primary-root">
            Bitte lade die Seite neu und sei so nett uns eine Nachricht im Chat zu hinterlassen. Unsere Techies werden
            diesen Fehler dann so schnell wie möglich beheben!
          </p>
        </div>
      </div>
    </div>
  );
}

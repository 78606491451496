import { useUserCentrics } from '@app/hooks/useUsercentrics';
import { Button, NotificationModal } from '@components/ui';
import VirtualizedAutocomplete from '@components/ui/VirtualizedAutocomplete';
import { ISelectOption } from '@legalosApi/types/ISelectOption';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
}

export function AddTemplateModal(props: Props) {
  const { t } = useTranslation();
  const { templates, templatesLoading, addTechnology, setting } = useUserCentrics();
  const templatesForSelect: ISelectOption[] =
    templates
      ?.filter((t) => t.dataProcessor !== '' && !setting?.consentTemplates.find((ct) => ct.templateId === t.templateId))
      .map((t) => ({
        value: t.templateId,
        label: t.dataProcessor,
        origLabel: t.dataProcessor,
      })) || [];

  const [thisTemplate, setThisTemplate] = useState<ISelectOption | null>(null);

  const handleClick = () => {
    thisTemplate && addTechnology(thisTemplate.value);
    setThisTemplate(null);
    props.onClose();
  };

  return (
    <NotificationModal {...props} withCloseButton={true}>
      <div className="flex flex-col gap-4 p-8">
        <h3 className="font-medium text-xl">{t('dashboard.consentmanagement.modal')}</h3>
        <p>{t('dashboard.consentmanagement.modal_desc')}</p>
        <VirtualizedAutocomplete
          disableCloseOnSelect={false}
          className="grow"
          multiple={false}
          options={templatesForSelect}
          value={thisTemplate}
          onChange={(_, val) => {
            setThisTemplate(val as ISelectOption);
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
        <Button className="mt-4" onClick={handleClick} disabled={!thisTemplate}>
          {t('common.add')}
        </Button>
      </div>
    </NotificationModal>
  );
}

import { gql, useMutation } from '@apollo/client';

export const UPLOAD_COMPANY_LOGO = gql`
  mutation UploadCompanyLogo($file: Upload!, $size: Int!) {
    uploadCompanyLogo(file: $file, size: $size) {
      id
      logoUrl
    }
  }
`;

export interface WhitelabelConfig {
  id: string;
  logoUrl: string;
}

export interface UploadCompanyLogoInput {
  file: File;
  size: number;
}

export interface UploadCompanyLogoResult {
  uploadCompanyLogo: WhitelabelConfig;
}

export function useWhitelabelConfigLogoUploadMutation() {
  return useMutation<UploadCompanyLogoResult, UploadCompanyLogoInput>(UPLOAD_COMPANY_LOGO, {
    refetchQueries: ['GetUser'],
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
  });
}


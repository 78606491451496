import { useNavigation } from '@app/hooks/useNavigation';
import { useQueryParams } from '@app/hooks/useQuestionaireRoute';
import { DocumentShareToken, parseJwt } from '@app/utils/parseJwt';
import OutputView from '@components/block/OutputView/OutputView';
import { useGetShareTokenJWTQuery } from '@dieterApi/sharetoken/useGetShareTokenJWTQuery';
import { useEffect } from 'react';
import './documentembed.sass';
// TODO/WM/ASK: how to mock the app to show this route content
export default function DocumentEmbed() {
  const query = useQueryParams();
  const { navigation, setNavigation } = useNavigation();
  const token = query.get('token') || '';
  const { data: shareTokenJWT } = useGetShareTokenJWTQuery({
    variables: { id: token },
  });
  const jwt = shareTokenJWT?.getShareTokenJWT?.jwt;
  const tokenInfo = jwt ? parseJwt<DocumentShareToken>(jwt)! : undefined;
  // There is a timing problem in setting this to true
  // apparently the navigation context is not yet set
  useEffect(() => {
    if (navigation.isPrintView) return;
    setNavigation((nav) => void (nav.isPrintView = true));
  }, [navigation]);

  useEffect(() => {
    return () => {
      setNavigation((nav) => void (nav.isPrintView = false));
    };
  }, []);

  return (
    <div className="dtDocumentEmbed">
      {tokenInfo && (
        <OutputView
          localQuestionnaireId={tokenInfo.localQuestionnaireId}
          documentNumber={tokenInfo.documentNumber}
          token={jwt}
          onlyContent={true}
        />
      )}
    </div>
  );
}

import { gql, useMutation } from '@apollo/client';

// Define the GraphQL mutation
const ADD_DIETER_AVV = gql`
  mutation AddDieterAVV {
    addDieterAVV
  }
`;

// TypeScript interface for the result
export interface AddDieterAVVResult {
  addDieterAVV: boolean;
}

// React Hook
export function useAddDieterAVVMutation() {
  return useMutation<AddDieterAVVResult>(ADD_DIETER_AVV, {
    refetchQueries: ['GetUser'],
  });
}

import AccountMenuItems from '@components/ui/AccountMenu/AccountMenuItems.tsx';
import { useGetBillingPortalSessionQuery } from '@dieterApi/stripe/useGetBillingPortalSessionQuery';
import { User } from '@dieterApi/user/useUserQuery';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UserRoleChip } from '../UserRoleChip/UserRoleChip';
import { DeleteAccountModal } from './DeleteAccountModal';
import './accountmenu.sass';
// code-snippet from https://mui.com/material-ui/react-menu/

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  user?: User | null;
}

export default function AccountMenu({ anchorEl, handleClose, user }: Props) {
  const { t } = useTranslation();
  const [getPortalSession, { data, loading }] = useGetBillingPortalSessionQuery();
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = React.useState(false);
  const portalUrl = data?.getBillingPortalSession;

  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (open) {
      getPortalSession();
    }
  }, [anchorEl]);

  const userName = user?.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user?.email || 'N N';

  return (
    <React.Fragment>
      <Menu
        className="dtAccountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        //onClick={handleClose}
        slotProps={{
          root: {
            sx: {
              zIndex: 1300,
            },
          },
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!(!user || user.isAnonymous) && (
          <div>
            <div className=" px-4 flex flex-col gap-2 mb-3">
              <span className="text-primary-root">{userName}</span>{' '}
              <div className="flex items-center gap-2 text-sm">
                <span className="text-gray-500">{t('common.role')}</span>
                <UserRoleChip role={user?.role} />
              </div>
            </div>
            <Divider />
          </div>
        )}
        <AccountMenuItems user={user} onClose={handleClose} portalUrl={portalUrl} loading={loading} />
      </Menu>
      <DeleteAccountModal open={openDeleteAccountModal} onClose={() => setOpenDeleteAccountModal(false)} />
    </React.Fragment>
  );
}

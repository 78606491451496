import { useUser } from '@app/context/UserContext';

import cx from 'classnames';
import { Dashboard } from '../useDashboard';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useTasks } from '@app/context/TaskContext';
import { ApplicationStatus } from '@dieterApi/user/useUserQuery';
import { useState } from 'react';

import { pickLatest } from '@app/utils/pickLatest';
import { AllProcessTasks } from '@dieterApi/task/useTaskQuery';
import { useTranslation } from 'react-i18next';
import { DocumentItem } from '../Topic/Documents/DocumentItem';
import { FormItem } from '../Topic/Forms/FormItem';
import { ProcessItem } from '../Topic/Process/ProcessItem';
import { OpenTaskType, UserFlowItemType } from '../constants';
import './summary.sass';

interface Props {
  dashboard: Dashboard;
  type: UserFlowItemType | OpenTaskType;
}

type SortOptions = 'asc' | 'desc';

export function Summary({ dashboard, type }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const tasks = useTasks();

  const titleMapping = {
    [UserFlowItemType.document]: t('dashboard.summary.titles.documents'),
    [UserFlowItemType.task]: t('dashboard.summary.titles.tasks'),
    [UserFlowItemType.questionnaire]: t('dashboard.summary.titles.questionnaires'),
    [OpenTaskType.openTask]: t('dashboard.summary.titles.opentasks'),
  };

  let body: JSX.Element[] | JSX.Element | undefined;

  const [sortOrder, setSortOrder] = useState<SortOptions>('desc');

  switch (type) {
    // DOCUMENTS

    // .sort((a, b) => sortByModified(a, b, sortOrder));
    case UserFlowItemType.document:
      const questionnaireCandidates = user?.topics.flatMap((t) =>
        t.applications
          .filter((app) => (app.questionnaires?.length || 0) > 0)
          .flatMap((app) => pickLatest(app.questionnaires!.filter((q) => q.progress === 1)))
      );

      if (!questionnaireCandidates || questionnaireCandidates.length === 0) return null;
      const questionnaireIds = questionnaireCandidates.map((q) => q?.id).filter(Boolean);
      const questionnaires = user?.questionnaires
        ?.filter((q) => questionnaireIds?.includes(q.id))
        .flatMap((q) =>
          q.application.documentLabels.map((label, idx) => {
            return { idx, q };
          })
        );
      if (!questionnaires || questionnaires.length === 0) {
        body = <p key="x">{t('dashboard.summary.nodocuments')}</p>;
        break;
      }
      body = questionnaires.map((qs) => {
        return <DocumentItem key={qs.q.id + qs.idx} questionnaire={qs.q} dashboard={dashboard} index={qs.idx} />;
      });
      break;
    // QUESTIONNAIRES
    case UserFlowItemType.questionnaire:
      const apps = user?.topics
        .flatMap((t) => t.applications)
        .filter((a) => a.status === ApplicationStatus.COMPLETED)
        .flatMap((a) =>
          a.sectionLabels.map((label, idx) => {
            return { idx, a };
          })
        );

      if (!apps || apps.length === 0) {
        body = <p>{t('dashboard.summary.noquestionnaires')}</p>;
        break;
      }
      body = apps.map((as) => <FormItem key={as.a.id + as.idx} dashboard={dashboard} app={as.a} index={as.idx} />);

      break;
    // PROCESSES
    case UserFlowItemType.task:
      const doneTasks = tasks?.filter((t) => t.done && AllProcessTasks.includes(t.type));
      if (!doneTasks || doneTasks.length === 0) {
        body = <p>{t('dashboard.summary.notasks')}</p>;
        break;
      }

      body = doneTasks?.map((t) => <ProcessItem key={t.id} task={t} />);

      break;
    // OPEN TASKS
    case OpenTaskType.openTask:
      body = <></>;
      break;
    default:
      const _exhaustiveCheck: never = type;
      break;
  }

  const handleSort = (event: SelectChangeEvent<string>) => {
    setSortOrder(event.target.value as SortOptions);
  };

  return (
    <div className="dtSummary flex flex-col gap-20">
      <div className="dtSummary_header flex items-center">
        <div className={cx('icon', type)} />
        <span data-testid={`summary-${type}-title`}>{titleMapping[type]}</span>
        <div className="dtSummary_header_sort flex items-center">
          <label>{t('dashboard.summary.sort')}</label>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={sortOrder}
              onChange={handleSort}
              label={t('dashboard.summary.sort')}
              sx={{ fontSize: '12px' }}
            >
              <MenuItem value={'desc'} sx={{ fontSize: '12px' }}>
                {t('dashboard.summary.sortasc')}
              </MenuItem>
              <MenuItem value={'asc'} sx={{ fontSize: '12px' }}>
                {t('dashboard.summary.sortdesc')}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="grid-cols-1 xl:grid-cols-2 grid gap-9">{body}</div>
    </div>
  );
}

import { useUser } from '@app/context/UserContext';

import { partnerList } from '@app/utils/partnerList';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

import { useSnackbar } from '@app/hooks/useSnackbar';
import './listentry.sass';

import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { useUserValueDeleteMutation } from '@dieterApi/user/useUserValueDeleteMutation';
import { useState } from 'react';
import { Button } from '../../../../../components/ui/Button/Button';
import { NotificationModal } from '../../../../../components/ui/NotificationModal/NotificationModal';
import CheckboxList from './CheckboxList';
import { extractPartnerData } from './extractPartnerData';

interface PartnerQuickSelectModalProps {
  open: boolean;
  onClose: () => void;
}

export function PartnerQuickSelectModal({ open, onClose }: PartnerQuickSelectModalProps) {
  const { user } = useUser();
  const [expanded, setExpanded] = useState<string | false>(false);
  const allPartners = partnerList.flatMap((cat) => cat.services);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [addUserValue] = useUserValueAddMutation();
  const [deleteUserValue] = useUserValueDeleteMutation();
  const { enqueueSnackbar } = useSnackbar();
  const partnerData = (user && extractPartnerData(user).map((partner) => partner.partnerName)) || [];

  const handleToggle = (value: string, operation: 'add' | 'remove') => {
    const partner = allPartners.find((partner) => partner.service === value);
    if (!partner) return;

    switch (operation) {
      case 'add':
        addUserValue({
          variables: { key: 'DATA_PROCESSOR_NAME', value: partner.service, createIndex: true },
          context: { userId: user?.id || '' },
        })
          .then((result) => {
            const index = result.data?.addUserValue.index;
            addUserValue({
              variables: { key: 'DATA_PROCESSOR_TYPE', value: partner.contractType, index },
              context: { userId: user?.id || '' },
            });
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
          });

        break;
      case 'remove':
        deleteUserValue({
          variables: { key: 'DATA_PROCESSOR_NAME', value: partner.service },
          context: { userId: user?.id || '' },
        })
          .then((result) => {
            const index = result.data?.deleteUserValue.index;
            deleteUserValue({ variables: { key: 'DATA_PROCESSOR_TYPE', index }, context: { userId: user?.id || '' } });
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error', stack: e.stack });
          });
        break;
    }
  };
  return (
    <NotificationModal open={open} onClose={onClose}>
      <div className="dtPartnerQuickSelectModal flex flex-col gap-10">
        <h3>Schnellauswahl Vertragspartner</h3>
        <p>
          Finde hier auf dem schnellsten Weg bekannte Vertragspartner mit deren Service du arbeitest. Einfach den Haken
          beim relevanten VP setzen und bestätigen.
        </p>
        <div>
          {partnerList.map((cat) => {
            const activeServices = cat.services.filter((service) => partnerData.includes(service.service)).length;
            return (
              <MuiAccordion
                className="dtPartnerQuickSelectAccordion"
                key={cat.category}
                expanded={expanded === cat.category}
                onChange={handleChange(cat.category)}
              >
                <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
                  <div className="flex gap-10 items-center">
                    <span>{cat.category}</span> {activeServices > 0 && <div className="count">{activeServices}</div>}
                  </div>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                  <CheckboxList
                    values={cat.services.map((svc) => svc.service)}
                    currentValues={partnerData}
                    onToggle={handleToggle}
                  />
                </MuiAccordionDetails>
              </MuiAccordion>
            );
          })}
        </div>
        <div className="button">
          <Button onClick={onClose}>Bestätigen</Button>
        </div>
      </div>
    </NotificationModal>
  );
}

// mutation for creating an error

import { gql, useMutation } from '@apollo/client';

const VERIFY_MFA = gql`
  mutation VerifyMFA($token: String!) {
    verifyMFA(token: $token)
  }
`;

export interface VerifyMFAResult {
  verifyMFA: boolean;
}

export interface VerifyMFAInput {
  token: string;
}

export function useMFAVerifyMutation() {
  return useMutation<VerifyMFAResult, VerifyMFAInput>(VERIFY_MFA, {
    refetchQueries: ['GetUser'],
  });
}

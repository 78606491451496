import { Reference, gql, useMutation } from '@apollo/client';

const DELETE_SIGNATURE_REQUEST = gql`
  mutation DeleteSignatureRequest($id: String!) {
    deleteSignatureRequest(id: $id)
  }
`;

export interface DeleteSignatureRequestInput {
  id: string;
}

export interface DeleteSignatureRequestResult {
  deleteSignatureRequest: string;
}

export function useDeleteSignatureRequestMutation() {
  return useMutation<DeleteSignatureRequestResult, DeleteSignatureRequestInput>(DELETE_SIGNATURE_REQUEST, {
    optimisticResponse: (input) => ({
      deleteSignatureRequest: input.id,
    }),
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          fields: {
            getSignatureRequests(existingSignatureRequestRefs = [], { readField }) {
              const newSignatureRequests = existingSignatureRequestRefs.filter(
                (signatureRequestRef: Reference) => readField('id', signatureRequestRef) !== data.deleteSignatureRequest
              );
              return newSignatureRequests;
            },
          },
        });
      }
    },
  });
}

import { Icon } from '@components/ui';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState.ts';
import { StepLabel } from '@mui/material';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import cx from 'classnames';

import { ISection } from '@legalosApi/types/ISection.ts';

export interface OutlineProps {
  activeIds: Array<string>;
  onSection: (section: ISection) => void;
  sections: Array<ISection>;
  saveAndClose?: boolean;
  revisit?: boolean;
}
export function OutlineMobile({ activeIds, onSection, sections, revisit }: OutlineProps) {
  return (
    <div className={cx('block md:hidden', { 'mt-[40px]': revisit })}>
      <Stepper orientation="vertical" activeStep={sections.findIndex((section) => activeIds.includes(section._id))}>
        {sections.map((section) => (
          <Step
            key={section._id}
            completed={section.state === QuestionnaireState.COMPLETE}
            onClick={section.state !== QuestionnaireState.UNTOUCHED ? () => onSection(section) : undefined}
          >
            <StepLabel
              StepIconComponent={(iconProps) => (
                <div className="h-6 w-6 flex items-center justify-center">
                  {section.state !== QuestionnaireState.UNTOUCHED ? (
                    <Icon
                      className={iconProps.active ? 'text-primary-root' : 'text-slate-300'}
                      type={section.state === QuestionnaireState.COMPLETE ? 'check-16' : 'hourglass-16'}
                    />
                  ) : iconProps.active ? (
                    <div className="rounded-full h-2 w-2 bg-primary-root" />
                  ) : (
                    <div className="rounded-full h-2 w-2 bg-slate-300" />
                  )}
                </div>
              )}
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

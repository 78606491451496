import { useUser } from '@app/context/UserContext';
import { useUserUpdateLocaleMutation } from '@dieterApi/user/useUserUpdateLocaleMutation';
import LanguageIcon from '@mui/icons-material/Language';
import { Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  inHeader?: boolean;
}

export type Locales = 'de' | 'en' | 'fr';

function LangSelect({ inHeader = true }: Props) {
  const languages: Locales[] = ['de', 'en', 'fr'];
  const { user } = useUser();
  const { t, i18n } = useTranslation();
  const [setUserLocale] = useUserUpdateLocaleMutation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (lng: Locales) => {
    i18n.changeLanguage(lng);
  };

  React.useEffect(() => {
    if (i18n.language.slice(0, 2) !== user?.locale) {
      const locale = (
        ['de', 'en', 'fr'].includes(i18n.language.slice(0, 2)) ? i18n.language.slice(0, 2) : 'en'
      ) as Locales;
      setUserLocale({
        variables: {
          locale,
        },
      }).catch((e) => {});
    }
  }, [i18n.language.slice(0, 2)]);

  React.useEffect(() => {
    user?.locale && i18n.changeLanguage(user?.locale);
  }, [user?.locale]);

  return (
    <>
      <Tooltip title={t('layout.global.header.language_dropdown_button', 'Current Language')}>
        <button
          className={inHeader ? 'dtHeader__button flex items-center gap-1' : 'flex items-center gap-1'}
          onClick={handleAccountClick}
          data-testid="language-select-button"
        >
          <LanguageIcon fontSize="small" /> {i18n.language.slice(0, 2).toUpperCase()}
        </button>
      </Tooltip>
      <Menu
        className="dtAccountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        sx={{ zIndex: 9999999 }}
        onClose={handleClose}
        onClick={handleClose}
        // ... other props
      >
        {languages.map((lng) => (
          <MenuItem key={lng} selected={i18n.language.slice(0, 2).includes(lng)} onClick={() => handleClick(lng)}>
            {lng.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LangSelect;

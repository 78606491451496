import { gql, useLazyQuery } from '@apollo/client';
import { UserValue } from '@dieterApi/user/useUserQuery';

export const SCAN_RESULT_FIELDS = gql`
  fragment ScanResultFields on ScanResult {
    createdAt
    propertyId
    results
    scanFinished
    startUrl
    dpsUrl
    reportUrl
    reportPdf
    reportPdfS3
    reportPreview
  }
`;

const GET_SCAN_RESULT = gql`
  ${SCAN_RESULT_FIELDS}
  query GetScanResult($propertyId: String!) {
    getScanResult(propertyId: $propertyId) {
      ...ScanResultFields
    }
  }
`;

export interface ScanResultInput {
  propertyId: string;
}

export interface ScanResultData {
  createdAt: string;
  propertyId: string;
  results: string; // adjust this to your result structure
  scanFinished: boolean;
  startUrl: string;
  dpsUrl: string;
  reportUrl: string;
  reportPdf: string;
  reportPdfS3: string;
  reportPreview: string;
  userValues: UserValue[]; // adjust this to your result structure
}

export interface ScanResultQueryResult {
  getScanResult: ScanResultData;
}

export function useScanResultQuery() {
  return useLazyQuery<ScanResultQueryResult, ScanResultInput>(GET_SCAN_RESULT, { pollInterval: 2000 });
}

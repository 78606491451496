import { gql, useLazyQuery } from '@apollo/client';

const FILE_SHARE_TOKEN = gql`
  query ShareTokenFile(
    $type: FileShareTokenType!
    $description: String
    $fileId: String
    $topicId: String
    $userValueIndex: String
  ) {
    getFileShareToken(
      type: $type
      description: $description
      fileId: $fileId
      topicId: $topicId
      userValueIndex: $userValueIndex
    )
  }
`;

export interface FileShareTokenInput {
  type: 'download' | 'upload';
  description?: string;
  fileId?: string;
  topicId?: string;
  userValueIndex?: string;
}

export interface FileShareTokenResult {
  getFileShareToken: string;
}

export function useFileShareTokenQuery() {
  return useLazyQuery<FileShareTokenResult, FileShareTokenInput>(FILE_SHARE_TOKEN);
}

import { Tasks } from '@dieterApi/task/useTaskQuery';
import { TaskItemDone } from './TaskItemDone';

export interface Props {
  tasks?: Tasks;
}

export function DoneTaskList({ tasks }: Props) {
  const tasksReverse = tasks?.slice().reverse();

  return (
    <div className="dtTaskList">
      {/* {tasksDone && <h3>Zuletzt abgeschlossen</h3>} */}
      <div className="dtTaskList__column flex flex-col">
        {tasksReverse?.map((task) => <TaskItemDone key={task.id} task={task} dismissable={false} />)}
      </div>
    </div>
  );
}

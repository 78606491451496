import { copyToClipboard } from '@app/utils/copyToClipboard';
import { FileItem, FileUpload, Input, NotificationModal } from '@components/ui';
import { useFileShareTokenQuery } from '@dieterApi/file/useFileShareTokenQuery';
import { UserFile, UserTopic } from '@dieterApi/user/useUserQuery';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button as MuiButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { PartnerTableMeta } from './typesAndEnums';

export const RequestTomsModal = ({
  topic,
  tableMeta,
  ...props
}: {
  open: boolean;
  onClose: () => void;
  topic: UserTopic;
  tableMeta?: PartnerTableMeta;
}) => {
  // only temporary in here, before we have proper UI
  const [hasCopied, setHasCopied] = useState(false);
  const [getFileUploadToken, { data: tokenData }] = useFileShareTokenQuery();
  const [addUserValue] = useUserValueAddMutation();
  const token = tokenData?.getFileShareToken;
  const url = token ? window.location.origin + '/file?token=' + token : '';
  const index = tableMeta?.rowData[2] as string;
  const existingToms = tableMeta?.rowData[5];

  useEffect(() => {
    const index = tableMeta?.rowData[2] as string;
    const partnerName = tableMeta?.rowData[0] as string;
    index &&
      partnerName &&
      getFileUploadToken({
        variables: {
          type: 'upload',
          topicId: topic.id,
          description: `${partnerName} - Technische und Organisatorische Maßnahmen`,
          userValueIndex: index,
        },
      });
  }, [tableMeta]);

  const handleCopyClick = () => {
    copyToClipboard(url).then(() => setHasCopied(true));
  };

  const handleTomsUpload = (file: UserFile) => {
    addUserValue({
      variables: {
        key: 'DATA_PROCESSOR_TOMS',
        value: file.id,
        index,
      },
    });
  };

  return (
    <NotificationModal {...props}>
      <div className="flex flex-col gap-10 p-8">
        <h3 className="font-medium text-xl">Partner TOMs verwalten</h3>
        <p>
          Bitte versende den folgenden Link an deinen Vertragspartner. Dieser leitet ihn ohne Anmeldung zu einer
          Upload-Seite weiter. Die dort hochgeladene Datei, wird automatisch diesem Vertragspartner zugeordnet.
        </p>
        <div className="flex w-100">
          <div className="flex-1">
            <Input
              type="text"
              value={url}
              onFocus={(e) => e.target.select()}
              textSize={'small'}
              readOnly={true}
              data-testid={'input-share-url'}
            />
          </div>
          <div>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={hasCopied ? 'Kopiert!' : 'In Zwischenablage kopieren'}
            >
              <MuiButton
                onClick={handleCopyClick}
                onMouseLeave={() => setTimeout(() => setHasCopied(false), 1000)}
                data-testid={'button-copy-clipboard'}
              >
                <ContentCopyIcon />
              </MuiButton>
            </Tooltip>
          </div>
        </div>
        <div className="mt-6 flex flex-col gap-2">
          <span>Oder du lädst die Datei direkt hoch:</span>
          <FileUpload topicId="topic-av" onComplete={handleTomsUpload} />
        </div>
        {existingToms && (
          <p className="mt-6">
            <strong>Bereits hochgeladene TOMs:</strong>
            <FileItem file={existingToms} withActions={true} />
          </p>
        )}
      </div>
    </NotificationModal>
  );
};

import { useNavigation } from '@app/hooks/useNavigation';
import { User } from '@dieterApi/user/useUserQuery';
import { useEffect, useState } from 'react';
import { IMessage, MessageNames } from '../context/MessageContext';
import { NavigationState } from '../context/NavigationContext';
import { useUser } from '../context/UserContext';

import { useMessageQueue } from '@app/context/MessageQueueContext';
import { useTasks } from '@app/context/TaskContext';
import { hashObject } from '@app/utils/hashObject';
import { Tasks } from '@dieterApi/task/useTaskQuery';
// Define the state for a message
export interface IMessageState {
  isOpen: boolean; // Whether the message is currently open
  wasOpen?: boolean; // Whether the message was open before
  contextHash?: string; // The hash of the context object
}

interface IConditionContext {
  user: User | undefined;
  tasks: Tasks | undefined;
  navigation: NavigationState;
}

export interface MessageDefinition {
  name: MessageNames;
  recurring: boolean;
  condition: (context: IConditionContext) => boolean;
  order?: number;
}

/**
 * Factory function to create a custom hook for managing a message.
 *
 * @param messageName - The name of the message.
 * @param recurring - Whether the message is recurring.
 * @param evaluateCondition - Function to evaluate whether the condition for showing the message is met.
 * @returns A custom hook for managing the message.
 */
export function createUseMessageHook({ name, recurring, condition, order = 99 }: MessageDefinition) {
  // The custom hook
  return function useMessage(): IMessage {
    // Get the navigation state and the function to update it
    const { navigation, setNavigation, hasLoaded } = useNavigation();
    // Get the user state
    const { user } = useUser();
    // Get the tasks state
    const tasks = useTasks();

    const { messageQueue, enqueueMessage, dequeueMessage } = useMessageQueue();

    // Basic state for the message
    const [state, setState] = useState<IMessageState>({
      isOpen: false,
    });

    // The message object to be returned by the hook
    const message: IMessage = {
      isOpen: state.isOpen,
      wasOpen: state.wasOpen,
      open: () => setState({ ...state, isOpen: true }), // Function to open the message, while checking the condition

      close: () => {
        setNavigation((nav) => {
          if (!nav.dismissedMessages.find((m) => m.contextHash === state.contextHash && m.name === name))
            nav.dismissedMessages.push({ contextHash: state.contextHash!, name: name });
        });
        dequeueMessage(name);
        setState({ ...state, isOpen: false, wasOpen: true });
      }, // Function to close the message
      setContext: (context: any) => {
        hashObject(context).then((hash) => {
          // only the first 8 characters of the hash
          setState({ ...state, contextHash: hash.slice(0, 8) });
        });
      }, // Function to set the context for the message
    };

    // Effect to evaluate the condition and enqueue the message if the condition is met
    useEffect(() => {
      const conditionMet = condition({ user, tasks, navigation });
      const wasOpen = !!navigation.dismissedMessages.find(
        (m) => m.contextHash === state.contextHash && m.name === name
      );
      hasLoaded && setState({ ...state, wasOpen });

      if (
        conditionMet &&
        hasLoaded &&
        state.contextHash && // the context needs to be set for the message to be enqueued
        (!wasOpen || recurring)
      ) {
        enqueueMessage({ name: name, message, order });
      }
    }, [user, navigation, tasks]);

    useEffect(() => {
      // check if message is in first position of queue and then open it
      if (messageQueue.length > 0 && messageQueue[0].name === name) {
        message.open();
      }
    }, [messageQueue]);

    // Return the message object
    return message;
  };
}

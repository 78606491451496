import { NavigationSection } from '@app/context/constants';
import { parseNavState } from '@app/context/utils';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button } from '@components/ui';
import { Task } from '@dieterApi/task/useTaskQuery';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Props {
  task: Task;
  onConfirm: () => void;
}

export function SimpleProcessBody({ task, onConfirm }: Props) {
  const { t } = useTranslation();
  const { navigateDashboard } = useNavigation();
  const [addUserValue] = useUserValueAddMutation();

  // these functions are needed for callbackbackOnComplete when navigating after completing a task
  const history = useHistory();
  const setDieterDPO = () => {
    addUserValue({ variables: { key: 'IS_DIETER_DPO', value: 'Ja' } });
  };

  // The function to execute the callback from the task string
  const executeCallback = (callbackString: string) => {
    // Create a function that includes the necessary functions in scope
    const callbackFunction = new Function('setDieterDPO', 'history', callbackString);
    // Execute the function, passing in the required functions
    callbackFunction(setDieterDPO, history);
  };

  const handleClick = () => {
    if (task.navStateOnComplete) {
      const newState = parseNavState(task.navStateOnComplete);
      navigateDashboard(
        newState.activeSection || NavigationSection.Topic,
        newState.activeTopic,
        newState.activeSubSection
      );
      // setNavigation((nav) => ({ ...nav, ...newState }));
      window.scrollTo(0, 0);
    }
    if (task.callbackOnComplete) {
      executeCallback(task.callbackOnComplete);
    }
    onConfirm();
  };

  return (
    <div className="flex gap-2 w-full flex-col">
      <div className="grow">{task.confirmationText}</div>
      {!task.done && (
        <div className="flex gap-3 place-content-end">
          <div className="">
            <Button onClick={handleClick} data-testid="button-finish-task" className="text-base">
              {t('common.finish')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

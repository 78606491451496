import { gql, useQuery } from '@apollo/client';

const GET_SHARE_TOKEN_JWT = gql`
  query GetShareTokenJWT($id: String!) {
    getShareTokenJWT(id: $id) {
      id
      jwt
    }
  }
`;

export interface ShareTokenJWTInput {
  id: string;
}

export interface ShareTokenJWTResult {
  getShareTokenJWT: ShareToken;
}

export interface ShareToken {
  id: string;
  jwt: string;
}

export function useGetShareTokenJWTQuery({ variables }: { variables: ShareTokenJWTInput }) {
  return useQuery<ShareTokenJWTResult, ShareTokenJWTInput>(GET_SHARE_TOKEN_JWT, { variables, skip: !variables.id });
}

import cx from 'classnames';
import React, { useState } from 'react';
import { Icon } from '../Icon/Icon';
import { Spinner } from '../Spinner/Spinner';
import './ui-choicebutton.sass';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  spinner?: boolean;
  isSelected?: boolean;
  isHighlighted?: boolean;
}

export function ChoiceButton({
  children,
  className,
  disabled,
  onClick,
  spinner,
  isSelected = false,
  isHighlighted = false,
  ...props
}: Props) {
  const [indicator, setIndicator] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIndicator(true);
    onClick?.(e);
  };

  return (
    <div className="dtUiChoiceButtonContainer flex gap-20 ga4-checkup-step">
      <div className="flex-4">
        <button
          {...props}
          className={cx(
            'dtUiChoiceButton',
            { isSelected: isSelected || isHighlighted },
            className,
            'text-break',
            'ga4-checkup-step'
          )}
          disabled={disabled || spinner}
          onClick={disabled || spinner ? undefined : handleClick}
        >
          <div className="dtUiChoiceButton__spinner ga4-checkup-step">
            {spinner ? <Spinner color="primary" size="small" /> : null}
          </div>
          {children}
        </button>
      </div>
      <div className="flex-1 hidden md:flex">
        {/* {(isSelected || indicator) && ( */}
        <div className={cx('dtUIChoiceButton__selectIndicator flex', { indicatorVisible: isSelected || indicator })}>
          <Icon type="check-50" scale={0.45} size={30} />
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

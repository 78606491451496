import { UserApplicationQuestionnaires } from '@dieterApi/user/useUserQuery';
import { TaskItem } from './TaskItem';

import { Task } from '@dieterApi/task/useTaskQuery';
import { Divider, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskItemDone } from './TaskItemDone';

export interface TaskWithApp extends Task {
  app?: UserApplicationQuestionnaires;
}

export interface Props {
  tasks?: TaskWithApp[];
}

export function TaskList({ tasks }: Props) {
  const { t } = useTranslation();
  const MAX_TASKS = 4;
  const areTasksDone = tasks ? tasks.filter((task) => task.done && !task.isDismissed).length > 0 : false;
  const focusTask = tasks?.find((task) => task.isOverallFocus && !task.done);
  // exclude overall focus task
  const tasksLeft = tasks ? tasks.filter((task) => !task.done && !task.isDismissed && !task.isOverallFocus) : [];
  const areTasksLeft = tasksLeft.length > 0;
  const tasksReverse = tasks?.slice().reverse();
  const lastDone = tasksReverse?.find((task) => task.done);
  const isXL = useMediaQuery('(min-width: 1280px)');

  const maxTasksIncludingDone = cumulativeSum(tasks?.map((task) => (task.done ? 0 : 1)) || []).findIndex(
    (sum) => sum >= MAX_TASKS + 1
  );

  return (
    <div className="flex flex-col gap-7 mt-8">
      {focusTask && (
        <>
          <TaskItem task={focusTask} isFocusTask />
          <div id={`portal-container-${focusTask.id}`} className="col-span-full hidden -mt-9" />
        </>
      )}
      {lastDone && !lastDone.isDismissed && (
        <>
          <div>
            <h3 className="text-base text-gray-500">{t('dashboard.tasks.tasklist.lastfinished')}</h3>
            <div className="flex flex-col">
              <TaskItemDone task={lastDone} />
            </div>
          </div>
          <Divider />
        </>
      )}
      <div>
        <h3 className="text-base !text-primary-root">
          {areTasksLeft ? t('dashboard.tasks.tasklist.nexttask') : t('dashboard.tasks.tasklist.notasks')}
        </h3>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-9">
          {tasksLeft?.slice(undefined, MAX_TASKS).map((task, i, tarr) =>
            isXL ? (
              <React.Fragment key={task.id}>
                <TaskItem task={task} indentedLeft={!(i % 2)} indentedRight={!!(i % 2)} />
                {(!!(i % 2) || i + 1 === tarr.length) && (
                  <>
                    <div id={`portal-container-${task.id}`} className="col-span-full hidden -mt-9" />
                    {!!tarr[i - 1]?.id && (
                      <div id={`portal-container-${tarr[i - 1].id}`} className="col-span-full hidden -mt-9" />
                    )}
                  </>
                )}
              </React.Fragment>
            ) : (
              <div className="" key={task.id}>
                {/* <Collapse in={!task.done} key={task.id}> */}
                <TaskItem key={task.id} task={task} />
                {/* </Collapse> */}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

function cumulativeSum(array: number[]) {
  let sum = 0;
  const cumulativeArray = array.map((num) => {
    sum += num;
    return sum;
  });
  return cumulativeArray;
}

import { createContext, useContext } from 'react';
import { createUseMessageHook } from '../hooks/createUseMessageHook';

type IMessageContext = Record<MessageNames, IMessage>;

const MessageContext = createContext<IMessageContext>({} as IMessageContext);

interface Props {
  children: React.ReactNode;
}

export interface IMessage {
  isOpen: boolean;
  wasOpen?: boolean;
  open: () => void;
  close: () => void;
  setContext: (context: any) => void;
}

export type MessageNames = 'newservice' | 'tutorial' | 'multitenant' | 'fullcompletion';

// Show tutorial for new users
const useTutorial = createUseMessageHook({
  name: 'tutorial',
  recurring: false,
  condition({ user, navigation }) {
    return !!(user?.hasPassword && !navigation.dashboardLocked && !navigation.roadBlock.modalOpen);
  },
  order: 10,
});

// A user has entered a new service in the DSE tree
const useNewServiceMessage = createUseMessageHook({
  name: 'newservice',
  recurring: false,
  condition({ user }) {
    // filter by userValues from the last 24 hours
    const oneDayAgo = new Date();
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    return !!(
      user?.isSubscriber &&
      user?.userValues.filter((uv) => new Date(uv.createdAt) > oneDayAgo).find((uv) => uv.key.startsWith('NEW_SERVICE'))
    );
  },
  order: 20,
});

// a user has completed all questionnaires
const useFullCompletionMessage = createUseMessageHook({
  name: 'fullcompletion',
  recurring: false,
  condition({ navigation }) {
    return navigation.progress === 1;
  },
  order: 30,
});

const useMultiTenantInfo = createUseMessageHook({
  name: 'multitenant',
  recurring: false,
  condition({ user }) {
    return user?.company.isPrincipal === false;
  },
  order: 30,
});

export function MessageProvider({ children }: Props) {
  const newservice = useNewServiceMessage();
  const tutorial = useTutorial();
  const fullcompletion = useFullCompletionMessage();
  const multitenant = useMultiTenantInfo();

  return (
    <MessageContext.Provider value={{ newservice, tutorial, fullcompletion, multitenant }}>
      {children}
    </MessageContext.Provider>
  );
}

/**
 * Hook to access the MessageContext.
 *   This hook provides all basic functionalities to show and hide messages based on conditions
 *   of user, tasks or navigation state.
 *   IMPORTANT: A message will only be displayed if the context has been set, by the message component.
 *              This is necessary, to distinguis between messages which share the same name, but have
 *              different context based on props. The context should always be an object, which makes
 *              this message instance unique. It will be automatically transformed to a hash, which is
 *
 * @returns The current context value for MessageContext.
 */
// eslint-disable-next-line react-refresh/only-export-components -- required for context, will not hurt HMR
export function useMessages() {
  return useContext(MessageContext);
}

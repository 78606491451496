import { gql } from "@apollo/client";

export const ENTITIES_QUERY = gql`
  query entities($input: GetEntitiesInput) {
    entities(input: $input) {
      _id
      userId
      type
      data
    }
  }
`;

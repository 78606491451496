import { gql, useQuery } from '@apollo/client';
import { ISignatureRequest, SIGNATURE_REQUEST_FIELDS } from './useSignatureRequestCreateMutation';

const LIST_SIGNATURE_REQUEST = gql`
  ${SIGNATURE_REQUEST_FIELDS}
  query ListSignatureRequest($questionnaireId: String!) {
    getSignatureRequests(questionnaireId: $questionnaireId) {
      ...SignatureRequestFields
    }
  }
`;

export interface ListSignatureRequestInput {
  questionnaireId: string;
}

export interface ListSignatureRequestResult {
  getSignatureRequests: ISignatureRequest[];
}

export function useListSignatureRequestQuery({ questionnaireId }: { questionnaireId: string }) {
  return useQuery<ListSignatureRequestResult, ListSignatureRequestInput>(LIST_SIGNATURE_REQUEST, {
    variables: { questionnaireId },
  });
}

import { useMessages } from '@app/context/MessageContext';
import { useUser } from '@app/context/UserContext';
import { Button, NotificationModal } from '@components/ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function NewServiceMessage() {
  const { t } = useTranslation();
  const { newservice } = useMessages();
  const { user } = useUser();

  const newServiceNames = user?.userValues
    .filter((uv) => uv.key.startsWith('NEW_SERVICE') && !uv.key.endsWith('URL'))
    .map((uv) => uv.value);

  useEffect(() => {
    newservice.setContext(newServiceNames);
  }, []);

  return (
    <NotificationModal open={newservice.isOpen} onClose={newservice.close}>
      <div className="flex flex-col p-[30px]">
        <div className="bg-primary-root -mx-[40px] px-9 py-7 -mt-[40px] flex flex-col text-center gap-4">
          <img src="/assets/images/dieter_claim.png" alt="Info" />
          <span className="text-white font-medium text-lg">{t('dashboard.messages.new_service.title')}</span>
        </div>
        <p className="mt-5">{t('dashboard.messages.new_service.p1')}</p>
        <p>{t('dashboard.messages.new_service.p2')}</p>
        <ul className="list-disc list-inside font-semibold">
          {newServiceNames?.map((name) => <li key={name}>{name}</li>)}
        </ul>

        <p>{t('dashboard.messages.new_service.p3')}</p>
        <p className="font-semibold">{t('dashboard.messages.new_service.p4')}</p>

        <div className="pointer-events-auto bg-white my-5">
          <div className="flex flex-col items-center text-center gap-5">
            <Button onClick={newservice.close} data-testid="button-close">
              {t('dashboard.messages.new_service.button')}
            </Button>
          </div>
        </div>
      </div>
    </NotificationModal>
  );
}

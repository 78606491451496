import { animated, useSpring } from '@react-spring/web';
import cx from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Slide = React.forwardRef<HTMLDivElement, Props>(function Fade(props, ref) {
  const { children, in: open, onClick, onEnter, onExited, ownerState, className, ...other } = props;
  const style = useSpring({
    from: { transform: 'translateX(150%)' },
    to: { transform: open ? 'translateX(0%)' : 'translateX(150%)' },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div
      ref={ref}
      style={style}
      {...other}
      className={cx('h-screen -z-50 pointer-events-none focus:outline-none', className)}
    >
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

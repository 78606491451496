import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { Button } from '@components/ui';
import { LogoUpload } from '@components/ui/LogoUpload/LogoUpload';
import { useWhitelabelConfigUpsertMutation } from '@dieterApi/whitelabel/useWhitelabelConfigUpsertMutation';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import './whitelabel-configurator.scss';

export const WhitelabelConfigForm = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [hasWhitelabelPerms, setHasWhitelabelPerms] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [primaryColor, setPrimaryColor] = useState('#ffffff');
  const [secondaryColor, setSecondaryColor] = useState('#000000');
  const [logoB64, setLogoB64] = useState<string | null>(null);

  useEffect(() => {
    if (user?.companies) {
      const principalCompany = user.companies.find((company) => company.isPrincipal === true);
      if (principalCompany?.features?.includes('Whitelabel')) {
        setHasWhitelabelPerms(true);
        setPrimaryColor(principalCompany.whitelabelConfig?.primaryColor || '#ab5fce');
        setSecondaryColor(principalCompany.whitelabelConfig?.secondaryColor || '#fdd213');
        setLogoB64(principalCompany.whitelabelConfig?.logoB64);
      } else {
        setHasWhitelabelPerms(false);
      }
    }
  }, [user]);

  const [upsertWhitelabelConfig, { loading, error }] = useWhitelabelConfigUpsertMutation();

  const handlePrimaryColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrimaryColor(e.target.value);
  };

  const handleSecondaryColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecondaryColor(e.target.value);
  };

  const handleSave = async () => {
    try {
      await upsertWhitelabelConfig({
        variables: {
          primaryColor,
          secondaryColor,
        },
      });
      enqueueSnackbar(t('route.whitelabel.saveSuccess', 'Whitelabel configuration saved successfully!'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t('route.whitelabel.saveError', 'Error saving whitelabel configuration.'), { variant: 'error' });
    }
  };

  return (
    <>
      {!hasWhitelabelPerms ? (
        <Redirect to="/dashboard" />
      ) : (
        <div className="flex flex-col items-center p-4 gap-8">
          {/* Color Configurations Section */}
          <div className="flex flex-col w-full max-w-lg gap-4 bg-white p-6 rounded-lg shadow-md">
            <h1 className="text-xl font-semibold">{t('route.whitelabel.header', 'Whitelabel Configurator')}</h1>
            <p className="text-sm sm:text-base">{t('route.whitelabel.description', 'With this menu, you can configure your own custom branding for you company and any of your sub-tenants.')}</p>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 sm:gap-6">
                <div className="flex flex-col sm:items-center sm:items-start">
                  <label className="font-semibold text-sm mb-2">
                    {t('route.whitelabel.primaryColor', 'Primary Color')}
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      type="color"
                      value={primaryColor}
                      onChange={handlePrimaryColorChange}
                      className="h-10 w-10 rounded-full border border-gray-300 shadow-sm"
                    />
                    <span className="font-mono text-xs sm:text-sm">{primaryColor}</span>
                  </div>
                </div>

                <div className="flex flex-col sm:items-center sm:items-start">
                  <label className="font-semibold text-sm mb-2">
                    {t('route.whitelabel.secondaryColor', 'Secondary Color')}
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      type="color"
                      value={secondaryColor}
                      onChange={handleSecondaryColorChange}
                      className="h-10 w-10 rounded-full border border-gray-300 shadow-sm"
                    />
                    <span className="font-mono text-xs sm:text-sm">{secondaryColor}</span>
                  </div>
                </div>

                <Button
                  className="w-full sm:w-32 text-base font-semibold"
                  spinner={loading}
                  onClick={handleSave}
                  disabled={loading}
                >
                  {t('route.whitelabel.save-colors', 'Save Colors')}
                </Button>
              </div>
            </div>
          </div>

          {/* Logo Upload Section */}
          <div className="w-full max-w-lg bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-bold mb-4">{t('route.whitelabel.logoSection', 'Custom Logo')}</h2>
            <LogoUpload currentLogoB64={logoB64} />
          </div>
        </div>
      )}
    </>
  );

};

